import {
  ADD_PLANNEDMAINTENANCE,
  DELETE_BULK_PLANNEDMAINTENANCE,
  DELETE_PLANNEDMAINTENANCE,
  EDIT_PLANNEDMAINTENANCE,
  GET_PLANNEDMAINTENANCE,
  SET_PLANNEDMAINTENANCE_DETAILS,
  GET_PLANNEDMAINTENANCEDETAILS,
  // GET_PLANNEDMAINTENANCEADD
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  users: [],
  currentUser: null,
  details:null,
  addUser:null,

};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PLANNEDMAINTENANCE: {
      return {
        ...state,
        users: action.payload,
      };
    }


    // case GET_PLANNEDMAINTENANCEADD: {
    //   return {
    //     ...state,
    //     addUser: action.payload,
    //   };
    // }



    case GET_PLANNEDMAINTENANCEDETAILS: {
      return {
        ...state,
        details: action.payload,
      };
    }


    case SET_PLANNEDMAINTENANCE_DETAILS: {
      return {
        ...state,
        currentUser: action.payload,
      };
    }
    case ADD_PLANNEDMAINTENANCE: {
      return {
        ...state,
        users: [action.payload, ...state.users],
      };
    }
    case EDIT_PLANNEDMAINTENANCE: {
      return {
        ...state,
        users: state.users.map(user => (user.id === action.payload.id ? action.payload : user)),
      };
    }
    case DELETE_PLANNEDMAINTENANCE: {
      return {
        ...state,
        users: state.users.filter(user => user.id !== action.payload),
      };
    }
    case DELETE_BULK_PLANNEDMAINTENANCE: {
      return {
        ...state,
        users: state.users.filter(user => !action.payload.includes(user.id)),
      };
    }
    default:
      return state;
  }
};
