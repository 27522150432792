import React from 'react';
import { List } from '@material-ui/core';
import NavMenuItem from './NavMenuItem';
import NavSection from './NavSection';
import NavCollapse from './NavCollapse';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import {settings,Dashboard,userManagement,vendorManagement,itemManagement,budgetManagement,serviceRequests,plannedMaintenance,Compliance, Vendors, AssignedSite, PaymentsToVendors, serviceRequestCallCenter, accountDetails,ContactUs, locationManagement}from '../../../@jumbo/constants/Menubar'
import clsx from 'clsx';
import { useSelector } from 'react-redux';
// const useStyles = makeStyles(theme => ({
//   sideNavMenu: {
//     position: 'relative',
//   },
// }));
const useStyles = makeStyles(theme => ({
  sideNavMenu: {
    position: 'relative',
  },
  navHeader: {
    position: 'relative',
    padding: '24px 16px 20px 16px',
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 10,
    letterSpacing: 1.5,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      display: 'none',
    },
  },
  navSection: {
    position: 'relative',
    fontWeight: theme.typography.fontWeightRegular,
    // '&:not(:first-child) .Cmt-navHeader': {
    //   borderTop: props => `solid 1px ${props.sidebarTheme.borderColor}`,
    //   marginTop: 10,
    // },
    // '&:not(:last-child)': {
    //   '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
    //     borderBottom: props => `solid 1px ${props.sidebarTheme.borderColor}`,
    //     paddingTop: 10,
    //     paddingBottom: 10,
    //   },
    // },
  },
}));
const CmtVertical = props => {
  const { menuItems } = props;
  const classes = useStyles();
  const { authUser } = useSelector(({ auth }) => auth);
  // const {user_type, fm_module_access } = authUser
  const fm_module_access =authUser?.fm_module_access
  const userManagementAccess = fm_module_access? fm_module_access[0]?.is_view :null
  const BudgetManagementAccess = fm_module_access? fm_module_access[5]?.is_view :null
  const itemManagementAccess = fm_module_access? fm_module_access[6]?.is_view :null
  const vendorManagementAccess = fm_module_access? fm_module_access[9]?.is_view :null
  const serviceRequestAccess = fm_module_access? fm_module_access[10]?.is_view :null
  const plannedMaintenanceAccess = fm_module_access? fm_module_access[11]?.is_view :null
const locationAccess= fm_module_access? fm_module_access[4]?.is_view :null
  return (
    <List component="nav" disablePadding className={classes.sideNavMenu}>
      {/* {menuItems.map((item, index) => {
        switch (item.type) {
          case 'section':
            return <NavSection {...item} key={index} />;
          case 'collapse':
            return <NavCollapse {...item} key={index} />;
          case 'item':
            return <NavMenuItem {...item} key={index} />;
          default:
            return null;
        }
      })} */}
<List component="div" className={clsx(classes.navHeader, 'Cmt-navHeader')}>
    Menu
    </List>
    {authUser?.user_type===3 || (authUser?.user_type===4 ) ||  (authUser?.user_type===5 ) ?<NavMenuItem  {...Dashboard}  />:null}

       
      {authUser?.user_type===3 || (authUser?.user_type===4 && userManagementAccess)? <NavMenuItem {...userManagement}  />:null}
      {authUser?.user_type===3 || (authUser?.user_type===4 )? <NavMenuItem  {...settings}  />:null}
      {authUser?.user_type===3 || (authUser?.user_type===4 && locationAccess) ? <NavMenuItem  {...locationManagement}  />:null} 
       {authUser?.user_type===3 || (authUser?.user_type===4 && BudgetManagementAccess)?<NavMenuItem {...budgetManagement}  />:null}
       {authUser?.user_type===3 || (authUser?.user_type===4 && itemManagementAccess)?<NavMenuItem {...itemManagement}  />:null}
       {authUser?.user_type===3 || (authUser?.user_type===4 && vendorManagementAccess)?<NavMenuItem {...vendorManagement}  />:null}

       {authUser?.user_type===3 || (authUser?.user_type===4 && serviceRequestAccess)?<NavMenuItem {...serviceRequests}  />:null}
       {authUser?.user_type===3 || (authUser?.user_type===4 && plannedMaintenanceAccess)?<NavMenuItem {...plannedMaintenance}  />:null}
       {authUser?.user_type===5? <NavMenuItem  {...Vendors}  />:null}
       {authUser?.user_type===5? <NavMenuItem  {...AssignedSite}  />:null}
       {authUser?.user_type===5? <NavMenuItem  {...PaymentsToVendors}  />:null}
       {authUser?.user_type===6? <NavMenuItem  {...serviceRequestCallCenter}  />:null}
       {authUser?.user_type===3? <NavMenuItem  {...accountDetails}  />:null}
       {authUser?.user_type===5? null:<NavMenuItem  {...ContactUs}  />}
       {authUser?.user_type===3 || (authUser?.user_type===4 )? <NavMenuItem  {...Compliance}  />:null}
       
    </List>
  );
};

export default CmtVertical;
