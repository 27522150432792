import React, { useEffect, useRef, useState } from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import useStyles from './index.style';
import { Button } from '@material-ui/core';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import SelfHelpContent from '../SelfHelpContent/index';
import WebAssetIcon from '@material-ui/icons/WebAsset';
import LabelIcon from '@material-ui/icons/Label';
import BrandingWatermarkIcon from '@material-ui/icons/BrandingWatermark';
import ImageIcon from '@material-ui/icons/Image';
import ApartmentIcon from '@material-ui/icons/Apartment';
import AssignmentIcon from '@material-ui/icons/Assignment';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import FaceIcon from '@material-ui/icons/Face';
import { Block, CheckCircleOutline } from '@material-ui/icons';
import { Active, Inactive } from '@jumbo/constants/stringConstant';
import BuildIcon from "@material-ui/icons/Build";
import moment from 'moment';
import SettingsCellIcon from '@material-ui/icons/SettingsCell';
import ImageAspectRatioIcon from '@material-ui/icons/ImageAspectRatio';
import FlipToFrontIcon from '@material-ui/icons/FlipToFront';
// import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
import FlipToBackIcon from '@material-ui/icons/FlipToBack';
import TodayIcon from '@material-ui/icons/Today';
import OpacityIcon from '@material-ui/icons/Opacity';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import StraightenIcon from '@material-ui/icons/Straighten';
import InvertColorsOffIcon from '@material-ui/icons/InvertColorsOff';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import DescriptionIcon from '@material-ui/icons/Description';
import BugReportIcon from '@material-ui/icons/BugReport';
import ExposureIcon from '@material-ui/icons/Exposure';
import ListAltIcon from '@material-ui/icons/ListAlt';
import CropFreeIcon from '@material-ui/icons/CropFree';

const UserDetailView = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const { currentUser } = useSelector(({ ASSETLOCATION }) => ASSETLOCATION);
  const downloadBarcodeQRCode = useSelector(({ ASSETLOCATION }) => ASSETLOCATION.downloadBarcodeQRCode);
  const assetTypeArray = useSelector(({ ASSETLOCATION }) => ASSETLOCATION.assetType);
  const {
    asset_id,
    brand,
    department,
    status,
    image,
    floor,
    model,
    name,
    site_id,
    type,
    _id,
    qrcode,
    barcode,
    tenant_id,
    system_category_id,
    class_category_id,
    type_category_id,
    subtype_category_id,
    installation_date,
    replacement_cost,
    client_critical_asset,
    strategic_asset,
    life_expectancy,
    remaining_life_expectancy,
    warranty,
    description,
    items,
  } = currentUser;
  const [barQrCodeImage, setBarQrCodeImage] = useState(null);
  const [assetType, setAssetType] = useState('');
  const dispatch = useDispatch();
  const componentRef = useRef();
  console.log("currentUser",currentUser)
  console.log("downloadBarcodeQRCode",downloadBarcodeQRCode)
  useEffect(() => {
    // if(currentUser)
    // const userDetail =
    // {
    //   _id: item?._id,
    //   assetId: item?.asset_id,
    // }
    // dispatch(getAssetDownloadBarcodeQRCode(userDetail));
    const assetName = assetTypeArray?.filter(item => type === item._id);
    setAssetType(assetName[0]?.name);
  }, []);

  const handleQrCode = () => {
    if(currentUser?.qrcode_with_asset_id == null)
    {
      setBarQrCodeImage(downloadBarcodeQRCode?.data?.qrcode_with_asset_id)  
    }
    else
    {
      setBarQrCodeImage('qrCode');
    }
  };
  const handleBarCode = () => {
    if(currentUser?.barcode_with_asset_id == null)
    {
      setBarQrCodeImage(downloadBarcodeQRCode?.data?.barcode_with_asset_id)  
    }
    else
    {
      setBarQrCodeImage('barCode');
    }
    
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.userInfoRoot} id={'barcodeScanner'}>
        <Box mr={3} display="flex" alignItems="center">
          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.titleRoot}>Asset Detail</Typography>
              <Box ml={1}></Box>
            </Box>
          </Box>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}>
            <Button variant="contained" style={{ minWidth: '100px' }} color="primary" onClick={handleBarCode}>
              Barcode
            </Button>
          </Box>
          <Box ml={1}>
            <Button variant="contained" style={{ minWidth: '100px' }} color="primary" onClick={handleQrCode}>
              QR code
            </Button>
          </Box>
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box px={6} py={5}>
        {barQrCodeImage ? (
          <Dialog open={barQrCodeImage ? true : false} onClose={e => setBarQrCodeImage(null)} width="200px">
            <Typography align="center" className={classes.titleRoot} mt={5} style={{ marginTop: '20px' }}>
              {barQrCodeImage !== 'qrCode' ? 'BarCode' : 'QR Code'}
            </Typography>
            <Box
              display="flex"
              justifyContent="flex-end"
              ml={10}
              mr={10}
              mt={10}
              ref={componentRef}
              style={{ border: '1px solid black' }}>
              <Box p={5}>
                <img
                  src={barQrCodeImage === 'qrCode' ? currentUser?.qrcode_with_asset_id || downloadBarcodeQRCode?.data?.qrcode_with_asset_id : currentUser?.barcode_with_asset_id || downloadBarcodeQRCode?.data?.barcode_with_asset_id}
                  alt="qrcode"
                />
              </Box>
            </Box>
            <Box display="flex" justifyContent="flex-end" mb={4} mt={5} ml={2} mr={7}>
              <Button variant="outlined" onClick={() => setBarQrCodeImage(null)}>
                Cancel
              </Button>
              <Box ml={2}>
                <Button
                  variant="contained"
                  style={{ minWidth: '100px' }}
                  color="primary"
                  onClick={() =>
                    window.open(
                      barQrCodeImage === 'qrCode' ? currentUser?.qrcode_with_asset_id : currentUser?.barcode_with_asset_id,
                      '_self',
                    )
                  }>
                  Download
                </Button>
              </Box>
            </Box>
          </Dialog>
        ) : null}
        <GridContainer>
          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center" ml={5}>
              <WebAssetIcon />
              <Box ml={5} display="flex">
            <Typography className={classes.labelField}>Asset Name</Typography>
            <Typography className={classes.labelFieldMiddle}>:</Typography>
            <Typography className={classes.textField}>{name}</Typography>
          </Box>  
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="center" ml={5}>
            <BrandingWatermarkIcon/>
            <Box ml={5} color="primary" display='flex'>
            <Typography className={classes.labelField}>Brand</Typography>
            <Typography className={classes.labelFieldMiddle}>:</Typography>
            <Typography className={classes.textField}>{brand}</Typography> 
            </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="center" ml={5}>
            <BrandingWatermarkIcon/>
            <Box ml={5} color="primary" display='flex'>
            <Typography className={classes.labelField}>Model</Typography>
            <Typography className={classes.labelFieldMiddle}>:</Typography>
            <Typography className={classes.textField}>{model}</Typography>
            </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center" ml={5}>
              <VerticalSplitIcon/>
              <Box ml={5} display='flex'>
              <Typography className={classes.labelField}>Asset Type</Typography>
              <Typography className={classes.labelFieldMiddle}>:</Typography>
            <Typography className={classes.textField}>{assetType}</Typography>    
              </Box>
            </Box>
            </Grid>

          <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="center" ml={5}>
            <ApartmentIcon/>
            <Box ml={5} display='flex'>
            <Typography className={classes.labelField}>Floor</Typography>
            <Typography className={classes.labelFieldMiddle}>:</Typography>
            <Typography className={classes.textField}>{floor}</Typography>
             
            </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="center" ml={5}>
            <LabelIcon/>
            <Box ml={5} color="primary" display='flex'>
            <Typography className={classes.labelField}>Department</Typography>
            <Typography className={classes.labelFieldMiddle}>:</Typography>
            <Typography className={classes.textField}>{department}</Typography>
              
            </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="center" ml={5}>
            <AssignmentIcon/>
            <Box ml={5} color="primary" display='flex'>
            <Typography className={classes.labelField}>ID</Typography>
            <Typography className={classes.labelFieldMiddle}>:</Typography>
            <Typography className={classes.textField}>{asset_id}</Typography>
              
            </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="center" ml={5}>
             <FaceIcon/>
              <Box ml={5} display='flex'>
              <Typography className={classes.labelField}>Tenant</Typography>
              <Typography className={classes.labelFieldMiddle}>:</Typography>
            <Typography className={classes.textField}>{tenant_id?.first_name} {tenant_id?.last_name}</Typography>
               </Box>
            </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="center" ml={5}>
             <SettingsCellIcon />
              <Box ml={5} display='flex'>
              <Typography className={classes.labelField}>System Category</Typography>
              <Typography className={classes.labelFieldMiddle}>:</Typography>
            <Typography className={classes.textField}>{system_category_id?.name}</Typography>
               </Box>
            </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="center" ml={5}>
             <ImageAspectRatioIcon />
              <Box ml={5} display='flex'>
              <Typography className={classes.labelField}>Class Category</Typography>
              <Typography className={classes.labelFieldMiddle}>:</Typography>
            <Typography className={classes.textField}>{class_category_id?.name}</Typography>
               </Box>
            </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="center" ml={5}>
             <FlipToFrontIcon />
              <Box ml={5} display='flex'>
              <Typography className={classes.labelField}>Type Category</Typography>
              <Typography className={classes.labelFieldMiddle}>:</Typography>
            <Typography className={classes.textField}>{type_category_id?.name}</Typography>
               </Box>
            </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="center" ml={5}>
             <FlipToBackIcon />
              <Box ml={5} display='flex'>
              <Typography className={classes.labelField}>SubType Category</Typography>
              <Typography className={classes.labelFieldMiddle}>:</Typography>
            <Typography className={classes.textField}>{subtype_category_id?.name}</Typography>
               </Box>
            </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="center" ml={5}>
             <TodayIcon />
              <Box ml={5} display='flex'>
              <Typography className={classes.labelField}>Installation Date</Typography>
              <Typography className={classes.labelFieldMiddle}>:</Typography>
            <Typography className={classes.textField}>{moment(installation_date * 1000).format("DD/MM/YYYY")}</Typography>
               </Box>
            </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="center" ml={5}>
             <LocalAtmIcon />
              <Box ml={5} display='flex'>
              <Typography className={classes.labelField}>Replacement Cost</Typography>
              <Typography className={classes.labelFieldMiddle}>:</Typography>
            <Typography className={classes.textField}>$ {replacement_cost}</Typography>
               </Box>
            </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="center" ml={5}>
             <ExposureIcon />
              <Box ml={5} display='flex'>
              <Typography className={classes.labelField}>Client Critical Asset</Typography>
              <Typography className={classes.labelFieldMiddle}>:</Typography>
            <Typography className={classes.textField}>{client_critical_asset === true ? "Yes" : "No"}</Typography>
               </Box>
            </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="center" ml={5}>
             <StraightenIcon />
              <Box ml={5} display='flex'>
              <Typography className={classes.labelField}>Strategic Asset</Typography>
              <Typography className={classes.labelFieldMiddle}>:</Typography>
            <Typography className={classes.textField}>{strategic_asset === true ? "Yes" : "No"}</Typography>
               </Box>
            </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="center" ml={5}>
             <OpacityIcon />
              <Box ml={5} display='flex'>
              <Typography className={classes.labelField}>Life Expectancy</Typography>
              <Typography className={classes.labelFieldMiddle}>:</Typography>
            <Typography className={classes.textField}>{moment(life_expectancy, 'X').format('DD/MM/YYYY')}</Typography>
               </Box>
            </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="center" ml={5}>
             <InvertColorsOffIcon />
              <Box ml={5} display='flex'>
              <Typography className={classes.labelField}>Remaining Life Expectancy</Typography>
              <Typography className={classes.labelFieldMiddle}>:</Typography>
            <Typography className={classes.textField}>{remaining_life_expectancy<0 ? '0' : remaining_life_expectancy}</Typography>
               </Box>
            </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center" ml={5}>
              {status === 1 ? <CheckCircleOutline /> : <Block />}
              <Box ml={5} display='flex'>
              <Typography className={classes.labelField}>Status (Active/Inactive)</Typography>
              <Typography className={classes.labelFieldMiddle}>:</Typography>
            <Typography className={classes.textField}>{status === 1 ? Active : Inactive}</Typography></Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="center" ml={5}>
             <TimelapseIcon />
              <Box ml={5} display='flex'>
              <Typography className={classes.labelField}>Warranty</Typography>
              <Typography className={classes.labelFieldMiddle}>:</Typography>
            <Typography className={classes.textField}>{warranty?.is_taken === true ? "Yes" : "No"}</Typography>
               </Box>
            </Box>
            </Grid>

            {warranty?.is_taken === true ?
            <>
            <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="center" ml={5}>
             <TodayIcon />
              <Box ml={5} display='flex'>
              <Typography className={classes.labelField}>Warranty Take On</Typography>
              <Typography className={classes.labelFieldMiddle}>:</Typography>
            <Typography className={classes.textField}>{moment(warranty?.taken_on * 1000).format('DD/MM/YYYY')}</Typography>
               </Box>
            </Box>
            </Grid>
            
            <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="center" ml={5}>
             <TodayIcon />
              <Box ml={5} display='flex'>
              <Typography className={classes.labelField}>Warranty Expiration Date</Typography>
              <Typography className={classes.labelFieldMiddle}>:</Typography>
            <Typography className={classes.textField}>{moment(warranty?.expiration_date * 1000).format('DD/MM/YYYY')}</Typography>
               </Box>
            </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="center" ml={5}>
             <ListAltIcon />
              <Box ml={5} display='flex'>
              <Typography className={classes.labelField}>Warranty Reference Number</Typography>
              <Typography className={classes.labelFieldMiddle}>:</Typography>
            <Typography className={classes.textField}>{warranty?.reference_number}</Typography>
               </Box>
            </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
          <Box display="flex" ml={5}>
             <BugReportIcon />
              <Box ml={5} display='flex'>
              <Typography className={classes.labelField}>Warranty Details</Typography>
              <Typography className={classes.labelFieldMiddle}>:</Typography>
              <Typography className={classes.DescriptionNote}>{warranty?.details}</Typography>
               </Box>
            </Box>
            </Grid>
            </>
            : null }

          <Grid item xs={12} sm={6}>
          <Box display="flex" ml={5}>
            <ImageIcon/>
            <Box ml={5} color="primary" component="p" className={classes.labelField}>
              Asset Image : <br />{' '}
              <img src={image ? image : process.env.PUBLIC_URL + '/placeholder1.jpeg'} alt="asset image" width="200px" />
            </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
          <Box display="flex" mb={{ xs: 4, sm: 5 }} ml={5}>
             <DescriptionIcon />
              <Box ml={5} display='flex'>
              <Typography className={classes.labelField}>Description</Typography>
              <Typography className={classes.labelFieldMiddle}>:</Typography>
            <Typography className={classes.DescriptionNote}>{description}</Typography>
               </Box>
            </Box>

            <Box display="flex" ml={5}>
              <BuildIcon />
              <Box display="flex" ml={5}>
              <Typography className={classes.labelField}>Service Type</Typography>
              <Typography className={classes.labelFieldMiddle}>:</Typography>
              <Typography className={classes.textField}>{currentUser?.service_types.map((item,index) => <li className={classes.serviceTypeView} key={index} style={{ listStyleType: "none" }}>{item?.name}</li>)}</Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Box mb={{ xs: 4, sm: 5 }}>
              <Typography className={classes.titleRoot}>Added Parts : </Typography>
            </Box>
            </Grid>
            
            {items?.map(data=>
            <GridContainer>
            <Grid item xs={3} sm={3}>
          <Box display="flex" alignItems="center" ml={5} mb={5}>
             <BuildIcon />
              <Box ml={5} display='flex'>
              <Typography className={classes.partLabelField}>Part Name :</Typography>
            <Typography className={classes.partTextField}>{data?.item_id?.name}</Typography>
               </Box>
            </Box>
            </Grid>

            <Grid item xs={3} sm={3}>
          <Box display="flex" alignItems="center" ml={5} mb={5}>
             <CropFreeIcon />
              <Box ml={5} display='flex'>
              <Typography className={classes.partLabelField}>Serial No. :</Typography>
            <Typography className={classes.partTextField}>{data?.serial_number==='' ? '-' : data?.serial_number}</Typography>
               </Box>
            </Box>
            </Grid>

            <Grid item xs={3} sm={3}>
          <Box display="flex" alignItems="center" ml={5} mb={5}>
             <TodayIcon />
              <Box ml={5} display='flex'>
              <Typography className={classes.partLabelField}>Installation Date :</Typography>
            <Typography className={classes.partTextField}>{moment(data?.installation_date,'X').format("DD/MM/YYYY")}</Typography>
               </Box>
            </Box>
            </Grid>

            <Grid item xs={3} sm={3}>
          <Box display="flex" alignItems="center" ml={5} mb={5}>
             <TodayIcon />
              <Box ml={5} display='flex'>
              <Typography className={classes.partLabelField}>Expiration Date :</Typography>
            <Typography className={classes.partTextField}>{ data?.expiration_date==null ? '-' : moment(data?.expiration_date,'X').format("DD/MM/YYYY")}</Typography>
               </Box>
            </Box>
            </Grid>
            </GridContainer>
            )}

          <Grid item xs={12} sm={12}>
            <SelfHelpContent siteId={site_id} assetId={_id} />
          </Grid>

        </GridContainer>
      </Box>
    </Dialog>
  );
};

export default UserDetailView;

UserDetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
