import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Block, CheckCircleOutline, Visibility, Edit } from "@material-ui/icons";
import CheckIcon from '@material-ui/icons/Check';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import CmtDropdownMenu from "@coremat/CmtDropdownMenu";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { sentMailToUser } from "../../../../../../redux/actions/Users";
import { updateCONTRACTORStatus } from "../../../../../../redux/actions/ContractorVendorManagment";
import { Activate, Deactivate } from "@jumbo/constants/stringConstant";
import { getContractorVendorList } from '../../../../../../redux/actions/ContractorVendorManagment';
import LooksOneIcon from '@material-ui/icons/LooksOne';
import LooksTwoIcon from '@material-ui/icons/LooksTwo';
import Looks3Icon from '@material-ui/icons/Looks3';
import SwapVerticalCircleIcon from '@material-ui/icons/SwapVerticalCircle';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.text.primary,
    textOverflow: "ellipsis",
    maxWidth: "150px",
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontWeight: 300,
  },
}));

const UserListRow = ({
  row,
  onUserDelete,
  onUserView,
  setPriorityDialog,
  getFMProviderParams,
  onPendingContractorView,
  onApprovedContractorView
}) => {
  const {
    vendorId,
    orderBy,
    order,
    debouncedSearchTerm,
    rowsPerPage,
    filterOptions,
  } = getFMProviderParams;

  const { authUser } = useSelector(({ auth }) => auth);
  const classes = useStyles();
  const dispatch = useDispatch();
  const getUserActions = (user) => {

    const actions = [{ action: "view", label: "View", icon: <Visibility /> }];

    if (user.status === 1 && user?.reviewer?.priority !== 1) {
      actions.push({ action: "setPriority", label: "Set Priority", icon: <SwapVerticalCircleIcon /> });
    }
    if (user.status === 1 && authUser?.fm_module_access[1]?.is_enable) {
      actions.push({ action: "suspend", label: Deactivate, icon: <Block /> });
    } else if (authUser?.fm_module_access[1]?.is_enable) {
      actions.push({
        action: "activate",
        label: Activate,
        icon: <CheckCircleOutline />,
      });
    }
    return actions;
  };

  const onUserMenuClick = (menu) => {
    if (menu.action === "view") {
      onUserView(row);
    }
    else if (menu.action === "setPriority") {
      setPriorityDialog(row);
    }
    else if (menu.action === "email") {
      dispatch(sentMailToUser());
    } else if (menu.action === "suspend") {
      dispatch(
        updateCONTRACTORStatus({ _id: row._id, status: 2 }, refreshContactList)
      );
    } else if (menu.action === "activate") {
      dispatch(
        updateCONTRACTORStatus({ _id: row._id, status: 1 }, refreshContactList)
      );
    } else if (menu.action === "delete") {
      onUserDelete(row);
    }
  };
  function refreshContactList() {
    dispatch(getContractorVendorList(vendorId, 2, orderBy, order, debouncedSearchTerm, 0, rowsPerPage, filterOptions))
  }
  const labelId = `enhanced-table-checkbox-${row._id}`;

  const userActions = getUserActions(row);

  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
      {
        row?.reviewer?.priority === 1 ? <TableCell padding="checkbox" ml="2px"><LooksOneIcon color="secondary" /></TableCell>
          : row?.reviewer?.priority === 2 ? <TableCell padding="checkbox" ml="2px"><LooksTwoIcon color="secondary" /></TableCell>
            : row?.reviewer?.priority === 3 ? <TableCell padding="checkbox" ml="2px"><Looks3Icon color="secondary" /></TableCell>
              : <TableCell padding="checkbox"></TableCell>
      }
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {row?.first_name}
        </Typography>
      </TableCell>
      <TableCell allign="center" component="th" id={labelId} scope="row">
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {row?.last_name}
        </Typography>
      </TableCell>
      <TableCell><Typography className={classes.titleRoot} component="div" variant="h4">
        {row?.email}
      </Typography></TableCell>
      <TableCell><Typography className={classes.titleRoot} component="div" variant="h4">
        {row?.service_type_details[0]?.name}
      </Typography></TableCell>
      <TableCell>
        <Box display="flex" alignItems='center'>
          <Typography className={classes.titleRoot} component="div" variant="h4" style={{ marginRight: '5px' }}>
            {row?.status === 1 ? `Active` : "Inactive"}
          </Typography>
          {row.status === 1 ? row?.contractor_induction_views_details?.length ?
            <CheckIcon style={{ cursor: "pointer" }} onClick={() => onApprovedContractorView(row)} /> : <HourglassEmptyIcon style={{ cursor: "pointer" }} onClick={() => onPendingContractorView(row)} /> : null}
        </Box>
      </TableCell>
      <TableCell align="center" onClick={(event) => event.stopPropagation()}>
        <CmtDropdownMenu
          items={userActions}
          onItemClick={onUserMenuClick}
          TriggerComponent={<Edit />}
        />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(UserListRow);
