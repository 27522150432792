import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {  Edit, Visibility } from '@material-ui/icons';
import CmtDropdownMenu from '../../../../../@coremat/CmtDropdownMenu';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { sentMailToUser } from '../../../../../redux/actions/Users';
import { getServiceActivities, updateServiceActivitiesStatus } from '../../../../../redux/actions/ServiceActivities';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    fontWeight:"300",
    letterSpacing: 0.25,
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    maxWidth: '150px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  
}));

const getUserActions = user => {
  const actions = [{ action: 'view', label: 'View', icon: <Visibility /> }];
  return actions;
};

const UserListRow = ({ row, onUserEdit, onUserDelete, onUserView, getServiceActivitiesParams, indexId }) => {
  const { orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions } = getServiceActivitiesParams;
  const classes = useStyles();
  const dispatch = useDispatch();

  const onUserMenuClick = menu => {
    if (menu.action === 'view') {
      onUserView(row);
    } else if (menu.action === 'edit') {
      onUserEdit(row);
    } else if (menu.action === 'email') {
      dispatch(sentMailToUser());
    } else if (menu.action === 'suspend') {
      dispatch(updateServiceActivitiesStatus({ _id: row._id, status: 2 }, refreshContactList));
    } else if (menu.action === 'activate') {
      dispatch(updateServiceActivitiesStatus({ _id: row._id, status: 1 }, refreshContactList));
    } else if (menu.action === 'delete') {
      onUserDelete(row);
    }
  };
  function refreshContactList() {
    dispatch(getServiceActivities(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
  }
  const labelId = `enhanced-table-checkbox-${row._id}`;

  const userActions = getUserActions(row);

  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={row._id} >
      <TableCell padding="checkbox"></TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {indexId + 1}
        </Typography>
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {row?.first_name}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {row?.item_name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {row?.assigned_quantity}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {/* {moment(row?.assigned_date * 1000).format('DD MMM YYYY')} */}
          {moment(row?.assigned_date * 1000).format('DD/MM/YYYY')}
        </Typography>
      </TableCell>


      <TableCell align="center"  style={{ cursor: 'pointer' }}>
      <Visibility onClick={()=>onUserView(row)}/>
      </TableCell>

      {/* <TableCell align="center" onClick={event => event.stopPropagation()}>
        <CmtDropdownMenu items={userActions} onItemClick={onUserMenuClick} TriggerComponent={<Edit />} />
      </TableCell> */}
    </TableRow>
  );
};

export default React.memo(UserListRow);
