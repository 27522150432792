import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Visibility } from "@material-ui/icons";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { getUserList } from "../../../../../../redux/actions/UserManagement";

const useStyles = makeStyles((theme) => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark,
    textOverflow: "ellipsis",
    maxWidth: "150px",
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontWeight: 300,
  },
}));

const UserListRow = ({ row, onUserView, getFMProviderParams }) => {
  const { orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions } = getFMProviderParams;
  const { authUser } = useSelector(({ auth }) => auth);
  const classes = useStyles();
  const dispatch = useDispatch();
  const getUserActions = (user) => {

    const actions = !authUser?.fm_module_access[1]?.is_edit ? [{ action: "view", label: "View", icon: <Visibility /> }] : [{ action: "view", label: "View", icon: <Visibility /> }];
    return actions;
  };

  function refreshContactList() {
    dispatch(
      getUserList(
        orderBy,
        order,
        debouncedSearchTerm,
        page,
        rowsPerPage,
        filterOptions
      )
    );
  }
  const labelId = `enhanced-table-checkbox-${row._id}`;

  const userActions = getUserActions(row);

  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
      <TableCell padding="checkbox"></TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {row?.first_name}
        </Typography>
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {row?.last_name}
        </Typography>
      </TableCell>
      <TableCell><Typography className={classes.titleRoot} component="div" variant="h4">
        {row?.email}
      </Typography></TableCell>
      <TableCell><Typography className={classes.titleRoot} component="div" variant="h4">
        {row?.service_type_details[0]?.name}
      </Typography></TableCell>
      <TableCell><Typography className={classes.titleRoot} component="div" variant="h4">
        {row?.approved_status === 1 ? `Pending` : "Approved"}
      </Typography></TableCell>
      <TableCell align="center" onClick={() => onUserView(row)} style={{ cursor: 'pointer' }}>
        <Visibility />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(UserListRow);
