import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';
import { setAuthUser } from './Auth';
import { history } from 'redux/store';
import JWTAuth from 'services/auth/jwt';
import { GET_CERTIFICATELIST, SET_CURRENT_CERTIFICATE } from '../../@jumbo/constants/ActionTypes';
import { CertificateList,CertificateAdd,CertificateDelete,CertificateUpdate} from '../../@jumbo/constants/ApiConstatnt'

export const getCertificateList = ( callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      (CertificateList)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_CERTIFICATELIST, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        console.log('error',error?.response?.data)
        if (error?.response?.data?.message === 'Certificate type data not found.') {
          if (callbackFun) callbackFun();
          dispatch(fetchError());
          dispatch({ type: GET_CERTIFICATELIST, payload: [] });
        }else if(error?.response?.status===401 ){
          dispatch(setAuthUser(null));
          history.push('/signin')
        } 
        else {
          console.log('error',error?.response)
          dispatch(fetchError(error?.response?.data?.message));
        }
      });

  };
};

// set current user for view tab
export const setCurrentCertificate = user => {
  return dispatch => {
    dispatch({ type: SET_CURRENT_CERTIFICATE, payload: user });
  };
};

// add new FM provider 
export const addNewCertificate = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(CertificateAdd, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Certificate type added successfully.'));
          }, 2000);

          // dispatch({ type:  ADD_SELFHELPCONTENT, payload: data.data });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if(error?.response?.status===401 ){
          dispatch(setAuthUser(null));
          history.push('/signin')
        } else{
  
        
          dispatch(fetchError(error?.response?.data?.message));}
      });

  };
};
export const updateCertificate = (id,user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(CertificateUpdate+id, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Certificate type status updated successfully.'));
          }, 2000);

          // dispatch({ type:  ADD_SELFHELPCONTENT, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if(error?.response?.status===401 ){
          dispatch(setAuthUser(null));
          history.push('/signin')
        } else{
  
        
          dispatch(fetchError(error?.response?.data?.message));}
      });

  };
};


export const deleteCertificate = (userIds, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete(CertificateDelete+userIds)
      .then(response => {
        if (response.status === 200) {
          setTimeout(() => {
            dispatch(fetchSuccess('Certificate type deleted successfully.'));  
          }, 2000);
          
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

