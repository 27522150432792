import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import CmtAvatar from '@coremat/CmtAvatar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { useDispatch, useSelector } from 'react-redux';
import CmtList from '@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import useStyles from './index.style';
import { Block, CheckCircleOutline } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// import ApprovedContractor from '../VendorDetails/ApprovedContractor/index'
// import ContractorVerification from '../VendorDetails/ContractorVerification/index'
import BuildIcon from "@material-ui/icons/Build";
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { useParams } from 'react-router';
import { getVendorManagementDetail } from 'redux/actions/VendorManagement';
import HomeIcon from '@material-ui/icons/Home';
import FaceIcon from '@material-ui/icons/Face';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import EventIcon from '@material-ui/icons/Event';
import moment from 'moment';
import Divider from '@material-ui/core/Divider';
import ImageIcon from '@material-ui/icons/Image';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import ItemAndServiceActivityTable from './ItemAndServiceActivityTable';
import BusinessIcon from '@material-ui/icons/Business';
// import FaceIcon from '@material-ui/icons/Face';
import DescriptionIcon from '@material-ui/icons/Description';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import Rating from '@material-ui/lab/Rating';

const UserDetailView = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  let { id } = useParams();
  const dispatch = useDispatch()
  const { currentUser } = useSelector(({ WORKORDER }) => WORKORDER);
  const { details } = useSelector(({ SERVICEREQUEST }) => SERVICEREQUEST);
  const [value, setValue] = React.useState();
  const [valueError, setValueError] = React.useState('');
  // console.log("currentUser", currentUser)

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.userInfoRoot}>
        <Box mr={3} display="flex" alignItems="center">
          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.titleRoot}>Work Order Detail</Typography>
            </Box>
          </Box>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}></Box>
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>

      <GridContainer>
        <Grid item xs={6} sm={6}>
          <Box ml={10} display="flex" alignItems="center" mt={{ xs: 4, sm: 7 }}>
            <BusinessIcon />
            <Typography ml={5} component="p" className={classes.titleRoot1}> Job Location : </Typography>
            <Typography ml={5} component="p" className={classes.titleRoot2}>{currentUser ? currentUser?.location_data?.name : ''}</Typography>
          </Box>
        </Grid>

        <Grid item xs={6} sm={6}>
          <Box display="flex" alignItems="center" mt={{ xs: 4, sm: 7 }}>
            <PriorityHighIcon />
            <Typography ml={5} component="p" className={classes.titleRoot1}>Priority : </Typography>
            <Typography ml={5} component="p" className={classes.titleRoot2}>{currentUser ? currentUser?.priority : ''}</Typography>
          </Box>
        </Grid>

        <Grid item xs={6} sm={6}>
          <Box ml={10} display="flex" alignItems="center">
            <BuildIcon />
            <Typography ml={5} component="p" className={classes.titleRoot1}> Service Type : </Typography>
            <Typography ml={5} component="p" className={classes.titleRoot2}>{currentUser ? currentUser?.service_type : ''}</Typography>
          </Box>
        </Grid>

        <Grid item xs={6} sm={6}>
          <Box display="flex" alignItems="center">
            <FaceIcon />
            <Typography ml={5} component="p" className={classes.titleRoot1}> Tenant : </Typography>
            <Typography ml={5} component="p" className={classes.titleRoot2}>{currentUser ? currentUser?.tenant_details?.first_name + " " + currentUser?.tenant_details?.last_name : ''}</Typography>
          </Box>
        </Grid>

        <Grid item xs={6} sm={6}>
          <Box ml={10} display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
            <DescriptionIcon />
            <Typography ml={5} component="p" className={classes.titleRoot1}> Description : </Typography>
            <Typography ml={5} component="p" className={classes.titleRoot2}>{currentUser ? currentUser?.description : ''}</Typography>
          </Box>
        </Grid>

        <Grid item xs={6} sm={6}>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
            <AssignmentIcon />
            <Typography ml={5} component="p" className={classes.titleRoot1}> Work Order ID : </Typography>
            <Typography ml={5} component="p" className={classes.titleRoot2}>{currentUser ? currentUser?.work_order_id : ''}</Typography>
          </Box>
        </Grid>
      </GridContainer>

      <Divider variant="middle" />

      <GridContainer>

        <Grid item xs={12} sm={12}>
          <Box mb={5} color="common.dark" className={classes.titleRoot1} mt={{ xs: 4, sm: 7 }}>
            Assign Vendor :
          </Box>
        </Grid>

        <Grid item xs={6} sm={6}>
          <Box ml={10} display="flex" alignItems="center">
            <FaceIcon />
            <Typography ml={5} component="p" className={classes.titleRoot1}>Vendor : </Typography>
            <Typography ml={5} component="p" className={classes.titleRoot2}>{currentUser ? currentUser?.vendor_details?.first_name + " " + currentUser?.vendor_details?.last_name : ''}</Typography>
          </Box>
        </Grid>

        <Grid item xs={6} sm={6}>
          <Box display="flex" alignItems="center">
            <EventIcon />
            <Typography ml={5} component="p" className={classes.titleRoot1}>Due Date : </Typography>
            <Typography ml={5} component="p" className={classes.titleRoot2}>{currentUser ? moment(currentUser?.due_date * 1000).format('DD/MM/YYYY') : ''}</Typography>
          </Box>
        </Grid>

        <Grid item xs={6} sm={6}>
          <Box ml={10} display="flex" alignItems="center">
            <FaceIcon />
            <Typography ml={5} component="p" className={classes.titleRoot1}>Contractor : </Typography>
            <Typography ml={5} component="p" className={classes.titleRoot2}>{currentUser ? currentUser?.contractor_details?.first_name + " " + currentUser?.contractor_details?.last_name : ''}</Typography>
          </Box>
        </Grid>

        <Grid item xs={6} sm={6}>
          <Box display="flex" alignItems="center">
            <EventIcon />
            <Box display="flex">
              <Typography className={classes.titleRoot1}>Vendor Assignment Date : </Typography>
              <Typography className={classes.titleRoot2}>
                {currentUser ? moment(currentUser?.vendor_assigned_date * 1000).format('DD/MM/YYYY') : ''}
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={6} sm={6}>
          <Box ml={10} display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
            <EventIcon />
            <Box display="flex">
              <Typography className={classes.titleRoot1}>Work Order Date : </Typography>
              <Typography className={classes.titleRoot2}>
                {currentUser ? moment(currentUser?.created_at * 1000).format('DD/MM/YYYY') : ''}
              </Typography>
            </Box>
          </Box>
        </Grid>

      </GridContainer>

      <Divider variant="middle" />

      <GridContainer>

        <Grid item xs={12} sm={12}>
          <Box mb={5} color="common.dark" className={classes.titleRoot1} mt={{ xs: 4, sm: 7 }}>
            Service Details :
          </Box>
        </Grid>

        <Grid item xs={6} sm={6}>
          <Box ml={10} display="flex" alignItems="center">
            <EventIcon />
            <Box display="flex">
              <Typography className={classes.titleRoot1}>Service Start Date-Time : </Typography>
              <Typography className={classes.titleRoot2}>
                { }
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={6} sm={6}>
          <Box display="flex" alignItems="center">
            <LocationCityIcon />
            <Box display="flex">
              <Typography className={classes.titleRoot1}>Service Start Location: </Typography>
              <Typography className={classes.titleRoot2}>
                { }
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={6} sm={6}>
          <Box ml={10} display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
            <EventIcon />
            <Box display="flex">
              <Typography className={classes.titleRoot1}>Service End Date-Time:</Typography>
              <Typography className={classes.titleRoot2}>
                { }
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={6} sm={6}>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
            <LocationCityIcon />
            <Box display="flex">
              <Typography className={classes.titleRoot1}>Service End Location: </Typography>
              <Typography className={classes.titleRoot2}>
                { }
              </Typography>
            </Box>
          </Box>
        </Grid>

      </GridContainer>

      <Divider variant="middle" />

      <Grid item xs={12} sm={12}>
        <Box ml={10} display="flex" component="fieldset" mt={3} borderColor="transparent">
          <ThumbUpIcon />
          <Box><Typography component="legend" className={classes.titleRoot1}>Rating : </Typography></Box>
          <Box style={{ marginLeft: "15px" }}>
            <Rating
              name="size-large"
              value={value}
              size="large"
              onChange={(event, newValue) => {
                setValue(newValue);
                setValueError('');
              }}
            />
            <p className="MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-marginDense" style={{ color: 'red' }}> {valueError} </p></Box>
        </Box>
      </Grid>

      <Divider variant="middle" />

      <GridContainer>
        <Grid item xs={12} sm={12}>
          <ItemAndServiceActivityTable />
        </Grid>
      </GridContainer>

    </Dialog>
  );
};

export default UserDetailView;

UserDetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
