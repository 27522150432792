import React from 'react';
import ProjectTable from './ProjectTable';
import CmtCardHeader from '../../../../@coremat/CmtCard/CmtCardHeader';
import CmtCard from '../../../../@coremat/CmtCard';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CmtCardContent from '../../../../@coremat/CmtCard/CmtCardContent';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  cardRoot: {
    position: 'relative',
    // overflow: 'scroll',
    margin:'0px 24px 10px 24px',
    '& .Cmt-card-content': {
      padding: 0,
      paddingBottom: 24,
    },
  },
  scrollbarRoot: {
    height: 340,
  },
  badgeRoot: {
    fontSize: 14,
    letterSpacing: 0.25,
    backgroundColor: alpha(theme.palette.warning.main, 0.15),
    color: theme.palette.warning.main,
    padding: '2px 10px',
    borderRadius: 30,
  },
  header:{
    padding:'5px 0px 15px 25px',
    fontSize:'18px',
    '& .MuiTypography-h4':{
      fontSize:'18px'
    }
  },
  stickyHead:{
    '& .MuiTableCell-stickyHeader':{
      background:theme.palette.primary.main,
    },
    '& .MuiTable-stickyHeader': {
      borderCollapse: 'collapse !important' 
  },
  },
}));
// User access table used in user detail view
const CurrentProjectsSummary = () => {
  const classes = useStyles();
  const { currentUser } = useSelector(({ FMPROVIDER }) => FMPROVIDER);
  return (
    <> <CmtCardHeader className={classes.header} style={{fontSize:'18px'}} title="Module Permissions">
    </CmtCardHeader>
    <CmtCard className={classes.cardRoot}>
     
      <CmtCardContent>
        <PerfectScrollbar className={classes.scrollbarRoot}>
          <ProjectTable data={currentUser?.fm_module_access}  />
        </PerfectScrollbar>
      </CmtCardContent>
    </CmtCard>
    </>
  );
};

export default CurrentProjectsSummary;
