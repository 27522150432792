import React, { useEffect, useState } from 'react';
import { Grid, Box, Button, Typography } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import GridContainer from '@jumbo/components/GridContainer';
import AppTextInput from '../../../../@jumbo/components/Common/formElements/AppTextInput';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CmtCard from '@coremat/CmtCard';
import ColorSelectBox from './ColorSelectBox';
import AppSelectBox from '@jumbo/components/Common/formElements/AppSelectBox';
import {
  primaryColorArray,
  secondaryAndTextColorArray,
  DaysArray,
  HoursArray,
} from '../../../../@jumbo/constants/CustomizeSetting';
import { useDispatch, useSelector } from 'react-redux';
import { updateCustomization } from '../../../../redux/actions/Customization';
import CmtCardContent from '../../../../@coremat/CmtCard/CmtCardContent';
import { alphaNumaricWithSpace, emptySpace } from '@jumbo/constants/ValidationRegex';
import { requiredMessage, alphaNumaricMessage } from '@jumbo/constants/ErrorMessages';
import { getCustomizationList } from '@jumbo/constants/ApiConstatnt';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import axios from '../../../../services/auth/jwt/config';
import { setAuthUser } from 'redux/actions/Auth';
import { history } from 'redux/store';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
const useStyles = makeStyles(theme => ({
  root: {
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    "& .MuiFormLabel-root": {
      color: '#909090'
    },
    "& .MuiInputLabel-shrink":{
color:theme.palette.text.primary
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink':{
      color: theme.palette.text.primary
    },
  },
  Wrap: {
    padding: '10px',
  },
  titleRoot: {
    fontSize: '18px',
    fontWeight: 600,
    marginTop: '14',

    color: theme.palette.text.primary,
  },
  imageButton: {
    fontSize: '10px',
    marginLeft: '5px',
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  logoButton: {
    marginLeft: '10px',
  },
  gridMargin: {
    marginTop: '20px',
  },
  gridMarginTop: {
    marginTop: '5px',
  },
}));

const Index = () => {
  const classes = useStyles();
  const { authUser } = useSelector(({ auth }) => auth);
  const [name, setName] = useState('');
  const [fmLogo, setFmLogo] = useState(null);
  const [nameError, setNameError] = useState('');
  const [primaryColor, setPrimaryColor] = useState('');
  const [secondaryColor, setSecondaryColor] = useState('');
  const [textColor, setTextColor] = useState('');
  const [currencyType, setCurrencyType] = useState('1');
  const [p1Days, setP1Days] = useState(0);
  const [p1Hours, setP1Hours] = useState(0);
  const [p2Days, setP2Days] = useState(0);
  const [p2Hours, setP2Hours] = useState(0);
  const [p3Days, setP3Days] = useState(0);
  const [p3Hours, setP3Hours] = useState(0);
  const [p4Days, setP4Days] = useState(0);
  const [p4Hours, setP4Hours] = useState(0);
  const [p5Days, setP5Days] = useState(0);
  const [p5Hours, setP5Hours] = useState(0);
  const dispatch = useDispatch();
  async function fetchData() {
    let response = await axios(getCustomizationList).catch(error => {
      if (error?.response?.status === 401) {
        dispatch(setAuthUser(null));
        history.push('/signin');
      } else {
        dispatch(fetchError(error?.response?.data?.message));
      }
    });
    let user = await response?.data;
    setName(response?.data?.data?.name);
    setPrimaryColor(response?.data?.data?.primary_color);
    setSecondaryColor(response?.data?.data?.secondary_color);
    setTextColor(response?.data?.data?.font_color);
    setCurrencyType(response?.data?.data?.currency?.toString() ?? '1');
    setP1Days(response?.data?.data?.priority[0]?.day ?? 0);
    setP2Days(response?.data?.data?.priority[1]?.day ?? 0);
    setP3Days(response?.data?.data?.priority[2]?.day ?? 0);
    setP4Days(response?.data?.data?.priority[3]?.day ?? 0);
    setP5Days(response?.data?.data?.priority[4]?.day ?? 0);
    setP1Hours(response?.data?.data?.priority[0]?.hour ?? 0);
    setP2Hours(response?.data?.data?.priority[1]?.hour ?? 0);
    setP3Hours(response?.data?.data?.priority[2]?.hour ?? 0);
    setP4Hours(response?.data?.data?.priority[3]?.hour ?? 0);
    setP5Hours(response?.data?.data?.priority[4]?.hour ?? 0);
    dispatch(fetchSuccess());
  }
  useEffect(() => {
    dispatch(fetchStart());
    fetchData();
  }, []);

  const handleLogo = e => {
    setFmLogo(e.target.files[0]);
  };
  const handleUpdate = () => {
    if (!name || name.match(emptySpace)) {
      setNameError(requiredMessage);
    } else if (!name.match(alphaNumaricWithSpace)) {
      setNameError(alphaNumaricMessage);
    }
    if (name && name.match(alphaNumaricWithSpace) && !name.match(emptySpace)) {
      const formData = new FormData();

      formData.append('name', name);
      formData.append('customizeLogo', fmLogo);
      formData.append('primary_color', primaryColor);
      formData.append('secondary_color', secondaryColor);
      formData.append('font_color', textColor);
      formData.append('currency', currencyType);
      formData.append(
        'priority',
        JSON.stringify([
          { level: 1, day: p1Days, hour: p1Hours },
          { level: 2, day: p2Days, hour: p2Hours },
          { level: 3, day: p3Days, hour: p3Hours },
          { level: 4, day: p4Days, hour: p4Hours },
          { level: 5, day: p5Days, hour: p5Hours },
        ]),
      );
      dispatch(updateCustomization(formData));
    }
  };
  return (
    <CmtCard className={classes.Wrap}>
      <CmtCardContent>
        <GridContainer>
          <Grid item xs={6} sm={6} md={6}>
            <AppTextInput
              className={classes.root}
              fullWidth
              variant="outlined"
              label="Name"
              value={name}
              disabled={!authUser?.fm_module_access[3]?.is_edit}
              onChange={e => {
                setName(e.target.value);
                setNameError('');
              }}
              helperText={nameError}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.titleRoot} component="h4">
                Logo :
              </Typography>
              <Button
                variant="contained"
                component="label"
                className={classes.logoButton}
                disabled={!authUser?.fm_module_access[3]?.is_edit}>
                <input
                  type="file"
                  name="file"
                  hidden
                  accept="image/png, image/gif, image/jpeg"
                  onChange={handleLogo}
                  disabled={!authUser?.fm_module_access[3]?.is_edit}
                  color="primary"
                />
                <CloudUploadIcon />
                <p className={classes.imageButton}>{fmLogo ? fmLogo?.name : 'Choose Logo'}</p>
              </Button>
            </Box>
          </Grid>
          <Grid item xs={4} sm={3}>
            <ColorSelectBox
              fullWidth
              data={primaryColorArray}
              label="Primary Color"
              valueKey="primary"
              variant="outlined"
              labelKey="primary"
              value={primaryColor}
              disabled={!authUser?.fm_module_access[3]?.is_edit}
              onChange={e => {
                setPrimaryColor(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <ColorSelectBox
              fullWidth
              data={secondaryAndTextColorArray}
              label="Secondary Color"
              valueKey="secondary"
              variant="outlined"
              labelKey="secondary"
              value={secondaryColor}
              disabled={!authUser?.fm_module_access[3]?.is_edit}
              onChange={e => {
                setSecondaryColor(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <ColorSelectBox
              fullWidth
              data={secondaryAndTextColorArray}
              label="Font Color"
              valueKey="secondary"
              variant="outlined"
              labelKey="secondary"
              value={textColor}
              disabled={!authUser?.fm_module_access[3]?.is_edit}
              onChange={e => {
                setTextColor(e.target.value);
              }}
            />
          </Grid>
        </GridContainer>
        <GridContainer className={classes.gridMargin}>
          <Grid item xs={6} sm={6} md={6}>
            <Grid item mt={5}>
              <Typography className={classes.titleRoot} component="h4">
                Time to Complete Service :
              </Typography>
            </Grid>
            <GridContainer className={classes.gridMarginTop}>
              <Grid item>
                <Typography className={classes.label}>P1 :</Typography>
              </Grid>
              <Grid item xs={4} sm={4}>
                <AppSelectBox
                  fullWidth
                  data={DaysArray}
                  label="Days"
                  valueKey="value"
                  variant="outlined"
                  labelKey="value"
                  value={p1Days}
                  disabled={true}
                  onChange={e => {
                    setP1Days(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={4} sm={4}>
                <AppSelectBox
                  fullWidth
                  data={HoursArray}
                  label="Hours"
                  valueKey="value"
                  variant="outlined"
                  labelKey="value"
                  value={p1Hours}
                  disabled={true}
                  onChange={e => {
                    setP1Hours(e.target.value);
                  }}
                />
              </Grid>
            </GridContainer>
            <GridContainer>
              <Grid item>
                <Typography className={classes.label}>P2 :</Typography>
              </Grid>
              <Grid item xs={4} sm={4}>
                <AppSelectBox
                  fullWidth
                  data={DaysArray}
                  label="Days"
                  valueKey="value"
                  variant="outlined"
                  labelKey="value"
                  value={p2Days}
                  disabled={true}
                  onChange={e => {
                    setP2Days(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={4} sm={4}>
                <AppSelectBox
                  fullWidth
                  data={HoursArray}
                  label="Hours"
                  valueKey="value"
                  variant="outlined"
                  labelKey="value"
                  value={p2Hours}
                  disabled={true}
                  onChange={e => {
                    setP2Hours(e.target.value);
                  }}
                />
              </Grid>
            </GridContainer>

            <GridContainer>
              <Grid item>
                <Typography className={classes.label}>P3 :</Typography>
              </Grid>
              <Grid item xs={4} sm={4}>
                <AppSelectBox
                  fullWidth
                  data={DaysArray}
                  label="Days"
                  valueKey="value"
                  variant="outlined"
                  labelKey="value"
                  value={p3Days}
                  disabled={true}
                  onChange={e => {
                    setP3Days(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={4} sm={4}>
                <AppSelectBox
                  fullWidth
                  data={HoursArray}
                  label="Hours"
                  valueKey="value"
                  variant="outlined"
                  labelKey="value"
                  value={p3Hours}
                  disabled={true}
                  onChange={e => {
                    setP3Hours(e.target.value);
                  }}
                />
              </Grid>
            </GridContainer>
            <GridContainer>
              <Grid item>
                <Typography className={classes.label}>P4 :</Typography>
              </Grid>
              <Grid item xs={4} sm={4}>
                <AppSelectBox
                  fullWidth
                  data={DaysArray}
                  label="Days"
                  valueKey="value"
                  variant="outlined"
                  labelKey="value"
                  value={p4Days}
                  disabled={true}
                  onChange={e => {
                    setP4Days(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={4} sm={4}>
                <AppSelectBox
                  fullWidth
                  data={HoursArray}
                  label="Hours"
                  valueKey="value"
                  variant="outlined"
                  labelKey="value"
                  value={p4Hours}
                  disabled={true}
                  onChange={e => {
                    setP4Hours(e.target.value);
                  }}
                />
              </Grid>
            </GridContainer>
            <GridContainer>
              <Grid item>
                <Typography className={classes.label}>P5 :</Typography>
              </Grid>
              <Grid item xs={4} sm={4}>
                <AppSelectBox
                  fullWidth
                  data={DaysArray}
                  label="Days"
                  valueKey="value"
                  variant="outlined"
                  labelKey="value"
                  value={p5Days}
                  disabled={true}
                  onChange={e => {
                    setP5Days(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={4} sm={4}>
                <AppSelectBox
                  fullWidth
                  data={HoursArray}
                  label="Hours"
                  valueKey="value"
                  variant="outlined"
                  labelKey="value"
                  value={p5Hours}
                  disabled={true}
                  onChange={e => {
                    setP5Hours(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button
                  variant="contained"
                  style={{ minWidth: '100px' }}
                  disabled={!authUser?.fm_module_access[3]?.is_edit}
                  color="primary"
                  onClick={handleUpdate}>
                  SAVE
                </Button>
              </Grid>
            </GridContainer>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <Grid item xs={6} sm={6} md={6}>
              <Typography className={classes.titleRoot}>Currency :</Typography>
              <FormControl component="fieldset">
                <RadioGroup
                  onChange={e => {
                    setCurrencyType(e.target.value);
                  }}
                  name="Currency"
                  value={currencyType}>
                  <FormControlLabel value="1" control={<Radio color="primary" />} label="AUD" />
                  <FormControlLabel value="2" control={<Radio color="primary" />} label="NZD" />
                  <FormControlLabel value="3" control={<Radio color="primary" />} label="USD" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </GridContainer>
      </CmtCardContent>
    </CmtCard>
  );
};

export default Index;
