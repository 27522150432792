import React from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import EmailIcon from '@material-ui/icons/Email';
import useStyles from './index.style';
import { Block, CheckCircleOutline } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import FaceIcon from '@material-ui/icons/Face'; 

const UserDetailView = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const { currentUser } = useSelector(({ usersReducer }) => usersReducer);

  const { first_name,last_name, email, status, phones, company, designation, profile_pic, starred } = currentUser;

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.userInfoRoot}>
        <Box mr={3} display="flex" alignItems="center">
          <Box className={classes.avatarView} mr={{ xs: 4, md: 6 }}>
            <CmtAvatar size={70} src={profile_pic} alt={first_name} />
          </Box>

          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.titleRoot}>
                {first_name?.toUpperCase()}
                {''}
                {last_name?.toUpperCase()}
              </Typography>
              
            </Box>
            {(designation || company) && (
              <Box mt={-1}>
                {designation && <Typography className={classes.subTitleRoot}>{designation}</Typography>}
                {company && <Typography className={classes.subTitleRoot}>@{company}</Typography>}
              </Box>
            )}
          </Box>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}>
            <Tooltip title={status}>
              <IconButton aria-label="filter list">
                {status === 'suspended' && <Block color="primary" />}
                {status === 'active' && <CheckCircleOutline color="primary" />}
              </IconButton>
            </Tooltip>
          </Box>
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box px={6} py={5}>
        <Box mb={5} component="p" color="common.dark">
          Sub Admin Management Detail
        </Box>

        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <FaceIcon />
          {/* <SupervisorAccountIcon /> */}
          <Box ml={5} >
            <Box ml={0} component="p">
              First name : {first_name}
            </Box>{' '}
            <Box ml={0} mt={5} component="p">
              Last name : {last_name}
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <EmailIcon />
          <Box ml={5}  component="p" className="pointer">
            {email}
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }}>
          {/* <PhoneIcon /> */}
          {status === 1 ? <CheckCircleOutline  /> : <Block  />}
          <Box ml={5}>
            Status : {status  === 1? 'Active' : 'Inactive'}
            
          </Box>
        </Box>
        
      </Box>
    </Dialog>
  );
};

export default UserDetailView;

UserDetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
