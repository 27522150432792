import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import { requiredMessage } from '../../../../../@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import AppSelectBox from '../../../../../@jumbo/components/Common/formElements/AppSelectBox';
import {
  addNewAssignItem,
  addNewAssignItemData,
  getAssignItem,
  getWarehouseList,
} from '../../../../../redux/actions/AssignItem';
import AssignItemTable from './AssignItemTable/index';
import axios from 'services/auth/jwt/config';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import { history } from 'redux/store';
import { setAuthUser } from 'redux/actions/Auth';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import { GET_WAREHOUSE } from '@jumbo/constants/ActionTypes';
import UserSelectBox from './UserSelectBox'

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
    '& .MuiDialog-paperWidthSm': {
      width: '100%',
      maxWidth: '1000px',
    },
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.text.primary,
    },
  },
  root: {
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    '& .MuiFormLabel-root': {
      color: '#909090',
    },
    '& .MuiInputLabel-shrink': {
      color: theme.palette.text.primary,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      color: theme.palette.text.primary,
    },
  },
}));

const AddEditUser = ({ open, onCloseDialog, getServiceActivitiesParams }) => {
  const classes = useStyles();
  const { users } = useSelector(({ VENDORMANAGEMENT }) => VENDORMANAGEMENT);
  const { currentUser } = useSelector(({ SERVICEACTIVITIES }) => SERVICEACTIVITIES);
  const { warehouse } = useSelector(({ ASSIGNITEM }) => ASSIGNITEM);
  // console.log("inventory",warehouse)
  // const Location = useSelector(({ LOCATION }) => LOCATION.users);
  const { orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions } = getServiceActivitiesParams;
  const [warehouseValue, setWarehouseValue] = useState('');
  const [venderValue, setVenderValue] = useState('');
  const [vendertypeError, setVenderTypeError] = useState('');
  const [warehouseTypeError, setWarehouseTypeError] = useState('');
  const [getData, setGetData] = useState([]);
  const [getItemId, setGetItemId] = useState();
  const [wareHouseData, setWareHouseData] = useState([]);
  const [nodataError, setNodataError] = useState('');
  const [noFlagdataError, setNoFlagdataError] = useState([]);
  const [open1, setOpen1] = React.useState(false);
  const dispatch = useDispatch();
  
// console.log("eror",getData)
let newData = getData?.filter(item => item.items_serial_number.length);
console.log("eror",newData  )
  const handleClose = () => {
    onUserSave(getData);
  };
  useEffect(() => {
    if (venderValue) {
      dispatch(getWarehouseList(venderValue));
    }
  }, [venderValue]);

  useEffect(() => {
    return () => {
      dispatch({ type: GET_WAREHOUSE, payload: null });
    };
  }, []);

  useEffect(() => {
    if (warehouseValue) {
      dispatch(addNewAssignItem(dataSet));
    }
  }, [warehouseValue]);

  const onSubmitClick = () => {
    if (!venderValue) {
      setVenderTypeError(requiredMessage);
    }
    if (!warehouseValue) {
      setWarehouseTypeError(requiredMessage);
    }
    if (venderValue && warehouseValue && !newData.length) {
      
      setNodataError('Please check the at least one checkbox the available part.');
    }
    // else if (noFlagdataError.length) {
    //   setNodataError(
    //     'Please enter the quantity value for at least one part and the value should be positive number and less than the available quantity.',
    //   );
    // }

    if (newData.length > 0 && venderValue && warehouseValue) {
      setOpen1(true);
    }
  };

  const dataSet = {
    vendor: venderValue,
    warehouse: warehouseValue,
  };

  const onUserSave = selectedItem => {
    let newData = getData?.filter(item => item.items_serial_number.length);
    const serviceActivityData = {
      items: newData,
    };

    if (currentUser) {
      dispatch();
    } else {
      dispatch(
        addNewAssignItemData(venderValue, warehouseValue, serviceActivityData, () => {
          onCloseDialog();
          dispatch(getAssignItem(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
        }),
      );
    }
  };

  const handelCancelClick = () => {
    setWarehouseValue('');
    onCloseDialog();
  };
  return (
    <>
      <Dialog open={open} onClose={handelCancelClick} className={classes.dialogRoot}>
        <DialogTitle className={classes.dialogTitleRoot}>
          {currentUser ? 'Edit Assign Parts' : 'Assign New Parts'}
        </DialogTitle>
        <Box>
          <Dialog
            open={open1}
            onClose={() => setOpen1(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogContent>
              <DialogContentText id="alert-dialog-description">Are you sure you want to assign Part ?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen1(false)} color="primary" variant="outlined">
                Cancel
              </Button>
              <Button onClick={handleClose} variant="contained" color="primary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
        <DialogContent dividers>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12} className={classes.root}>
                {/* <InputLabel
                  id="demo-simple-select-label"
                  style={{ marginBottom: '1%', marginLeft: 'auto', fontWeight: '700' }}>
                  Vendor
                </InputLabel> */}
                <UserSelectBox
                  // className={classes.root}
                  label="Vendor"
                  fullWidth
                  data={users?.data}
                  valueKey="_id"
                  variant="standard"
                  labelKey="first_name"
                  value={venderValue}
                  onChange={e => {
                    setVenderValue(e.target.value);
                    setVenderTypeError('');
                  }}
                  helperText={vendertypeError}
                />
              </Grid>
              <Grid item xs={12} sm={12} className={classes.root}>
                {/* <InputLabel
                  id="demo-simple-select-label"
                  style={{ marginBottom: '1%', marginLeft: 'auto', fontWeight: '700'}}>
                  Inventory Site
                </InputLabel> */}
                <AppSelectBox
                  fullWidth
                  data={warehouse?.data}
                  label="Inventory Site"
                  valueKey="_id"
                  variant="standard"
                  labelKey="name"
                  value={warehouseValue}
                  onChange={e => {
                    setWarehouseValue(prev => e.target.value);
                    setWarehouseTypeError('');
                  }}
                  helperText={warehouseTypeError}
                />
              </Grid>
            </GridContainer>
          </Box>
          <AssignItemTable
            setGetData={setGetData}
            setNodataError={setNodataError}
            // setNoFlagdataError={setNoFlagdataError}
            // noFlagdataError={noFlagdataError}
          />
          <p style={{ color: 'red' }}>{nodataError}</p>
          <br />
          <Box display="flex" justifyContent="flex-end" mb={4}>
            <Button onClick={handelCancelClick} variant="outlined">
              Cancel
            </Button>

            <Box ml={2}>
              <Button
                variant="contained"
                color="primary"
                style={{ minWidth: '100px' }}
                onClick={onSubmitClick}
                disabled={!getData}>
                {currentUser ? 'Update' : 'Assign'}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddEditUser;

AddEditUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
