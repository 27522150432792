import React, { useEffect, useState } from 'react';
import { Paper, Table, TableCell, TableContainer, TableRow } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import UserListRow from './UserListRow';
import UserTableHead from './UserTableHead';
import UserTableToolbar from './UserTableToolbar';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentFMProvider } from '../../../../redux/actions/UserManagement';
import { deleteLocation, geLocationList, updateLocationStatus } from '../../../../redux/actions/Locations';
import AddEditUser from './AddEditUser';
import ConfirmDialog from '@jumbo/components/Common/ConfirmDialog';
import { useDebounce } from '@jumbo/utils/commonHelper';
import useStyles from './index.style';
import UserDetailView from './UserDetailView';
import NoRecordFound from './NoRecordFound';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
// import LocationSearch from './LocationSearch/index'
import UserUpdate from './UpdateUser';
import { getSERVICETYPE } from '../../../../redux/actions/ServiceType';
import { getWarehouseList } from '../../../../redux/actions/Locations';
import MapView from './MapView/index';
import { history } from 'redux/store';
const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'Portfolio Location', isActive: true },
];

const Locations = () => {
  const classes = useStyles();
  const { users } = useSelector(({ LOCATION }) => LOCATION);
  const [orderBy, setOrderBy] = React.useState('name');
  const [order, setOrder] = React.useState('asc');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState({ name: '' });
  const [usersFetched, setUsersFetched] = useState(false);
  const [isFilterApplied, setFilterApplied] = useState(false);
  const [filterOptions, setFilterOptions] = React.useState([]);
  const [locationOptions, setLocationOptions] = React.useState([]);
  const [warehouseFilter,setWarehouseFilter] = useState(2);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchedData, setSearchedData] = useState('debouncedSearchPagevalidation');
  const [listView, setListView] = useState(1);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const dispatch = useDispatch();
  // calling FM Provider list api when component mount , with callback function
  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm !== searchedData) {
      dispatch(
        geLocationList(orderBy, order, debouncedSearchTerm, 0, rowsPerPage, filterOptions, locationOptions, () => {
          setFilterApplied(!!debouncedSearchTerm);
          setUsersFetched(true);
        }),
      );
      setSearchedData(debouncedSearchTerm);
      setPage(0);
    } else {
      if (listView === 1) {
        dispatch(
          geLocationList(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions, locationOptions, () => {
            setFilterApplied(!!debouncedSearchTerm);
            setUsersFetched(true);
          }),
        );
      } else {
        dispatch(geLocationList('name', 'asc', '', 0, -1, [], []));
      }
    }
    dispatch(
      getWarehouseList(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions, locationOptions, warehouseFilter, () => {
      }),
    );
    dispatch(getSERVICETYPE('name', 'asc', [1], '', 0, -1));
  }, [dispatch, filterOptions, locationOptions, debouncedSearchTerm, page, rowsPerPage, order, orderBy, listView]);
  const handleCloseUserDialog = () => {
    setOpenUserDialog(false);
    dispatch(setCurrentFMProvider(null));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = users.map(n => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleRowClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUserView = user => {
    window.localStorage.setItem('L_S_W_t', user?.type);
    history.push(`/location-management/${user._id}`);
    // dispatch(setCurrentFMProvider(user));
    // setOpenViewDialog(true);
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
    dispatch(setCurrentFMProvider(null));
  };
  const handleUserUpdate = user => {
    dispatch(setCurrentFMProvider(user));
    setOpenUpdateDialog(true);
  };
  const handleCloseUpdateDialog = () => {
    setOpenUpdateDialog(false);
    dispatch(setCurrentFMProvider(null));
  };

  const handleUserEdit = user => {
    dispatch(setCurrentFMProvider(user));
    setOpenUserDialog(true);
  };

  const handleDelete = user => {
    setSelectedUser(user);
    setOpenConfirmDeleteDialog(true);
  };
  const handleConfirmDelete = () => {
    setOpenConfirmDeleteDialog(false);
    dispatch(deleteLocation(selectedUser._id, refreshContactList));
  };
  const handleForceInactive = user => {
    setSelectedUser(user);
    setOpenConfirmDialog(true);
  };

  const handleConfirmForceInactive = () => {
    setOpenConfirmDialog(false);
    dispatch(updateLocationStatus({ _id: selectedUser._id, status: 2, is_force_update: true }, refreshContactList));
  };

  function refreshContactList() {
    dispatch(geLocationList(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions, locationOptions));
  }
  const handleCancelForceInactive = () => {
    setOpenConfirmDialog(false);
  };
  const handleCancelDelete = () => {
    setOpenConfirmDeleteDialog(false);
  };
  const isSelected = id => selected.indexOf(id) !== -1;

  return (
    <PageContainer heading="Portfolio Location" breadcrumbs={breadcrumbs}>
    <div className={classes.root}>
      {listView === 1 ? (
        <Paper className={classes.paper}>
          <UserTableToolbar
            selected={selected}
            setSelected={setSelected}
            onUserAdd={setOpenUserDialog}
            filterOptions={filterOptions}
            setFilterOptions={setFilterOptions}
            locationOptions={locationOptions}
            setLocationOptions={setLocationOptions}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            setSearchedData={setSearchedData}
            setPage={setPage}
            setListView={setListView}
            listView={listView}
          />
          <TableContainer className={classes.container}>
            <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" aria-label="sticky enhanced table">
              <UserTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={users?.total ?? 1}
              />
              <TableBody>
                {!!users?.data?.length ? (
                  users?.data
                    ?.slice(0 * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <UserListRow
                        indexId={page * rowsPerPage + index}
                        key={index}
                        row={row}
                        onRowClick={handleRowClick}
                        onUserEdit={handleUserEdit}
                        onForceInactive= {handleForceInactive}
                        onUserDelete={handleDelete}
                        onUserView={handleUserView}
                        onUserUpdate={handleUserUpdate}
                        isSelected={isSelected}
                        getFMProviderParams={{ orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions, locationOptions}}
                      />
                    ))
                ) : (
                  <TableRow style={{ height: 53 * 6 }}>
                    <TableCell colSpan={7} rowSpan={10}>
                      {isFilterApplied ? (
                        <NoRecordFound>There are no records found.</NoRecordFound>
                      ) : (
                        <NoRecordFound>{usersFetched ? 'There are no records found.' : 'Loading data...'}</NoRecordFound>
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={users?.total ?? 1}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Paper>
      ) : (
        <Paper className={classes.paper}>
          <UserTableToolbar
            selected={selected}
            setSelected={setSelected}
            onUserAdd={setOpenUserDialog}
            filterOptions={filterOptions}
            setFilterOptions={setFilterOptions}
            locationOptions={locationOptions}
            setLocationOptions={setLocationOptions}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            setSearchedData={setSearchedData}
            setPage={setPage}
            setListView={setListView}
          />
          <MapView />
        </Paper>
      )}

      {openUserDialog && (
        <AddEditUser
          open={openUserDialog}
          onCloseDialog={handleCloseUserDialog}
          getFMProviderParams={{ orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions, locationOptions }}
        />
      )}
      {openViewDialog && <UserDetailView open={openViewDialog} onCloseDialog={handleCloseViewDialog} />}
      {openUpdateDialog && (
        <UserUpdate
          open={openUpdateDialog}
          onCloseDialog={handleCloseUpdateDialog}
          getFMProviderParams={{ orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions, locationOptions }}
        />
      )}
      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm Inactive`}
        content={'If you Inactive this job location, all the service request and planned maintenance created for this job location will be deleted, do you want to  inactive this location?'}
        onClose={handleCancelForceInactive}
        onConfirm={handleConfirmForceInactive}
      />
       <ConfirmDialog
        open={openConfirmDeleteDialog}
        btnLabels={
          {
            cancel: 'Cancel',
            confirm: 'Delete',
          }
        }
        title={`Delete Location?`}
        content={selectedUser?.can_be_deleted && selectedUser?.type===1 ?'By deleting this location all the service requests and planned maintenance of this location will also be deleted, you will not be able to recover records.':'You will not be able to recover records.'}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </div>
    </PageContainer>
  );
};

export default Locations;
