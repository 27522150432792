import React from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import useStyles from './index.style';
import { Block, CheckCircleOutline } from '@material-ui/icons';
import BuildIcon from '@material-ui/icons/Build';
import DescriptionIcon from '@material-ui/icons/Description';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
const UserDetailView = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const { currentUser } = useSelector(({ SERVICEACTIVITIES }) => SERVICEACTIVITIES);
  const { name, fm_service_activity_status, service_type_name, fm_service_activity_charge } = currentUser;

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.userInfoRoot}>
        <Box mr={3} display="flex" alignItems="center">
          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.titleRoot}>Service Activity Detail</Typography>
            </Box>
          </Box>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box px={6} py={5}>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <BuildIcon />
          <Box ml={5} color="primary"  display="flex">
            <Typography className={classes.titleRoot1}>Service Type : </Typography>
            <Typography className={classes.titleRoot2}>{service_type_name}</Typography>
          </Box>
        </Box>

        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <MonetizationOnIcon />
          <Box ml={5}  display="flex">
            <Typography className={classes.titleRoot1}>Charges : </Typography>
            <Typography className={classes.titleRoot2}>
              {fm_service_activity_charge == null ? '-' : `$${fm_service_activity_charge}`}
            </Typography>
          </Box>{' '}
        </Box>

        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <DescriptionIcon />
          <Box ml={5}  display="flex">
            <Typography className={classes.titleRoot1}>Description : </Typography>
            <Typography className={classes.titleRoot2}>{name}</Typography>
          </Box>{' '}
        </Box>

        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }}>
          {fm_service_activity_status === 1 ? <CheckCircleOutline /> : <Block />}
          <Box ml={5}  display="flex">
            <Typography className={classes.titleRoot1}>Status (Active/Inactive) : </Typography>
            <Typography className={classes.titleRoot2}>
              {fm_service_activity_status === 1 ? 'Active' : 'Inactive'}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default UserDetailView;

UserDetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
