import { SET_DASHBOARD_DATA, GET_DASHBOARD, GET_DASHBOARD_CONTRACTORAPPLICATION, GET_ACCOUNT_DASHBOARD } from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  dashboardData: [],
  contractorList: [],
  // users,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_DASHBOARD_DATA: {
      return { ...state, dashboardData: action.payload };
    }
    case GET_DASHBOARD: {
      return {
        ...state,
        users: action.payload,
      };
    }
    case GET_DASHBOARD_CONTRACTORAPPLICATION: {
      return {
        ...state,
        contractorList: action.payload,
      };
    }
    case GET_ACCOUNT_DASHBOARD: {
      return {
        ...state,
        users: action.payload,
      };
    }
    default:
      return state;
  }
};
