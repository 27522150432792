import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Block, CheckCircleOutline, Visibility, Edit, Delete } from '@material-ui/icons';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import { Checkbox, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { sentMailToUser } from 'redux/actions/Users';
import { Activate, Deactivate } from '@jumbo/constants/stringConstant';
import { getSelfHelpContent, updateSelfHelpContent } from 'redux/actions/SelfHelpContent';
const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    fontWeight:300,
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    maxWidth: '220px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  checkBox:{
    padding: '0 100px 0 10px'
  },
  textSize:{
    fontWeight:300
  }
}));

const UserListRow = ({
  row,
  isSelected,
  onRowClick,
  onUserEdit,
  onUserDelete,
  onUserView,
  getFMProviderParams,
  onUserUpdate,
  indexId,
  siteId,
  assetId
}) => {
  const { id, orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions } = getFMProviderParams;
  const { authUser } = useSelector(({ auth }) => auth);
  const classes = useStyles();
  const dispatch = useDispatch();
  const getUserActions = user => {
    const actions =  !authUser?.fm_module_access[4]?.is_enable? [{ action: 'view', label: 'View', icon: <Visibility /> }]:[{ action: 'view', label: 'View', icon: <Visibility /> }
  ]

    if (user.status === 1 && authUser?.fm_module_access[4]?.is_enable) {
      actions.push({ action: 'suspend', label: Deactivate, icon: <Block /> });
    } else if (authUser?.fm_module_access[4]?.is_enable) {
      actions.push({
        action: 'activate',
        label: Activate,
        icon: <CheckCircleOutline />,
      });
    }
if(authUser?.fm_module_access[4]?.is_enable)
{
  actions.push({ action: 'delete', label: 'Delete', icon: <Delete /> })
}
    return actions;
  };
  const onUserMenuClick = menu => {
    if (menu.action === 'view') {
      onUserView(row);
    }  else if (menu.action === 'edit') {
      onUserEdit(row);
    } else if (menu.action === 'email') {
      dispatch(sentMailToUser());
    } else if (menu.action === 'suspend') {
      dispatch(updateSelfHelpContent({ _id: row._id, status: 2 }, refreshContactList));
    } else if (menu.action === 'activate') {
      dispatch(updateSelfHelpContent({ _id: row._id, status: 1 }, refreshContactList));
    } else if (menu.action === 'delete') {
      onUserDelete(row);
    }
  };
  function refreshContactList() {
    dispatch(getSelfHelpContent(siteId,assetId, orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
  }
  const labelId = `enhanced-table-checkbox-${row._id}`;

  const userActions = getUserActions(row);

  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
      <TableCell padding="checkbox"></TableCell>
      <TableCell>{Number(indexId)+1}</TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {row?.title}
        </Typography>
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {row?.doc_type===1?'Document':'Video'}
        </Typography>
      </TableCell>
      <TableCell><Typography className={classes.titleRoot} component="div" variant="h4">
      {row?.issue_name}
        </Typography></TableCell>

      <TableCell padding="checkbox" className={classes.checkBox}><Checkbox checked={row?.is_tenant_visible} name="checkedA" /></TableCell>
      <TableCell  padding="checkbox" className={classes.checkBox}><Checkbox checked={row?.is_vendor_contractor_visible} name="checkedA" /></TableCell>
      <TableCell className={classes.textSize}>{row?.status === 1 ? `Active` : 'Inactive'}</TableCell>
      <TableCell  align="center" onClick={event => event.stopPropagation()}>
        <CmtDropdownMenu items={userActions} onItemClick={onUserMenuClick} TriggerComponent={<Edit />} />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(UserListRow);
