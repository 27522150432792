import React from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import BuildIcon from '@material-ui/icons/Build';
import { useSelector } from 'react-redux';
import CmtList from '../../../../../@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import useStyles from './index.style';
import { Block, CheckCircleOutline } from '@material-ui/icons';
import UserTable from './UserTable/index';
import DescriptionIcon from '@material-ui/icons/Description';
import AttachMoneySharpIcon from '@material-ui/icons/AttachMoneySharp';
import ImageIcon from '@material-ui/icons/Image';
import FaceIcon from '@material-ui/icons/Face';
import BrandingWatermarkIcon from '@material-ui/icons/BrandingWatermark';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import Grid from '@material-ui/core/Grid';
import GridContainer from '@jumbo/components/GridContainer';

const UserDetailView = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const { details } = useSelector(({ ITEMSLISTMANAGEMENT }) => ITEMSLISTMANAGEMENT);
  
  const { name, description, charge, status, image ,model,make,life_expectancy,manufacturer_details} = details;

  // const service_types = details?.service_type_details?.map((item) => item.name);

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.userInfoRoot}>
        <Box mr={3} display="flex" alignItems="center">
          <Box className={classes.avatarView} mr={{ xs: 4, md: 6 }}></Box>

          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.titleRoot}>Part Detail</Typography>
              <Box ml={1}></Box>
            </Box>
          </Box>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}></Box>
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box px={6} py={5}>
        {/* <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <BuildIcon />
          <Box ml={5}  display="flex">
            <Typography className={classes.titleRoot1}>Part Name : </Typography>
            <Typography className={classes.titleRoot2}>{name}</Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <DescriptionIcon />
          <Box ml={5}  display="flex">
            <Typography className={classes.titleRoot1}>Description : </Typography>
            <Typography className={classes.titleRoot2}>{description}</Typography>
          </Box>
        </Box>

        <Box display="flex" mb={{ xs: 4, sm: 7 }}>
          <ImageIcon />
          <Box ml={5} >
            <Typography className={classes.titleRoot1}>Part Image :</Typography>
            
            <Typography className={classes.titleRoot2}>
              <img src={image} width="200" style={{ marginTop: '5%' }} />
            </Typography>
          </Box>
        </Box>

        <Box display="flex" mb={{ xs: 4, sm: 7 }}>
          <BuildIcon />
          <Box ml={5} display="flex" color="primary">
          <Typography className={classes.titleRoot1}>Service Type :{' '}</Typography>
          <Typography className={classes.titleRoot2}>
              {' '}
              {details?.service_type_details.map((item, index) => (
                <li key={index} className={classes.serviceTypeView} style={{ listStyleType: 'none' }}>
                  {item?.name}
                </li>
              ))}
            </Typography>
            
          </Box>
        </Box> */}
        

        <Box px={6} py={5}>
        <GridContainer>
          <Grid item xs={6} sm={6}>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <BuildIcon />
          <Box ml={5}  display="flex">
            <Typography className={classes.titleRoot1}>Part Name : </Typography>
            <Typography className={classes.titleRoot2}>{name}</Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <DescriptionIcon />
          <Box ml={5}  display="flex">
            <Typography className={classes.titleRoot1}>Description : </Typography>
            <Typography className={classes.titleRoot2}>{description}</Typography>
          </Box>
        </Box>
        <Box display="flex" mb={{ xs: 4, sm: 7 }}>
          <ImageIcon />
          <Box ml={5} >
            <Typography className={classes.titleRoot1}>Part Image :</Typography>
            
            <Typography className={classes.titleRoot2}>
              <img src={image} width="200" style={{ marginTop: '5%' }} />
            </Typography>
          </Box>
        </Box>

        <Box display="flex" mb={{ xs: 4, sm: 7 }}>
          <BuildIcon />
          <Box ml={5} display="flex" color="primary">
          <Typography className={classes.titleRoot1}>Service Type :{' '}</Typography>
          <Typography className={classes.titleRoot2}>
              {' '}
              {details?.service_type_details.map((item, index) => (
                <li key={index} className={classes.serviceTypeView} style={{ listStyleType: 'none' }}>
                  {item?.name}
                </li>
              ))}
            </Typography>
            
          </Box>
          </Box>
          
          </Grid>
          <Grid item xs={6} sm={6}>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <AttachMoneySharpIcon />
          <Box ml={5}  display="flex">
          <Typography className={classes.titleRoot1}>Part Price :</Typography>
            <Typography className={classes.titleRoot2}>${charge}</Typography>
             
          </Box>
        </Box>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <FaceIcon />
          <Box ml={5}  display="flex">
          <Typography className={classes.titleRoot1}>Manufacturer :</Typography>
            <Typography className={classes.titleRoot2}>{!manufacturer_details?.name?"-":manufacturer_details?.name}</Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <BrandingWatermarkIcon />
          <Box ml={5}  display="flex">
          <Typography className={classes.titleRoot1}>Make :</Typography>
            <Typography className={classes.titleRoot2}>{!make?"-":make}</Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <BrandingWatermarkIcon />
          <Box ml={5}  display="flex">
          <Typography className={classes.titleRoot1}>Model :</Typography>
            <Typography className={classes.titleRoot2}>{!model?"-":model}</Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <CalendarTodayIcon />
          <Box ml={5}  display="flex">
          <Typography className={classes.titleRoot1}>Life Expectancy :</Typography>
            <Typography className={classes.titleRoot2}>{!life_expectancy?"-":life_expectancy}</Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }}>
          {status === 1 ? <CheckCircleOutline /> : <Block />}
          <Box ml={5} display="flex">
          <Typography className={classes.titleRoot1}>Status (Active/Inactive):</Typography>
            <Typography className={classes.titleRoot2}>{status === 1 ? 'Active' : 'Inactive'}</Typography>

             </Box>
        </Box>
          </Grid>
        </GridContainer>
      </Box>






        {/* <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <AttachMoneySharpIcon />
          <Box ml={5}  display="flex">
          <Typography className={classes.titleRoot1}>Part Price :</Typography>
            <Typography className={classes.titleRoot2}>${charge}</Typography>
             
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <FaceIcon />
          <Box ml={5}  display="flex">
          <Typography className={classes.titleRoot1}>Manufacturer :</Typography>
            <Typography className={classes.titleRoot2}>{!manufacturer_details?.name?"-":manufacturer_details?.name}</Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <BrandingWatermarkIcon />
          <Box ml={5}  display="flex">
          <Typography className={classes.titleRoot1}>Model :</Typography>
            <Typography className={classes.titleRoot2}>{!model?"-":model}</Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <BrandingWatermarkIcon />
          <Box ml={5}  display="flex">
          <Typography className={classes.titleRoot1}>Make :</Typography>
            <Typography className={classes.titleRoot2}>{!make?"-":make}</Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <CalendarTodayIcon />
          <Box ml={5}  display="flex">
          <Typography className={classes.titleRoot1}>Life Expectancy :</Typography>
            <Typography className={classes.titleRoot2}>{!life_expectancy?"-":life_expectancy}</Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }}>
          {status === 1 ? <CheckCircleOutline /> : <Block />}
          <Box ml={5} display="flex">
          <Typography className={classes.titleRoot1}>Status (Active/Inactive):</Typography>
            <Typography className={classes.titleRoot2}>{status === 1 ? 'Active' : 'Inactive'}</Typography>

             </Box>
        </Box> */}
      </Box>

      <Box px={6} py={5}>
        <Box mb={5} style={{fontWeight:600}}>
          Logs :
        </Box>
        <UserTable />
      </Box>
    </Dialog>
  );
};

export default UserDetailView;

UserDetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
