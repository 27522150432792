import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  tableRowCellRoot: {
    fontSize: 14,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 16,
    letterSpacing: 0.4,
    textAlign:'center',
    color: theme.palette.text.primary,
    borderBottom: '0 none',
    '&:first-child': {
      paddingLeft: 24,
      textAlign:'left'
    },
    '&:last-child': {
      // textAlign: 'right',
      paddingRight: 24,
    },
  },
}));

const TableHeading = () => {
  const classes = useStyles();
  return (
    <TableRow>
      <TableCell className={classes.tableRowCellRoot}>Module Name</TableCell>
      <TableCell className={classes.tableRowCellRoot}>View</TableCell>
      <TableCell className={classes.tableRowCellRoot}>Add</TableCell>
      <TableCell className={classes.tableRowCellRoot}>Edit</TableCell>
      <TableCell className={classes.tableRowCellRoot}>Enable/Disable</TableCell>
   
    </TableRow>
  );
};

export default TableHeading;
