export const primaryColorArray = [
  { primary: "#FC8119", secondary: "#55DDE0" },
  { primary: "#6200EE", secondary: "#03DAC5" },
  { primary: "#1C61CA", secondary: "#E56B6F" },
  { primary: "#2EC4B6", secondary: "#FF9F1C" },
  { primary: "#EF8354", secondary: "#4F5D75" },
  { primary: "#D71371", secondary: "#5AB77F" },
  { primary: "#072AC8", secondary: "#FFC600" },
  { primary: "#7530FF", secondary: "#369FFF" },
  { primary: "#F26419", secondary: "#F9C80E" },
  { primary: "#2BA444", secondary: "#0A2E36" },
  { primary: "#33658A", secondary: "#55DDE0" },
];
export const secondaryAndTextColorArray = [
  { primary: "#FC8119", secondary: "#F0511E" },
  { primary: "#FC8119", secondary: "#3B1A45" },
  { primary: "#6200EE", secondary: "#03DAC5" },
  { primary: "#1C61CA", secondary: "#E56B6F" },
  { primary: "#2EC4B6", secondary: "#FF9F1C" },
  { primary: "#EF8354", secondary: "#4F5D75" },
  { primary: "#D71371", secondary: "#5AB77F" },
  { primary: "#072AC8", secondary: "#FFC600" },
  { primary: "#7530FF", secondary: "#369FFF" },
  { primary: "#F26419", secondary: "#F9C80E" },
  { primary: "#2BA444", secondary: "#0A2E36" },
  { primary: "#33658A", secondary: "#55DDE0" },
];

export const DaysArray1 = [
  // { value: '0' },
  { value: "1" },
  { value: "2" },
  { value: "3" },
  { value: "4" },
  { value: "5" },
  { value: "6" },
  { value: "7" },
  { value: "8" },
  { value: "9" },
  { value: "10" },
  { value: "11" },
  { value: "12" },
  { value: "13" },
  { value: "14" },
  { value: "15" },
  { value: "16" },
  { value: "17" },
  { value: "18" },
  { value: "19" },
  { value: "20" },
  { value: "21" },
  { value: "22" },
  { value: "23 " },
  { value: "24" },
  { value: "25" },
  { value: "26 " },
  { value: "27" },
  { value: "28" },
  { value: "29 " },
  { value: "30" },
];

export const statusArray = {
  vendor_not_assigned: 1,
  vendor_assigned: 2,
  service_started: 3,
  service_request_completed: 4,
  approve_work: 5,
  payment_sent: 6,
  rejected: 7,
};

export const Priority = [
  { priority: "P1" },
  { priority: "P2" },
  { priority: "P3" },
  { priority: "P4" },
  { priority: "P5" },
];

export const DaysArray = [
  { value: "0" },
  { value: "1" },
  { value: "2" },
  { value: "3" },
  { value: "4" },
  { value: "5" },
  { value: "6" },
  { value: "7" },
  { value: "8" },
  { value: "9" },
  { value: "10" },
  { value: "11" },
  { value: "12" },
  { value: "13" },
  { value: "14" },
  { value: "15" },
  { value: "16" },
  { value: "17" },
  { value: "18" },
  { value: "19" },
  { value: "20" },
  { value: "21" },
  { value: "22" },
  { value: "23 " },
  { value: "24" },
  { value: "25" },
  { value: "26 " },
  { value: "27" },
  { value: "28" },
  { value: "29 " },
  { value: "30" },
];
export const HoursArray = [
  { value: 0 },
  { value: 1 },
  { value: 2 },
  { value: 3 },
  { value: 4 },
  { value: 5 },
  { value: 6 },
  { value: 7 },
  { value: 8 },
  { value: 9 },
  { value: 10 },
  { value: 11 },
  { value: 12 },
  { value: 13 },
  { value: 14 },
  { value: 15 },
  { value: 16 },
  { value: 17 },
  { value: 18 },
  { value: 19 },
  { value: 20 },
  { value: 21 },
  { value: 22 },
  { value: 23 },
];
