import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';
import {
  DELETE_BULK_RETURNITEM,
  DELETE_RETURNITEM,
  GET_RETURNITEM,
  SET_RETURNITEM_DETAILS,
  GET_RETURNITEMDETAILS,
  GET_RETURNITEMADD,
  GET_RETURNWAREHOUSELIST
} from '../../@jumbo/constants/ActionTypes';
import {
  ReturnItemList,
  updateItems,
  updateItemsDetails,
  DetailsReturnItem,
  AddReturnItem,
  WarehouseList
} from '../../@jumbo/constants/ApiConstatnt';

// get Return list list
export const getReturnItemList = (sortBy, sort, searchTerm, page, limit, filterOptions, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(ReturnItemList, {
        params: { sortBy: `${sortBy}:${sort}`, search: searchTerm, page: page + 1, limit: limit, status: filterOptions[0] },
      })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_RETURNITEM, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError());
          dispatch({ type: GET_RETURNITEM, payload: [] });
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Part list not found.') {
          dispatch({ type: GET_RETURNITEM, payload: [] });
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};


export const getWarehouseList = (id, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(WarehouseList+id)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_RETURNWAREHOUSELIST, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError());
          dispatch({ type: GET_RETURNWAREHOUSELIST, payload: '' });
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Part list not found.') {
          dispatch({ type: GET_RETURNWAREHOUSELIST, payload: '' });
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};






// set current user for view tab
export const setItemList = user => {
  return dispatch => {
    dispatch({ type: SET_RETURNITEM_DETAILS, payload: user });
  };
};

// add onchange assign list post
export const addNewReturnItem = (user, callbackFun) => {
  return dispatch => {
    dispatch({ type: GET_RETURNITEMADD, payload: [] });
    dispatch(fetchStart());
    axios
      .post(AddReturnItem, user)
      .then(data => {
        // if (data) {
          if (data && data.data.message !== 'Part list not found.'){
          setTimeout(() => {
            dispatch(fetchSuccess(data?.data?.message));
          }, 2000);
        
          dispatch({ type: GET_RETURNITEMADD, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('Part list not found.'));
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Item list not found.') {
          dispatch(fetchError(error?.response?.data?.message));
          dispatch({ type: GET_RETURNITEMADD, payload: [] });
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

// add Assign item
export const addNewReturnItemData = (vendeorId, WhareHouseId, user, callbackFun) => {
  console.log('returnusers', user);
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`fm-provider/fm-items/return-items?vendorId=${vendeorId}&warehouseId=${WhareHouseId}`, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('New Return Part added successfully.'));
          }, 2000);
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const updateItemListStatus = (data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(updateItems, data)
      .then(response => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Return Part updated successfully.'));
          }, 3000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const getItemListDetails = userId => {
  return dispatch => {
    dispatch(fetchStart());

    axios
      .get(DetailsReturnItem + userId)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_RETURNITEMDETAILS, payload: data.data.data });
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Return List not found.') {
          dispatch(fetchError(error?.response?.data?.message));
          dispatch({ type: GET_RETURNITEMDETAILS, payload: [] });
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

export const setItemListDetails = user => {
  return dispatch => {
    dispatch({ type: SET_RETURNITEM_DETAILS, payload: user });
  };
};

// all function below are not in use currntly
export const deleteBulkUsers = (userIds, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/users/bulk-delete', { userIds })
      .then(response => {
        if (response.status === 200) {
          dispatch(fetchSuccess('Selected users were deleted successfully.'));
          dispatch({ type: DELETE_BULK_RETURNITEM, payload: userIds });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const deleteUser = (userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete('/users', { params: { id: userId } })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('Selected user was deleted successfully.'));
          dispatch({ type: DELETE_RETURNITEM, payload: userId });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const sentMailToUser = () => {
  return dispatch => {
    dispatch(fetchSuccess('Email has been sent to user successfully'));
  };
};

export const updateItemList = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(updateItemsDetails, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Return Part updated successfully.'));
          }, 3000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};
