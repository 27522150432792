import React from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import useStyles from './index.style';
import { Block, CheckCircleOutline } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import FaceIcon from '@material-ui/icons/Face';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FormGroup } from '@material-ui/core';
import ListAltIcon from '@material-ui/icons/ListAlt';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BugReportIcon from '@material-ui/icons/BugReport';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { Active, Inactive } from '@jumbo/constants/stringConstant';
const UserDetailView = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const { currentUser } = useSelector(({ SELFHELPCONTENT }) => SELFHELPCONTENT);
  const {
    asset_id,
    doc_type,
    is_tenant_visible,
    is_vendor_contractor_visible,
    issue_name,
    link,
    title,
    status,
  } = currentUser;

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.userInfoRoot}>
        <Box mr={3} display="flex" alignItems="center">
          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.titleRoot}>Self Help Content Detail</Typography>
              <Box ml={1}></Box>
            </Box>
          </Box>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}></Box>
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box px={6} py={5}>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <ListAltIcon />
          <Box ml={5} display="flex">
            <Typography className={classes.labelField}> Title :</Typography>
            <Typography className={classes.textField}> {title}</Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <AssignmentIcon />
          <Box ml={5} display="flex">
            <Typography className={classes.labelField}> ID :</Typography>
            <Typography className={classes.textField}> {asset_id}</Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <BugReportIcon />
          <Box ml={5} display="flex">
            <Typography className={classes.labelField}> Issue :</Typography>
            <Typography className={classes.textField}> {issue_name}</Typography>
          </Box>
        </Box>

        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <InsertDriveFileIcon />
          <Box ml={5} color="primary" display="flex">
            <Typography className={classes.labelField}> Type :</Typography>
            <Typography className={classes.textField}> {doc_type === 1 ? 'Document' : 'Video'}</Typography>
          </Box>
        </Box>
        <Box display="flex" mb={{ xs: 4, sm: 7 }}>
          <InsertDriveFileIcon />
          <Box ml={5} mb={5} color="primary" component="p" className={classes.labelField}>
            {doc_type === 1 ? (
              <>
                View Document:{' '}
                <Button
                  variant="contained"
                  style={{ minWidth: '100px' }}
                  color="primary"
                  onClick={() => window.open(link, '_self')}>
                  VIEW
                </Button>
              </>
            ) : (
              <>
                Video Link :{' '}
                <Button variant="contained" style={{ minWidth: '100px' }} color="primary" onClick={() => window.open(link)}>
                  VIEW
                </Button>
              </>
            )}
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <FaceIcon />{' '}
          <Box ml={5} className={classes.labelField}>
            Visible to :{' '}
          </Box>
          <FormGroup row>
            <FormControlLabel
              className={classes.textField}
              control={<Checkbox checked={is_tenant_visible} name="checkedA" />}
              label="Tenant"
            />
            <FormControlLabel
              className={classes.textField}
              control={<Checkbox checked={is_vendor_contractor_visible} name="checkedA" />}
              label="Vendor/Contractor"
            />
          </FormGroup>
        </Box>
        <Box mt={5} display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }}>
          {status === 1 ? <CheckCircleOutline /> : <Block />}
          <Box ml={5} display="flex">
            <Typography className={classes.labelField}> Status (Active/Inactive) :</Typography>
            <Typography className={classes.textField}> {status === 1 ? Active : Inactive}</Typography>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default UserDetailView;

UserDetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
