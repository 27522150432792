import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { requiredMessage } from '@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { alpha } from '@material-ui/core/styles';
import AppSelectBox from '@jumbo/components/Common/formElements/AppSelectBox';
import { fetchStart } from 'redux/actions';
import { assignUser } from 'redux/actions/Locations';
import { getUserListAssignUser } from '../../../../../../redux/actions/UserManagement';
import UserSelectBox from './UserSelectBox'
const useStyles = makeStyles(theme => ({
  root: {
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    "& .MuiFormLabel-root": {
      color: '#909090'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink':{
      color: theme.palette.text.primary
    }
  },
  dialogRoot: {
    position: 'relative',
    '& .MuiDialog-paperWidthSm': {
      width: '100%',
    },
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.text.primary,
    },
  },
  cardRoot: {
    position: 'relative',
    '& .Cmt-card-content': {
      padding: 0,
      paddingBottom: 24,
    },
  },
  scrollbarRoot: {
    height: 340,
  },
  badgeRoot: {
    fontSize: 14,
    letterSpacing: 0.25,
    backgroundColor: alpha(theme.palette.warning.main, 0.15),
    color: theme.palette.warning.main,
    padding: '2px 10px',
    borderRadius: 30,
  },
  tableRowCellRoot: {
    fontSize: 14,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 16,
    letterSpacing: 0.4,
    textAlign: 'center',
    color: theme.palette.common.dark,
    borderBottom: '0 none',
    '&:first-child': {
      paddingLeft: 24,
    },
    '&:last-child': {
      paddingRight: 24,
    },
  },
  tableRowRoot: {
    position: 'relative',
    transition: 'all .2s',
    borderTop: `solid 1px ${theme.palette.borderColor.main}`,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
      transform: 'translateY(-4px)',
      boxShadow: `0 3px 10px 0 ${alpha(theme.palette.common.dark, 0.2)}`,
      borderTopColor: 'transparent',
      '& $tableCellRoot': {
        color: theme.palette.text.primary,

        '&.success': {
          color: theme.palette.success.main,
        },
      },
    },
  },
  tableCellRoot: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: 14,
    letterSpacing: 0.25,
    textAlign: 'center',
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    borderBottom: '0 none',
    '&:first-child': {
      paddingLeft: 24,
      textAlign: 'left',
    },

    '& .Cmt-avatar-more': {
      fontSize: 10,
      color: theme.palette.primary.main,
    },
  },
  tableCellSecondaryColor: {
    color: theme.palette.text.secondary,
  },
  blockRoot: {
    display: 'block',
    fontSize: 14,
  },
}));

const AddEditUser = ({ open, onCloseDialog, userType, siteId, fetchData, fmAdmin, accountUser }) => {
  const classes = useStyles();
  const { authUser } = useSelector(({ auth }) => auth);
  const users = useSelector(({ FMPROVIDER }) => FMPROVIDER.AssignUserList);
  const [assignedUser, setAssignedUser] = useState(userType === 4 ? fmAdmin?._id : accountUser?._id);
  const [assignedUserError, setAssignedUserError] = useState('');
  const dispatch = useDispatch();
  useEffect(() => {
    if (userType === 4) {
      dispatch(fetchStart());
      dispatch(getUserListAssignUser('first_name', 'asc', '', 0, -1, [1], undefined, 4, 1));
    } else {
      dispatch(fetchStart());
      dispatch(getUserListAssignUser('first_name', 'asc', '', 0, -1, [1], undefined, 5, 1));
    }
  }, []);
  const onSubmitClick = () => {
    if (!assignedUser) {
      setAssignedUserError(requiredMessage);
    }
    if (assignedUser) {
      onUserSave();
    }
  };

  const onUserSave = () => {
    const userDetail = {
      siteId,
      userId: assignedUser,
      userType,
    };
    const data = users?.filter(item => item?._id === assignedUser);
    dispatch(
      assignUser(userDetail, data[0],fmAdmin?.created_by,authUser?._id,authUser?.user_type, () => {
        onCloseDialog();
        fetchData();
      }),
    );
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>
        {userType == 4 ? 'Assign FM Admin' : 'Assign Account User'}
      </DialogTitle>
      <DialogContent dividers>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
          <GridContainer>
            <Grid item xs={12} sm={12} className={classes.root}>
              <UserSelectBox
                fullWidth
                data={users}
                label={userType === 4 ? 'FM Admin' : 'Account User'}
                valueKey="_id"
                variant="outlined"
                labelKey='first_name'
                value={assignedUser}
                onChange={e => {
                  setAssignedUser(e.target.value);
                  setAssignedUserError('')
                }}
                helperText={assignedUserError}
              />
            </Grid>
          </GridContainer>
        </Box>

        <Box display="flex" justifyContent="flex-end" mb={4} mt={5}>
          <Button variant="outlined" onClick={onCloseDialog}>
            Cancel
          </Button>
          <Box ml={2}>
            <Button variant="contained" style={{ minWidth: '100px' }} color="primary" onClick={onSubmitClick}>
              ASSIGN
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditUser;

AddEditUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
