import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import { useDispatch } from 'react-redux';
import { getContractorVendorList, updatePriority } from '../../../../../../redux/actions/ContractorVendorManagment';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
  dialogActionsRoot: {
    padding: '16px 24px',
  },
}));

const ConfirmDialog = ({ open, onClose, onCloseDialog, title, content, btnLabels, getContractorParams, contractorId }) => {
  const classes = useStyles();
  const [priority, setPriority] = useState('');
  const { vendorId, orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions } = getContractorParams;
  const [priorityError, setPriorityError] = useState('');
  const dispatch = useDispatch();

  const submit = () => {
    if (!priority) {
      setPriorityError("Please choose priority")
    }
    else {
      dispatch(updatePriority({ _id: contractorId._id, priority: priority }, refreshContractorList))
    }
  }

  function refreshContractorList() {
    dispatch(
      getContractorVendorList(vendorId, 2, orderBy, order, debouncedSearchTerm, 0, rowsPerPage, filterOptions)
    )
    onCloseDialog();
  }
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="confirm-dialog-title" className={classes.dialogRoot}>
      <DialogTitle id="confirm-dialog-title" className={classes.dialogTitleRoot}>
        Select Contractor Priority
      </DialogTitle>
      <DialogContent dividers>
        {/* <DialogContentText> */}
          <FormControl component="fieldset">
            <RadioGroup
              column="true"
              onChange={(e) => {
                setPriority(Number(e.target.value));
                setPriorityError('')
              }}
              value={priority}
            >
              <FormControlLabel
                value={1}
                control={<Radio color="primary" />}
                label="Highest"
              />
              <FormControlLabel
                value={2}
                control={<Radio color="primary" />}
                label="Higher"
              />
              {contractorId?.reviewer?.priority == 1 || contractorId?.reviewer?.priority == 2 ? null
                : <FormControlLabel
                  value={3}
                  control={<Radio color="primary" />}
                  label="High"
                />}
            </RadioGroup>
          </FormControl>
        {/* </DialogContentText> */}
        <Grid>
          <p style={{ marginLeft: '5px', color: 'red' }} className="MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-marginDense" >
            {priorityError}
          </p>
        </Grid>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActionsRoot }}>
        <Button onClick={onCloseDialog} color="primary" variant="outlined">
          {btnLabels.cancel}
        </Button>
        <Button onClick={submit} color="primary" variant="contained">
          {btnLabels.confirm}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.defaultProps = {
  open: false,
  title: 'Confirm Delete',
  btnLabels: {
    cancel: 'Cancel',
    confirm: 'Confirm',
  },
};

export default React.memo(ConfirmDialog);
