import React, { useEffect, useState } from 'react';
import { Grid, Box, Button, Typography } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import GridContainer from '@jumbo/components/GridContainer';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CmtCard from '@coremat/CmtCard';

import AppSelectBox from '@jumbo/components/Common/formElements/AppSelectBox';
import { primaryColorArray, secondaryAndTextColorArray, DaysArray, HoursArray } from '@jumbo/constants/CustomizeSetting';
import { useDispatch, useSelector } from 'react-redux';
// import { getCustomization, updateCustomization } from '../../../../../redux/actions/Customization';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import { alphaNumaric } from '@jumbo/constants/ValidationRegex';
import { requiredMessage, alphaNumaricMessage } from '@jumbo/constants/ErrorMessages';
import { budgetList } from '@jumbo/constants/ApiConstatnt';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import axios from '../../../../../../services/auth/jwt/config';
import { setAuthUser } from 'redux/actions/Auth';
import { history } from 'redux/store';
import { useLocation, useParams } from 'react-router';
import { capitalizeFLetter, isValidEmail } from '@jumbo/utils/commonHelper';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import { getSERVICETYPE } from 'redux/actions/ServiceType';
import { updateLocation } from 'redux/actions/Locations';
import LocationSearch from '../../LocationSearch/index';

const useStyles = makeStyles(theme => ({
  root: {
    '& .Mui-disabled': {
      color: theme.palette.text.primary,
    },
    maxWidth: '400px',
  },
  switch: {
    '& .Mui-disabled': {
      color: theme.palette.secondary.main,
    },
    maxWidth: '400px',
  },
  Wrap: {
    padding: '10px',
  },
  titleRoot: {
    fontSize: '16px',
    fontWeight: 600,
    marginTop: '14',
    marginBottom: '14',
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    marginRight: '10px',
    display: 'flex',
  },
  gridMargin: {
    marginTop: '20px',
  },
  gridMarginTop: {
    marginTop: '5px',
  },
  serviceType: {
    overflow: 'auto',
    maxHeight: '300px',
  },
  serviceTypeList: {
    marginLeft: '2px',
    marginRight: '2px',
    fontSize: '16px',
    fontWeight: 600,
    letterSpacing: 0.25,
    textOverflow: 'ellipsis',
    maxWidth: '250px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  totalTitle: {
    fontSize: '20px',
    fontWeight: 600,
    marginTop: '3',
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    marginRight: '10px',
    display: 'flex',
  },
}));

const Index = () => {
  const classes = useStyles();
  const allServiceTypeList = useSelector(({ SERVICETYPE }) => SERVICETYPE.users);
  const [data, setData] = useState([]);

  const dispatch = useDispatch();
  let { id } = useParams();
  async function fetchData() {
    let response = await axios(budgetList + '?location_id=' + id).catch(error => {
      if (error?.response?.status === 401) {
        dispatch(setAuthUser(null));
        history.push('/signin');
      } else {
        dispatch(fetchError(error?.response?.data?.message));
      }
    });
    let user = await response?.data;
    setData(response?.data?.data?.data);

    dispatch(fetchSuccess());
  }
  useEffect(() => {
    dispatch(fetchStart());
    fetchData();
  }, []);
  return (
    <CmtCard className={classes.Wrap}>
      <CmtCardContent>
        <GridContainer style={{ marginBottom: '20px' }}>
          <Grid item xs={8} sm={6} md={6}>
            <Typography className={classes.totalTitle}>
            Job Location Budget :{' '}{data ? (data[0]?.site_budget ? `$${data[0]?.site_budget?.toLocaleString(undefined, { maximumFractionDigits: 2 })}` : '$0') : '$0'}
            </Typography>
          </Grid>
          <Grid item xs={8} sm={5} md={5}>
            <Typography className={classes.totalTitle}>
              Remaining Budget :{' '}
              {data ? (data[0]?.remaining_site_budget ? `$${data[0]?.remaining_site_budget?.toLocaleString(undefined, { maximumFractionDigits: 2 })}` : '$0') : '$0'}
            </Typography>
          </Grid>
        </GridContainer>
       
          {data
            ? data[0]?.service_type_details?.map((item,index) => (
              <GridContainer key={index}>
                  <Grid item xs={8} sm={6} md={6} >
                    {' '}
                    <Box ml={5}>
                      <Typography className={classes.titleRoot}>
                        {' '}
                        <span className={classes.serviceTypeList}>{item?.service_type_name}</span> Budget :{' '}
                        {item?.budget ? `$${item?.budget?.toLocaleString(undefined, { maximumFractionDigits: 2 })}` : '$0'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={8} sm={6} md={6} >
                    {' '}
                    <Box ml={5}>
                      <Typography className={classes.titleRoot}>
                        Remaining <span className={classes.serviceTypeList}>{item?.service_type_name}</span> Budget :{' '}
                        {item?.remaining_service_budget ? `$${item?.remaining_service_budget?.toLocaleString(undefined, { maximumFractionDigits: 2 })}` : '$0'}
                      </Typography>
                    </Box>
                  </Grid>
                  </GridContainer>
              ))
            : null}{' '}
       
      </CmtCardContent>
    </CmtCard>
  );
};

export default Index;
