import React from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import useStyles from './index.style';
import { Grid } from '@material-ui/core';
import GridContainer from '@jumbo/components/GridContainer';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCard from '@coremat/CmtCard';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import { TableCell, TableRow } from '@material-ui/core';
import moment from 'moment';
const UserDetailView = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const { currentUser } = useSelector(({ CALLCENTER }) => CALLCENTER);
  const {
    asset_details,
    asset_type_details,
    description,
    issue_details,
    location_details,
    service_type_details,
    tenant_details,
    created_at,
  } = currentUser;
  console.log(currentUser);
  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.userInfoRoot}>
        <Box mr={3} display="flex" alignItems="center">
          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.titleRoot}>Service Request Details</Typography>
              <Box ml={1}></Box>
            </Box>
          </Box>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}></Box>
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box px={6} py={5}>
        <Box alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <GridContainer>
            <Grid item xs={12} sm={6}>
              <Box display='flex'>
              <Typography className={classes.labelField}>  ID :</Typography>
            <Typography className={classes.textField}> {asset_details?.asset_id}</Typography>
               
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display='flex'>
              <Typography className={classes.labelField}> Job Location :</Typography>
            <Typography className={classes.textField}> {location_details?.name}</Typography>
              
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display='flex'>
              <Typography className={classes.labelField}> Tenant :</Typography>
            <Typography className={classes.textField}> {tenant_details?.first_name} {tenant_details?.last_name}</Typography>
               
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display='flex'>
              <Typography className={classes.labelField}> Service Type :</Typography>
            <Typography className={classes.textField}> {service_type_details?.name}</Typography>
                
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display='flex'>
              <Typography className={classes.labelField}>   Description :</Typography>
            <Typography className={classes.textField}> {description}</Typography>
               
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display='flex'>
              <Typography className={classes.labelField}> Asset Type Problem Area :</Typography>
            <Typography className={classes.textField}> {asset_type_details?.name}</Typography>
                
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display='flex'>
              <Typography className={classes.labelField}>  Request Date</Typography>
            <Typography className={classes.textField}> {moment(created_at * 1000).format('DD/MM/YYYY')}</Typography>
               
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display='flex'>
              <Typography className={classes.labelField}>  Problem details :</Typography>
            <Typography className={classes.textField}> {issue_details?.name}</Typography>
               
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box display='flex' mb={5}>
              <Typography className={classes.labelField}>  Request Time :</Typography>
            <Typography className={classes.textField}> {moment(created_at * 1000).format('hh:mm A')}</Typography>
               
              </Box>
            </Grid>
          </GridContainer>
          <CmtCard className={classes.cardRoot}>
            <CmtCardHeader title="Asset"></CmtCardHeader>
            <CmtCardContent>
              <PerfectScrollbar className={classes.scrollbarRoot}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead className={classes.labelField}>
                    <TableRow >
                      <TableCell>Asset Name</TableCell>
                      <TableCell>Floor</TableCell>
                      <TableCell>Department</TableCell>
                      <TableCell>ID</TableCell>
                      <TableCell>Brand</TableCell>
                      <TableCell>Model</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography className={classes.tableRowCellRoot}>{asset_details?.name}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className={classes.tableRowCellRoot}>{asset_details?.floor}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className={classes.tableRowCellRoot}>{asset_details?.department}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className={classes.tableRowCellRoot}>{asset_details?.asset_id}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className={classes.tableRowCellRoot}>{asset_details?.brand}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className={classes.tableRowCellRoot}>{asset_details?.model}</Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </PerfectScrollbar>
            </CmtCardContent>
          </CmtCard>
        </Box>
      </Box>
    </Dialog>
  );
};

export default UserDetailView;

UserDetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
