import React, { useEffect, useState } from 'react';
import { Grid, Box, Button, Typography } from '@material-ui/core';
import GridContainer from '@jumbo/components/GridContainer';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CmtCard from '@coremat/CmtCard';
import { useDispatch, useSelector } from 'react-redux';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import AssignFMAdmin from './AssignFMAdmin';
import { setAuthUser } from 'redux/actions/Auth';
import { history } from 'redux/store';
import { useParams } from 'react-router';
import axios from 'services/auth/jwt/config';
import { assignedListLoaction } from '../../../../../../@jumbo/constants/ApiConstatnt';

const useStyles = makeStyles(theme => ({
  root: {
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
  },
  Wrap: {
    padding: '10px',
  },
  titleRoot: {
    fontSize: '18px',
    fontWeight: 600,
    marginTop: '14',

    color: theme.palette.text.primary,
  },
  gridMargin: {
    marginTop: '20px',
  },
  gridMarginTop: {
    marginTop: '5px',
  },
  titleRootUser: {
    fontSize: '16px',
    fontWeight: 600,
    marginTop: '14',
    display: 'flex',
    whiteSpace: 'nowrap',
  },
  titleRootUserName: {
    marginLeft:'5px',
    fontSize: '16px',
    fontWeight: 300,
    marginTop: '14',
    maxWidth: '350px',
    letterSpacing: 0.25,
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    maxWidth: '350px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'wrap',
  },
}));

const Index = () => {
  const classes = useStyles();
  let { id } = useParams();
  const { authUser } = useSelector(({ auth }) => auth);
  const [FMAdmin, setFMAdmin] = useState('');
  const [accountUser, setAccountUser] = useState('');
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [userType, setUserType] = useState(null);
  const dispatch = useDispatch();

  async function fetchData() {
    let response = await axios(assignedListLoaction + id).catch(error => {
      if (error?.response?.status === 401) {
        dispatch(setAuthUser(null));
        history.push('/signin');
      } else {
        dispatch(fetchError(error?.response?.data?.message));
      }
    });
    let user = await response?.data;
    setFMAdmin(response?.data?.data?.fm_admin_details);
    setAccountUser(response?.data?.data?.account_user_details);
    dispatch(fetchSuccess());
  }

  useEffect(() => {
    dispatch(fetchStart());
    fetchData();
  }, []);

  const handleCloseUserDialog = () => {
    setOpenUserDialog(false);
  };

  return (
    <CmtCard className={classes.Wrap}>
      <CmtCardContent>
        <GridContainer>
          <Grid item xs={6} sm={6} md={6}>
            <Box ml={2}>
              <Typography className={classes.titleRoot}>FM Admin </Typography>
            </Box>
            <Box ml={2} mt={10}>
              <Typography className={classes.titleRootUser}>
                Assigned FM Admin :{' '}
                <span className={classes.titleRootUserName}>{FMAdmin ? FMAdmin?.first_name + ' ' + FMAdmin?.last_name : '-'}</span>
              </Typography>
            </Box>
            <Box ml={2} mt={10}>
              {authUser?.fm_module_access[4]?.is_edit ? (
                <Button
                  variant="contained"
                  style={{ minWidth: '100px' }}
                  color="primary"
                  onClick={e => {
                    setUserType(4);
                    setOpenUserDialog(true);
                  }}>
                  ASSIGN FM ADMIN
                </Button>
              ) : null}
            </Box>
          </Grid>

          <Grid item xs={6} sm={6} md={6}>
            <Box ml={2}>
              <Typography className={classes.titleRoot}>Account User </Typography>
            </Box>
            <Box ml={2} mt={10}>
              <Typography className={classes.titleRootUser}>
                Assigned Account User :{' '}
                <span className={classes.titleRootUserName}>
                  {accountUser ? accountUser?.first_name + ' ' + accountUser?.last_name : '-'}
                </span>
              </Typography>
            </Box>
            <Box ml={2} mt={10}>
              {authUser?.fm_module_access[4]?.is_edit ? (
                <Button
                  variant="contained"
                  style={{ minWidth: '100px' }}
                  color="primary"
                  onClick={e => {
                    setUserType(5);
                    setOpenUserDialog(true);
                  }}>
                  ASSIGN ACCOUNT USER
                </Button>
              ) : null}
            </Box>
          </Grid>
        </GridContainer>
      </CmtCardContent>
      {openUserDialog && (
        <AssignFMAdmin
          fetchData={fetchData}
          open={openUserDialog}
          onCloseDialog={handleCloseUserDialog}
          userType={userType}
          fmAdmin={FMAdmin}
          accountUser={accountUser}
          siteId={id}
        />
      )}
    </CmtCard>
  );
};

export default Index;
