import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Grid from "@material-ui/core/Grid";
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import EmailIcon from '@material-ui/icons/Email';
import useStyles from './index.style';
import { numberOnly2 } from '@jumbo/constants/ValidationRegex';
import { onlyNumberNotAllow } from '@jumbo/constants/ErrorMessages';
import { Button } from '@material-ui/core';
import FaceIcon from '@material-ui/icons/Face';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import CertificationTable from '../CertificationTable/index'
import BuildIcon from "@material-ui/icons/Build";
import RateReviewIcon from '@material-ui/icons/RateReview';
import { getContractorVendorList, updateContractor } from '../../../../../../redux/actions/ContractorVendorManagment';
import { emptySpace } from '@jumbo/constants/ValidationRegex';
import { requiredMessage } from '@jumbo/constants/ErrorMessages';

const UserDetailView = ({ open, onCloseDialog, getFMProviderParams }) => {
  const classes = useStyles();
  const { currentUser } = useSelector(({ CONTRACTOR }) => CONTRACTOR);
  const [reviewNote, setReviewNote] = useState('')
  const [reviewNoteError, setReviewNoteError] = useState('')
  const { first_name, last_name, email, status, user_type, _id } = currentUser;
  const { vendorId, orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions } = getFMProviderParams;
  const dispatch = useDispatch()

  const onUserSave = (approvestatus) => {

    if (reviewNote.match(emptySpace)) {
      setReviewNoteError(requiredMessage)
    }
    else if (reviewNote.match(numberOnly2)) {
      setReviewNoteError(onlyNumberNotAllow);
    }
    else {

      const userDetail = {
        "approved_status": approvestatus,
        'reviewer_notes': reviewNote
      };

      dispatch(
        updateContractor(_id, userDetail, () => {
          onCloseDialog();
          dispatch(
            getContractorVendorList(vendorId, 1, orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions)
          );
        })
      );
    }
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.userInfoRoot}>
        <Box mr={3} display="flex" alignItems="center">
          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.titleRoot}>Contractor Details</Typography>
              <Box ml={1}></Box>
            </Box>
          </Box>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}></Box>
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box px={6} py={5}>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <PermIdentityIcon />
          <Box display="flex">
            <Typography className={classes.titleRoot1}>Vendor Name :</Typography>
            <Typography className={classes.titleRoot2}>{currentUser?.vendor_first_name + " " + currentUser?.vendor_last_name}</Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <FaceIcon />
          <Box display="flex">
            <Typography className={classes.titleRoot1}>First Name :</Typography>
            <Typography className={classes.titleRoot2}>{first_name}</Typography>
            <Typography className={classes.titleRoot1}>Last Name :</Typography>
            <Typography className={classes.titleRoot2}>{last_name}</Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <EmailIcon />
          <Typography className={classes.titleRoot1}>Email :</Typography>
          <Typography className={classes.titleRoot2}>{email}</Typography>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <BuildIcon />
          <Typography className={classes.titleRoot1}>Service Type :</Typography>
          <Typography className={classes.titleRoot2}>{currentUser?.service_type_details.map((item,index) => <li className={classes.serviceTypeView} key={index} style={{ listStyleType: "none" }}>{item?.name}</li>)}</Typography>
        </Box>
        <Typography className={classes.titleRoot1} style={{ marginBottom: '20px' }}>Certifications :</Typography>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <CertificationTable />
        </Box>
        <Box display="flex" mb={{ xs: 4, sm: 7 }}>
          <RateReviewIcon style={{ marginTop: '22px' }} />
          <Grid item xs={12} sm={6} style={{ marginLeft: '10px' }}>
            <TextField className={classes.textFieldRoot} fullWidth id="standard-multiline-static" display="flex" label="Reviewer Notes :" multiline minRows={4}
              onChange={e => {
                setReviewNote(e.target.value);
                setReviewNoteError('');
              }}
              helperText={reviewNoteError}
            />
          </Grid>
        </Box>
        <Box display="flex" justifyContent="center" mb={4} mt={5}>
          <Button
            variant="contained"
            style={{ minWidth: "100px" }}
            color="primary"
            onClick={e => onUserSave(2)}
          >
            Approve Contractor
          </Button>
          <Button variant="outlined"
            onClick={e => onUserSave(3)}
            style={{ marginLeft: "15px" }}
          >
            Reject Contractor
          </Button>
          <Box ml={2}>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default UserDetailView;

UserDetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};

