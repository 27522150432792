import {
    ADD_WORK_ORDER,
    GET_WORK_ORDER,
    GET_WORK_ORDER_DETAILS,
  } from '../../@jumbo/constants/ActionTypes';
  
  const INIT_STATE = {
    users: [],
    currentUser: null,
    // details:null,
  };

  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_WORK_ORDER: {
        return {
          ...state,
          users: action.payload,
        };
      }
      case ADD_WORK_ORDER: {
        return {
          ...state,
          users: [action.payload, ...state.users],
        };
      }
      case GET_WORK_ORDER_DETAILS: {
        return {
          ...state,
          currentUser: action.payload,
        };
      }
      default:
        return state;
    }
  };