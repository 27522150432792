import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';
import { GET_BUDGETMANAGEMENT } from '../../@jumbo/constants/ActionTypes';
import { budgetList, updateBudget } from '../../@jumbo/constants/ApiConstatnt';
import { setAuthUser } from './Auth';
import { history } from 'redux/store';

// get FM provider list
export const getBudgetManagement = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(budgetList)
      .then(data => {
        if (data.status === 200) {
            dispatch(fetchSuccess());
            dispatch({ type: GET_BUDGETMANAGEMENT, payload: data.data.data });
        } else {
          dispatch({ type: GET_BUDGETMANAGEMENT, payload: [] });
          dispatch(fetchError());
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Budget list not found.') {
          dispatch(fetchError());
          dispatch({ type: GET_BUDGETMANAGEMENT, payload: [] });
        }else if(error?.response?.status===401 ){
          dispatch(setAuthUser(null));
          history.push('/signin')
        } 
        else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });

  };
};

// add new FM provider 
export const addBudgetManagement = (users) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(updateBudget, users)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('New Budget added successfully.'));
          }, 2000);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if(error?.response?.status===401 ){
          dispatch(setAuthUser(null));
          history.push('/signin')
        } else{        
          dispatch(fetchError(error?.response?.data?.message));}
      });

  };
};

export const updateBudgetManagement = (users, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(updateBudget, users)
      .then(data => { 
        setTimeout(() => {
          dispatch(fetchSuccess('Budget management updated successfully.'));
        }, 3000);
          if (callbackFun) callbackFun(data.data);
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};
