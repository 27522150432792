import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import AppSelectBox from "@jumbo/components/Common/formElements/AppSelectBox";
// import Dialog from "@material-ui/core/Dialog";
// import Box from "@material-ui/core/Box";
import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import CmtAvatar from "@coremat/CmtAvatar";
import Typography from "@material-ui/core/Typography";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import { useDispatch, useSelector } from "react-redux";
import CmtList from "@coremat/CmtList";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import ClearIcon from "@material-ui/icons/Clear";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import useStyles from "./index.style";
import { Block, CheckCircleOutline } from "@material-ui/icons";
import { Button, Switch, Tooltip } from "@material-ui/core";
import FaceIcon from "@material-ui/icons/Face";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import UserAccessModule from "../UserAccessModule/index";
import AppTextInput from "@jumbo/components/Common/formElements/AppTextInput";
import {
  lettersOnlyMessage,
  requiredMessage,
} from "@jumbo/constants/ErrorMessages";
import { stringOnly } from "@jumbo/constants/ValidationRegex";
import { capitalizeFLetter } from "@jumbo/utils/commonHelper";
import { getUserList, updateUserData } from "redux/actions/UserManagement";
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCard from '@coremat/CmtCard';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import { intranet } from '@fake-db';
import { alpha } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableHeading from '../UserAccessADDEdit/TableHeading';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import {  Checkbox } from '@material-ui/core';
import clsx from 'clsx';

const UserUpdate = ({ open, onCloseDialog, getFMProviderParams }) => {
  const classes = useStyles();
  const { currentUser } = useSelector(({ FMPROVIDER }) => FMPROVIDER);
  const {
    first_name,
    last_name,
    email,
    status,
    user_type,
    fm_module_access,
    _id
  } = currentUser;
  const {
    orderBy,
    order,
    debouncedSearchTerm,
    page,
    rowsPerPage,
    filterOptions,
  } = getFMProviderParams;
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [setEmail] = useState("");
  const [emailError,setEmailError] = useState("");
  const [userStatus, setUserStatus] = useState(null);
  const [userAccess,setUserAccess] = useState([])
  const [value, setValue] = useState('1');
const [contactNo,setContactNo] = useState('');
  const [contactNoError,setContactNoError]=useState('');
const [serviceTypeValue, setServiceTypeValue] = useState('');
const [serviceTypeValueError, setServiceTypeValueError] = useState('');
  const dispatch = useDispatch();
  useEffect(() => {
    setFirstName(first_name);
    setLastName(last_name);
    setUserStatus(status);
    setUserAccess(fm_module_access)
  }, []);
  // console.log("data", currentUser);
  const handleChange = (event) => {
    setUserStatus(userStatus ? false : true);
  };
  const onViewClick = raw => {
    const modifiedAccess = userAccess.map((item ,index)=> item.module_name === raw.module_name ? raw.is_view===true?{ ...raw , is_view:false} :{ ...raw , is_view:true} : item  )
 setUserAccess(modifiedAccess)
   };
   const onAddClick = raw => {
     const modifiedAccess = userAccess.map((item ,index)=> item.module_name === raw.module_name ? raw.is_add===true?{ ...raw , is_add:false} :{ ...raw , is_add:true} : item  )
  setUserAccess(modifiedAccess)
    };
    const onEditClick = raw => {
     const modifiedAccess = userAccess.map((item ,index)=> item.module_name === raw.module_name ? raw.is_edit===true?{ ...raw , is_edit:false} :{ ...raw , is_edit:true} : item  )
  setUserAccess(modifiedAccess)
    };
    const onEnableClick = raw => {
     const modifiedAccess = userAccess.map((item ,index)=> item.module_name === raw.module_name ? raw.is_enable===true?{ ...raw , is_enable:false} :{ ...raw , is_enable:true} : item  )
  setUserAccess(modifiedAccess)
    };
  const onSubmitClick = () => {
    if (!firstName) {
      setFirstNameError(requiredMessage);
    } else if (!firstName.match(stringOnly)) {
      setFirstNameError(lettersOnlyMessage);
    }
    if (!lastName) {
      setLastNameError(requiredMessage);
    } else if (!lastName.match(stringOnly)) {
      setLastNameError(lettersOnlyMessage);
    }
    if (
      firstName &&
      firstName.match(stringOnly) &&
      lastName &&
      lastName.match(stringOnly)
    ) {
      onUserSave();
    }
  };
  const onUserSave = () => {
    const fname = capitalizeFLetter(firstName);
    const lname = capitalizeFLetter(lastName);
    const userDetail = {
      _id:_id,
      email,
      first_name: fname,
      last_name: lname,
      status: userStatus,
      user_type: user_type,
      fm_module_access: user_type===4 ?userAccess:null,
    };

    dispatch(
      updateUserData(userDetail, () => {
        onCloseDialog();
        dispatch(
          getUserList(
            orderBy,
            order,
            debouncedSearchTerm,
            page,
            rowsPerPage,
            filterOptions
          )
        );
      })
    );
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>
        {currentUser ? 'Edit Details' : 'Add New Vendor'}
      </DialogTitle>
      
      <DialogContent dividers>

      <DialogTitle className={classes.dialogTitleRoot}>
          {currentUser ? 'Vendor Details' : 'Vendor Details'}
        </DialogTitle>

        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
          <GridContainer>
            <Grid item xs={12} sm={6}>
              <AppTextInput
                className={classes.root}
                fullWidth
                variant="outlined"
                label="First name"
                value={firstName}
                onChange={e => {
                  setFirstName(e.target.value);
                  setFirstNameError('');
                }}
                helperText={firstNameError}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <AppTextInput
                fullWidth
                variant="outlined"
                label="Last name"
                value={lastName}
                onChange={e => {
                  setLastName(e.target.value);
                  setLastNameError('');
                }}
                helperText={lastNameError}
              />
            </Grid>
          </GridContainer>
        </Box>

        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            fullWidth
            variant="outlined"
            label="Email Address"
            value={email}
            onChange={e => {
              setEmail(e.target.value);
              setEmailError('');
            }}
            helperText={emailError}
          />
        </Box>

        <Box mb={{ xs: 6, md: 5 }}>
          <AppSelectBox
            fullWidth
            // data={users?.data}
            label="Service Type"
            valueKey="_id"
            variant="outlined"
            labelKey="name"
            value={serviceTypeValue}
            onChange={e => {
              setServiceTypeValue(e.target.value)
              setServiceTypeValueError('')
            }}
            helperText={serviceTypeValueError}
          />
        </Box>

        <Box mb={{ xs: 6, md: 5 }}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Status</FormLabel>
            <RadioGroup aria-label="issueManagement" name="issueManagement1" value={value} onChange={handleChange}>
              <FormControlLabel value="1" control={<Radio color="primary" />} label="Active" />
              <FormControlLabel value="2" control={<Radio color="primary" />} label="Inactive" />
            </RadioGroup>
          </FormControl>
        </Box>
        
        <DialogTitle className={classes.dialogTitleRoot}>
          {currentUser ? 'Contact Details' : 'Contact Information'}
        </DialogTitle>
        
          {/* <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer> */}
              {/* <Grid item xs={12} sm={6}> */}
              <Box mb={{ xs: 6, md: 5 }}>
                <AppTextInput
                  className={classes.root}
                  fullWidth
                  variant="outlined"
                  label="Phone No."
                  value={contactNo}
                  onChange={e => {
                    setContactNo(e.target.value);
                    setContactNoError('');
                  }}
                  helperText={contactNoError}
                />
                </Box>
              {/* </Grid> */}
            {/* </GridContainer>
          </Box> */}
        {/* </DialogContent> */}

        <DialogTitle className={classes.dialogTitleRoot}>
          {currentUser ? 'Insurance Details' : 'Insurance Information'}
        </DialogTitle>

        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button variant='outlined' onClick={onCloseDialog}>Cancel</Button>
          <Box ml={2}>
            <Button variant="contained" style={{ minWidth: '100px' }} color="primary" onClick={onSubmitClick}>
              ADD
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default UserUpdate;

UserUpdate.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
