import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import React, { useEffect, useState } from 'react';
import AppTextInput from "@jumbo/components/Common/formElements/AppTextInput";
import makeStyles from "@material-ui/core/styles/makeStyles";
const useStyles = makeStyles((theme) => ({
  suggestion:{
    listStyle: 'none',
    fontSize: '14px',
    lineHeight: 'inherit',
    border: '1px solid',
    borderColor: 'grey',
    cursor: 'pointer',
    "li":{
      
    }
  },
  
  listStyle:{
   
    padding: '5px',
    "&:hover, &.active": {
      backgroundColor: 'rgb(0 0 0 / 4%)',
    },
  },
  editLocation:{
    minWidth:'190px',
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    "& .MuiFormLabel-root": {
      color: '#909090'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink':{
      color: theme.palette.text.primary
    }
  }
}))
const PlacesAutocomplete = ( {setAddress,setLatLang, setLocationObject,address,variantType,addressError,setAddressError}) => {
  const classes = useStyles();
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });
  useEffect(() => {
    if(variantType===1){ setValue(address)}
    
  }, [])
let latlagData ={}
  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    setAddressError('')
    setLatLang('')
    setValue(e.target.value);
    setAddress(e.target.value)
  };

  const handleSelect = ({ description }) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(description, false);
    clearSuggestions();
setAddress(description)
    // Get latitude and longitude via utility functions
    getGeocode({ address: description })
      .then((results) => {getLatLng(results[0]).then(({ lat, lng }) => {
        setLatLang({ lat, lng })
        // console.log("📍 Coordinates: ", { lat, lng });
        
      });  setLocationObject(results[0])})
      
      .catch((error) => {
        console.log("😱 Error: ", error);
      });
  };

  const renderSuggestions = () =>
  // console.log('data',data)
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li className={classes.listStyle} key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <div ref={ref}>
       <AppTextInput
                
                label={variantType===1 ?'':'Address'}
                fullWidth
                multiline
                value={value}
                onChange={handleInput}
                variant={variantType===1?'standard':'outlined'}
                helperText={addressError}
                disabled={!ready}
                placeholder={address}
                className={classes.editLocation}
              />
      {/* <input
        value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder="Where are you going?"
      /> */}
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === "OK" && <ul className={classes.suggestion}>{renderSuggestions()}</ul>}
    </div>
  );
};
export default PlacesAutocomplete