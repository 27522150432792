import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { requiredMessage } from '../../../../../@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import AppSelectBox from '../../../../../@jumbo/components/Common/formElements/AppSelectBox';
import { addNewReturnItem, addNewReturnItemData, getReturnItemList,getWarehouseList } from '../../../../../redux/actions/ReturnItem';
import ReturnItemTable from './ReturnItemTable/index';
import axios from 'services/auth/jwt/config';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import { history } from 'redux/store';
import { setAuthUser } from 'redux/actions/Auth';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import InputLabel from '@material-ui/core/InputLabel';
import UserSelectBox from './UserSelectBox'
import {
  
  GET_RETURNWAREHOUSELIST
} from '@jumbo/constants/ActionTypes';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
    '& .MuiDialog-paperWidthSm': {
      width: '100%',
      maxWidth: '1000px',
    },
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.text.primary,
    },
  },
  root: {
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    "& .MuiFormLabel-root": {
      color: '#909090'
    },
    "& .MuiInputLabel-shrink":{
color:theme.palette.text.primary
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink':{
      color: theme.palette.text.primary
    },
  },
}));

const AddEditUser = ({ open, onCloseDialog, getServiceActivitiesParams }) => {
  const classes = useStyles();
  const { users } = useSelector(({ VENDORMANAGEMENT }) => VENDORMANAGEMENT);
  const { currentUser } = useSelector(({ SERVICEACTIVITIES }) => SERVICEACTIVITIES);
  const { wareHouse } = useSelector(({ RETURNITEM }) => RETURNITEM);
  const { orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions } = getServiceActivitiesParams;
  const [warehouseValue, setWarehouseValue] = useState('');
  const [venderTypeError, setVenderTypeError] = useState('');
  const [warehouseTypeValueError, setWarehouseTypeValueError] = useState('');
  const [venderValue, setVenderValue] = useState('');
  const [getData, setGetData] = useState([]);
  const [wareHouseData, setWareHouseData] = useState([]);
  const [nodataError, setNodataError] = useState('');
  const [noFlagdataError, setNoFlagdataError] = useState([]);
  const [open1, setOpen1] = React.useState(false);
  const dispatch = useDispatch();

useEffect(() => {


  return () => {
    dispatch({ type: GET_RETURNWAREHOUSELIST, payload: null });
  }
}, [])


  useEffect(() => {
    if(venderValue)
    {
      dispatch(getWarehouseList(venderValue));
    }
    
  }, [venderValue])
  const handleConfirmBtn = () => {
    onUserSave(getData);
  };
  // async function fetchData() {
  //   let response = await axios('locations/list', {
  //     params: { sortBy: 'first_name:asc', search: '', page: 1, limit: -1, status: 1, type: 2 },
  //   }).catch(error => {
  //     if (error?.response?.status === 401) {
  //       dispatch(setAuthUser(null));
  //       history.push('/signin');
  //     } else {
  //       dispatch(fetchError(error?.response?.data?.message));
  //     }
  //   });
  //   let user = await response?.data;
  //   setWareHouseData(response?.data?.data);

  //   dispatch(fetchSuccess());
  // }

  // useEffect(() => {
  //   dispatch(fetchStart());
  //   fetchData();
  // }, []);

  useEffect(() => {
    if (warehouseValue && venderValue) {
      dispatch(addNewReturnItem(dataSet));
    }
  }, [warehouseValue, venderValue]);
  
  let newData = getData?.filter(item => item.items_serial_number.length)
  const onSubmitClick = () => {
    
    if (!venderValue) {
      setVenderTypeError(requiredMessage);
    }
    if (!warehouseValue) {
      setWarehouseTypeValueError(requiredMessage);
    }
    if (venderValue && warehouseValue && !newData.length ) {
      setNodataError(
        'Please check the at least one checkbox the available part.',
      );
    }
    // if (venderValue && warehouseValue && !getData.length && !noFlagdataError.length) {
    //   setNodataError(
    //     'Please check the at least one checkbox the available part.',
    //   );
    // }
    //  else if (noFlagdataError.length) {
    //   setNodataError(
    //     'Please enter the quantity value for at least one part and the value should be positive number and less than the available quantity.',
    //   );
    // }

    if (newData.length && venderValue && warehouseValue ) {
      setOpen1(true);
      
    }
    // if (getData.length && venderValue && warehouseValue && !noFlagdataError.length) {
    //   setOpen1(true);
      
    // }
  };

  const dataSet = {
    vendor: venderValue,
    warehouse: warehouseValue,
  };


  const onUserSave = selectedItem => {
    let newData = getData?.filter(item => item.items_serial_number.length)
    const serviceActivityData = {
      items: newData,
    };

    if (currentUser) {
      dispatch();
    } else {
      
      dispatch(
        addNewReturnItemData(venderValue, warehouseValue, serviceActivityData, () => {
          onCloseDialog();
          dispatch(getReturnItemList(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
        }),
      );
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
        <DialogTitle className={classes.dialogTitleRoot}>
          {currentUser ? 'Edit Return Part Details' : 'Add New Return Parts'}
        </DialogTitle>
        <Box>
          <Dialog
            open={open1}
            onClose={() => setOpen1(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogContent>
              <DialogContentText id="alert-dialog-description">Are you sure you want to return Part ?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen1(false)} color="primary" variant="outlined">
                Cancel
              </Button>
              <Button onClick={handleConfirmBtn} variant="contained" color="primary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
        <DialogContent dividers>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12} className={classes.root}>
              {/* <InputLabel id="demo-simple-select-label" style={{marginBottom:"1%",marginLeft:"auto",fontWeight:"700"}}>Vendor</InputLabel> */}
                <UserSelectBox
                  fullWidth
                  label='Vendor'
                  data={users?.data}
                  valueKey="_id"
                  variant="standard"
                  labelKey="first_name"
                  value={venderValue}
                  onChange={e => {
                    setVenderValue(e.target.value);
                    setVenderTypeError('');
                  }}
                  helperText={venderTypeError}
                />
              </Grid>
              <Grid item xs={12} sm={12} className={classes.root}>
              {/* <InputLabel id="demo-simple-select-label" style={{marginBottom:"1%",marginLeft:"auto",fontWeight:"700"}}>Inventory Site</InputLabel> */}
                <AppSelectBox
                  fullWidth
                  label='Inventory Site'
                  data={wareHouse?.data}
                  valueKey="_id"
                  variant="standard"
                  labelKey="name"
                  value={warehouseValue}
                  onChange={e => {
                    setWarehouseValue(prev => e.target.value);
                    setWarehouseTypeValueError('');
                  }}
                  helperText={warehouseTypeValueError}
                />
              </Grid>
            </GridContainer>
          </Box>
          <ReturnItemTable
            setGetData={setGetData}
            setNodataError={setNodataError}
            setNoFlagdataError={setNoFlagdataError}
            noFlagdataError={noFlagdataError}
          />
          
          <p style={{ color: 'red' }}>{nodataError}</p>
          <br />
          <Box display="flex" justifyContent="flex-end" mb={4} >
            <Button onClick={onCloseDialog} variant="outlined">
              Cancel
            </Button>
            <Box ml={2}>
              <Button variant="contained" color="primary" style={{ minWidth: '100px' }} onClick={onSubmitClick}>
                Return
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddEditUser;

AddEditUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
