import {
  ADD_FMPROVIDER,
  DELETE_BULK_FMPROVIDER,
  DELETE_FMPROVIDER,
  EDIT_FMPROVIDER,
  GET_FMPROVIDER,
  GET_LOCATIONLIST,
  SET_ASSET_SERVICETYPE,
  GET_ASSIGNEDUSERLOCATION,
  GET_WAREHOUSE_LIST
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  users: [],
  currentUser: null,
  AssigendUser:null,
  warehouse:[]
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LOCATIONLIST: {
      return {
        ...state,
        users: action.payload,
      };
    }
    case GET_WAREHOUSE_LIST: {
      return {
        ...state,
        warehouse: action.payload,
      };
    }
    case SET_ASSET_SERVICETYPE: {
      return {
        ...state,
        currentUser: action.payload,
      };
    }
    case ADD_FMPROVIDER: {
      return {
        ...state,
        users: [action.payload, ...state.users],
      };
    }
    case EDIT_FMPROVIDER: {
      return {
        ...state,
        users: state.users.map(user => (user.id === action.payload.id ? action.payload : user)),
      };
    }
    case GET_ASSIGNEDUSERLOCATION: {
      return {
        ...state,
        AssigendUser: action.payload,
      };
    }
    case DELETE_FMPROVIDER: {
      return {
        ...state,
        users: state.users.filter(user => user.id !== action.payload),
      };
    }
    case DELETE_BULK_FMPROVIDER: {
      return {
        ...state,
        users: state.users.filter(user => !action.payload.includes(user.id)),
      };
    }
    default:
      return state;
  }
};
