import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '../../../../../@jumbo/components/Common/formElements/AppTextInput';
import Button from '@material-ui/core/Button';
import { requiredMessage } from '../../../../../@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { alpha } from '@material-ui/core/styles';
import AppSelectBox from '../../../../../@jumbo/components/Common/formElements/AppSelectBox';
import {emptySpace} from '../../../../../@jumbo/constants/ValidationRegex'
import { addNewIssueManagement, getIssueManagement, UpdateIssue } from '../../../../../redux/actions/IssueManagement';
import { capitalizeFLetter } from '../../../../../@jumbo/utils/commonHelper';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  root: {
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
  },
  appSelectRoot: {
    "& .MuiFormLabel-root": {
      color: '#c7c3bf'
    }
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.text.primary,
    },
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.text.primary),
    },
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
  },
}));

const AddEditUser = ({ open, onCloseDialog, getIssueManagementParams }) => {
  const classes = useStyles();
  const { users } = useSelector(({ SERVICETYPE }) => SERVICETYPE);
  const { currentUser } = useSelector(({ ISSUEMANAGEMENT })=> ISSUEMANAGEMENT);
  const { orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions } = getIssueManagementParams;
  const [serviceTypeValueError, setServiceTypeValueError] = useState('');
  const [issue, setIssue] = useState('');
  const [nameError, setNameError] = useState('');
  const [value, setValue] = useState('1');
  const [serviceTypeValue, setServiceTypeValue] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser) {
      setServiceTypeValue(currentUser?.service_type_id);
      setValue(currentUser?.fm_issue_status);
      setIssue(currentUser?.name);
    }
  }, [currentUser]);

  const handleChange = event => {
    setValue(event.target.value);
  };
  const onSubmitClick = () => {
    
    if (issue.match(emptySpace)) {
      setNameError(requiredMessage);
    }
     if (!serviceTypeValue) {
      setServiceTypeValueError(requiredMessage);
    } 
    if(!issue.match(emptySpace) && serviceTypeValue){
      onUserSave();
    }
  };

  const onUserSave = () => {
    const iname = capitalizeFLetter(issue)
    const issueManagementData = {
      name:iname,
      status: value,
      service_type_id: serviceTypeValue,
    };

    if (currentUser) {
      dispatch(
        UpdateIssue(issueManagementData,currentUser._id, () => {
          onCloseDialog();
          dispatch(getIssueManagement(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
        }),
      );
    } else {
      dispatch(
        addNewIssueManagement(issueManagementData, () => {
          onCloseDialog();
          dispatch(getIssueManagement(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
        }),
      );
    }
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>
        {currentUser ? 'Edit Issue Details' : 'Add New Issue'}
      </DialogTitle>
      <DialogContent dividers>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }} style={{maxWidth:'300px'}}>
          <GridContainer>
            <Grid item xs={12} sm={12}>
              <AppTextInput
              className={classes.textFieldRoot}
                fullWidth
                variant="outlined"
                label="Issue"
                value={issue}
                onChange={e => {
                  setIssue(e.target.value);
                  setNameError('');
                }}
                disabled={currentUser?.is_vcg_creation?true:false}
                helperText={nameError}
              />
            </Grid>
            <Grid item xs={12} sm={12} className={currentUser?.is_vcg_creation?classes.appSelectRoot:""}>
              <AppSelectBox
                 className={classes.root}
                fullWidth
                data={users?.data}
                label="Service Type"
                valueKey="_id"
                variant="outlined"
                labelKey="name"
                value={serviceTypeValue}
                onChange={e => {setServiceTypeValue(e.target.value)
                  setServiceTypeValueError('')
                }}
                disabled={currentUser?.is_vcg_creation?true:false}
                helperText={serviceTypeValueError}
              />
            </Grid>
          </GridContainer>
        </Box>

        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button variant='outlined' onClick={onCloseDialog}>Cancel</Button>
          <Box ml={2}>
            <Button variant="contained" style={{minWidth:'100px'}} color="primary" onClick={onSubmitClick} disabled={currentUser?.is_vcg_creation?true:false}>
            {currentUser?'Update':'Add'} 
            </Button>
          </Box>
        </Box>

      </DialogContent>
    </Dialog>
  );
};

export default AddEditUser;

AddEditUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};