import { fetchError, fetchStart, fetchSuccess } from './Common';
// import axios from 'axios';
import axios from '../../services/auth/jwt/config';
import {
  
  DELETE_BULK_CONTACTUSQUERY,
  DELETE_CONTACTUSQUERY,
  EDIT_CONTACTUSQUERY,
  GET_CONTACTUSQUERY,
  SET_CONTACTUSQUERY_DETAILS,
  GET_CONTACTUSDETAILS
} from '../../@jumbo/constants/ActionTypes';
import {listContactUsQuery ,detailsContactUsQuery,statusContactUsQuery,addContactUs} from '../../@jumbo/constants/ApiConstatnt'

export const getContactUsQuery = (sortBy, sort, filterOptions, searchTerm, page, limit, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(listContactUsQuery, {
        params: { sortBy: `${sortBy}:${sort}`, q: searchTerm, page: page + 1, limit: limit, status: filterOptions[0] },
      })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_CONTACTUSQUERY, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch({ type: GET_CONTACTUSQUERY, payload: [] });
          dispatch(fetchError());
          
        }
      })
      .catch(error => {
        if (error?.response?.data?.message == 'Contact Us list not found.') {
          
          dispatch(fetchError(error?.response?.data?.message));
          dispatch({ type: GET_CONTACTUSQUERY, payload: [] });
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

export const getContactUsDetails = (userId) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(detailsContactUsQuery+userId)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_CONTACTUSDETAILS, payload: data.data.data });
          // if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'FM Provider not found.') {
          dispatch(fetchError(error?.response?.data?.message));
          dispatch({ type: GET_CONTACTUSDETAILS, payload: [] });
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};


export const setCurrentQuery = user => {
  return dispatch => {
    dispatch({ type: SET_CONTACTUSQUERY_DETAILS, payload: user });
  };
};

export const addNewContactUs = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(addContactUs, user)
      .then(data => {
        // console.log('add data', data);
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess(data.data.message));
          }, 2000);

          // dispatch({ type:  ADD_FMPROVIDER, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const sentMailToUser = () => {
  return dispatch => {
    dispatch(fetchSuccess('Email has been sent to user successfully'));
  };
};

export const updateUser = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/users', user)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('Selected user was updated successfully .'));
          dispatch({ type: EDIT_CONTACTUSQUERY, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const updateContactUsQueryStatus = (data, callbackFun) => {
  // console.log("statuscallid",data)

  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(statusContactUsQuery+data._id)
      .then(response => {
        // console.log("statusresponse",response)
        if (data) {
          // alert("hello")
          setTimeout(() => {
            dispatch(fetchSuccess('Contact Us Query status was updated successfully.'));
          }, 2000);
          // dispatch({ type: EDIT_USER, payload: response.data });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};


export const deleteBulkUsers = (userIds, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/users/bulk-delete', { userIds })
      .then(response => {
        if (response.status === 200) {
          dispatch(fetchSuccess('Selected users were deleted successfully.'));
          dispatch({ type: DELETE_BULK_CONTACTUSQUERY, payload: userIds });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const deleteUser = (userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete('/users', { params: { id: userId } })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('Selected user was deleted successfully.'));
          dispatch({ type: DELETE_CONTACTUSQUERY, payload: userId });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};
