import React from "react";
import { Redirect, Route, Switch } from "react-router";
import Login from "./Auth/Login";
import Signup from "./Auth/Register";
import ForgotPassword from "./Auth/ForgotPassword";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import UserManagement from "./modules/UserManagement/index";
import SubAdmin from "./modules/SubAdmin/index";
import Profile from "./Apps/Profile/index";
import AboutUs from "./ContentManager/AboutUs/index";
import PrivacyPolicy from "./ContentManager/PrivacyPolicy/index";
import TermsAndConditions from "./ContentManager/TermsAndCondition/index";
import FAQ from "./ContentManager/FAQ/index";
import Home from "./Dashboards/Home";
import Settings from "./modules/Settings/index";
import ContactUs from "./ContactUsQuery/Contactus";
import VendorManagement from "./modules/VendorManagement/index";
import FMAdminDemo from "FMAdminDemo";
import ItemsManagement from "./modules/ItemsManagement/index";
import UserDetailView from "./modules/VendorManagement/UserDetailView/index";
import PlannedMaintance from "./modules/PlannedMaintance/index";
import ServiceRequest from "./modules/ServiceRequest/index";
import BudgetManagement from "./modules/BudgetManagement/index";
import AssignedSite from "./modules/AccountUser/AssignedSite/index";
import CallCenterServiceRequest from "./modules/CallCenter/CallCenterServiceRequest/index";
import LocationDetail from "./modules/Settings/Locations/LocationDetails/index";
import Vendors from "./modules/AccountUser/Vendor/index";
import Account from "./modules/Account/index";
import PaymentToVendors from "./modules/AccountUser/PaymentToVendor";
import ContactUsQueries from "./modules/ContactUsQueries/index";
import Location from "./modules/Settings/Locations/index";
import { Helmet } from "react-helmet";
import { project_name } from "../config";

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);

  return (
    <Route
      {...rest}
      render={(props) =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/signin",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  const { authUser } = useSelector(({ auth }) => auth);

  const location = useLocation();

  if (location.pathname === "" || location.pathname === "/") {
    if (authUser?.user_type === 6) {
      return <Redirect to={"/service-requests-call-center"} />;
    } else {
      return <Redirect to={"/dashboard"} />;
    }
  } else if (authUser && location.pathname === "/signin") {
    if (authUser?.user_type === 6) {
      return <Redirect to={"/service-requests-call-center"} />;
    } else {
      return <Redirect to={"/dashboard"} />;
    }
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{project_name}-Admin Dashboard</title>
        <meta name="title" content={project_name} />
        <meta name="description" content={project_name} />
        <meta name="og:title" content={project_name} />
        <meta name="og:description" content={project_name} />
      </Helmet>
      <Switch>
        <Route path="/signin" component={Login} />
        <Route path="/signup" component={Signup} />
        <Route path="/forgot-password" component={ForgotPassword} />
        {/* coustome routes */}
        <RestrictedRoute path="/profile" component={Profile} />
        <RestrictedRoute path="/user-management" component={UserManagement} />
        <RestrictedRoute path="/contact-us" component={ContactUs} />
        <RestrictedRoute path="/about-us" component={AboutUs} />
        <RestrictedRoute path="/privacy-policy" component={PrivacyPolicy} />
        <RestrictedRoute
          path="/terms-and-conditions"
          component={TermsAndConditions}
        />
        <RestrictedRoute path="/faq" component={FAQ} />
        <RestrictedRoute path="/subadminmanagement" component={SubAdmin} />
        <RestrictedRoute path="/dashboard" component={Home} />

        <RestrictedRoute path="/settings" component={Settings} />
        <RestrictedRoute
          exact
          path="/location-management"
          component={Location}
        />

        <RestrictedRoute
          exact
          path="/vendor-management"
          component={VendorManagement}
        />
        <RestrictedRoute
          exact
          path="/vendor-management/vendor-management-details/:id"
          component={UserDetailView}
        />
        <RestrictedRoute path="/items-management" component={ItemsManagement} />
        <RestrictedRoute
          path="/budget-management"
          component={BudgetManagement}
        />
        {/* <RestrictedRoute path="/budget-management-edit" component={EditPropertyCard} /> */}
        {/* <RestrictedRoute path='*' exact={true} component={FMAdminDemo} /> */}
        <RestrictedRoute path="/items-management" component={ItemsManagement} />
        <RestrictedRoute path="/settings" component={Settings} />
        {/* <RestrictedRoute path="/vendor-management" component={VendorManagement} /> */}
        <RestrictedRoute
          path="/location-management/:id"
          component={LocationDetail}
        />
        <RestrictedRoute
          path="/planned-maintenance"
          component={PlannedMaintance}
        />
        <RestrictedRoute path="/account-details" component={Account} />

        {/* <RestrictedRoute path="/vendor-management/vendor-management-details" component={UserDetailView} /> */}
        {/* <RestrictedRoute path="/service-requests" component={FMAdminDemo} /> */}
        <RestrictedRoute path="/service-requests" component={ServiceRequest} />
        <RestrictedRoute path="/assigned-sites" component={AssignedSite} />
        <RestrictedRoute path="/vendors" component={Vendors} />
        <RestrictedRoute
          path="/payments-to-vendors"
          component={PaymentToVendors}
        />
        <RestrictedRoute
          path="/service-requests-call-center"
          component={CallCenterServiceRequest}
        />
        <RestrictedRoute
          path="/contactusqueries"
          component={ContactUsQueries}
        />
        <RestrictedRoute path="*" exact={true} component={FMAdminDemo} />

        {/*<Route path="/layout-builder" component={LayoutBuilder} />*/}
      </Switch>

      {/* {location.pathname !== '/signin' && location.pathname !== '/signup' && location.pathname !== '/forgot-password' && (
        <TourGuide />
      )} */}
    </React.Fragment>
  );
};

export default Routes;
