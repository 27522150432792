import {
  ADD_ASSIGNITEM,
  DELETE_BULK_ASSIGNITEM,
  DELETE_ASSIGNITEM,
  EDIT_ASSIGNITEM,
  GET_ASSIGNITEM,
  SET_ASSIGNITEM_DETAILS,
  GET_ASSIGNITEMDETAILS,
  GET_ASSIGNITEMADD,
  GET_WAREHOUSE
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  users: [],
  currentUser: null,
  details:null,
  addUser:null,
  warehouse:null

};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ASSIGNITEM: {
      return {
        ...state,
        users: action.payload,
      };
    }

    case GET_WAREHOUSE: {
      return {
        ...state,
        warehouse: action.payload,
      };
    }


    case GET_ASSIGNITEMADD: {
      return {
        ...state,
        addUser: action.payload,
      };
    }



    case GET_ASSIGNITEMDETAILS: {
      return {
        ...state,
        details: action.payload,
      };
    }


    case SET_ASSIGNITEM_DETAILS: {
      return {
        ...state,
        currentUser: action.payload,
      };
    }
    case ADD_ASSIGNITEM: {
      return {
        ...state,
        users: [action.payload, ...state.users],
      };
    }
    case EDIT_ASSIGNITEM: {
      return {
        ...state,
        users: state.users.map(user => (user.id === action.payload.id ? action.payload : user)),
      };
    }
    case DELETE_ASSIGNITEM: {
      return {
        ...state,
        users: state.users.filter(user => user.id !== action.payload),
      };
    }
    case DELETE_BULK_ASSIGNITEM: {
      return {
        ...state,
        users: state.users.filter(user => !action.payload.includes(user.id)),
      };
    }
    default:
      return state;
  }
};
