import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import Button from '@material-ui/core/Button';
import AppSelectBox from '@jumbo/components/Common/formElements/AppSelectBox';
import { requiredMessage } from '@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { numberOnly, numberOnly2, emptySpace } from '@jumbo/constants/ValidationRegex';
import { addItemsLocation, getItemLocation, updateItemLocation } from '../../../../../../../redux/actions/LocationsItems';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.text.primary,
    },
  },
  root: {
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    "& .MuiFormLabel-root": {
      color: '#909090'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink':{
      color: theme.palette.text.primary
    }
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  titleRoot1: {
    fontWeight: '600',
    marginLeft: '15px',
    whiteSpace: 'nowrap',
    '&.MuiTypography-body1': {
      fontSize: 16,
    },
  },
  titleRoot2: {
    fontWeight: '300',
    marginLeft: '5px',
    fontSize: 16,
    textOverflow: 'ellipsis',
    maxWidth: '350px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  appSelectRoot: {
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    "& .MuiFormLabel-root": {
      color: '#909090'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink':{
      color: theme.palette.text.primary
    }
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    titleRoot: {
      fontSize: 14,
      letterSpacing: 0.25,
      color: theme.palette.text.primary,
      textOverflow: 'ellipsis',
      maxWidth: '150px',
      display: 'block',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
}));

const AddEditUser = ({ open, onCloseDialog, getSERVICETYPEParams, id }) => {
  const classes = useStyles();
  const { currentUser } = useSelector(({ LocationsItems }) => LocationsItems);
  const { users } = useSelector(({ ItemsList }) => ItemsList);
  const { orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions } = getSERVICETYPEParams;
  const [itemName, setItemName] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState('');
  const [price, setPrice] = useState('');
  const [serviceTypeValue, setServiceTypeValue] = useState('');
  const [designation, setDesignation] = useState('');
  const [phones, setPhones] = useState([{ phone: '', label: 'home' }]);
  const [item, setItem] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [statusGet, setStatusGet] = useState('');
  const [addItem, setAddItem] = useState('');
  const [quantity, setQuantity] = useState('');
  const [qError, setQError] = useState('')
  const [threshold, setThreshold] = useState('');
  const [tError, setTError] = useState('')
  const [itemError, setItemError] = useState('')
  const [warehouse, setWarehouse] = useState('')
  // const [errorState, setErrorState] = useState('')
  // errorState
  const [serialNumber, setSerialNumber] = useState([]);
  const [serialNumberError, setSerialNumberError] = useState('');
  const [inputList, setInputList] = useState([''])
  const dispatch = useDispatch();
  const [itemNameEdit, setItemNameEdit] = useState('');
  const [finalOldSerialNumber, setFinalOldSerialNumber]= useState('')
  // console.log("inputList", inputList);
  // console.log('Current User',currentUser);
  // console.log("serialNumber",serialNumber)

  useEffect(() => {
    if (currentUser) {
      setAddItem(currentUser?.item_id)
      setQuantity(currentUser?.remaining_quantity?.toString())
      setItemNameEdit(currentUser?.name)
      setThreshold(currentUser?.threshold?.toString())
      setInputList(currentUser?.remaining_items_serial_number)
    }
  }, [currentUser]);
  console.log("finalOldSerialNumber",finalOldSerialNumber)

  useEffect(() => {
    let check = inputList?.map((item)=>{
      let check2 = currentUser?.remaining_items_serial_number?.filter((a)=>{
        if(a!=item) return a;
      })
      setFinalOldSerialNumber(check2)
    })
  }, [currentUser]);

  useEffect(()=> {
    setQuantity(inputList.length);
  },[inputList.length])

  let FindMatchSerial = inputList.filter((el, index) => inputList.indexOf(el) !== index)
console.log("inputList",inputList)
console.log("currentUser",currentUser)
  const onSubmitClick = () => {

    if (!addItem) {
      setItemError(requiredMessage);
    }
    if (!quantity) {
      setQError(requiredMessage)
    }
    else if (!quantity.toString().match(numberOnly2) || Number(quantity) < 0) {
      setQError('Enter positive number only.');
    }
    if (!threshold) {
      setTError(requiredMessage)
    }
    else if (!threshold.toString().match(numberOnly2) || threshold.match(emptySpace)) {
      setTError('Enter positive number only.')
    }
    else if(Number(threshold) < 1) {
      setTError("Threshold Quantity should not be 0.")
    }
    if(FindMatchSerial.length >= 1)
    {
      setSerialNumberError("Please enter different serial number.")
    }
    // if (inputList?.map(data=>{return(data?.includes(''))}) || inputList?.map(data=>{return(data?.match(emptySpace))})) {
    //   setSerialNumberError(requiredMessage)
    // }
    // !inputList?.map(data=>{return(data?.includes(''))}) && !inputList?.map(data=>{return(data?.match(emptySpace))}) && 

    if(inputList.includes('') || inputList.includes(emptySpace)) setSerialNumberError(requiredMessage)
    
    if (quantity && quantity?.toString().match(numberOnly) && Number(quantity) >= 0 && 
        addItem && 
        !threshold.match(emptySpace) && threshold?.toString().match(numberOnly) && threshold && Number(threshold) > 0 &&
        inputList && !inputList.includes('') &&
        // !inputList?.includes('') && !inputList?.includes(emptySpace) && 
        !FindMatchSerial.length){
      onUserSave();
    }
  };

  const onUserSave = () => {

    if (currentUser) {
      const userDetail = {
        warehouseId: id,
        item_id: addItem,
        new_quantity: Number(quantity),
        threshold: Number(threshold),
        items_serial_number: inputList, 
      }
      dispatch(
        updateItemLocation(userDetail, () => {
          onCloseDialog();
          dispatch(getItemLocation(id, orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
        }),
      );
    } else {
      const userDetail = {
        warehouseId: id,
        item_id: addItem,
        quantity: quantity,
        threshold: threshold,
        items_serial_number: inputList, 
      }
      dispatch(
        addItemsLocation(userDetail, () => {
          onCloseDialog();
          dispatch(getItemLocation(id, orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
        }),
      );
    }
  };

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    const list = [...inputList];
    list[index] = value.slice(0, 24);
    setInputList(list);
  }
  const handleAddMoreRow = () => {
    setInputList([...inputList,''])
  }

  const handleRemovePart =(index)=>{
    const list = [...inputList];
    list.splice(index, 1)
    setInputList(list)
  }

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>{currentUser ? 'Edit Part Detail' : 'Add New Part'}</DialogTitle>
      <DialogContent dividers>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
          <GridContainer className={classes.titleRoot}>
            <Grid item xs={12} sm={12} className={currentUser ? classes.appSelectBox : classes.root}>
              {currentUser ? <AppTextInput
                fullWidth
                label="Part"
                labelkey="name"
                disabled={currentUser ? true : false}
                variant="outlined"
                value={itemNameEdit}
              /> :
                <AppSelectBox
                  fullWidth
                  data={users?.data}
                  label="Part"
                  valueKey="_id"
                  variant="outlined"
                  labelKey="name"
                  value={addItem}
                  disabled={currentUser}
                  onChange={(e) => {
                    setAddItem(e.target.value);
                    setItemError('');
                  }}
                  helperText={itemError}
                />
              }
            </Grid>

            <Grid item xs={6} sm={6}>
              <AppTextInput
                // className={classes.root}
                // pattern="[1-9]"
                InputProps={{
                  pattern: "[1-9]",
                  inputProps: { min: 0, pattern: "[1-9]" },
                }}
                fullWidth
                // type='number'
                variant="outlined"
                label="Quantity"
                value={inputList.length}
                disabled={inputList.length>=0}
                // onChange={e => { setQuantity(e.target.value); setQError('') }}
                onKeyPress={event => {
                          if(['-','+','e','.','/','*'].includes(event.key)) {
                            event.preventDefault();
                          }
                        }}
                onPaste={(e)=>{
                          e.preventDefault()
                          return false;
                        }} 
                onCopy={(e)=>{
                          e.preventDefault()
                          return false;
                        }}
                helperText={qError}
              />
            </Grid>

            <Grid item xs={6} sm={6}>
              <AppTextInput
                className={classes.root}
                // pattern="[1-9]"
                InputProps={{
                  pattern: "[1-9]",
                  inputProps: { min: 1, pattern: "[1-9]" },
                }}
                fullWidth
                variant="outlined"
                label="Set Threshold Quantity"
                value={threshold}
                onChange={e => { setThreshold(e.target.value); setTError('') }}
                onKeyPress={event => {
                          if(['-','+','e','.','/','*'].includes(event.key)) {
                            event.preventDefault();
                          }
                        }}
                onPaste={(e)=>{
                          e.preventDefault()
                          return false;
                        }} 
                onCopy={(e)=>{
                          e.preventDefault()
                          return false;
                        }}
                helperText={tError}
              />
            </Grid>
            
            {inputList?.map((x, i) => {
              console.log("x",x)
              return (
                <>
                  <Grid item xs={10} sm={10} className={currentUser ? classes.appSelectRoot : classes.root}>
                    <AppTextInput
                      // className={classes.root}
                      // pattern="[1-9]"
                      InputProps={{
                        pattern: "[1-9]",
                        inputProps: { min: 0, pattern: "[1-9]" },
                      }}
                      fullWidth
                      variant="outlined"
                      label="Serial Number"
                      name="serialNumber"
                      value={x}
                      onChange={e => { handleInputChange(e, i); setSerialNumberError('')}}
                      onKeyPress={event => {
                        if (['-', '+', 'e', '.',' '].includes(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onPaste={(e) => {
                        e.preventDefault()
                        return false;
                      }}
                      onCopy={(e) => {
                        e.preventDefault()
                        return false;
                      }}
                      disabled={currentUser?.remaining_items_serial_number?.includes(x) && i<currentUser?.remaining_items_serial_number?.length}
                      helperText={ FindMatchSerial.includes(x) && !x.match(emptySpace) && x ? "Please enter different serial number." 
                                    : (!x && serialNumberError) || (x.match(emptySpace) && serialNumberError) ? "This field is required!" : ''}
                    />
                  </Grid>
                  <Grid item xs={2} sm={2}>
                  {inputList.length !== 1 && (
                  <IconButton aria-label="delete" onClick={()=>handleRemovePart(i)}>
                    <DeleteIcon />
                  </IconButton>
                   )}
                  </Grid>
                </>
              );
            })
            }
            
            <Box ml={4}><Button variant="outlined" onClick={handleAddMoreRow}>+ Add More</Button></Box>

            {/* <Box ml={5}>
              <a style={{ cursor: 'pointer' }} onClick={handleAddMoreRow}>
                {'Add more'}
              </a>
            </Box> */}

          </GridContainer>
        </Box>
        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button variant="outlined" onClick={onCloseDialog}>Cancel</Button>
          <Box ml={2}>
            <Button variant="contained" color="primary" onClick={onSubmitClick}>
              {currentUser ? 'Update Part' : 'Add Part'}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditUser;

AddEditUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
