import React, {  useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '../../../../@jumbo/components/Common/formElements/AppTextInput';
import Button from '@material-ui/core/Button';
import { emailNotValid, lettersOnlyMessage, requiredMessage } from '../../../../@jumbo/constants/ErrorMessages';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { capitalizeFLetter, isValidEmail } from '../../../../@jumbo/utils/commonHelper';
import { getUserList, addNewUser } from '../../../../redux/actions/UserManagement';
import { stringOnly } from '../../../../@jumbo/constants/ValidationRegex';
import AppSelectBox from '@jumbo/components/Common/formElements/AppSelectBox';
import CmtCardHeader from '../../../../@coremat/CmtCard/CmtCardHeader';
import CmtCard from '../../../../@coremat/CmtCard';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CmtCardContent from '../../../../@coremat/CmtCard/CmtCardContent';
import { alpha } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableHeading from '../UserAccessADDEdit/TableHeading';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Checkbox } from '@material-ui/core';
import clsx from 'clsx';
const useStyles = makeStyles(theme => ({
  root: {
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    "& .MuiFormLabel-root": {
      color: '#909090'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink':{
      color: theme.palette.text.primary
    }
  },
  dialogRoot: {
    position: 'relative',
    '& .MuiDialog-paperWidthSm': {
      width: '100%',
      maxWidth: '1000px',
    },
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 19,
      color: theme.palette.text.primary,
    },
  },
  cardRoot: {
    position: 'relative',
    '& .Cmt-card-content': {
      padding: 0,
      paddingBottom: 24,
    },
  },
  scrollbarRoot: {
    height: 340,
  },
  tableRowRoot: {
    position: 'relative',
    transition: 'all .2s',
    fontWeight: 300,
    borderTop: `solid 1px ${theme.palette.borderColor.main}`,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
      transform: 'translateY(-4px)',
      boxShadow: `0 3px 10px 0 ${alpha(theme.palette.common.dark, 0.2)}`,
      borderTopColor: 'transparent',
      '& $tableCellRoot': {
        color: theme.palette.text.primary,
        '&.success': {
          color: theme.palette.success.main,
        },
      },
    },
  },
  tableCellRoot: {
    paddingLeft: 10,
    paddingRight: 10,
  padding:0,
    fontSize: 14,
    letterSpacing: 0.25,
    textAlign: 'center',
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    borderBottom: '0 none',
    width:'240px',
    '&:first-child': {
      paddingLeft: 24,
      textAlign: 'left',
    },
    '& .Cmt-avatar-more': {
      fontSize: 10,
      color: theme.palette.primary.main,
    },
   '& .MuiCheckbox-root': {
      color: theme.palette.primary.main
  }
  },
  stickyHead:{
    '& .MuiTableCell-stickyHeader':{
      background:theme.palette.primary.main,
    },
    '& .MuiTable-stickyHeader': {
      borderCollapse: 'collapse !important' 
  },
  },
  header:{
    padding:'5px 0px 15px 1px',
    fontSize:'18px',
    '& .MuiTypography-h4':{
      fontSize:'18px'
    }
  }
}));

const userRoleArray = [
  { name: 'FM Sub-Admin', _id: 4 },
  { name: 'Call Center User', _id: 6 },
  { name: 'Account User', _id: 5 },
];
// Add new user
const AddEditUser = ({ open, onCloseDialog, getFMProviderParams }) => {
  const classes = useStyles();
  const { orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions } = getFMProviderParams;
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [userRole, setUserRole] = useState(4);
  const [userAccess, setUserAccess] = useState([
    {
      module_name: 'User Management',
      is_view: false,
      is_add: false,
      is_edit: false,
      is_enable: false,
    },
    {
      module_name: 'Service Types',
      is_view: false,
      is_add: false,
      is_edit: false,
      is_enable: false,
    },
    {
      module_name: 'Service Activities',
      is_view: false,
      is_add: false,
      is_edit: false,
      is_enable: false,
    },
    {
      module_name: 'Customize',
      is_view: false,
      is_add: false,
      is_edit: false,
      is_enable: false,
    },
    {
      module_name: 'Locations',
      is_view: false,
      is_add: false,
      is_edit: false,
      is_enable: false,
    },
    {
      module_name: 'Budget Management',
      is_view: false,
      is_add: false,
      is_edit: false,
      is_enable: false,
    },
    {
      module_name: 'Parts Management',
      is_view: false,
      is_add: false,
      is_edit: false,
      is_enable: false,
    },
    {
      module_name: 'Assign Parts',
      is_view: false,
      is_add: false,
      is_edit: false,
      is_enable: false,
    },
    {
      module_name: 'Return Parts',
      is_view: false,
      is_add: false,
      is_edit: false,
      is_enable: false,
    },
    {
      module_name: 'Vendor Management',
      is_view: false,
      is_add: false,
      is_edit: false,
      is_enable: false,
    },
    {
      module_name: 'Service Requests',
      is_view: false,
      is_add: false,
      is_edit: false,
      is_enable: false,
    },
    {
      module_name: 'Planned Maintenance',
      is_view: false,
      is_add: false,
      is_edit: false,
      is_enable: false,
    },
  ]);

  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [emailError, setEmailError] = useState('');

  const dispatch = useDispatch();
  const onSubmitClick = () => {
    if (!firstName) setFirstNameError(requiredMessage);
    else if (!firstName.match(stringOnly)) setFirstNameError(lettersOnlyMessage);

    if (!lastName) setLastNameError(requiredMessage);
    else if (!lastName.match(stringOnly)) setLastNameError(lettersOnlyMessage);

    if (!email) setEmailError(requiredMessage);
    else if (!isValidEmail(email)) setEmailError(emailNotValid);

    if (firstName && firstName.match(stringOnly) && lastName && lastName.match(stringOnly) && email && isValidEmail(email))
      onUserSave();
  };
  const onViewClick = raw => {
    const modifiedAccess = userAccess.map(item =>
      item.module_name === raw.module_name
        ? raw.is_view === true && raw.is_add === false && raw.is_edit === false && raw.is_enable === false
          ? { ...raw, is_view: false }
          : { ...raw, is_view: true }
        : item,
    );
    setUserAccess(modifiedAccess);
  };
  const onAddClick = raw => {
    const modifiedAccess = userAccess.map(item =>
      item.module_name === raw.module_name
        ? raw.is_add === true
          ? { ...raw, is_add: false }
          : { ...raw, is_add: true, is_view: true }
        : item,
    );
    setUserAccess(modifiedAccess);
  };
  const onEditClick = raw => {
    const modifiedAccess = userAccess.map(item =>
      item.module_name === raw.module_name
        ? raw.is_edit === true
          ? { ...raw, is_edit: false }
          : { ...raw, is_edit: true, is_view: true }
        : item,
    );
    setUserAccess(modifiedAccess);
  };
  const onEnableClick = raw => {
    const modifiedAccess = userAccess.map(item =>
      item.module_name === raw.module_name
        ? raw.is_enable === true
          ? { ...raw, is_enable: false }
          : { ...raw, is_enable: true, is_view: true }
        : item,
    );
    setUserAccess(modifiedAccess);
  };
  const onUserSave = () => {
    const fname = capitalizeFLetter(firstName);
    const lname = capitalizeFLetter(lastName);
    const userDetail = {
      email,
      first_name: fname,
      last_name: lname,
      status: 1,
      user_type: userRole,
      fm_module_access: userRole === 4 ? userAccess : null,
    };

    dispatch(
      addNewUser(userDetail, () => {
        onCloseDialog();
        dispatch(getUserList(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
      }),
    );
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>Add New User</DialogTitle>
      <DialogContent dividers>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
          <GridContainer>
            <Grid item xs={12} sm={6}>
              <AppTextInput
                className={classes.root}
                fullWidth
                variant="outlined"
                label="First Name"
                value={firstName}
                onChange={e => {
                  setFirstName(e.target.value);
                  setFirstNameError('');
                }}
                helperText={firstNameError}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AppTextInput
                className={classes.root}
                fullWidth
                variant="outlined"
                label="Last Name"
                value={lastName}
                onChange={e => {
                  setLastName(e.target.value);
                  setLastNameError('');
                }}
                helperText={lastNameError}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <AppTextInput
                className={classes.root}
                fullWidth
                variant="outlined"
                label="Email Address"
                value={email}
                onChange={e => {
                  setEmail(e.target.value);
                  setEmailError('');
                }}
                helperText={emailError}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <AppSelectBox
                fullWidth
                data={userRoleArray}
                label="User Role"
                valueKey="_id"
                variant="outlined"
                labelKey="name"
                value={userRole}
                onChange={e => {
                  setUserRole(e.target.value);
                }}
              />
            </Grid>
          </GridContainer>
        </Box>

        {userRole === 4 ? (
          <Box>
             <CmtCardHeader className={classes.header} style={{fontSize:'18px'}} title="Module Permissions"></CmtCardHeader>
            <CmtCard className={classes.cardRoot}>
             
              <CmtCardContent>
                <PerfectScrollbar className={classes.scrollbarRoot}>
                  <Table stickyHeader aria-label="sticky table" className={classes.stickyHead} style={{borderCollapse:'collapse'}}>
                    <TableHead>
                      <TableHeading />
                    </TableHead>
                    <TableBody>
                      {userAccess?.map((row, index) => (
                        <TableRow className={clsx(classes.tableRowRoot)} key={index}>
                          <TableCell className={classes.tableCellRoot}>
                            <Box>{row?.module_name}</Box>
                          </TableCell>
                          <TableCell className={classes.tableCellRoot}>
                            <Box>
                              {' '}
                              <Checkbox  checked={row?.is_view} onClick={e => onViewClick(row)} />
                            </Box>
                          </TableCell>
                          <TableCell className={classes.tableCellRoot}>
                            <Box>
                              {' '}
                              <Checkbox  checked={row?.is_add} onClick={e => onAddClick(row)} />
                            </Box>
                          </TableCell>
                          <TableCell className={classes.tableCellRoot}>
                            <Box>
                              {' '}
                              <Checkbox checked={row?.is_edit} onClick={e => onEditClick(row)} />
                            </Box>
                          </TableCell>
                          <TableCell className={classes.tableCellRoot}>
                            <Box>
                              {' '}
                              <Checkbox checked={row?.is_enable} onClick={e => onEnableClick(row)} />
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </PerfectScrollbar>
              </CmtCardContent>
            </CmtCard>
          </Box>
        ) : null}

        <Box display="flex" justifyContent="flex-end" mb={4} mt={5}>
          <Button variant="outlined" onClick={onCloseDialog}>
            Cancel
          </Button>
          <Box ml={2}>
            <Button variant="contained" style={{ minWidth: '100px' }} color="primary" onClick={onSubmitClick}>
              ADD
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditUser;

AddEditUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
