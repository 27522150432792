import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Block, CheckCircleOutline, MoreHoriz, Visibility, Edit, Delete } from '@material-ui/icons';
import CmtDropdownMenu from '../../../../../@coremat/CmtDropdownMenu';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { sentMailToUser } from '../../../../../redux/actions/Users';
import { getIssueManagement, updateIssueManagementStatus } from '../../../../../redux/actions/IssueManagement';
import { Activate, Deactivate } from '../../../../../@jumbo/constants/stringConstant';
import StarsIcon from '@material-ui/icons/Stars';

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.text.primary,
    fontSize: 14,
    letterSpacing: 0.25,
    textOverflow: 'ellipsis',
    maxWidth: '150px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontWeight: 300,
  },
  starIcon: {
    color: theme.palette.text.secondary,
  },
}));

const getUserActions = user => {
  const actions = [
    { action: 'view', label: 'View', icon: <Visibility /> },
    { action: 'edit', label: 'Edit', icon: <Edit /> },
  ];

  if (user.fm_issue_status === 1) {
    actions.push({ action: 'suspend', label: Deactivate, icon: <Block /> });
  } else {
    actions.push({
      action: 'activate',
      label: Activate,
      icon: <CheckCircleOutline />,
    });
  }
  if (user?.is_vcg_creation !== true) {
    actions.push({ action: 'delete', label: 'Delete', icon: <Delete /> });
  }

  return actions;
};

const UserListRow = ({
  row,
  isSelected,
  onRowClick,
  onUserEdit,
  onUserDelete,
  onUserView,
  getIssueManagementParams,
  indexId,
}) => {
  const { orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions } = getIssueManagementParams;

  const classes = useStyles();
  const dispatch = useDispatch();

  const onUserMenuClick = menu => {
    if (menu.action === 'view') {
      onUserView(row);
    } else if (menu.action === 'edit') {
      onUserEdit(row);
    } else if (menu.action === 'email') {
      dispatch(sentMailToUser());
    } else if (menu.action === 'suspend') {
      dispatch(updateIssueManagementStatus({ _id: row._id, status: 2 }, refreshContactList));
    } else if (menu.action === 'activate') {
      dispatch(updateIssueManagementStatus({ _id: row._id, status: 1 }, refreshContactList));
    } else if (menu.action === 'delete') {
      onUserDelete(row);
    }
  };

  function refreshContactList() {
    dispatch(getIssueManagement(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
  }

  const labelId = `enhanced-table-checkbox-${row._id}`;
  const userActions = getUserActions(row);

  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
      {row?.is_vcg_creation == true ? (
        <TableCell padding="checkbox" ml="2px">
          <StarsIcon color="secondary" />
        </TableCell>
      ) : (
        <TableCell padding="checkbox"></TableCell>
      )}
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {indexId + 1}
        </Typography>
      </TableCell>

      <TableCell>
        {' '}
        <Typography className={classes.titleRoot} component="div">
          {row?.service_type_name}
        </Typography>
      </TableCell>
      <TableCell>
        {' '}
        <Typography className={classes.titleRoot} component="div">
          {row?.name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.titleRoot} component="div">
          {row?.fm_issue_status === 1 ? `Active` : 'Inactive'}
        </Typography>
      </TableCell>

      <TableCell align="center" onClick={event => event.stopPropagation()}>
        <CmtDropdownMenu items={userActions} onItemClick={onUserMenuClick} TriggerComponent={<Edit />} />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(UserListRow);
