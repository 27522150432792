import React, { useEffect, useState } from 'react';
import { Paper, Table, TableCell, TableContainer, TableRow } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import UserListRow from './UserListRow';
import UserTableHead from './UserTableHead';
import UserTableToolbar from './UserTableToolbar';
import { useDispatch, useSelector } from 'react-redux';
// import { deleteUser } from '../../../redux/actions/Users';
import AddEditUser from './AddEditUser';
import ConfirmDialog from '../../../@jumbo/components/Common/ConfirmDialog';
import { useDebounce } from '../../../@jumbo/utils/commonHelper';
import useStyles from './index.style';
import UserDetailView from './UserDetailView';
import NoRecordFound from './NoRecordFound';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import {
  getServiceRequest,
  getServiceRequsetDetails,
  getServiceRequsetQuotation,
  deleteUser,
  setEdittServiceRequset,
  getAllTenantList
} from '../../../redux/actions/ServiceRequest';
import { getVendorManagement } from '../../../redux/actions/VendorManagement';
import Map from './MapView/index';
import Calendar from './Calendar/index'
import { getSERVICETYPE } from '../../../redux/actions/ServiceType';
import { deleteLocation, geLocationList } from '../../../redux/actions/Locations';
import {  getTenantList } from 'redux/actions/Tenant';
import { deleteAsset, getAssetDetail, getCategoriesList, getASSETLOCATIONList, getAssetType, setCurrentASSETLOCATION } from 'redux/actions/AssetLocation';
import { useParams } from 'react-router';
import moment from 'moment';
const breadcrumbs = [
  { label: 'Home', link: '/' },

  { label: 'Services Request', isActive: true },
];

const UsersModule = () => {
  const classes = useStyles();
  const { users } = useSelector(({ SERVICEREQUEST }) => SERVICEREQUEST);
  const authUser = useSelector(({ auth }) => auth);
  console.log("auth",authUser)
  const [orderBy, setOrderBy] = React.useState('tenant_details.first_name');
  const [order, setOrder] = React.useState('asc');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState({ name: '' });
  const [ setUsersFetched] = useState(false);
  const [isFilterApplied, setFilterApplied] = useState(false);
  const [filterOptions, setFilterOptions] = React.useState([]);
  const [filterOptionsStatus, setFilterOptionsStatus] = React.useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [listView, setListView] = useState(1);
  const [locationId, setLocationId] = useState('');
  const [searchedData, setSearchedData] = useState('debouncedSearchPagevalidation');
  const [dueDate, setDueDate] = useState(null);
  const [tenantId, setTenantId] = useState('');
  const [vendorId, setVendorId] = useState('');
  const [serviceTypeId, setServiceTypeId] = useState('');
// console.log("date",dueDate)
const [assetTypeID, setAssetTypeID] = useState('');
const [startRange, setStartRange] = useState('');
const [endRange, setEndRange] = useState('');
  const dispatch = useDispatch();
let date =Number(moment(dueDate).format('X'))

  
  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm !== searchedData) {
      dispatch(
        getServiceRequest(orderBy, order, debouncedSearchTerm, 0, rowsPerPage, filterOptionsStatus,locationId,vendorId,serviceTypeId,assetTypeID,dueDate, () => {
          setFilterApplied(!!debouncedSearchTerm);
          setUsersFetched(true);
        }),
      );
      setSearchedData(debouncedSearchTerm);
      setPage(0);
    } else {
      dispatch(
        getServiceRequest(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptionsStatus,locationId,vendorId,serviceTypeId,assetTypeID,dueDate, () => {
          setFilterApplied(!!debouncedSearchTerm);
          setUsersFetched(true);
        }),
      );
    }
    dispatch(getVendorManagement('name', 'asc', '', 0, -1, [1], ''));

    dispatch(getAllTenantList(authUser?.authUser?._id))
    dispatch(geLocationList('name', 'asc', '', 0, '', -1, [1]))
    // dispatch(geLocationList('name', 'asc', '', 0, -1, [], []));
    dispatch(getVendorManagement('name', 'asc', '', 0, -1, [1]));
    dispatch(getSERVICETYPE('name', 'asc', '', '', 0, -1));
    dispatch(getAssetType())
    // dispatch(getTenantList(locationId,'name', 'asc', '', 0, -1, [1]))
  }, [dispatch, filterOptions, debouncedSearchTerm, orderBy, order, page, rowsPerPage, filterOptionsStatus,searchedData,locationId,vendorId,serviceTypeId,assetTypeID,dueDate]);

  const handleCloseUserDialog = () => {
    setOpenUserDialog(false);
    dispatch(setEdittServiceRequset(null))
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = users.map(n => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleRowClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUserView = user => {
    if(user?._id){
dispatch(getServiceRequsetDetails(user?._id));
    }
    else{
      dispatch(getServiceRequsetDetails(user));
    }
    

if(user?.quotations_requested_date){
  dispatch(getServiceRequsetQuotation(user?._id));
}
    
    setOpenViewDialog(true);
  };

  const handleCloseViewDialog = (user) => {
    setOpenViewDialog(false);
  };

  const handleUserEdit = user => {
    setOpenUserDialog(true);
  
  };

  const handleUserDelete = user => {
    setSelectedUser(user);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    dispatch(deleteUser(selectedUser._id,() => {
      dispatch(getServiceRequest(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptionsStatus));
    }));
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const isSelected = id => selected.indexOf(id) !== -1;

  return (
    <PageContainer heading="Service Request" breadcrumbs={breadcrumbs}>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <UserTableToolbar
            selected={selected}
            setSelected={setSelected}
            onUserAdd={setOpenUserDialog}
            filterOptions={filterOptions}
            setFilterOptions={setFilterOptions}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            setListView={setListView}
            listView={listView}
            setPage={setPage}
            setSearchedData={setSearchedData}
            setFilterOptionsStatus={setFilterOptionsStatus}
            filterOptionsStatus={filterOptionsStatus}
            setLocationId={setLocationId}
            locationId={locationId}
            dueDate={dueDate}
            setDueDate={setDueDate}
            tenantId={tenantId}
            setTenantId={setTenantId}
            vendorId={vendorId}
            setVendorId={setVendorId}
            serviceTypeId={serviceTypeId}
            setServiceTypeId={setServiceTypeId}
            assetTypeID={assetTypeID}
            setAssetTypeID={setAssetTypeID}
            startRange={startRange}
            setStartRange={setStartRange}
            endRange={endRange}
            setEndRange={setEndRange}
          />
          {/* {listView === 1 ? (
            <>
              {' '}
              <TableContainer className={classes.container}>
                <Table
                  stickyHeader
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  aria-label="sticky enhanced table">
                  <UserTableHead
                    classes={classes}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={users?.total ?? 1}
                  />
                  <TableBody>
                    {!!users?.data?.length ? (
                      users?.data
                        ?.slice(0 * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => (
                          <UserListRow
                            indexId={page * rowsPerPage + index}
                            key={index}
                            row={row}
                            onRowClick={handleRowClick}
                            onUserEdit={handleUserEdit}
                            onUserDelete={handleUserDelete}
                            onUserView={handleUserView}
                            isSelected={isSelected}
                            onUserAdd={setOpenUserDialog}
                            getSERVICETYPEParams={{
                              orderBy,
                              order,
                              debouncedSearchTerm,
                              page,
                              rowsPerPage,
                              filterOptionsStatus,
                            }}
                          />
                        ))
                    ) : (
                      <TableRow style={{ height: 53 * 6 }}>
                        <TableCell colSpan={7} rowSpan={10}>
                          {isFilterApplied ? (
                            <NoRecordFound>There are no records found with your filter.</NoRecordFound>
                          ) : (
                            <NoRecordFound>{'There are no records found.'}</NoRecordFound>
                          )}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={users?.total ?? 1}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
            </>
          ) : (
            <Map onUserView={handleUserView} />
          )} */}
          {
            listView === 1 ?(
              <>
                {' '}
                <TableContainer className={classes.container}>
                  <Table
                    stickyHeader
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    aria-label="sticky enhanced table">
                    <UserTableHead
                      classes={classes}
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={users?.total ?? 1}
                    />
                    <TableBody>
                      {!!users?.data?.length ? (
                        users?.data
                          ?.slice(0 * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => (
                            <UserListRow
                              indexId={page * rowsPerPage + index}
                              key={index}
                              row={row}
                              onRowClick={handleRowClick}
                              onUserEdit={handleUserEdit}
                              onUserDelete={handleUserDelete}
                              onUserView={handleUserView}
                              isSelected={isSelected}
                              onUserAdd={setOpenUserDialog}
                              getSERVICETYPEParams={{
                                orderBy,
                                order,
                                debouncedSearchTerm,
                                page,
                                rowsPerPage,
                                filterOptionsStatus,
                              }}
                            />
                          ))
                      ) : (
                        <TableRow style={{ height: 53 * 6 }}>
                          <TableCell colSpan={7} rowSpan={10}>
                            {isFilterApplied ? (
                              <NoRecordFound>There are no records found with your filter.</NoRecordFound>
                            ) : (
                              <NoRecordFound>{'There are no records found.'}</NoRecordFound>
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 50]}
                  component="div"
                  count={users?.total ?? 1}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                />
              </>
            ):null
          }
          {listView === 0?<Map onUserView={handleUserView} />:null}
          {
            listView === 3?<Calendar onUserView={handleUserView} setOpenUserDialog={setOpenUserDialog}/>:null
          }
          
        </Paper>

        {openUserDialog && (
          <AddEditUser
            open={openUserDialog}
            onCloseDialog={handleCloseUserDialog}
            getSERVICETYPEParams={{ orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptionsStatus }}
          />
        )}
        {openViewDialog && (
          <UserDetailView
            open={openViewDialog}
            onCloseDialog={handleCloseViewDialog}
            setOpenUserDialog={setOpenUserDialog}
            users={users}
          />
        )}

        <ConfirmDialog
          open={openConfirmDialog}
          title={`Delete Service Request?`}
          content={'You will not be able to recover records.'}
          btnLabels={
            {
              cancel: 'Cancel',
              confirm: 'Delete',
            }
          }
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete}
        />
      </div>
    </PageContainer>
  );
};

export default UsersModule;
