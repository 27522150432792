import {
  ADD_TENANT,
  DELETE_BULK_TENANT,
  DELETE_TENANT,
  EDIT_TENANT,
  GET_TENANT,
  SET_TENANT_DETAILS,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  users: [],
  currentUser: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TENANT: {
      return {
        ...state,
        users: action.payload,
      };
    }
    case SET_TENANT_DETAILS: {
      return {
        ...state,
        currentUser: action.payload,
      };
    }
    case ADD_TENANT: {
      return {
        ...state,
        users: [action.payload, ...state.users],
      };
    }
    case EDIT_TENANT: {
      return {
        ...state,
        users: state.users.map(user => (user.id === action.payload.id ? action.payload : user)),
      };
    }
    case DELETE_TENANT: {
      return {
        ...state,
        users: state.users.filter(user => user.id !== action.payload),
      };
    }
    case DELETE_BULK_TENANT: {
      return {
        ...state,
        users: state.users.filter(user => !action.payload.includes(user.id)),
      };
    }
    default:
      return state;
  }
};
