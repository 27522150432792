import React from 'react';
import Box from '@material-ui/core/Box';
import ProjectTable from './ProjectTable';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCard from '@coremat/CmtCard';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import { intranet } from '@fake-db';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  cardRoot: {
    overflow: 'overlay',
    position: 'relative',
    '& .Cmt-card-content': {
      padding: 0,
      paddingBottom: 24,
    },
  },
  scrollbarRoot: {
    height: 340,
  },
  badgeRoot: {
    fontSize: 14,
    letterSpacing: 0.25,
    backgroundColor: alpha(theme.palette.warning.main, 0.15),
    color: theme.palette.warning.main,
    padding: '2px 10px',
    borderRadius: 30,
  },
}));

const CurrentProjectsSummary = () => {
  const classes = useStyles();
  const { projectsList } = intranet;
  const { currentUser } = useSelector(({ FMPROVIDER }) => FMPROVIDER);
  console.log('user',currentUser)
  return (
    <CmtCard className={classes.cardRoot}>
      <CmtCardHeader title="Module Permissions">
      </CmtCardHeader>
      <CmtCardContent>
        <PerfectScrollbar className={classes.scrollbarRoot}>
          <ProjectTable data={currentUser?.fm_module_access} />
        </PerfectScrollbar>
      </CmtCardContent>
    </CmtCard>
  );
};

export default CurrentProjectsSummary;
