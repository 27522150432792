import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { sentMailToUser } from "../../../../../../../redux/actions/Users";
import { updateFMProviderStatus } from "../../../../../../../redux/actions/UserManagement";
import { getVendorManagement } from "../../../../../../../redux/actions/VendorManagement";
import GetAppIcon from '@material-ui/icons/GetApp';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark,
    textOverflow: "ellipsis",
    maxWidth: "150px",
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontWeight: 300,
  },
  tableCell: {
    fontWeight: 300,
  }
}));

const UserListRow = ({
  row,
  onUserEdit,
  onUserDelete,
  onUserView,
  getFMProviderParams,
  onUserUpdate,
  indexId,
}) => {
  const {
    orderBy,
    order,
    debouncedSearchTerm,
    page,
    rowsPerPage,
    filterOptions,
  } = getFMProviderParams;

  const classes = useStyles();
  const dispatch = useDispatch();

  function refreshContactList() {
    dispatch(
      getVendorManagement(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
  }
  const labelId = `enhanced-table-checkbox-${row._id}`;

  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={row._id} onClick={()=>onUserView(row)} className='pointer'>
      <TableCell padding="checkbox"></TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Typography component="div" className={classes.tableCell}>
          {row?.name==='Other'?row?.title:row?.name}
        </Typography>
      </TableCell>
      {/* <TableCell component="th" id={labelId} scope="row" padding="none">
        <Typography component="div" className={classes.tableCell}>
          {moment(row?.certified_on * 1000).format('hh:mm A DD/MM/YYYY')}
        </Typography>
      </TableCell> */}
      <TableCell><Typography component="div" className={classes.tableCell}>
        {moment(row?.valid_till * 1000).format('hh:mm A DD/MM/YYYY')}
      </Typography></TableCell>
      <TableCell className="pointer" align="center" onClick={(e) =>{e.stopPropagation(); window.open(row.certificate, "_self")}}>
        <GetAppIcon />
      </TableCell>

    </TableRow>
  );
};

export default React.memo(UserListRow);
