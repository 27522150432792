import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import LocationDetail from './LocationDetail/index';
import Tenent from './Tenent/index';
import AssignedUser from './AssignedUser/index';
import Assest from './Assest/index';
import Items from './Items/index';
import Logs from './Logs/index';
import IndictionContent from './IndictionContent/index';
import Budget from './Budget/index';
import ThresholdAmount from './ThresholdAmount/index';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    marginBottom: '10px',
  },
});
const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'Portfolio Location', link: '/location-management' },
  { label: 'Location Details', isActive: true },
];
export default function CenteredTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(Number(window.localStorage.getItem('l_d_i')) ?? 0);
  const locationType = window.localStorage.getItem('L_S_W_t') ? window.localStorage.getItem('L_S_W_t') : null;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <PageContainer heading="Location Details" breadcrumbs={breadcrumbs}>
      <Paper className={classes.root}>
        {locationType == 1 ? (
          <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary">
            <Tab label="Details" style={{ minWidth: '110px' }} />
            <Tab label="Tenants" style={{ minWidth: '110px' }} />
            <Tab label="Assigned Users" style={{ minWidth: '110px' }} />
            <Tab label="Assets" style={{ minWidth: '110px' }} />
            <Tab label="Budget" style={{ minWidth: '110px' }} />
            <Tab label="Induction Content" />
            {/* <Tab label="Threshold Amount" /> */}
          </Tabs>
        ) : (
          <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary">
            <Tab label="Details" />
            <Tab label="Parts" />
            <Tab label="Logs" />
          </Tabs>
        )}
      </Paper>

      {value === 0 ? <LocationDetail /> : null}
      {value === 1 && locationType == 1 ? <Tenent /> : null}
      {value === 2 && locationType == 1 ? <AssignedUser /> : null}
      {value === 3 && locationType == 1 ? <Assest /> : null}
      {value === 4 && locationType == 1 ? <Budget /> : null}
      {value === 5 && locationType == 1 ? <IndictionContent /> : null}
      {/* {value === 6 && locationType == 1 ? <ThresholdAmount /> : null} */}
      {value === 1 && locationType == 2 ? <Items /> : null}
      {value === 2 && locationType == 2 ? <Logs /> : null}
    </PageContainer>
  );
}
