import React, { useEffect, useState } from 'react';
import { Paper } from '@material-ui/core';
import {  makeStyles } from "@material-ui/core";
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { AboutUsSlug, listCMS } from '@jumbo/constants/ApiConstatnt';
import { useDispatch } from 'react-redux';
import axios from '../../../services/auth/jwt/config'
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import { history } from 'redux/store';
import { setAuthUser } from 'redux/actions/Auth';

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    "& .Cmt-header-root": {
      paddingTop: 4,
      paddingBottom: 4,
    },
    "& .Cmt-card-content": {
      padding: "0 0 16px !important",
    },
  },
  mainContent:{
    padding:'100px',
backgroundColor:theme.palette.common.white,
minHeight:'70vh'
  },
  typography: {
    padding: theme.spacing(2),
  },
  
}));
const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'About Us', isActive: true },
];

const UsersModule = () => {
  const classes = useStyles();
  const [content,setContent]=useState(null)
  const dispatch = useDispatch()
  async function fetchData() {
    let response = await axios(
      listCMS+AboutUsSlug
    ).catch(error => {
      if (error?.response?.status === 401) {
        dispatch(setAuthUser(null));
        history.push('/signin');
      } else {
        dispatch(fetchError(error?.response?.data?.message));
      }
    });
    // let user = await response?.data;
    setContent(response?.data?.data?.content);
    dispatch(fetchSuccess());
   
  }

  useEffect(() => {
    dispatch(fetchStart());
    fetchData();
  },[]); 
  return (
    <PageContainer heading="About Us" breadcrumbs={breadcrumbs}>
      <div >
        <Paper className={classes.mainContent}>
        <div dangerouslySetInnerHTML={{__html: content}} />
         
        </Paper>

      </div>
    </PageContainer>
  );
};

export default UsersModule;
