import React, {  useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import Button from '@material-ui/core/Button';
import { requiredMessage } from '@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { capitalizeFLetter } from '@jumbo/utils/commonHelper';
import { emptySpace } from '@jumbo/constants/ValidationRegex';
import { alpha } from '@material-ui/core/styles';
import LocationSearch from '../LocationSearch/index';
import { addNewLocation, geLocationList } from '../../../../../redux/actions/Locations';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { Typography } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import imageCompression from 'browser-image-compression';
const useStyles = makeStyles(theme => ({
  root: {
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    "& .MuiFormLabel-root": {
      color: '#909090'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink':{
      color: theme.palette.text.primary
    }
  },
  typeList: {
    // width: 200,
    fontSize: 14,
    height: 300,
    backgroundColor: theme.palette.background.default,
    overflow: 'auto',
  },
  dialogRoot: {
    position: 'relative',
    '& .MuiDialog-paperWidthSm': {
      width: '100%',
      maxWidth: '1000px',
    },
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.text.primary,
    },
  },
  cardRoot: {
    position: 'relative',
    '& .Cmt-card-content': {
      padding: 0,
      paddingBottom: 24,
    },
  },
  scrollbarRoot: {
    height: 340,
  },
  info1: {
    fontWeight: '600',
    fontSize: 14,
    whiteSpace: 'nowrap',
  },
  info2: {
    fontWeight: '300',
    marginLeft: '5px',
    fontSize: 14,
  },
  badgeRoot: {
    fontSize: 14,
    letterSpacing: 0.25,
    backgroundColor: alpha(theme.palette.warning.main, 0.15),
    color: theme.palette.warning.main,
    padding: '2px 10px',
    borderRadius: 30,
  },
  tableRowCellRoot: {
    fontSize: 14,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 16,
    letterSpacing: 0.4,
    textAlign: 'center',
    color: theme.palette.common.dark,
    borderBottom: '0 none',
    '&:first-child': {
      paddingLeft: 24,
    },
    '&:last-child': {
      paddingRight: 24,
    },
  },
  tableRowRoot: {
    position: 'relative',
    transition: 'all .2s',
    borderTop: `solid 1px ${theme.palette.borderColor.main}`,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
      transform: 'translateY(-4px)',
      boxShadow: `0 3px 10px 0 ${alpha(theme.palette.common.dark, 0.2)}`,
      borderTopColor: 'transparent',
      '& $tableCellRoot': {
        color: theme.palette.text.primary,
        '&.success': {
          color: theme.palette.success.main,
        },
      },
    },
  },
  tableCellRoot: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: 14,
    letterSpacing: 0.25,
    textAlign: 'center',
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    borderBottom: '0 none',
    '&:first-child': {
      paddingLeft: 24,
      textAlign: 'left',
    },
    '& .Cmt-avatar-more': {
      fontSize: 10,
      color: theme.palette.primary.main,
    },
  },
  imageButton: {
    fontSize: '10px',
    marginLeft: '5px',
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  imageButtonWrapper:{
marginTop:'10px'
  },
  tableCellSecondaryColor: {
    color: theme.palette.text.secondary,
  },
  blockRoot: {
    display: 'block',
    fontSize: 14,
  },
  errText:{
    color: 'red',
    marginLeft: '14px',
    marginRight: '14px',
    fontSize: '0.75rem',
    marginTop: '3px',
    textAlign: 'left',
    fontFamily: 'Normal',
    fontWeight: 400,
    lineHeight: 1.66,

}
}));

const AddEditUser = ({ open, onCloseDialog, getFMProviderParams }) => {
  const classes = useStyles();
  const currentUser = useSelector(({ usersReducer }) => usersReducer.currentUser);
  const { users } = useSelector(({ SERVICETYPE }) => SERVICETYPE);
  const { orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions, locationOptions } = getFMProviderParams;
  const [name, setName] = useState('');
  const [type, setType] = useState('1');
  const [siteType, setSiteType] = useState('');
  const [siteTypeError, setSiteTypeError] = useState('');
  const [nameError, setNameError] = useState('');
  const [address, setAddress] = useState('');
  const [locationObject, setLocationObject] = useState('');
  const [latLang, setLatLang] = useState('');
  const [serviceType, setServiceType] = useState([]);
  const [siteImage, setSiteImage] = useState(null);
  const [addressError, setAddressError] = useState('');
  const [serviceTypeError, setServiceTypeError] = useState('');
  const dispatch = useDispatch();

  const onFilterOptionClick = (e,option) => {
    e.stopPropagation();
    setServiceType(prevState => {
      if (prevState.includes(option._id)) {
        return prevState.filter(item => item !== option._id);
      } else {
        return [...prevState, option._id];
      }
    });
    setServiceTypeError('');
  };
  const onSubmitClick = () => {
    if (!name || name.match(emptySpace)) {
      setNameError(requiredMessage);
    } 
    if (!address) {
      setAddressError(requiredMessage);
    } else if (!latLang) {
      setAddressError('Please select one address from suggestion.');
    }
    if (!serviceType.length) {
      setServiceTypeError(requiredMessage);
    }
    if (!siteType) {
      setSiteTypeError(requiredMessage);
    }
    if(type==1){
      if (name && !name.match(emptySpace) && address && latLang && serviceType?.length && siteType) {
        onUserSave();
      }
    }
    else{
      if (name && !name.match(emptySpace) && address && latLang) {
        onUserSave();
      }
    }
   
  };

  const onUserSave = () => {
    const fname = capitalizeFLetter(name);
    const formData = new FormData();
    formData.append('name', fname);
    formData.append('type', type);
    formData.append('address', address);
    formData.append('location', JSON.stringify(locationObject));
    formData.append('latitude', latLang?.lat);
    formData.append('longitude', latLang?.lng);
    formData.append('status', 1);

    if (type === '1') {
      formData.append('siteImage', siteImage);
      formData.append('service_types', JSON.stringify(serviceType));
      formData.append('site_type', siteType);
    } else {
      const service = [];
      formData.append('siteImage', null);
      formData.append('service_types', JSON.stringify(service));
    }

    dispatch(
      addNewLocation(formData, () => {
        onCloseDialog();
        dispatch(geLocationList(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions, locationOptions));
      }),
    );
  };
  async function handleImageUpload(event) {
    const imageFile = event.target.files[0];
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      setSiteImage(compressedFile);
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>{currentUser ? 'Edit User Details' : 'Add New Location'}</DialogTitle>
      <DialogContent dividers>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
          <GridContainer>
            <Grid item xs={12} sm={12}>
              <AppTextInput
                className={classes.root}
                fullWidth
                variant="outlined"
                label="Name"
                value={name}
                onChange={e => {
                  setName(e.target.value);
                  setNameError('');
                }}
                helperText={nameError}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography className={classes.titleRoot}>Location Type</Typography>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  onChange={e => {
                    setType(e.target.value);
                  }}
                  name="Device Type"
                  value={type}>
                  <FormControlLabel value="1" control={<Radio color="primary" />} label="Job Location" />
                  <FormControlLabel value="2" control={<Radio color="primary" />} label="Inventory Site" />
                </RadioGroup>
              </FormControl>
              <br/>
              {type === '1' ? (
                <Button variant="contained" component="label" mt={10} className={classes.imageButtonWrapper}>
                  <input type="file" hidden accept="image/png, image/gif, image/jpeg" name="file" onChange={handleImageUpload} />
                  <CloudUploadIcon />
                <p className={classes.imageButton}>{siteImage ? siteImage?.name : 'Choose file'}</p>
                </Button>
              ) : null}
            </Grid>

            {type === '1' ? (
              <Grid item xs={12} sm={6}>
                <Typography className={classes.titleRoot}>Service Type</Typography>
                <Box className={classes.typeList}>
                  {users.data?.map((value, index) => {
                    const labelId = `transfer-list-all-item-${value}-label`;

                    return (
                      <ListItem key={index} role="listitem" button onClick={e=> document.getElementById(value._id).click()}>
                        <ListItemIcon>
                          <Checkbox id={value._id} onClick={e => onFilterOptionClick(e,value)} tabIndex={-1} disableRipple />
                        </ListItemIcon>
                        <ListItemText
                          id={labelId}
                          primary={`${value.name}`}
                          style={{
                            fontSize: 14,
                            letterSpacing: 0.25,
                            textOverflow: 'ellipsis',
                            wordWrap: 'break-word',
                            display: 'block',
                            // overflow: 'hidden',
                           
                          }}
                        />
                      </ListItem>
                    );
                  })}
                </Box>
                <p className={classes.errText}>
                  {serviceTypeError}
                </p>
              </Grid>
            ) : null}
          </GridContainer>
        </Box>
        {type === '1' ?
            <Grid item xs={12} sm={12}>
              <Box mb={5}>
              <Box display="flex">
                <Box mt={2}><Typography>Job Location Type :</Typography></Box>
                <Box ml={3}><FormControl component="fieldset">
                  <RadioGroup row
                    onChange={e => {
                      setSiteType(e.target.value);
                      setSiteTypeError('');
                    }}
                    // name="Device Type"
                    value={siteType}>
                    <FormControlLabel value="1" control={<Radio color="primary" />} label="Metro" />
                    <FormControlLabel value="2" control={<Radio color="primary" />} label="Regional" />
                    <FormControlLabel value="3" control={<Radio color="primary" />} label="Remote" />
                    <FormControlLabel value="4" control={<Radio color="primary" />} label="Remote +" />
                  </RadioGroup>
                </FormControl>
                </Box>
                </Box>
                <p
                className="MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-marginDense"
                style={{ color: 'red' }}>
                {siteTypeError}
              </p>
              </Box>
            {/* : null } */}

            {/* {type === 1 ? ( */}
            <Box mb={5} display='flex'>

            {siteType === '1' ? 
            <>
              <Typography className={classes.info1}> Note : </Typography>
              <Typography className={classes.info2}>Sites that are within 0 - 50 km of the General Post Office should be defined as 'Metro' areas.</Typography>
            </>

            : siteType === '2' ? 
            <>
              <Typography className={classes.info1}> Note : </Typography>
              <Typography className={classes.info2}>Sites that are within 50 - 100 km of the General Post Office should be defined as 'Regional' areas.</Typography>
            </>

            : siteType === '3' ? 
            <>
              <Typography className={classes.info1}> Note : </Typography>
              <Typography className={classes.info2}>Sites that are within 50 - 100 km of the General Post Office should be defined as 'Remote' areas.</Typography>
            </>
            
            : siteType === '4' ?
            <> 
              <Typography className={classes.info1}> Note : </Typography>
              <Typography className={classes.info2}>Sites that are more than 100 km from the General Post Office are defined as 'Remote +' areas.</Typography>
            </>
            
            :  
            <>
              <Typography className={classes.info1}></Typography>
              <Typography className={classes.info2}></Typography>
            </>
          }
            </Box>
            </Grid>
          : null }

        <LocationSearch
          setAddress={setAddress}
          setLatLang={setLatLang}
          setLocationObject={setLocationObject}
          address={address}
          variantType={0}
          addressError={addressError}
          setAddressError={setAddressError}
        />

        <Box display="flex" justifyContent="flex-end" mb={4} mt={5}>
          <Button variant="outlined" onClick={onCloseDialog}>
            Cancel
          </Button>
          <Box ml={2}>
            <Button variant="contained" style={{ minWidth: '100px' }} color="primary" onClick={onSubmitClick}>
              ADD
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditUser;

AddEditUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
