import { alpha, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
    '& .MuiDialog-paperWidthSm': {
      width: '100%',
      maxWidth:'1200px'
    },
  },
  userInfoRoot: {
    borderBottom: `1px solid ${theme.palette.borderColor.main}`,
    padding: '20px 24px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  avatarView: {
    [theme.breakpoints.down('sm')]: {
      '& .Cmt-avatar-size': {
        width: 40,
        height: 40,
      },
    },
  },
  titleRoot: {
    fontSize: '16px',
    color:  theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.up('md')]: {
      fontSize: '16px',
    },
  },
  titleRoot1: {
    fontWeight: '600',
    marginLeft: '15px',
    whiteSpace: 'nowrap',
    '&.MuiTypography-body1': {
      fontSize: 14,
    },
  },
  titleRoot3: {
    fontWeight: '300',
    marginLeft: '5px',
    fontSize: 14,
    textOverflow: 'ellipsis',
    maxWidth: '350px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  titileroot2:{
    fontSize: '14px',
    fontWeight: 600,
    marginTop: '5px',
    marginBottom: '14',
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    marginRight: '10px',
    display:'flex'
  },
  serviceTypeView: {
    marginLeft: 5,
    color: theme.palette.text.primary,
      fontSize: 14,
    letterSpacing: 0.25,
      textOverflow: 'ellipsis',
      maxWidth:'350px',
      // display: 'block',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
  },
  serviceTypeList:{
    // paddingTop: '0px !important',
    // paddingBottom: '0px !important',
    '& .MuiListItem-root': {
      width: '100%',
      display: 'flex',
      position: 'relative',
      boxSizing: 'border-box',
      // textAlign: 'left',
      alignItems: 'center',
      // paddingTop: '0px !important',
      // paddingBottom: '0px !important',
      justifyContent: 'flex-start',
      textDecoration: 'none',
  },

    '& .MuiTypography-body1': {
      fontSize: '14px',
      fontWeight: '300',
      fontFamily: 'Normal',
      letterSpacing: 0.25,
      textOverflow: 'ellipsis',
      maxWidth: '250px',
      display: 'block',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      lineHeight: 1.5,
  },
    marginLeft:'2px',
    marginRight:'2px',
    fontSize: '14px',
    fontWeight: '600',
    letterSpacing: 0.25,
    textOverflow: 'ellipsis',
    maxWidth: '250px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  subTitleRoot: {
    fontSize: 12,
    letterSpacing: 0.4,
    color: theme.palette.text.secondary,
  },
  labelRoot: {
    backgroundColor: alpha(theme.palette.common.dark, 0.05),
    color: theme.palette.text.disabled,
    padding: '4px 10px',
    borderRadius: 4,
    textTransform: 'capitalize',
  },
  WrapSite:{
    minHeight:'320px'
  },
  serviceType:{
    maxHeight:'150px',
    minWidth: '200px',
    overflow:'auto'
  },
  wrapBudget:{
    overflow:'auto'
  }
}));

export default useStyles;
