import React from "react";
import { Box } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import CmtImage from "../../../../@coremat/CmtImage";
import { useSelector } from "react-redux";

const FooterLogo = ({ color, ...props }) => {
  const { Customization } = useSelector(({ CUSTOMIZATION }) => CUSTOMIZATION);
  // console.log('customization',Customization)
  const logoUrl = Customization?.logo ? Customization?.logo : "/logo.png";
  // const logoUrl = color === 'white' ?  : process.env.PUBLIC_URL+ '/logo.png';

  return (
    <Box className="pointer" {...props}>
      <NavLink to="/">
        <CmtImage src={logoUrl} alt="logo" style={{ maxWidth: "100px" }} />
      </NavLink>
    </Box>
  );
};

export default FooterLogo;
