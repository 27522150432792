import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ServiceType from './ServicesType/index';
import ServiceActivity from './ServiceActivity/index';
import IssueManagement from './IssueManagement/index';
import Customization from './Customizations/index';
import Locations from './Locations/index';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import { useSelector } from 'react-redux';
import CertificateType from './CertificateType/index';
const p_color = window.localStorage.getItem('p_c') ?? '#FC8119';
const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    marginBottom: '10px',
  },
  active: {
    color: p_color,
    borderBottom: `2px solid ${p_color}`,
  },
  inactive: {
    color: 'rgba(0, 0, 0, 0.87)',
    borderBottom: `2px solid #fff !important`,
    '& .PrivateTabIndicator-colorPrimary-77': {
      borderBottom: `2px solid #fff !important`,
    },
  },
});
const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'Settings', isActive: true },
];
export default function CenteredTabs() {
  const classes = useStyles();
  const { authUser } = useSelector(({ auth }) => auth);
  const [value, setValue] = React.useState(
    authUser?.fm_module_access[1]?.is_view ? 0 : authUser?.fm_module_access[2]?.is_view ? 1 : 2,
  );

  return (
    <PageContainer heading="Settings" breadcrumbs={breadcrumbs}>
      <Paper className={classes.root}>
        <Tabs value={value} indicatorColor="primary" textColor="primary">
          {authUser?.fm_module_access[1]?.is_view ? (
            <Tab className={value === 0 ? classes.active : ''} label="Service Types" onClick={e => setValue(0)} />
          ) : null}
          {authUser?.fm_module_access[2]?.is_view ? (
            <Tab
              className={value === 1 ? classes.active : classes.inactive}
              label="Service Activities"
              style={{
                borderBottom: value === 1 ? `2px solid ${p_color}` : '',
                zIndex: 8,
                color: value === 1 ? p_color : 'rgba(0, 0, 0, 0.87)',
              }}
              onClick={e => setValue(1)}
            />
          ) : null}
          <Tab
            className={value === 2 ? classes.active : classes.inactive}
            label="Issues"
            style={{
              borderBottom: value === 2 ? `2px solid ${p_color}` : '',
              zIndex: 8,
              color: value === 2 ? p_color : 'rgba(0, 0, 0, 0.87)',
            }}
            onClick={e => setValue(2)}
          />
          {authUser?.fm_module_access[3]?.is_view ? (
            <Tab
              className={value === 3 && value !== 2 ? classes.active : classes.inactive}
              style={{
                borderBottom: value === 3 ? `2px solid ${p_color}` : '',
                zIndex: 8,
                color: value === 3 ? p_color : 'rgba(0, 0, 0, 0.87)',
              }}
              label="Customize"
              onClick={e => setValue(3)}
            />
          ) : null}

          <Tab
            className={value === 4 ? classes.active : classes.inactive}
            label="Certificate Type"
            style={{
              borderBottom: value === 4 ? `2px solid ${p_color}` : '',
              zIndex: 8,
              color: value === 4 ? p_color : 'rgba(0, 0, 0, 0.87)',
            }}
            onClick={e => setValue(4)}
          />
        </Tabs>
      </Paper>

      {value === 0 ? <ServiceType /> : null}
      {value === 1 ? <ServiceActivity /> : null}
      {value === 2 ? <IssueManagement /> : null}
      {value === 3 ? <Customization /> : null}
      {value === 4 ? <CertificateType /> : null}
    </PageContainer>
  );
}
