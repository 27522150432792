import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import Button from '@material-ui/core/Button';
import { emailNotValid, lettersOnlyMessage, requiredMessage } from '@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { capitalizeFLetter, isValidEmail } from '@jumbo/utils/commonHelper';
import { stringOnly } from '@jumbo/constants/ValidationRegex';
import { addNewTenant, getTenantList, updateTenant } from 'redux/actions/Tenant';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
  },
  dialogRoot: {
    position: 'relative',
    '& .MuiDialog-paperWidthSm': {
      width: '100%',
      // maxWidth: '1000px',
    },
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.text.primary,
    },
  },
}));

const AddEditUser = ({ open, onCloseDialog, getFMProviderParams }) => {
  const classes = useStyles();
  const currentUser = useSelector(({ TENANT }) => TENANT.currentUser);
  const { id, orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions } = getFMProviderParams;
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [status, setStatus] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser) {
      setFirstName(currentUser?.first_name);
      setLastName(currentUser?.last_name);
      setEmail(currentUser.email);
      setStatus(currentUser?.status);
    }
  }, [currentUser]);

  const onSubmitClick = () => {
    if (!firstName) setFirstNameError(requiredMessage);
    else if (!firstName.match(stringOnly)) setFirstNameError(lettersOnlyMessage);

    if (!lastName) setLastNameError(requiredMessage);
    else if (!lastName.match(stringOnly)) setLastNameError(lettersOnlyMessage);

    if (!email) setEmailError(requiredMessage);
    else if (!isValidEmail(email)) setEmailError(emailNotValid);

    if (firstName && firstName.match(stringOnly) && lastName && lastName.match(stringOnly) && email && isValidEmail(email)) {
      onUserSave();
    }
  };

  const onUserSave = () => {
    const fname = capitalizeFLetter(firstName);
    const lname = capitalizeFLetter(lastName);
    const userDetail = {
      email,
      first_name: fname,
      last_name: lname,
      status: status,
    };

    if (currentUser) {
      dispatch(
        updateTenant(currentUser?._id, userDetail, () => {
          onCloseDialog();
          dispatch(getTenantList(id, orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
        }),
      );
    } else {
      dispatch(
        addNewTenant(id, userDetail, () => {
          onCloseDialog();
          dispatch(getTenantList(id, orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
        }),
      );
    }
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>{currentUser ? 'Edit Threshold Amount' : ''}</DialogTitle>
      <DialogContent dividers>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
          <GridContainer>
            <Grid item xs={12} sm={12}>
            <Box ml={5} display="flex">
            <Typography className={classes.labelField}>Service Types :</Typography>
            <Typography className={classes.textField}> {" Cleaning"}</Typography>
          </Box>
            </Grid>
            <Grid item xs={12} sm={12}>
            <Box ml={5} display="flex">
            <Typography className={classes.labelField}>Priority :</Typography>
            <Typography className={classes.textField}> {" P2"}</Typography>
          </Box>
            </Grid>
            <Grid item xs={12} sm={12}>
              <AppTextInput
                className={classes.root}
                fullWidth
                variant="outlined"
                label="Threshold Amount"
                value={''}
                onChange={e => {
                  // setEmail(e.target.value);
                  // setEmailError('');
                }}
                // disabled={currentUser ? true : false}
                // helperText={emailError}
              />
            </Grid>
          </GridContainer>
        </Box>

        {/* <Box >
  <UserAccessADDEdit userAccess={userAccess} setUserAccess={setUserAccess} setUserModuleAccess={setUserModuleAccess} userModuleAccess={userModuleAccess}/>
</Box> */}

        <Box display="flex" justifyContent="flex-end" mb={4} mt={5}>
          <Button variant="outlined" onClick={onCloseDialog}>
            Cancel
          </Button>
          <Box ml={2}>
            <Button variant="contained" style={{ minWidth: '100px' }} color="primary" onClick={onSubmitClick}>
              {currentUser ? 'UPDATE' : 'ADD'}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditUser;

AddEditUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
