import React , { useState }from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import CmtAvatar from '@coremat/CmtAvatar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { useSelector } from 'react-redux';
import CmtList from '@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import useStyles from './index.style';
import { Block, CheckCircleOutline } from '@material-ui/icons';
import { Button, Tooltip } from '@material-ui/core';
import FaceIcon from '@material-ui/icons/Face';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import UserAccessModule from '../UserAccessModule/index'
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

const UserDetailView = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { currentUser } = useSelector(({ INDUCTION }) => INDUCTION);
  const { doc_type,title,status,link } = currentUser;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.userInfoRoot}>
        <Box mr={3} display="flex" alignItems="center">
          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <Typography
                className={classes.titleRoot}
                >
                Induction Detail
              </Typography>
              <Box ml={1}></Box>
            </Box>
          </Box>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}></Box>
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box px={6} py={5}>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <FaceIcon />
            <Typography className={classes.titleRoot1}>Title :</Typography>
            <Typography className={classes.titleRoot2}>{title}</Typography>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
        <InsertDriveFileIcon />
          <Typography className={classes.titleRoot1}>Type :</Typography>
          <Typography className={classes.titleRoot2}>{doc_type === 1 ? 'Document' : 'Video'}</Typography>
        </Box>

        <Box display="flex">
        <InsertDriveFileIcon />
        <Typography className={classes.materialViewRow}>
          {doc_type===1?
          <>
          View Document : <Button variant="contained" style={{ minWidth: '100px'}} color="primary" onClick={()=>window.open(link,'_self')}> VIEW </Button>
          </> :
          <> 
          Video Link : <Button variant="contained" style={{ minWidth: '100px'}} color="primary" onClick={()=>window.open(link)}> VIEW </Button>
          </>
          }
          </Typography>
          </Box>
       
       
        <Box display="flex" alignItems="center" mt={5} mb={{ xs: 4, sm: 5 }}>
          {status === 1 ? <CheckCircleOutline /> : <Block />}
          <Typography className={classes.titleRoot1}>Status (Active/Inactive) :</Typography>
          <Typography className={classes.titleRoot2}>{status === 1 ? 'Active' : 'Inactive'}</Typography>
        </Box>
      </Box>
      
      
    </Dialog>
  );
};

export default UserDetailView;

UserDetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
