import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { Box } from '@material-ui/core';
import { alpha, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { requiredMessage } from '../../../@jumbo/constants/ErrorMessages';
import CmtCard from '../../../@coremat/CmtCard';
import CmtCardHeader from '../../../@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '../../../@coremat/CmtCard/CmtCardContent';
import { useDispatch, useSelector } from 'react-redux';
import { addNewContactUs } from '../../../redux/actions/ContactUsQuery';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authLogo: {
    maxWidth: '70px',
    width: '5rem',
  },
  authContent: {
    padding: 30,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    maxWidth: '500px',
    minWidth: '500px',

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    "& .MuiFormLabel-root": {
      color: '#909090'
    },
    "& .MuiInputLabel-shrink":{
color:theme.palette.text.primary
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink':{
      color: theme.palette.text.primary
    },
  },
  formcontrolLabelRoot: {
    '& .MuiFormControlLabel-label': {
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
  },
}));

export default function ContactUs() {
  const classes = useStyles();
  const authUser = useSelector(({ auth }) => auth);
  const [email] = useState(authUser?.authUser?.email);
  const [name] = useState(authUser?.authUser?.first_name + ' ' + authUser?.authUser?.last_name);
  const [description, setDescription] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [imageError, setImageError] = useState('');
  const [image, setImage] = useState();

  const dispatch = useDispatch();

  const handleClear = () => {
    setDescription('');
    setImage('');
    setDescriptionError('');
    setImageError('');
    document.getElementById('outlined-multiline-flexible').value = '';
    document.getElementById('image-Filed').value = '';
  };
  const handelSubmit = () => {
    if (!description) {
      setDescriptionError(requiredMessage);
    }
    if (image?.length >= 6) {
      setImageError('Please select image between one to five.');
    }
    if (image ) {
      if (description && image?.length < 6) {
        onUserSave();
      }
    }
    else{
      if (description) {
        onUserSave();
      }
    }
  };

  const onUserSave = () => {
    const formData = new FormData();
    formData.append('username', name);
    formData.append('email', email);

    formData.append('query', description);
    if (image && image?.length < 6) {
      image.forEach(element => {
        formData.append('contactUsImage', element);
      });
    }

    dispatch(
      addNewContactUs(formData, () => {
        clearData();
      }),
    );
  };

  const clearData = () => {
    setDescription('');
    setImage('');
    document.getElementById('image-Filed').value = '';
    document.getElementById('outlined-multiline-flexible').value = '';
  };
  return (
    <>
      <CmtCard>
        <CmtCardHeader title="Contact Us">
          <Box className={classes.textUppercase} fontSize={5} color="text.secondary"></Box>
        </CmtCardHeader>
        <CmtCardContent>
          <Box mb={2}>
            <TextField
              label="Name"
              fullWidth
              disabled
              value={name}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              id="name"
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="Email"
              fullWidth
              disabled
              // onChange={event => setEmail(event.target.value)}
              value={email}
              margin="normal"
              variant="outlined"
              id="email"
              className={classes.textFieldRoot}
            />
          </Box>
          <Box Box mb={2}>
            <TextField
              className={classes.textFieldRoot}
              id="outlined-multiline-flexible"
              label="Description"
              multiline
              minRows={7}
              maxRows={7}
              value={description}
              onChange={event => {
                setDescription(event.target.value);
                setDescriptionError('');
              }}
              variant="outlined"
              helperText={descriptionError}
            />
          </Box>

          <Box Box mb={6}>
            <Button variant="contained" component="label">
              Image Upload
              <input
                type="file"
                multiple
                id="image-Filed"
                accept="image/png, image/jpeg"
                onChange={e => {
                  setImage([...e.target.files]);
                  setImageError('');
                }}
              />
            </Button>{' '}
            <br />
            <span style={{color:'red'}}>{imageError}</span>
          </Box>
          <Box display={'flex'}>
            {' '}
            <Box mr={5}>
              <Button variant="outlined" mr={5} onClick={handleClear}>
                {' '}
                Clear
                {/* <IntlMessages id="appModule.signIn" /> */}
              </Button>
            </Box>
            <Box>
              <Button variant="contained" color="primary" onClick={handelSubmit} ml={5}>
                {' '}
                Submit
                {/* <IntlMessages id="appModule.signIn" /> */}
              </Button>
            </Box>
          </Box>
        </CmtCardContent>
      </CmtCard>
    </>
  );
}
