import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableHeading from './TableHeading';
import TableBody from '@material-ui/core/TableBody';
import TableItem from './TableItem';
import { alpha, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({

  stickyHead:{
    '& .MuiTableCell-stickyHeader':{
      background:theme.palette.primary.main,
    },
    '& .MuiTable-stickyHeader': {
      borderCollapse: 'collapse !important' 
  },
  },
}));
const ProjectTable = ({ data }) => {
  const classes = useStyles();
  return (
    
      <Table stickyHeader aria-label="sticky table" className={classes.stickyHead} style={{borderCollapse:'collapse'}}>
        <TableHead >
          <TableHeading />
        </TableHead>
        <TableBody>
          {data?.map((row,index) => (
            <TableItem row={row} key={index} />
          ))}
        </TableBody>
      </Table>
  
  );
};

export default ProjectTable;
