import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';
import {
  DELETE_BULK_PLANNEDMAINTENANCE,
  GET_PLANNEDMAINTENANCE,
  SET_PLANNEDMAINTENANCE_DETAILS,
  GET_PLANNEDMAINTENANCEDETAILS,
} from '../../@jumbo/constants/ActionTypes';
import {
  PlannedMaintenanceDetails,
  PlannedMaintenanceList,
  PlannedMaintenanceStatus,
  PlannedMaintenanceUpdate,
  PlannedMaintenanceAdd,
  deletePlannedMaintenance,
} from '../../@jumbo/constants/ApiConstatnt';

// get Planned maintenace list
export const getPlannedMaintenanceList = (sortBy, sort, searchTerm, page, limit, filterOptions, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(PlannedMaintenanceList, {
        params: { sortBy: `${sortBy}:${sort}`, search: searchTerm, page: page + 1, limit: limit, status: filterOptions[0] },
      })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_PLANNEDMAINTENANCE, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError());
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Planned Maintenance List not found.') {
          dispatch({ type: GET_PLANNEDMAINTENANCE, payload: [] });
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

// set current user for view tab
export const setItemList = user => {
  return dispatch => {
    dispatch({ type: SET_PLANNEDMAINTENANCE_DETAILS, payload: user });
  };
};

// add Planned MAitennace
export const addNewPlannedMaintanance = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(PlannedMaintenanceAdd, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('New Planned Maintenance added successfully.'));
          }, 2000);
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const updatePlannedMaintenanceStatus = (data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(PlannedMaintenanceStatus, data)
      .then(response => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Planned maintenance status updated successfully.'));
          }, 3000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const getPlannedMaintananceDetails = userId => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(PlannedMaintenanceDetails + userId)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_PLANNEDMAINTENANCEDETAILS, payload: data.data.data });
        } else {
          dispatch(fetchError());
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Planned Maintenance List not found.') {
          dispatch(fetchError(error?.response?.data?.message));
          dispatch({ type: GET_PLANNEDMAINTENANCEDETAILS, payload: [] });
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

export const setPlannedMaintenanceDetails = user => {
  return dispatch => {
    dispatch({ type: SET_PLANNEDMAINTENANCE_DETAILS, payload: user });
  };
};

// all function below are not in use currntly
export const deleteBulkUsers = (userIds, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/users/bulk-delete', { userIds })
      .then(response => {
        if (response.status === 200) {
          dispatch(fetchSuccess('Selected users were deleted successfully.'));
          dispatch({ type: DELETE_BULK_PLANNEDMAINTENANCE, payload: userIds });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const deleteUser = (userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete(deletePlannedMaintenance + userId)
      .then(data => {
        if (data.status === 200) {
          setTimeout(() => {
            dispatch(fetchSuccess('Planned maintenance deleted successfully.'));
          }, 1000);
          dispatch({ type: GET_PLANNEDMAINTENANCE, payload: [] });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const sentMailToUser = () => {
  return dispatch => {
    dispatch(fetchSuccess('Email has been sent to user successfully'));
  };
};

export const updatePlannedMaintenance = (user, id, callbackFun) => {
  console.log('plannedid', id);
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(PlannedMaintenanceUpdate + id, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Planned maintenance updated successfully.'));
          }, 3000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};
