import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';
import {
  DELETE_BULK_ASSIGNITEM,
  DELETE_ASSIGNITEM,
  GET_ASSIGNITEM,
  SET_ASSIGNITEM_DETAILS,
  GET_ASSIGNITEMDETAILS,
  GET_ASSIGNITEMADD,
  GET_WAREHOUSE
} from '../../@jumbo/constants/ActionTypes';
import {
  GetAssignItemList,
  AssignItemsList,
  updateItems,
  updateItemsDetails,
  DetailsAssignItem,
  GetWarehouseList
} from '../../@jumbo/constants/ApiConstatnt';

// get Assign item list
export const getAssignItem = (sortBy, sort, searchTerm, page, limit, filterOptions, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(AssignItemsList, {
        params: { sortBy: `${sortBy}:${sort}`, search: searchTerm, page: page + 1, limit: limit, status: filterOptions[0] },
      })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_ASSIGNITEM, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError());
          dispatch({ type: GET_ASSIGNITEM, payload: [] });
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Part list not found.') {
          dispatch({ type: GET_ASSIGNITEM, payload: [] });
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

export const getWarehouseList = (id, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(GetWarehouseList+id)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_WAREHOUSE, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError());
          dispatch({ type: GET_WAREHOUSE, payload: '' });
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Part list not found.') {
          dispatch({ type: GET_WAREHOUSE, payload: '' });
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};










// set current user for view tab
export const setItemList = user => {
  return dispatch => {
    dispatch({ type: SET_ASSIGNITEM_DETAILS, payload: user });
  };
};

// add onchange assign list post
export const addNewAssignItem = (user, callbackFun) => {
  return dispatch => {
    dispatch({ type: GET_ASSIGNITEMADD, payload: [] });
    dispatch(fetchStart());
    axios
      .post(GetAssignItemList, user)
      .then(data => {
        if (data && data.data.message !== 'Part list not found.') {
          setTimeout(() => {
            dispatch(fetchSuccess(data?.data?.message));
          }, 2000);

          dispatch({ type: GET_ASSIGNITEMADD, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError(data?.data?.message));
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Part list not found.') {
          dispatch(fetchError(error?.response?.data?.message));
          dispatch({ type: GET_ASSIGNITEMADD, payload: [] });
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

// add Assign item
export const addNewAssignItemData = (vendeorId, WhareHouseId, user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`fm-provider/fm-items/assign-items?vendorId=${vendeorId}&warehouseId=${WhareHouseId}`, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Part assigned successfully.'));
          }, 2000);
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const updateItemListStatus = (data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(updateItems, data)
      .then(response => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Assign Part status updated successfully.'));
          }, 3000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const getItemListDetails = userId => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(DetailsAssignItem + userId)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_ASSIGNITEMDETAILS, payload: data.data.data });
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Item List not found.') {
          dispatch(fetchError(error?.response?.data?.message));
          dispatch({ type: GET_ASSIGNITEMDETAILS, payload: [] });
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

export const setItemListDetails = user => {
  return dispatch => {
    dispatch({ type: SET_ASSIGNITEM_DETAILS, payload: user });
  };
};

// all function below are not in use currntly
export const deleteBulkUsers = (userIds, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/users/bulk-delete', { userIds })
      .then(response => {
        if (response.status === 200) {
          dispatch(fetchSuccess('Selected users were deleted successfully.'));
          dispatch({ type: DELETE_BULK_ASSIGNITEM, payload: userIds });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const deleteUser = (userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete('/users', { params: { id: userId } })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('Selected user was deleted successfully.'));
          dispatch({ type: DELETE_ASSIGNITEM, payload: userId });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const sentMailToUser = () => {
  return dispatch => {
    dispatch(fetchSuccess('Email has been sent to user successfully'));
  };
};

export const updateItemList = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(updateItemsDetails, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Assign Part updated successfully.'));
          }, 3000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};
