import React from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import EmailIcon from '@material-ui/icons/Email';
import useStyles from './index.style';
import { Block, CheckCircleOutline } from '@material-ui/icons';
import FaceIcon from '@material-ui/icons/Face';
import { Grid } from '@material-ui/core';
import WebAssetIcon from '@material-ui/icons/WebAsset';
import GridContainer from '@jumbo/components/GridContainer';
import { Active, Inactive } from '@jumbo/constants/stringConstant';
import BuildIcon from "@material-ui/icons/Build";

const UserDetailView = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const { currentUser } = useSelector(({ ACCOUNTVENDORS }) => ACCOUNTVENDORS);
  const { data, accountData } = currentUser;
console.log(currentUser)
  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.userInfoRoot}>
        <Box mr={3} display="flex" alignItems="center">
          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.titleRoot}>Vendors Detail</Typography>
              <Box ml={1}></Box>
            </Box>
          </Box>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}></Box>
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box px={6} py={5}>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <FaceIcon />
          <Grid item xs={8} sm={7}>
          <Box ml={5} display="flex">
            <Typography className={classes.labelField}>First Name :</Typography>
            <Typography className={classes.textField}> {data?.first_name}</Typography>
            <Typography style={{marginLeft:"20px"}} className={classes.labelField}> Last Name :</Typography>
            <Typography className={classes.textField}> {data?.last_name}</Typography>
          </Box>
          </Grid>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <EmailIcon />
          <Grid item xs={8} sm={7}>
          <Box ml={5} color="primary" display="flex">
            <Typography className={classes.labelField}>Email :</Typography>
            <Typography className={classes.textField}>{data?.email}</Typography>
          </Box>
          </Grid>
          </Box>
          <Box display="flex" item xs={8} sm={7}>
            <BuildIcon />
            <Box display="flex" ml={5}>
              <Typography className={classes.labelField}>For Service Type : </Typography>
              <Box>
                {data?.service_types_ids?.map((service,index) => (
                  <Typography key={index} className={classes.textField}>{service?.name}</Typography>
                ))}
              </Box>
            </Box>
          </Box>
        
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }} mt={5}>
          {data?.status === 1 ? <CheckCircleOutline /> : <Block />}
          <Box ml={5} display="flex">
            <Typography className={classes.labelField}> Status (Active/Inactive) : </Typography>
            <Typography className={classes.textField}> {data?.status === 1 ? Active : Inactive}</Typography>
          </Box>
        </Box>
        <Box mt={10} mb={5}>
        <Typography className={classes.titleRoot}>Account Informations :</Typography>
        </Box>
     { accountData?.external_accounts?  <GridContainer style={{marginTop:'10px'}}>
        <Grid item xs={4} sm={6}>
            <Box display="flex" alignItems="center" ml={5}>
              <WebAssetIcon />
              <Box ml={5} display="flex">
                <Typography className={classes.labelField}>Account Number : </Typography>
                <Typography className={classes.textField}>{accountData?.external_accounts?.data[0]?.account}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4} sm={6}>
            <Box display="flex" alignItems="center" ml={5}>
              <WebAssetIcon />
              <Box ml={5} display="flex">
              <Typography className={classes.labelField}>Bank Name : </Typography>
              <Typography className={classes.textField}>{accountData?.external_accounts?.data[0]?.bank_name}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4} sm={6}>
            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }} ml={5}>
              <WebAssetIcon />
              <Box ml={5} display="flex">
              <Typography className={classes.labelField}>Routing Number : </Typography>
              <Typography className={classes.textField}>{accountData?.external_accounts?.data[0]?.routing_number}</Typography>
              </Box>
            </Box>
          </Grid>
          
          </GridContainer> : <Box mt={5} mb={5} ml={5} alignItems='center'><Typography > - Payment information is not added yet by the vendor.</Typography></Box>}
      </Box>
    </Dialog>
  );
};

export default UserDetailView;

UserDetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
