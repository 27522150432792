import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { requiredMessage } from '../../../../@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import AppSelectBox from '../../../../@jumbo/components/Common/formElements/AppSelectBox';
import {
  getPlannedMaintenanceList,
  updatePlannedMaintenance,
  addNewPlannedMaintanance,
} from '../../../../redux/actions/PlannedMaintenance';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import axios from 'services/auth/jwt/config';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import { history } from 'redux/store';
import { setAuthUser } from 'redux/actions/Auth';
import { getASSETLOCATIONList } from '../../../../redux/actions/AssetLocation';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Select from 'react-select';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.text.primary,
    },
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 200,
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
      marginLeft:"8px"
    },
    "& .MuiFormLabel-root": {
      color: '#909090'
    },
    "& .MuiInputLabel-shrink":{
color:theme.palette.text.primary
    },
  },
  root: {
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    "& .MuiFormLabel-root": {
      color: '#909090'
    },
    "& .MuiInputLabel-shrink":{
color:theme.palette.text.primary
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink':{
      color: theme.palette.text.primary
    },
  },
  assignInventorySiteView: {
    zIndex: 99,
    minHeight: '40px',
    minWidth: '200px',
    fontSize: '16px',
    '& .chip':{
      background:`${theme.palette.primary.main} !important`
    },
    '& .highlightOption':{
      background:`${theme.palette.primary.main} !important`
    },
    '& .multiSelectContainer li':{
        '&:hover': {
          background:`${theme.palette.primary.main} !important`,
       },   
    },
    '& li':{
      fontSize:'14px',
      '&:hover': {
        background:`${theme.palette.primary.main} !important`,
     },
    },
  '& input':{
    fontSize:'16px',
    paddingLeft:'5px',
    minHeight: '28px',
  },
  '& .optionContainer':{
    border: '1px solid #ccc',
    borderRadius: '4px',
    display: 'block',
    margin: 0,
    maxHeight: '125px',
    overflowY: 'auto',
    padding: 0,
},
  },
}));

const duration = [
  { name: 'Every month ', id: 1 },
  { name: 'Every 2 months', id: 2 },
  { name: 'Every 3 months', id: 3 },
  { name: 'Every 6 months', id: 6 },
  { name: 'Every 12 months', id: 12 },
];

const AddEditUser = ({ open, onCloseDialog, getServiceActivitiesParams }) => {
  const classes = useStyles();
  const { users } = useSelector(({ SERVICETYPE }) => SERVICETYPE);
  const { currentUser } = useSelector(({ PLANNEDMAINTENANCE }) => PLANNEDMAINTENANCE);
  const ASSETLOCATION = useSelector(({ ASSETLOCATION }) => ASSETLOCATION.users);
  const { orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions } = getServiceActivitiesParams;
  const [propertyValue, setPropertyValue] = useState('');
  const [durationValue, setDurationValue] = useState('');
  const [serviceTypeValue, setServiceTypeValue] = useState('');
  const [dateValue, setDateValue] = useState(null);
  const [startDateValue, setStartDateValue] = useState('');
  const [assetIdValue, setAssetIdValue] = useState('');
  const [serviceDateValue, setServiceDateValue] = useState(null);
  const [serviceTypeValueError, setServiceTypeValueError] = useState('');
  const [propertyError, setPropertyError] = useState('');
  const [durationError, setDurationError] = useState('');
  const [serviceDateError, setServiceDateError] = useState('');
  const [startDateError, setStartDateError] = useState('');
  const [assetIdError, setAssetIdError] = useState('');
  const [siteServiceType, setSiteServiceType] = useState([]);
  const [propertyData, setPropertyData] = useState([]);
  const [status, setStatus] = useState(1);
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const p_color=window.localStorage.getItem('p_c') ?? '#FC8119'
  const s_color=window.localStorage.getItem('s_c') ?? '#F0511E'
  const t_color=window.localStorage.getItem('t_c') ?? '#3B1A45'
  const dispatch = useDispatch();
  let date = moment().format('YYYY-MM-DD');
  console.log("siteServiceType",siteServiceType)

  async function fetchData() {
    let response = await axios('locations/list', {
      params: { sortBy: 'first_name:asc', search: '', page: 1, limit: -1, status: 1, type: 1 },
    }).catch(error => {
      if (error?.response?.status === 401) {
        dispatch(setAuthUser(null));
        history.push('/signin');
      } else {
        dispatch(fetchError());
      }
    });
    let user = await response?.data;
    getServiceTypeEdit(user, currentUser?.site_id);
    setPropertyData(response?.data?.data);

    dispatch(fetchSuccess());
  }

  useEffect(() => {
    dispatch(fetchStart());

    fetchData();
  }, []);

  useEffect(() => {
    if (currentUser) {
      dispatch(getASSETLOCATIONList(currentUser?.site_id, 'name', 'asc', '', 0, -1, 1000));
      setPropertyValue(currentUser?.site_id);
      setServiceTypeValue(currentUser?.service_type_id);
      setDurationValue(currentUser?.duration);
      setDateValue(moment(currentUser?.start_date * 1000).format('YYYY-MM-DD'));
      getNextServiceDate(moment(currentUser?.next_service_date * 1000).format('YYYY-MM-DD'));
      setServiceDateValue(moment(currentUser?.next_service_date * 1000).format('YYYY-MM-DD'))
      setStartDateValue(moment(currentUser?.installation_date * 1000).format('YYYY-MM-DD'))
      setStatus(currentUser?.status);
      setAssetIdValue(currentUser?.asset_id);
    }
  }, [currentUser]);

  const onSubmitClick = () => {
    if (!propertyValue) {
      setPropertyError(requiredMessage);
    }
    if (!durationValue) {
      setDurationError(requiredMessage);
    }
    if (!serviceTypeValue[0]) {
      setServiceTypeValueError(requiredMessage);
    }
    if (!serviceDateValue) {
      setServiceDateError(requiredMessage);
    }
    if (!dateValue) {
      setStartDateError(requiredMessage);
    }
    if (!assetIdValue) {
      setAssetIdError(requiredMessage);
    }

    if (moment(dateValue).format('x') < moment(moment().format('YYYY-MM-DD')).format('x')) {
      setStartDateError("Please select valid date, you can't select past date .");
    }
    if(moment(serviceDateValue).format('x') < (moment(dateValue).add(durationValue, 'M').set({hour:0,minute:0,second:0,millisecond:0}).format('x'))){
      setServiceDateError("Please select valid date, next service date should be grater than installation date.");
    }
    
    if (moment(serviceDateValue).format('x') < moment(moment().format('YYYY-MM-DD')).format('x')) {
      setServiceDateError("Please select valid date, you can't select past date .");
    }
    if (
      propertyValue &&
      durationValue &&
      serviceTypeValue &&
      serviceDateValue &&
      dateValue &&
      moment(dateValue).format('x') >= moment(moment().format('YYYY-MM-DD')).format('x') &&
      // moment(serviceDateValue).format('x') >= moment(moment().format('YYYY-MM-DD')).format('x') &&
      moment(serviceDateValue).format('x') >= moment(dateValue).add(durationValue, 'M').set({hour:0,minute:0,second:0,millisecond:0}).format('x') &&
      assetIdValue
    ) {
      onUserSave();
    }
  };

  let usersArr = [];

  const getServiceTypeEdit = (data, siteId) => {
    let location = data?.data?.data?.filter(row => {
      if (row._id === siteId) {
        return row.service_types;
      }
    });

    let newusers = users?.data.map(row => {
      const newLocation = location?.forEach(data => {
        let a = data.service_types.find(item => {
          if (item === row._id) {
            usersArr.push(row);
          }
        });
      });
    });

    setSiteServiceType(usersArr);
  };

  const getServiceType = e => {
    let location = propertyData?.data?.filter(row => {
      if (row._id === e.target.value || row._id === e) {
        return row.service_types;
      }
    });

    let newusers = users?.data.map(row => {
      const newLocation = location?.forEach(data => {
        let a = data.service_types.find(item => {
          if (item === row._id) {
            usersArr.push(row);
          }
        });
      });
    });

    setSiteServiceType(usersArr);
  };

  const getNextServiceDate = (duration, date) => {
    if (duration && date) {
      var futureMonth = moment(date)
        .add(duration, 'M')
        .format('YYYY-MM-DD');
      setServiceDateValue(futureMonth);
      setServiceDateError('');
    }
  };

  const onUserSave = () => {
    const serviceActivityData = {
      site_id: propertyValue,
      service_type_id: serviceTypeValue,
      duration: durationValue,
      next_service_date: moment(serviceDateValue, 'YYYY-MM-DD').format('X'),
      installation_date: moment(dateValue, 'YYYY-MM-DD').format('X'),
      status: status,
      asset_id: assetIdValue,
    };

    if (currentUser) {
      dispatch(
        updatePlannedMaintenance({ ...serviceActivityData }, currentUser?._id, () => {
          onCloseDialog();
          dispatch(getPlannedMaintenanceList(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
        }),
      );
    } else {
      dispatch(
        addNewPlannedMaintanance(serviceActivityData, () => {
          onCloseDialog();
          dispatch(getPlannedMaintenanceList(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
        }),
      );
    }
  };
  const handleDateChange = date => {
    setDateValue(date);
    setStartDateError('');
    getNextServiceDate(durationValue, date);
    setStartDateValue(date)
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>
        {currentUser ? 'Edit Planned Maintenance Service Details' : 'Add New Planned Maintenance Service'}
      </DialogTitle>
      <DialogContent dividers>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
          <GridContainer>
            <Grid item xs={12} sm={12} className={classes.root}>
              <AppSelectBox
                fullWidth
                data={propertyData?.data}
                label="Job Location"
                valueKey="_id"
                variant="outlined"
                labelKey="name"
                value={propertyValue}
                onChange={e => {
                  setPropertyValue(e.target.value);
                  getServiceType(e);
                  setPropertyError('');
                  setAssetIdValue('')
                  setServiceTypeValue('')
                  dispatch(getASSETLOCATIONList(e.target.value, 'name', 'asc', '', 0, -1, 1000));
                }}
                helperText={propertyError}></AppSelectBox>
            </Grid>

            <Grid item xs={12} sm={12}>
                    <Select
                      className={classes.assignInventorySiteView}
                      placeholder="Service Type"
                      value={siteServiceType?.map(item=>{
                        return{ value:item?._id, label:item?.name}
                      }).filter(obj => obj?.value === serviceTypeValue)}
                      onChange={e=>{setServiceTypeValue(e?.value ?? ''); setServiceTypeValueError('')}}
                      isClearable={isClearable}
                      isSearchable={isSearchable}
                      options={siteServiceType?.map(item=>{
                        return{ value:item?._id, label:item?.name}
                      })}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                        ...theme.colors,
                          text: t_color,
                          // primary25: s_color,
                          primary: p_color,
                        },
                      })}
                    />
                  <p className={classes.errText} style={{ color: 'red' }}>{serviceTypeValueError}</p>

              {/* <AppSelectBox
                fullWidth
                data={siteServiceType}
                label="Service Type"
                valueKey="_id"
                variant="outlined"
                labelKey="name"
                value={serviceTypeValue}
                onChange={e => {
                  setServiceTypeValue(e.target.value);
                  setServiceTypeValueError('');
                }}
                helperText={serviceTypeValueError}
              /> */}
            </Grid>

            <Grid item xs={12} sm={12} className={classes.root}>
              <AppSelectBox
                fullWidth
                data={duration}
                label="Duration"
                valueKey="id"
                variant="outlined"
                labelKey="name"
                value={durationValue}
                onChange={e => {
                  setDurationValue(e.target.value);
                  setDurationError('');
                  getNextServiceDate(e.target.value, dateValue);
                }}
                helperText={durationError}></AppSelectBox>
            </Grid>

            <Grid item xs={12} sm={12}  >
            <KeyboardDatePicker
              placeholder='DD/MM/YYYY'
                margin="normal"
                id="date-picker-dialog"
                label="Installation Date"
                format="DD/MM/YYYY"
                value={dateValue}
                minDate={new Date()}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'Due Date',
                }}
                className={classes.textField}
                helperText={startDateError}
              />
              {/* <TextField
                id="date"
                label="Installation Date"
                type="date"
                value={dateValue}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: date,
                }}
                onChange={e => {
                  setDateValue(e.target.value);
                  setStartDateError('');
                  getNextServiceDate(durationValue, e.target.value);
                  setStartDateValue(e.target.value)
                }}
                helperText={startDateError}
              /> */}
            </Grid>

            <Grid item xs={6} sm={6}>
              {/* <TextField
                id="date"
                label="Start Date"
                type="date"
                disabled={true}
                value={startDateValue}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              /> */}
              <KeyboardDatePicker
              placeholder='DD/MM/YYYY'
                margin="normal"
                id="date-picker-dialog"
                label='Start Date'
                disabled={true}
                value={dateValue?startDateValue:null}
                className={classes.textField}
                format="DD/MM/YYYY"
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                // className={classes.textField}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
            <KeyboardDatePicker
            disabled={serviceDateValue? false:true}
              placeholder='DD/MM/YYYY'
                margin="normal"
                id="date-picker-dialog"
                label="Next Service Date"
                format="DD/MM/YYYY"
                value={serviceDateValue}
                minDate={serviceDateValue}
                onChange={date => {
                  setServiceDateValue(date);
                  setServiceDateError('');
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                className={classes.textField}
                helperText={serviceDateError}
              />
              {/* <TextField
                id="date"
                label="Next Service Date"
                type="date"
                value={serviceDateValue}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: serviceDateValue,
                }}
                onChange={e => {
                  setServiceDateValue(e.target.value);
                  setServiceDateError('');
                }}
                helperText={serviceDateError}
                style={{cursor:"pointer"}}
              /> */}
            </Grid>

            <Grid item xs={12} sm={12} className={classes.root}>
              <AppSelectBox
                fullWidth
                data={ASSETLOCATION?.data}
                label="Asset"
                valueKey="_id"
                variant="outlined"
                labelKey="name"
                value={assetIdValue}
                onChange={e => {
                  setAssetIdValue(e.target.value);
                  setAssetIdError('');
                }}
                helperText={assetIdError}></AppSelectBox>
            </Grid>
          </GridContainer>
        </Box>

        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button onClick={onCloseDialog} variant="outlined">
            Cancel
          </Button>
          <Box ml={2}>
            <Button variant="contained" color="primary" style={{ minWidth: '100px' }} onClick={onSubmitClick}>
              {currentUser ? 'Update' : 'Add'}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditUser;

AddEditUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
