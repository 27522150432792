import React, { useEffect, useState } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Block, CheckCircleOutline, Visibility, Edit, Delete } from '@material-ui/icons';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import { Typography } from '@material-ui/core';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { sentMailToUser } from 'redux/actions/Users';
import { Activate, Deactivate } from '@jumbo/constants/stringConstant';
import { getTenantList, updateTenantStatus } from 'redux/actions/Tenant';
import { getThresholdAmount } from 'redux/actions/ThresholdAmount';
import InputAdornment from '@material-ui/core/InputAdornment';

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    fontWeight: 300,
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    maxWidth: '150px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  taxtSize: {
    fontWeight: 300
  },
  thresholdAmount: {
    maxWidth: "100px",
    padding: '3px',
    borderRadius: '3px',
    borderStyle: 'solid',
    // textAlign: "center"
  },
  border: {
    color: theme.palette.text.primary,
    '&:focus': {
      border: `2px solid ${theme.palette.text.primary}`,
    },
  },
}));

const UserListRow = ({
  row,
  editRow,
  isSelected,
  onRowClick,
  onUserEdit,
  onUserDelete,
  onUserView,
  getFMProviderParams,
  onUserUpdate,
  indexId,
  key,
  handleChangePriority,
  serviceTypeIndex
}) => {
  const { id, orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions } = getFMProviderParams;
  const { authUser } = useSelector(({ auth }) => auth);
  const { currentUser } = useSelector(({ THRESHOLDAMOUNT }) => THRESHOLDAMOUNT);
  const [priority1, setPriority1] = useState();
  const [priority2, setPriority2] = useState();
  const [priority3, setPriority3] = useState();
  const [priority4, setPriority4] = useState();
  const [priority5, setPriority5] = useState();
  const classes = useStyles();
  const dispatch = useDispatch();
  // console.log("Current User", currentUser?.data?.map((row)=>row))
  
  let a1 = currentUser?.data?.map((row)=>row)
  let a2 = a1?.priority?.map((item)=>{console.log("value : ",item?.amount)})

  // let allAmount = currentUser?.data?.map((item)=>item);
  // let middleAmount = allAmount?.map((dev)=>dev);
  // let finalAmount = middleAmount?.map((dir)=>dir);
  // let fullyFinalAmount = finalAmount?.priority?.map((data)=>{console.log("final data",data?.amount)});

  // let oldAmount = currentUser?.data?.priority?.map(item=>{
  //   return(item)})

  //   console.log("oldAmount",oldAmount)

  useEffect(()=>{

    
    if(currentUser)
    {
      // let allAmount = currentUser?.data?.map((item1)=>item1);
      // let middleAmount = allAmount?.map((item2)=>item2);
      // let finalAmount = middleAmount?.map((item3)=>item3);
      // let fullyFinalAmount = finalAmount?.priority?.map((dev)=>dev);
      // let originalAmount = fullyFinalAmount?.map((dir)=>{console.log("dir",dir?.amount)})

      // if (finalAmount?.value === 1)
      // setPriority1(finalAmount?.amount)
      // else if(finalAmount?.value === 2)
      // setPriority2(finalAmount?.amount)
      // else if(finalAmount?.value === 3)
      // setPriority3(finalAmount?.amount)
      // else if(finalAmount?.value === 4)
      // setPriority4(finalAmount?.amount)
      // else if(finalAmount?.value === 5)
      // setPriority5(finalAmount?.amount)
      
    }
    
  },[currentUser])

  function refreshContactList() {
    dispatch(getThresholdAmount(id, orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
  }
  const labelId = `enhanced-table-checkbox-${row._id}`;

  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
      <TableCell padding="checkbox"></TableCell>
      <TableCell>{indexId + 1}</TableCell>
      <TableCell component="th" id={labelId} scope="row">
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {row?.service_type_details?.name}
        </Typography>
      </TableCell>
      {editRow === true ?
      row?.priority?.map ((item,index) => <TableCell component="th" id={labelId} scope="row">
      <input type='number'
      // border="2px"
      defaultValue={item?.amount}
        placeholder={`P${index+1} Amount`}
        className={classes.thresholdAmount}
        onKeyPress={event => {
          if (['-', '+', 'e', '.', '/', '*'].includes(event.key)) {
            event.preventDefault();
          }
        }}
        onPaste={(e) => {
          e.preventDefault()
          return false;
        }}
        onCopy={(e) => {
          e.preventDefault()
          return false;
        }} 
        // value={priority1}
        onChange={(e)=>handleChangePriority(e.target.value,serviceTypeIndex,index)}
        />

    </TableCell>)
    
        
         :
        <>
        {row?.priority?.map((item,index)=>
          <TableCell component="th" id={labelId} scope="row"> <Typography className={classes.titleRoot}>{item?.amount === 0 ? "-" : item?.amount}</Typography> </TableCell>
          )}
        </>
      }
    </TableRow>
  );
};

export default React.memo(UserListRow);
