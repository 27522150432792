import { lighten, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(4),
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  dialogQR: {
    position: 'relative',
    '& .MuiDialog-paperWidthSm': {
      width: '100%',
      maxWidth: '1000px',
      overflow:'hidden'
    },
  },
  container: {
    maxHeight: 415,
  },
  table: {
    minWidth: 750,
    '& .MuiTableCell-head':{
      fontWeight:600
          }
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark,
    textOverflow: 'ellipsis',
    maxWidth:'150px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  codeTitle: {
    fontSize: 16,
    color:  theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.up('md')]: {
      fontSize: 18,
    },
  },
}));

export default useStyles;
