import axios from '../../services/auth/jwt/config';
import { fetchStart, fetchSuccess } from './Common';
import { GET_DASHBOARD, GET_ACCOUNT_DASHBOARD, GET_DASHBOARD_CONTRACTORAPPLICATION } from '../../@jumbo/constants/ActionTypes';
import { dashboard, accdashboard, dashboardContractorApplication } from '../../@jumbo/constants/ApiConstatnt';

export const fetchDashboardCardData = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios.get(dashboard).then(({ data }) => {
      dispatch(fetchSuccess());
      dispatch({
        type: GET_DASHBOARD,
        payload: data.data,
      });
    });
  };
};

export const fetchDashboardContractorApplication = (sortBy, sort, searchTerm, page, limit, filterOptions, callbackFun, user_type, allFmUser) => {
  return dispatch => {
    dispatch(fetchStart());
    axios.get(dashboardContractorApplication,{
      params: {
        sortBy: `${sortBy}:${sort}`,
        search: searchTerm,
        page: page + 1,
        limit: limit,
        status: filterOptions[0],
        user_type: user_type,
        allFmUser: allFmUser,
      },
    }).then(({ data }) => {
      dispatch(fetchSuccess());
      dispatch({
        type: GET_DASHBOARD_CONTRACTORAPPLICATION,
        payload: data.data,
      });
      if (callbackFun) callbackFun(data.data);
    });
  };
};

export const fetchAccountDashboard = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios.get(accdashboard).then(({ data }) => {
      dispatch(fetchSuccess());
      dispatch({
        type: GET_ACCOUNT_DASHBOARD,
        payload: data.data,
      });
    });
  };
};