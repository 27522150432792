import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '../../../../@jumbo/components/Common/formElements/AppTextInput';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Button from '@material-ui/core/Button';
import { emailNotValid, lettersOnlyMessage, requiredMessage } from '../../../../@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { capitalizeFLetter, isValidEmail } from '../../../../@jumbo/utils/commonHelper';
import { getVendorManagement, addVendorManagement, UpdateVendor } from '../../../../redux/actions/VendorManagement';
import { getWarehouseList } from '../../../../redux/actions/Locations';
import { stringOnly } from '../../../../@jumbo/constants/ValidationRegex';
import { alpha } from '@material-ui/core/styles';
import { Checkbox } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Multiselect from 'multiselect-react-dropdown';

const useStyles = makeStyles(theme => ({
  root: {
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    "& .MuiFormLabel-root": {
      color: '#909090'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink':{
      color: theme.palette.text.primary
    }
  },
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.text.primary,
    },
  },
  titleRoot: {
    padding: 0,
    paddingBottom: 5,
    paddingTop: 15,
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.text.primary,
    }
  },
  assignInventoryTitle: {
    minWidth: 175,
    padding: 0,
    paddingBottom: 5,
    paddingTop: 15,
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.text.primary,
    },
  },
  vendorTitleRoot: {
    padding: 0,
    paddingBottom: 5,
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.text.primary,
    }
  },
  typeList: {
    width: 535,
    fontSize: 14,
    height: 300,
    backgroundColor: theme.palette.background.default,
    overflow: 'auto',
  },
  cardRoot: {
    position: 'relative',
    '& .Cmt-card-content': {
      padding: 0,
      paddingBottom: 24,
    },
  },
  scrollbarRoot: {
    height: 340,
  },
  badgeRoot: {
    fontSize: 14,
    letterSpacing: 0.25,
    backgroundColor: alpha(theme.palette.warning.main, 0.15),
    color: theme.palette.warning.main,
    padding: '2px 10px',
    borderRadius: 30,
  },
  tableRowCellRoot: {
    fontSize: 14,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 16,
    letterSpacing: 0.4,
    textAlign: 'center',
    color: theme.palette.common.dark,
    borderBottom: '0 none',
    '&:first-child': {
      paddingLeft: 24,
    },
    '&:last-child': {
      paddingRight: 24,
    },
  },
  tableRowRoot: {
    position: 'relative',
    transition: 'all .2s',
    borderTop: `solid 1px ${theme.palette.borderColor.main}`,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
      transform: 'translateY(-4px)',
      boxShadow: `0 3px 10px 0 ${alpha(theme.palette.common.dark, 0.2)}`,
      borderTopColor: 'transparent',
      '& $tableCellRoot': {
        color: theme.palette.text.primary,
        '&.success': {
          color: theme.palette.success.main,
        },
      },
    },
  },
  serviceTypeList: {
    fontSize: 14,
    letterSpacing: 0.25,
    textOverflow: 'ellipsis',

    wordWrap: 'break-word'
  },
  tableCellRoot: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: 14,
    letterSpacing: 0.25,
    textAlign: 'center',
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    borderBottom: '0 none',
    '&:first-child': {
      paddingLeft: 24,
    },
    '& .Cmt-avatar-more': {
      fontSize: 10,
      color: theme.palette.primary.main,
    },
  },
  tableCellSecondaryColor: {
    color: theme.palette.text.secondary,
  },
  blockRoot: {
    display: 'block',
    fontSize: 14,
  },
  contactColor: {
    color: theme.palette.primary.main,
  },
  vendorTitle: {
    padding: 0,
    paddingBottom: 5,
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.text.primary,
    },
  },
  errText:{
    color: 'red',
    marginLeft: '14px',
    marginRight: '14px',
    fontSize: '0.75rem',
    marginTop: '3px',
    textAlign: 'left',
    fontFamily: 'Normal',
    fontWeight: 400,
    lineHeight: 1.66,
},
assignInventorySiteView: {
  minHeight: '40px',
  minWidth: '350px',
  fontSize: '18px',
  '& .chip':{
    background:`${theme.palette.primary.main} !important`
  },
  '& .highlightOption':{
    background:`${theme.palette.primary.main} !important`
  },
  '& .multiSelectContainer li':{
      '&:hover': {
        background:`${theme.palette.primary.main} !important`,
     },   
  },
  '& li':{
    fontSize:'14px',
    '&:hover': {
      background:`${theme.palette.primary.main} !important`,
   },
  },
'& input':{
  fontSize:'16px',
  paddingLeft:'5px'
}
},
}));

const splitName = user => {
  if (user) {
    const [fName, mName, lName] = user.name.split(' ');
    return [fName, lName ? mName + ' ' + lName : mName];
  }
  return ['', ''];
};

const AddEditUser = ({ open, onCloseDialog, getFMProviderParams }) => {
  const classes = useStyles();
  const currentUser = useSelector(({ VENDORMANAGEMENT }) => VENDORMANAGEMENT.currentUser);
  const { warehouse } = useSelector(({ LOCATION }) => LOCATION);
  const { orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions } = getFMProviderParams;
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [userRole, setUserRole] = useState(4);
  const [value, setValue] = useState('1');
  const [contactNo, setContactNo] = useState('');
  const [country, setCountry] = useState(null);
  const [contactNoError, setContactNoError] = useState('');
  const [serviceTypeValue, setServiceTypeValue] = useState([]);
  const [serviceTypeValueError, setServiceTypeValueError] = useState('');
  const [selectedServiceType, setSelectedServiceType] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState([]);
  const [warehouseValue, setWarehouseValue] = useState([]);
  const [warehouseValueError, setWarehouseValueError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [status, setStatus] = useState(1)
  // const [selectedServiceType, setSelectedServiceType] = useState([]);
  const { users } = useSelector(({ SERVICETYPE }) => SERVICETYPE);
  const dispatch = useDispatch();
  // console.log("serviceTypeValue : ",serviceTypeValue);

  useEffect(() => {
    if (currentUser) {
      setFirstName(currentUser?.first_name);
      setLastName(currentUser?.last_name);
      setEmail(currentUser?.email);
      setServiceTypeValue(currentUser?.service_types_ids)
      setStatus(currentUser?.status)
      setContactNo(currentUser?.contact_details?.mobile_number)
      setCountry({
        name: "",
        dialCode: currentUser?.contact_details?.dial_code,
        countryCode: currentUser?.contact_details?.country_code,
        format: "+... .. ......."
      })
      // setServiceTypeValue(currentUser?.service_types_ids);
      setWarehouseValue(currentUser?.assign_to_warehouse);

      const filterWarehouseArray = warehouse?.data?.map(item => {
        let data = false;
        data = currentUser?.assign_to_warehouse?.find(service => (item._id === service ? true : false));
        return data ? { name: item.name, _id: item._id, select: true } : { name: item.name, _id: item._id, select: false };
      });
      setSelectedWarehouse(filterWarehouseArray.filter(item=>item?.select));
      setWarehouseValue(filterWarehouseArray.filter(item=>item?.select));

      const filterSelectedServiceTypeArray = users?.data?.map(item => {
        let data = false;
        data = currentUser?.service_types_ids?.find(service => (item._id === service._id ? true : false));
        return data ? { name: item.name, _id: item._id, select: true } : { name: item.name, _id: item._id, select: false };
      });
      setSelectedServiceType(filterSelectedServiceTypeArray);
    }
  }, [currentUser]);

  const onFilterOptionClick1 = (e, option) => {
    e.stopPropagation();
    const checkData = selectedServiceType?.map(item => {
      if (item._id === option._id) {
        return item.select
          ? { name: item.name, _id: item._id, select: false }
          : { name: item.name, _id: item._id, select: true };
      } else {
        return item;
      }
      
    });
    setSelectedServiceType(checkData);
    const filterServiceId = checkData?.filter(item => item.select)
    const filterServiceId2 = filterServiceId?.map(item => item._id)
    setServiceTypeValue(filterServiceId2)
    setServiceTypeValueError('');
  };

  const onFilterOptionClick = (e, option) => {
    e.stopPropagation();
    setServiceTypeValue(prevState => {
      if (prevState.includes(option._id)) {
        return prevState.filter(item => item !== option._id);
      } else {
        return [...prevState, option._id];
      }
    });
    setServiceTypeValueError('');
  };

  const handleChange = event => {
    setValue(event.target.value);
  };

  const onSubmitClick = () => {

    if (!firstName) {
      setFirstNameError(requiredMessage);
    } else if (!firstName.match(stringOnly)) {
      setFirstNameError(lettersOnlyMessage);
    }
    if (!lastName) {
      setLastNameError(requiredMessage);
    } else if (!lastName.match(stringOnly)) {
      setLastNameError(lettersOnlyMessage);
    }
    if (!email) {
      setEmailError(requiredMessage);
    } else if (!isValidEmail(email)) {
      setEmailError(emailNotValid);
    }
    if (!serviceTypeValue?.length) {
      setServiceTypeValueError(requiredMessage)
    }
    if (!contactNo) {
      setContactNoError(requiredMessage)
    }
    else if (contactNo.length < 6) {
      setContactNoError('Please enter valid phone number.')
    }
    if (!warehouseValue?.length) {
      setWarehouseValueError(requiredMessage)
    }
    if (firstName && firstName.match(stringOnly) && lastName && lastName.match(stringOnly) && email && isValidEmail(email) && serviceTypeValue?.length && contactNo && contactNo.length > 5 && warehouseValue?.length) {
      onUserSave();
    }
  };

  const onUserSave = () => {
    const fname = capitalizeFLetter(firstName);
    const lname = capitalizeFLetter(lastName);

    const userDetail = {
      first_name: fname,
      last_name: lname,
      email: email,
      contact_details: {
        dial_code: `${country?.dialCode}`,
        country_code: country?.countryCode,
        mobile_number: contactNo
      },
      status: status,
      service_types_ids: serviceTypeValue,
      assign_to_warehouse: warehouseValue,
    };

    if (currentUser) {
      dispatch(
        UpdateVendor(currentUser._id, userDetail, () => {
          onCloseDialog();
          dispatch(getVendorManagement(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
        }),
      );
    } else {
      dispatch(
        addVendorManagement(userDetail, () => {
          onCloseDialog();
          dispatch(getVendorManagement(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
        }),
      );
    }
  };

  console.log("serviceTypeValue",serviceTypeValue)

  const p_color = window.localStorage.getItem('p_c') ?? '#FC8119'
  const t_color = window.localStorage.getItem('t_c') ?? '#F0511E'

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>{currentUser ? 'Edit Vendor Details' : 'Add New Vendor'}</DialogTitle>
      <DialogContent dividers>
        {/* <DialogTitle className={classes.vendorTitleRoot}>
          {currentUser ? 'Vendor Details' : 'Vendor Details'}
        </DialogTitle> */}

        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
          <GridContainer>
            <Grid item xs={12} sm={6}>
              <AppTextInput
                className={classes.root}
                fullWidth
                variant="outlined"
                label="First Name"
                value={firstName}
                onChange={e => {
                  setFirstName(e.target.value.slice(0, 20));
                  setFirstNameError('');
                }}
                helperText={firstNameError}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <AppTextInput
                className={classes.root}
                fullWidth
                variant="outlined"
                label="Last Name"
                value={lastName}
                onChange={e => {
                  setLastName(e.target.value.slice(0, 20));
                  setLastNameError('');
                }}
                helperText={lastNameError}
              />
            </Grid>
          </GridContainer>
        </Box>

        <Grid item xs={12} sm={12}>
          <AppTextInput
            className={currentUser ? "" : classes.root}
            fullWidth
            variant="outlined"
            label="Email Address"
            value={email}
            disabled={currentUser ? true : false}
            onChange={e => {
              setEmail(e.target.value);
              setEmailError('');
            }}
            helperText={emailError}
          />
        </Grid>

        {/* {currentUser === null ? (
          <Grid item xs={12} sm={6}>
            <Typography className={classes.titleRoot}>Service Types</Typography>
            <Box className={classes.typeList}>
              {users.data?.map((value, index) => {
                const labelId = `transfer-list-all-item-${value}-label`;
                return (
                  <ListItem key={index} role="listitem" button onClick={e => document.getElementById(value._id).click()}>
                    <ListItemIcon>
                      <Checkbox onClick={e => onFilterOptionClick(e, value)} id={value._id} tabIndex={-1} disableRipple />
                    </ListItemIcon>
                    <ListItemText
                      id={labelId}
                      primary={`${value.name}`}
                      style={{
                        fontSize: 14,
                        letterSpacing: 0.25,
                        textOverflow: 'ellipsis',
                        wordWrap: 'break-word'
                      }}
                    />
                  </ListItem>
                );
              })}
            </Box>
            <p className={classes.errText} style={{ color: 'red' }}>
              {serviceTypeValueError}
            </p>
          </Grid>
        ) : (
          <Grid item xs={12} sm={6}>
            <DialogTitle className={classes.titleRoot}>Service Types</DialogTitle>
            <Box className={classes.typeList}>
              {selectedServiceType?.map((value, index) => {
                const labelId = `transfer-list-all-item-${value}-label`;
                return (
                  <ListItem key={index} role="listitem" button onClick={e => document.getElementById(value._id).click()}>
                    <ListItemIcon >
                      <Checkbox
                        id={value._id}
                        onClick={e => onFilterOptionClick1(e, value)}
                        checked={value.select}
                        tabIndex={-1}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={labelId}
                      primary={`${value.name}`}
                      style={{
                        fontSize: 14,
                        letterSpacing: 0.25,
                        textOverflow: 'ellipsis',
                        wordWrap: 'break-word'
                      }}
                    />
                  </ListItem>
                );
              })}
            </Box>
            <p className={classes.errText} style={{ color: 'red' }}>
              {serviceTypeValueError}
            </p>
          </Grid>
        )} */}

            <Grid item xs={12} sm={12}>
            <Box>
            <Box><DialogTitle className={classes.assignInventoryTitle}>Service Types</DialogTitle></Box>
            <Box mt={1}>
                <Multiselect
                options={users?.data} // Options to display in the dropdown
                selectedValues={serviceTypeValue} // Preselected value to persist in dropdown
                onSelect={(selectedList, selectedItem)=>{setServiceTypeValue(selectedList); setServiceTypeValueError("")}} // Function will trigger on select event
                onRemove={(selectedList, selectedItem)=>setServiceTypeValue(selectedList)} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options
                placeholder="Service Type"
                className={classes.assignInventorySiteView}
              />
              <p className={classes.errText} style={{ color: 'red' }}>{serviceTypeValueError}</p>
                </Box>
                </Box>
                </Grid>   

            <GridContainer>
            <Grid item xs={12} sm={12}>
            <Box>
            <Box><DialogTitle className={classes.assignInventoryTitle}>Assign Inventory Site</DialogTitle></Box>
            <Box mt={1}>
                <Multiselect
                options={warehouse?.data} // Options to display in the dropdown
                selectedValues={selectedWarehouse} // Preselected value to persist in dropdown
                onSelect={(selectedList, selectedItem)=>{setWarehouseValue(selectedList);setWarehouseValueError("")}} // Function will trigger on select event
                onRemove={(selectedList, selectedItem)=>setWarehouseValue(selectedList)} // Function will trigger on remove event
                displayValue={"name"} // Property name to display in the dropdown options
                placeholder="Inventory Site"
                className={classes.assignInventorySiteView}
                />
              <p className={classes.errText} style={{ color: 'red' }}>
                  {warehouseValueError}
                </p>
              </Box>
              </Box>
              </Grid>   
              </GridContainer>

        <DialogTitle className={classes.titleRoot}>
          {currentUser ? 'Contact Information' : 'Contact Information'}
        </DialogTitle>

        {currentUser ? <PhoneInput
        className={classes.root}
          inputStyle={{ color: t_color }}
          country={country?.countryCode}
          value={country?.dialCode + contactNo}
          onChange={(value, data, event, formattedValue) => {
            setContactNo(value.slice(data.dialCode.length))
            setCountry(data)
            setContactNoError('')
          }}

        /> : <PhoneInput
        className={classes.root}
          inputStyle={{ color: t_color }}
          country={'us'}
          onChange={(value, data, event, formattedValue) => {
            setContactNo(value.slice(data.dialCode.length))
            setCountry(data)
            setContactNoError('')
          }}

        />}
        <p className={classes.errText} style={{ color: 'red' }}>
          {contactNoError}
        </p>

        <DialogTitle className={classes.titleRoot}>
          {currentUser ? 'Insurance Information' : 'Insurance Information'}
        </DialogTitle>

        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button variant="outlined" onClick={onCloseDialog}>
            Cancel
          </Button>
          <Box ml={2}>
            <Button variant="contained" style={{ minWidth: '100px' }} color="primary" onClick={onSubmitClick}>
              {currentUser ? 'Update' : 'Add'}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditUser;

AddEditUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
