import React from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import BuildIcon from '@material-ui/icons/Build';
import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import useStyles from './index.style';
import FaceIcon from '@material-ui/icons/Face';
import UserTable from './UserTable/index';
import DescriptionIcon from '@material-ui/icons/Description';
import AssignVender from './AssignVender/index';
import GridContainer from '../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import BusinessIcon from '@material-ui/icons/Business';
import ImageIcon from '@material-ui/icons/Image';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import { setCurrentServiceRequset, setEdittServiceRequset } from '../../../../redux/actions/ServiceRequest';
import { useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import WorkOrder from './WorkOrder/index'

const UserDetailView = ({ open, onCloseDialog, setOpenUserDialog, users }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { details } = useSelector(({ SERVICEREQUEST }) => SERVICEREQUEST);

  const handelOpenAssignVendor = () => {
    if (details?.vendor_details === null) {
      dispatch(setCurrentServiceRequset(details));
      setOpenUserDialog(true);
    } else {
      dispatch(setEdittServiceRequset(details));
      setOpenUserDialog(true);
    }
  };
  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.userInfoRoot}>
        <Box mr={3} display="flex" alignItems="center">
          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.titleRoot}>{' Service Request Detail'}</Typography>
              <Box ml={1}></Box>
            </Box>
          </Box>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box px={6} py={5}>
        <GridContainer>
          <Grid item xs={6} sm={6}>
            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
              <BusinessIcon />
              <Box ml={5}  display="flex">
                <Typography className={classes.titleRoot1}>Job Location :</Typography>
                <Typography className={classes.titleRoot2}>
                  {!details?.location_name ? '-' : details?.location_name}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }}>
              <BuildIcon />
              <Box ml={5}  display="flex">
                <Typography className={classes.titleRoot1}>Service Type :</Typography>
                <Typography className={classes.titleRoot2}>
                  {!details?.service_type ? '-' : details?.service_type}
                </Typography>
              </Box>
            </Box>

            <Box display="flex" alignItems="top" mb={{ xs: 4, sm: 5 }}>
              <ImageIcon />
              <Box ml={5}  display="flex">
                <Typography className={classes.titleRoot1}>Images :</Typography>
                <Typography >
                  <span>
                    {!details?.service_request_image.length
                      ? '-'
                      : details?.service_request_image?.map((item, index) => (
                          <img src={item} width="140" style={{ marginLeft: '2%',marginRight: '2%', marginTop: '2%' }} key={index} />
                        ))}
                  </span>
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
              <PriorityHighIcon />
              <Box ml={5}  display="flex">
                <Typography className={classes.titleRoot1}>Priority :</Typography>
                <Typography className={classes.titleRoot2}>{!details?.priority ? '-' : details?.priority}</Typography>
              </Box>
            </Box>
            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
              <FaceIcon />
              <Box ml={5}  display="flex">
                <Typography className={classes.titleRoot1}>Tenant :</Typography>
                <Typography className={classes.titleRoot2}>
                  {!details?.tenant_details ? '-' : details?.tenant_details?.first_name} {details?.tenant_details?.last_name}
                </Typography>
              </Box>
            </Box>

            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }}>
              <DescriptionIcon />
              <Box ml={5}  display="flex">
                <Typography className={classes.titleRoot1}>Description :</Typography>
                <Typography className={classes.titleRoot2}>{!details?.description ? '-' : details?.description}</Typography>
              </Box>
            </Box>
          </Grid>
        </GridContainer>
      </Box>
      <Divider variant="middle" />
      {<AssignVender handelOpenAssignVendor={handelOpenAssignVendor} users={users} />}

      <Divider variant="middle" />
      <Box px={6} py={5}>
        <Box mb={5}  color="common.dark" className={classes.titleRoot1}>
          Images :
        </Box>
        <div>
          <UserTable />
        </div>
      </Box>
      {/* <Divider variant="middle" />
      <Box px={6} py={5} mt={5}> */}
        {/* <div> */}
        {/* <WorkOrder /> */}
        {/* </div> */}
      {/* </Box> */}
    </Dialog>
  );
};

export default UserDetailView;

UserDetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
