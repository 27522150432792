import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import CmtAvatar from '@coremat/CmtAvatar';
import Typography from '@material-ui/core/Typography';

import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { useDispatch, useSelector } from 'react-redux';
// import CmtList from '../../../../@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import useStyles from './index.style';
import { Block, CheckCircleOutline } from '@material-ui/icons';
import { Button, Switch, Tooltip } from '@material-ui/core';
import FaceIcon from '@material-ui/icons/Face';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
// import UserAccessModule from '../UserAccessModule/index';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import { lettersOnlyMessage, requiredMessage } from '@jumbo/constants/ErrorMessages';
import { stringOnly } from '@jumbo/constants/ValidationRegex';
import { capitalizeFLetter } from '@jumbo/utils/commonHelper';
import { getUserList, updateUserData } from 'redux/actions/UserManagement';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCard from '@coremat/CmtCard';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import { intranet } from '@fake-db';
import { alpha } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableHeading from '../UserAccessADDEdit/TableHeading';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Checkbox } from '@material-ui/core';
import clsx from 'clsx';

const UserUpdate = ({ open, onCloseDialog, getFMProviderParams }) => {
  const classes = useStyles();
  const { currentUser } = useSelector(({ FMPROVIDER }) => FMPROVIDER);
  const { first_name, last_name, email, status, user_type, fm_module_access, _id } = currentUser;
  const { orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions } = getFMProviderParams;
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userStatus, setUserStatus] = useState(null);
  const [userAccess, setUserAccess] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    setFirstName(first_name);
    setLastName(last_name);
    setUserStatus(status);
    setUserAccess(fm_module_access);
  }, []);
  console.log('data', currentUser);
  const handleChange = event => {
    setUserStatus(userStatus === 1 ? 2 : 1);
  };
  const onViewClick = raw => {
    const modifiedAccess = userAccess.map((item, index) =>
      item.module_name === raw.module_name
        ? raw.is_view === true && raw.is_add === false && raw.is_edit === false && raw.is_enable === false
          ? { ...raw, is_view: false }
          : { ...raw, is_view: true }
        : item,
    );
    setUserAccess(modifiedAccess);
  };
  const onAddClick = raw => {
    const modifiedAccess = userAccess.map((item, index) =>
      item.module_name === raw.module_name
        ? raw.is_add === true
          ? { ...raw, is_add: false }
          : { ...raw, is_add: true, is_view: true }
        : item,
    );
    setUserAccess(modifiedAccess);
  };
  const onEditClick = raw => {
    const modifiedAccess = userAccess.map((item, index) =>
      item.module_name === raw.module_name
        ? raw.is_edit === true
          ? { ...raw, is_edit: false }
          : { ...raw, is_edit: true, is_view: true }
        : item,
    );
    setUserAccess(modifiedAccess);
  };
  const onEnableClick = raw => {
    const modifiedAccess = userAccess.map((item, index) =>
      item.module_name === raw.module_name
        ? raw.is_enable === true 
          ? { ...raw, is_enable: false }
          : { ...raw, is_enable: true, is_view: true }
        : item,
    );
    setUserAccess(modifiedAccess);
  };
  const onSubmitClick = () => {
    if (!firstName) {
      setFirstNameError(requiredMessage);
    } else if (!firstName.match(stringOnly)) {
      setFirstNameError(lettersOnlyMessage);
    }
    if (!lastName) {
      setLastNameError(requiredMessage);
    } else if (!lastName.match(stringOnly)) {
      setLastNameError(lettersOnlyMessage);
    }
    if (firstName && firstName.match(stringOnly) && lastName && lastName.match(stringOnly)) {
      onUserSave();
    }
  };
  const onUserSave = () => {
    const fname = capitalizeFLetter(firstName);
    const lname = capitalizeFLetter(lastName);
    const userDetail = {
      _id: _id,
      email,
      first_name: fname,
      last_name: lname,
      status: userStatus,
      user_type: user_type,
      fm_module_access: user_type === 4 ? userAccess : null,
    };

    dispatch(
      updateUserData(userDetail, () => {
        onCloseDialog();
        dispatch(getUserList(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
      }),
    );
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.userInfoRoot}>
        <Box mr={3} display="flex" alignItems="center">
          {/* <Box className={classes.avatarView} mr={{ xs: 4, md: 6 }}>
            <CmtAvatar size={70} alt={first_name} />
          </Box> */}

          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <Typography
                className={classes.titleRoot}>
                  Update User Detail
                {/* {first_name?.toUpperCase()} {last_name?.toUpperCase()} */}
              </Typography>
              <Box ml={1}></Box>
            </Box>
          </Box>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}></Box>
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>

      <Box px={6} py={5}>
        {/* <Box mb={5} component="h3" color="primary.dark">
          User Detail
        </Box> */}
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <FaceIcon />
          <Box ml={5} display='flex'>
            <Box mt={5}>
              <AppTextInput
                className={classes.root}
                fullWidth
                label="First name"
                value={firstName}
                onChange={e => {
                  setFirstName(e.target.value);
                  setFirstNameError('');
                }}
                helperText={firstNameError}
              />
            </Box>
            <Box mt={5} ml={5}>
              <AppTextInput
                fullWidth
                label="Last name"
                value={lastName}
                onChange={e => {
                  setLastName(e.target.value);
                  setLastNameError('');
                }}
                helperText={lastNameError}
              />
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <EmailIcon />
          <Box ml={5} color="primary" component="p" className="pointer">
            Email : {email}
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <PermIdentityIcon />
          <Box ml={5} color="primary" component="p" className="pointer">
            User Role : {user_type === 4 ? 'FM Sub-Admin' : user_type === 5 ? 'Account User' : 'Call Center User'}
          </Box>
        </Box>

        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }}>
          {status === 1 ? <CheckCircleOutline /> : <Block />}
          <Box ml={5}>Status (Active/Inactive) : {status === 1 ? 'Active' : 'Inactive'}</Box>
        </Box>
      </Box>
      {user_type === 4 ? (
        <Box>
          <CmtCard className={classes.cardRoot}>
            <CmtCardHeader title="Module Permissions"></CmtCardHeader>
            <CmtCardContent>
              <PerfectScrollbar className={classes.scrollbarRoot}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableHeading />
                  </TableHead>
                  <TableBody>
                    {userAccess?.map((row, index) => (
                      <TableRow className={clsx(classes.tableRowRoot)} key={index}>
                        <TableCell className={classes.tableCellRoot}>
                          <Box>{row?.module_name}</Box>
                        </TableCell>
                        <TableCell className={classes.tableCellRoot}>
                          <Box>
                            {' '}
                            <Checkbox checked={row?.is_view} onClick={e => onViewClick(row)} />
                          </Box>
                        </TableCell>
                        <TableCell className={classes.tableCellRoot}>
                          <Box>
                            {' '}
                            <Checkbox checked={row?.is_add} onClick={e => onAddClick(row)} />
                          </Box>
                        </TableCell>
                        <TableCell className={classes.tableCellRoot}>
                          <Box>
                            {' '}
                            <Checkbox checked={row?.is_edit} onClick={e => onEditClick(row)} />
                          </Box>
                        </TableCell>
                        <TableCell className={classes.tableCellRoot}>
                          <Box>
                            {' '}
                            <Checkbox checked={row?.is_enable} onClick={e => onEnableClick(row)} />
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </PerfectScrollbar>
            </CmtCardContent>
          </CmtCard>
        </Box>
      ) : null}

      <Box display="flex" justifyContent="flex-end" mb={4} mt={5} mr={5}>
        <Button variant="outlined" onClick={onCloseDialog}>
          Cancel
        </Button>
        <Box ml={2}>
          <Button variant="contained" style={{ minWidth: '100px' }} color="primary" onClick={onSubmitClick}>
            Update
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default UserUpdate;

UserUpdate.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
