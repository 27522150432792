import React from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import EmailIcon from '@material-ui/icons/Email';
import useStyles from './index.style';
import { Block, CheckCircleOutline } from '@material-ui/icons';
import FaceIcon from '@material-ui/icons/Face';
import { Grid } from '@material-ui/core';

const UserDetailView = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const { currentUser } = useSelector(({ PAYMENTTOVENDORS }) => PAYMENTTOVENDORS);
  const {serviceRequestData,vendorPaymentData } = currentUser;
console.log('paymenttovendor',currentUser)
  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.userInfoRoot}>
        <Box mr={3} display="flex" alignItems="center">
          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.titleRoot}>Payment To Vendor Detail</Typography>
              <Box ml={1}></Box>
            </Box>
          </Box>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}></Box>
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box px={6} py={5}>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <FaceIcon />
          <Grid item xs={8} sm={6} md={6}>
            <Box ml={5} component="p" className={classes.subTitleRoot}>
              Vendor Name : {vendorPaymentData?.vendor_details?.first_name} {vendorPaymentData?.vendor_details?.last_name}
            </Box>
          </Grid>
          <Grid item xs={8} sm={6} md={6}>
            <Box ml={5} component="p" className={classes.subTitleRoot}>
              Amount : $ {vendorPaymentData?.charge}
            </Box>
          </Grid>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <EmailIcon />
          <Grid item xs={8} sm={6} md={6}>
            <Box ml={5} color="primary" component="p">
              {/* Email : {email} */}
            </Box>
          </Grid>
          <Grid item xs={8} sm={6} md={6}>
            <Box display="flex">
              <Box ml={5} color="primary" component="p">
                For Service Type :
              </Box>
              <Box>
                {serviceRequestData?.map((service,index) => (
                  <Box ml={2} color="primary" component="p" key={index}>
                    {service?.description}
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <FaceIcon />
          <Grid item xs={8} sm={6} md={6}>
            <Box ml={5} component="p" className={classes.subTitleRoot}>
              Vendor Name : {vendorPaymentData?.vendor_details?.first_name} {vendorPaymentData?.vendor_details?.last_name}
            </Box>
          </Grid>
          <Grid item xs={8} sm={6} md={6}>
            <Box ml={5} component="p" className={classes.subTitleRoot}>
              Amount : $ {vendorPaymentData?.charge}
            </Box>
          </Grid>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }}>
          {/* {status === 1 ? <CheckCircleOutline /> : <Block />} */}
          {/* <Box ml={5}>Status (Active/Inactive) : {status === 1 ? 'Active' : 'Inactive'}</Box> */}
        </Box>
      </Box>
    </Dialog>
  );
};

export default UserDetailView;

UserDetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
