import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import Button from '@material-ui/core/Button';
import { alphaNumaricMessage, requiredMessage } from '@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { capitalizeFLetter } from '@jumbo/utils/commonHelper';
import { addNewASSETLOCATION, getASSETLOCATIONList, getCategoriesList, getAssetPartsList, getClassCategoriesList, getTypeCategoriesList, getSubTypeCategoriesList, updateASSETLOCATION } from 'redux/actions/AssetLocation';
import { alphaNumaric, emptySpace } from '@jumbo/constants/ValidationRegex';
import AppSelectBox from '@jumbo/components/Common/formElements/AppSelectBox';
import { alpha } from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { alphaNumaricWithSpace, numberOnly2 } from '../../../../../../../@jumbo/constants/ValidationRegex';
import UserSelectBox from '../../AssignedUser/UserSelectBox';
import imageCompression from 'browser-image-compression';
import ConfirmDialog from '@jumbo/components/Common/ConfirmDialog';
import { Typography, Checkbox } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import { Divider } from '@material-ui/core';
import AddPart from '../AddPart';
import Multiselect from 'multiselect-react-dropdown';
import moment from 'moment';
import InputAdornment from '@material-ui/core/InputAdornment';

const useStyles = makeStyles(theme => ({
  root: {
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    "& .MuiFormLabel-root": {
      color: '#909090',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      color: theme.palette.text.primary,
    }
  },
  dialogRoot: {
    position: 'relative',
    '& .MuiDialog-paperWidthSm': {
      width: '100%',
      maxWidth: '1200px',
    },
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.text.primary,
    },
  },
  titleRoot: {
    padding: 0,
    paddingBottom: 5,
    paddingTop: 15,
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.text.primary,
    }
  },
  typeList: {
    width: 400,
    fontSize: 14,
    height: 300,
    overflow: 'auto',
  },
  imageButton: {
    fontSize: '10px',
    marginLeft: '5px',
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  imageButtonWrapper: {
    marginBottom: '10px',
  },
  appSelectBox: {
    color: '#c7c3bf',
    '& .MuiFormLabel-root': {
      color: '#c7c3bf',
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    marginRight: '5px',
  },
  divider: {
    width: '1px',
    backgroundColor: 'black',
    marginLeft: '20px',
    marginRight: '20px',
    height: '1cm',
  },
  helperText: {
    marginLeft: '15px',
    marginRight: '15px',
  },
  datePicker: {
    marginLeft: '15px',
    marginRight: '15px',
  },
  serviceTypeView: {
    minWidth: '120px',
  },
  // serviceTypeSearchView: {
  //   minHeight: '40px',
  //   minWidth: '350px',
  //   fontSize: '18px',
  //   '& .chip':{
  //     background:`${theme.palette.primary.main} !important`
  //   },
  //   '& .highlightOption':{
  //     background:`${theme.palette.primary.main} !important`
  //   },
  //   '& .multiSelectContainer':{
  //     paddingLeft: '5px',
  //     fontSize: '16px',
  //   }
  // },
  serviceTypeSearchView: {
    minHeight: '40px',
    minWidth: '350px',
    fontSize: '18px',
    '& .chip':{
      background:`${theme.palette.primary.main} !important`
    },
    '& .highlightOption':{
      background:`${theme.palette.primary.main} !important`
    },
    '& .multiSelectContainer li':{
      
        '&:hover': {
          background:`${theme.palette.primary.main} !important`,
       },
      
      
    },
    '& li':{
      fontSize:'14px',
      '&:hover': {
        background:`${theme.palette.primary.main} !important`,
     },
    },
  '& input':{
    fontSize:'16px',
    paddingLeft:'5px'
  }
        // paddingLeft: '5px',
      // fontSize: '16px',
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 200,
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
      marginLeft: "8px",
    },
    "& .MuiFormLabel-root": {
      color: '#909090',
    },
    "& .MuiInputLabel-shrink": {
      color: theme.palette.text.primary,
    },
  },
}));

const AddEditUser = ({ open, onCloseDialog, siteId, getFMProviderParams }) => {
  const classes = useStyles();
  const { currentUser, assetType, systemCategories, classCategories, typeCategories, subTypeCategories } = useSelector(({ ASSETLOCATION }) => ASSETLOCATION);
  const tenantList = useSelector(({ TENANT }) => TENANT.users);
  const { id, orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions } = getFMProviderParams;
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [floor, setFloor] = useState('');
  const [floorError, setFloorError] = useState('');
  const [department, setDepartment] = useState('');
  const [departmentError, setDepartmentError] = useState('');
  const [assetId, setAssetId] = useState('');
  const [assetIdError, setAssetIdError] = useState('');
  const [brand, setBrand] = useState('');
  const [brandError, setBrandError] = useState('');
  const [model, setModel] = useState('');
  const [modelError, setModelError] = useState('');
  const [type, setType] = useState('');
  const [typeError, setTypeError] = useState('');
  const [assetImage, setAssetImage] = useState('');
  const [tenant, setTenant] = useState('');
  const [tenantError, setTenantError] = useState('');
  const [serviceTypeValue, setServiceTypeValue] = useState([]);
  const [serviceTypeValueError, setServiceTypeValueError] = useState('');
  const [selectedServiceType, setSelectedServiceType] = useState([]);
  const [systemCategory, setSystemCategory] = useState('');
  const [systemCategoryError, setSystemCategoryError] = useState('');
  const [classCategory, setClassCategory] = useState('');
  const [classCategoryError, setClassCategoryError] = useState('');
  const [typeCategory, setTypeCategory] = useState('');
  const [typeCategoryError, setTypeCategoryError] = useState('');
  const [subTypeCategory, setSubTypeCategory] = useState('');
  const [subTypeCategoryError, setSubTypeCategoryError] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [installationDate, setInstallationDate] = useState(null);
  const [installationDateError, setInstallationDateError] = useState('');
  const [lifeExpectancy, setLifeExpectancy] = useState('');
  const [lifeExpectancyError, setLifeExpectancyError] = useState('');
  const [warrantyIsTaken, setWarrantyIsTaken] = useState('');
  const [warrantyError, setWarrantyError] = useState('');
  const [warrantTakenOn, setWarrantTakenOn] = useState(null);
  const [warrantTakenOnError, setWarrantTakenOnError] = useState('');
  const [warrantyExpirationDate, setWarrantyExpirationDate] = useState(null);
  const [warrantyExpirationDateError, setWarrantyExpirationDateError] = useState('');
  const [warrantyReferenceNumber, setWarrantyReferenceNumber] = useState('');
  const [warrantyReferenceNumberError, setWarrantyReferenceNumberError] = useState('');
  const [warrantyDetails, setWarrantyDetails] = useState('');
  const [warrantyDetailsError, setWarrantyDetailsError] = useState('');
  const [strategicAsset, setStrategicAsset] = useState('true');
  const [strategicAssetError, setStrategicAssetError] = useState('');
  const [clientCriticalAsset, setClientCriticalAsset] = useState('true');
  const [clientCriticalAssetError, setClientCriticalAssetError] = useState('');
  const [replacementCost, setReplacementCost] = useState('');
  const [replacementCostError, setReplacementCostError] = useState('');
  const [checkAddPart, setCheckAddPart] = useState(false);
  const [addPartData, setAddPartData] = useState([]);
  const [serialNumberError, setSerialNumberError] = useState('');
  const [sameSerialNumber, setSameSerialNumber] = useState(false)
  const [partNameError, setPartNameError] = useState('');
  const [expirationDateError, setExpirationDateError] = useState('');
  const [listFinal, setListFinal] = useState([]);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const locationDetail = useSelector(({ LOCATION }) => LOCATION.currentUser);
  const dispatch = useDispatch();

  const handleServiceTypesSelectForItem = (selectedList) => {
    setServiceTypeValue(selectedList);
    setServiceTypeValueError("");
    let serviceTypeId = selectedList.map((item) => item?._id)
    const usersData = {
      service_type_ids: serviceTypeId
    }
    if (serviceTypeId?.length)
      dispatch(getAssetPartsList(usersData))
  }

  useEffect(() => {
    if (currentUser) {
      const usersData = {
        service_type_ids: currentUser?.service_types
      }
      dispatch(getAssetPartsList(usersData))
      dispatch(getCategoriesList())
      dispatch(getClassCategoriesList(currentUser?.system_category_id?._id, -1))
      dispatch(getTypeCategoriesList(currentUser?.class_category_id?._id, -1))
      dispatch(getSubTypeCategoriesList(currentUser?.type_category_id?._id, -1))
      setAssetId(currentUser?.asset_id)
      setName(currentUser?.name);
      setFloor(currentUser?.floor);
      setDepartment(currentUser?.department);
      setBrand(currentUser?.brand);
      setAssetImage(currentUser?.image);
      setModel(currentUser?.model);
      setType(currentUser?.type);
      setTenant(currentUser?.tenant_id?._id);
      setSystemCategory(currentUser?.system_category_id?._id);
      setClassCategory(currentUser?.class_category_id?._id);
      setTypeCategory(currentUser?.type_category_id?._id);
      setSubTypeCategory(currentUser?.subtype_category_id?._id);
      setDescription(currentUser?.description);
      setInstallationDate(moment(currentUser?.installation_date * 1000).format('YYYY-MM-DD'));
      setLifeExpectancy(currentUser?.life_expectancy_months);
      // currentUser?.remaining_life_expectancy<0 ? '0' : 
      setWarrantyIsTaken(currentUser?.warranty?.is_taken?.toString());
      if (currentUser?.warranty?.is_taken) {
        setWarrantTakenOn(moment(currentUser?.warranty?.taken_on * 1000).format('YYYY-MM-DD'));
        setWarrantyExpirationDate(moment(currentUser?.warranty?.expiration_date * 1000).format('YYYY-MM-DD'));
        setWarrantyReferenceNumber(currentUser?.warranty?.reference_number);
        setWarrantyDetails(currentUser?.warranty?.details);
      }
      setStrategicAsset(currentUser?.strategic_asset?.toString());
      setClientCriticalAsset(currentUser?.client_critical_asset?.toString());
      setReplacementCost(currentUser?.replacement_cost);
      setCheckAddPart(currentUser?.items?.length ? true : false)

      const filterSelectedServiceTypeArray = locationDetail?.map(item => {
        let data = false;
        data = currentUser?.service_types?.find(service => (item._id === service ? true : false));
        return data ? { name: item.name, _id: item._id, select: true } : { name: item.name, _id: item._id, select: false };
      });
      setSelectedServiceType(filterSelectedServiceTypeArray.filter(item => item?.select));
      setServiceTypeValue(filterSelectedServiceTypeArray.filter(item => item?.select))
    }
  }, [currentUser]);

  let addPartDataWithCorrectDateFormat

  const onSubmitClick = () => {

    if (!name || name.match(emptySpace)) setNameError(requiredMessage);
    else if (!name.match(alphaNumaricWithSpace)) setNameError(alphaNumaricMessage);

    if (!brand || brand.match(emptySpace)) setBrandError(requiredMessage);
    else if (!brand.match(alphaNumaricWithSpace)) setBrandError(alphaNumaricMessage);

    if (!model || model.match(emptySpace)) setModelError(requiredMessage);
    else if (!model.match(alphaNumaricWithSpace)) setModelError(alphaNumaricMessage);

    if (!type) setTypeError(requiredMessage);

    if (!description || description.match(emptySpace)) setDescriptionError(requiredMessage);

    if (!floor || floor.match(emptySpace)) setFloorError(requiredMessage);
    else if (!floor.match(alphaNumaricWithSpace)) setFloorError(alphaNumaricMessage);

    if (!department || department.match(emptySpace)) setDepartmentError(requiredMessage);
    else if (!department.match(alphaNumaricWithSpace)) setDepartmentError(alphaNumaricMessage);

    if (!tenant) setTenantError(requiredMessage);

    if (!systemCategory) setSystemCategoryError(requiredMessage);

    if (!classCategory) setClassCategoryError(requiredMessage);

    if (!typeCategory) setTypeCategoryError(requiredMessage);

    if (!subTypeCategory) setSubTypeCategoryError(requiredMessage);

    if (!installationDate) setInstallationDateError(requiredMessage)
    // if (moment(installationDate).format('x') < moment(moment().format('YYYY-MM-DD')).format('x')) {
    //   setInstallationDateError("Please select valid date, you can't select past date .");
    // }

    if (!warrantyIsTaken) setWarrantyError(requiredMessage)

    if (warrantyIsTaken === 'true') {
      if (!warrantTakenOn) setWarrantTakenOnError(requiredMessage)
      if (moment(warrantTakenOn).format('x') < moment(installationDate).format('X')) {
        setWarrantTakenOnError("Please select valid date, you can't select past date .");
      }
      // moment(warrantTakenOn).format('x') < moment(moment().format('YYYY-MM-DD')).format('x') || 
      if (!warrantyExpirationDate) setWarrantyExpirationDateError(requiredMessage)
      if (moment(warrantyExpirationDate).format('x') < moment(warrantTakenOn).format('X')) {
        setWarrantyExpirationDateError("Please select valid date, you can't select past date .");
      }
      // if(moment(warrantTakenOn).format('x') < moment(installationDate).format('X')) setWarrantyExpirationDateError("Please select valid date, you can't select past date .");
      // moment(warrantyExpirationDate).format('x') < moment(moment().format('YYYY-MM-DD')).format('x') || 
      if (!warrantyReferenceNumber || warrantyReferenceNumber.match(emptySpace)) setWarrantyReferenceNumberError(requiredMessage);
      else if (!warrantyReferenceNumber.match(alphaNumaricWithSpace)) setWarrantyReferenceNumberError(alphaNumaricMessage);

      if (!warrantyDetails || warrantyDetails.match(emptySpace)) setWarrantyDetailsError(requiredMessage);
      else if (!warrantyDetails.match(alphaNumaricWithSpace)) setWarrantyDetailsError(alphaNumaricMessage);
    }
    
    if (!lifeExpectancy.toString().match(numberOnly2) || Number(lifeExpectancy) < 1) setLifeExpectancyError("Enter positive number only.");
    
    // if (Number(lifeExpectancy) < 1) setLifeExpectancyError("Enter positive number only.");
    
    if (!lifeExpectancy || lifeExpectancy.toString().match(emptySpace)) setLifeExpectancyError(requiredMessage);

    if (!strategicAsset) setStrategicAssetError(requiredMessage);

    if (!clientCriticalAsset) setClientCriticalAssetError(requiredMessage);

    if (!replacementCost.match(numberOnly2) || Number(replacementCost) < 0) setReplacementCostError("Enter positive number only.")

    if (Number(replacementCost < 1)) setReplacementCostError("Replacement Cost should not be 0.");
    
    if (!replacementCost || replacementCost.match(emptySpace)) setReplacementCostError(requiredMessage);

    if (!serviceTypeValue?.length) setServiceTypeValueError(requiredMessage);

    listFinal.forEach((data) => {
      if (!data.item_id) setPartNameError(requiredMessage)
      // if (data?.item_id) {
      //   if (!data?.serial_number) setSerialNumberError(requiredMessage)
      //   if (data?.serial_number.match(emptySpace)) setSerialNumberError(requiredMessage)
      // }
      // if (data?.serial_number) {
        if (moment(data?.installation_date).format('DD/MM/YYYY') === moment(data?.expiration_date).format('DD/MM/YYYY')) setExpirationDateError("Expiration Date should not equal to Installation Date")
        if (moment(data?.installation_date).format('DD/MM/YYYY') > moment(data?.expiration_date).format('DD/MM/YYYY')) setExpirationDateError("Expiration Date should be greater to Installation Date")
      // }
    })

    if (!addPartData?.length) {
      setPartNameError(requiredMessage)
    }

    if (addPartData?.length) {
      addPartDataWithCorrectDateFormat = addPartData?.map((finalData) => {
        // finalData?.serial_number && !finalData?.serial_number.match(emptySpace) && 
        if (!sameSerialNumber &&  finalData?.expiration_date ? moment(finalData?.expiration_date) > moment(finalData?.installation_date) : moment(finalData?.installation_date)) {
          return ({
            item_id: finalData?.item_id,
            serial_number: finalData?.serial_number,
            installation_date: moment(finalData?.installation_date, 'YYYY-MM-DD').format('X') == "Invalid date" ? finalData?.installation_date : Number(moment(finalData?.installation_date, 'YYYY-MM-DD').format('X')),
            expiration_date: moment(finalData?.expiration_date, 'YYYY-MM-DD').format('X') == "Invalid date" ? finalData?.expiration_date : Number(moment(finalData?.expiration_date, 'YYYY-MM-DD').format('X'))
          }
          )
        }
      }).filter(x => x !== undefined)
    }

    const storeData = [];
    listFinal.forEach((finalData) => {
      if (!finalData?.item_id) storeData.push(1);
      // if (!finalData?.serial_number) storeData.push(1);
      // if (finalData?.serial_number.match(emptySpace)) storeData.push(1);
      if (finalData?.expiration_date && (moment(finalData?.expiration_date).format('DD/MM/YYYY') < moment(finalData?.installation_date).format('DD/MM/YYYY'))) storeData.push(1);
    })

if (warrantyIsTaken == 'false')
{
  if(!checkAddPart)
  {
    if(name && name.match(alphaNumaricWithSpace) &&
    brand && brand.match(alphaNumaricWithSpace) &&
    model && model.match(alphaNumaricWithSpace) &&
    type &&
    description && !description.match(emptySpace) &&
    floor && floor.match(alphaNumaricWithSpace) &&
    department && department.match(alphaNumaricWithSpace) &&
    tenant &&
    systemCategory &&
    classCategory &&
    typeCategory &&
    subTypeCategory &&
    installationDate &&
    warrantyIsTaken &&
    lifeExpectancy && Number(lifeExpectancy) > 0 &&
    strategicAsset &&
    clientCriticalAsset &&
    replacementCost && Number(replacementCost) > 0 &&
    serviceTypeValue?.length) 
    {
      onUserSave();
    }
  }
  
  if(checkAddPart)
  {
    if(name && name.match(alphaNumaricWithSpace) &&
    brand && brand.match(alphaNumaricWithSpace) &&
    model && model.match(alphaNumaricWithSpace) &&
    type &&
    description && !description.match(emptySpace) &&
    floor && floor.match(alphaNumaricWithSpace) &&
    department && department.match(alphaNumaricWithSpace) &&
    tenant &&
    systemCategory &&
    classCategory &&
    typeCategory &&
    subTypeCategory &&
    installationDate &&
    warrantyIsTaken &&
    lifeExpectancy && Number(lifeExpectancy) > 0 &&
    strategicAsset &&
    clientCriticalAsset &&
    replacementCost && replacementCost > 0 &&
    addPartDataWithCorrectDateFormat?.length && serviceTypeValue?.length &&
    !storeData?.length) 
    {
      onUserSave();
    }
  } 
}
      
else if (warrantyIsTaken == 'true')
{
  if(!checkAddPart)
  {
    if(name && name.match(alphaNumaricWithSpace) &&
    brand && brand.match(alphaNumaricWithSpace) &&
    model && model.match(alphaNumaricWithSpace) &&
    type &&
    description && !description.match(emptySpace) &&
    floor && floor.match(alphaNumaricWithSpace) &&
    department && department.match(alphaNumaricWithSpace) &&
    tenant &&
    systemCategory &&
    classCategory &&
    typeCategory &&
    subTypeCategory &&
    installationDate &&
    warrantyIsTaken &&
    lifeExpectancy && Number(lifeExpectancy) > 0 &&
    strategicAsset &&
    clientCriticalAsset &&
    replacementCost && replacementCost > 0 &&
    serviceTypeValue?.length &&
    warrantTakenOn &&
    moment(warrantTakenOn).format('x') >= moment(installationDate).format('X') &&
    warrantyExpirationDate &&
    moment(warrantyExpirationDate).format('x') >= moment(warrantTakenOn).format('x') &&
    warrantyReferenceNumber && !warrantyReferenceNumber.match(emptySpace) && warrantyReferenceNumber.match(alphaNumaricWithSpace) &&
    warrantyDetails && !warrantyDetails.match(emptySpace) && warrantyDetails.match(alphaNumaricWithSpace)) 
    {
      onUserSave();
    }
  }
  // moment(warrantTakenOn).format('x') >= moment(moment().format('YYYY-MM-DD')).format('x') && 
  // moment(warrantyExpirationDate).format('x') >= moment(moment().format('YYYY-MM-DD')).format('x') && 
  if(checkAddPart)
  {
    if(name && name.match(alphaNumaricWithSpace) &&
    brand && brand.match(alphaNumaricWithSpace) &&
    model && model.match(alphaNumaricWithSpace) &&
    type &&
    description && !description.match(emptySpace) &&
    floor && floor.match(alphaNumaricWithSpace) &&
    department && department.match(alphaNumaricWithSpace) &&
    tenant &&
    systemCategory &&
    classCategory &&
    typeCategory &&
    subTypeCategory &&
    installationDate &&
    warrantyIsTaken &&
    lifeExpectancy && Number(lifeExpectancy) > 0 &&
    strategicAsset &&
    clientCriticalAsset &&
    replacementCost && replacementCost > 0 &&
    addPartDataWithCorrectDateFormat?.length && serviceTypeValue?.length &&
    warrantTakenOn &&
    moment(warrantTakenOn).format('x') >= moment(installationDate).format('X') &&
    warrantyExpirationDate &&
    moment(warrantyExpirationDate).format('x') >= moment(warrantTakenOn).format('x') &&
    warrantyReferenceNumber && !warrantyReferenceNumber.match(emptySpace) && warrantyReferenceNumber.match(alphaNumaricWithSpace) &&
    warrantyDetails && !warrantyDetails.match(emptySpace) && warrantyDetails.match(alphaNumaricWithSpace) &&
    !storeData?.length) 
    {
      onUserSave();
    }
  } 
}
// moment(warrantTakenOn).format('x') >= moment(moment().format('YYYY-MM-DD')).format('x') && 
// moment(warrantyExpirationDate).format('x') >= moment(moment().format('YYYY-MM-DD')).format('x') && 
    // if (warrantyIsTaken === 'false' &&
    //   name && name.match(alphaNumaricWithSpace) &&
    //   brand && brand.match(alphaNumaricWithSpace) &&
    //   model && model.match(alphaNumaricWithSpace) &&
    //   type &&
    //   description && description.match(alphaNumaricWithSpace) &&
    //   floor && floor.match(alphaNumaricWithSpace) &&
    //   department && department.match(alphaNumaricWithSpace) &&
    //   tenant &&
    //   systemCategory &&
    //   classCategory &&
    //   typeCategory &&
    //   subTypeCategory &&
    //   moment(installationDate).format('X') >= moment().format('X') &&
    //   warrantyIsTaken &&
    //   lifeExpectancy && lifeExpectancy.toString().match(numberOnly2) && !(lifeExpectancy < 1) &&
    //   strategicAsset &&
    //   clientCriticalAsset &&
    //   // lifeExpectancy && lifeExpectancy > 0 &&
    //   replacementCost && replacementCost > 0 &&
    //   addPartDataWithCorrectDateFormat?.length || true && serviceTypeValue?.length &&
    //   !storeData?.length &&
    //   !checkAddPart) {
    //   alert("Warranty: No, AddPart: No")
    //   onUserSave();
    // }

    // if (warrantyIsTaken === 'false' &&
    //   name && !name.match(emptySpace) && name.match(alphaNumaricWithSpace) &&
    //   brand && !brand.match(emptySpace) && brand.match(alphaNumaricWithSpace) &&
    //   model && !model.match(emptySpace) && model.match(alphaNumaricWithSpace) &&
    //   type &&
    //   description && !description.match(emptySpace) && description.match(alphaNumaricWithSpace) &&
    //   floor && !floor.match(emptySpace) && floor.match(alphaNumaricWithSpace) &&
    //   department && !department.match(emptySpace) && department.match(alphaNumaricWithSpace) &&
    //   tenant &&
    //   systemCategory &&
    //   classCategory &&
    //   typeCategory &&
    //   subTypeCategory &&
    //   moment(installationDate).format('X') >= moment().format('X') &&
    //   warrantyIsTaken &&
    //   lifeExpectancy && !lifeExpectancy.toString().match(emptySpace) && lifeExpectancy.toString().match(numberOnly2) && !(lifeExpectancy < 1) &&
    //   strategicAsset &&
    //   clientCriticalAsset &&
    //   replacementCost && !replacementCost.match(emptySpace) && !(replacementCost < 1) && replacementCost.match(numberOnly2) &&
    //   addPartDataWithCorrectDateFormat?.length || true && serviceTypeValue?.length &&
    //   !storeData?.length &&
    //   checkAddPart) {
    //   alert("Warranty: No, AddPart: Yes")
    //   onUserSave();
    // }

    // if (warrantyIsTaken === 'true' &&
    //   name && !name.match(emptySpace) && name.match(alphaNumaricWithSpace) &&
    //   brand && !brand.match(emptySpace) && brand.match(alphaNumaricWithSpace) &&
    //   model && !model.match(emptySpace) && model.match(alphaNumaricWithSpace) &&
    //   type &&
    //   description && !description.match(emptySpace) && description.match(alphaNumaricWithSpace) &&
    //   floor && !floor.match(emptySpace) && floor.match(alphaNumaricWithSpace) &&
    //   department && !department.match(emptySpace) && department.match(alphaNumaricWithSpace) &&
    //   tenant &&
    //   systemCategory &&
    //   classCategory &&
    //   typeCategory &&
    //   subTypeCategory &&
    //   moment(installationDate).format('X') >= moment().format('X') &&
    //   warrantyIsTaken &&
    //   lifeExpectancy && !lifeExpectancy.toString().match(emptySpace) && lifeExpectancy.toString().match(numberOnly2) && !(lifeExpectancy < 1) &&
    //   strategicAsset &&
    //   clientCriticalAsset &&
    //   replacementCost && !replacementCost.match(emptySpace) && !(replacementCost < 1) && replacementCost.match(numberOnly2) &&
    //   addPartDataWithCorrectDateFormat?.length || true && serviceTypeValue?.length &&
    //   warrantTakenOn &&
    //   moment(warrantTakenOn).format('x') >= moment(moment().format('YYYY-MM-DD')).format('x') &&
    //   warrantyExpirationDate &&
    //   moment(warrantyExpirationDate).format('x') >= moment(moment().format('YYYY-MM-DD')).format('x') &&
    //   warrantyReferenceNumber && !warrantyReferenceNumber.match(emptySpace) && warrantyReferenceNumber.match(alphaNumaricWithSpace) &&
    //   warrantyDetails && !warrantyDetails.match(emptySpace) && warrantyDetails.match(alphaNumaricWithSpace) &&
    //   !storeData?.length &&
    //   !checkAddPart) {
    //   alert("Warranty: Yes, AddPart: No")
    //   onUserSave();
    // }

    // if (warrantyIsTaken === 'true' &&
    //   name && !name.match(emptySpace) && name.match(alphaNumaricWithSpace) &&
    //   brand && !brand.match(emptySpace) && brand.match(alphaNumaricWithSpace) &&
    //   model && !model.match(emptySpace) && model.match(alphaNumaricWithSpace) &&
    //   type &&
    //   description && !description.match(emptySpace) && description.match(alphaNumaricWithSpace) &&
    //   floor && !floor.match(emptySpace) && floor.match(alphaNumaricWithSpace) &&
    //   department && !department.match(emptySpace) && department.match(alphaNumaricWithSpace) &&
    //   tenant &&
    //   systemCategory &&
    //   classCategory &&
    //   typeCategory &&
    //   subTypeCategory &&
    //   moment(installationDate).format('X') >= moment().format('X') &&
    //   warrantyIsTaken &&
    //   lifeExpectancy && !lifeExpectancy.toString().match(emptySpace) && lifeExpectancy.toString().match(numberOnly2) && !(lifeExpectancy < 1) &&
    //   strategicAsset &&
    //   clientCriticalAsset &&
    //   replacementCost && !replacementCost.match(emptySpace) && !(replacementCost < 1) && replacementCost.match(numberOnly2) &&
    //   addPartDataWithCorrectDateFormat?.length || true && serviceTypeValue?.length &&
    //   warrantTakenOn &&
    //   moment(warrantTakenOn).format('x') >= moment(moment().format('YYYY-MM-DD')).format('x') &&
    //   warrantyExpirationDate &&
    //   moment(warrantyExpirationDate).format('x') >= moment(moment().format('YYYY-MM-DD')).format('x') &&
    //   warrantyReferenceNumber && !warrantyReferenceNumber.match(emptySpace) && warrantyReferenceNumber.match(alphaNumaricWithSpace) &&
    //   warrantyDetails && !warrantyDetails.match(emptySpace) && warrantyDetails.match(alphaNumaricWithSpace) &&
    //   !storeData?.length &&
    //   checkAddPart) {
    //   alert("Warranty: Yes, AddPart: Yes")
    //   onUserSave();
    // }


    // if(warrantyIsTaken == 'false' &&
    //     name && !name.match(emptySpace) && name.match(alphaNumaricWithSpace) &&
    //     brand && !brand.match(emptySpace) && brand.match(alphaNumaricWithSpace) &&
    //     model && !model.match(emptySpace) && model.match(alphaNumaricWithSpace) &&
    //     type &&
    //     description && !description.match(emptySpace) && description.match(alphaNumaricWithSpace) &&
    //     floor && !floor.match(emptySpace) && floor.match(alphaNumaricWithSpace) &&
    //     department && !department.match(emptySpace) && department.match(alphaNumaricWithSpace) &&
    //     tenant &&
    //     systemCategory && 
    //     classCategory && 
    //     typeCategory && 
    //     subTypeCategory &&
    //     moment(installationDate).format('X') >= moment().format('X') &&
    //     warrantyIsTaken &&
    //     lifeExpectancy && !lifeExpectancy.toString().match(emptySpace) && lifeExpectancy.toString().match(numberOnly2) && !(lifeExpectancy<1) &&
    //     strategicAsset && 
    //     clientCriticalAsset && 
    //     replacementCost && !replacementCost.match(emptySpace) && !(replacementCost<1) && replacementCost.match(numberOnly2) &&
    //     addPartDataWithCorrectDateFormat?.length || true && serviceTypeValue?.length)
    //     {
    //       const storeData = [];
    //       listFinal.forEach((finalData)=>{
    //         if(!finalData?.item_id) storeData.push(1);
    //         if(!finalData?.serial_number) storeData.push(1);
    //         if(finalData?.serial_number.match(emptySpace)) storeData.push(1);
    //       })

    //       if(!currentUser)
    //       { 
    //         if(checkAddPart && addPartDataWithCorrectDateFormat?.length && !storeData?.length)
    //         {
    //           onUserSave();
    //         }
    //         else if(!checkAddPart)
    //         {
    //           onUserSave();
    //         }
    //       }

    //       else if(currentUser)
    //       {
    //         if(checkAddPart && addPartDataWithCorrectDateFormat?.length && !storeData?.length)
    //         {
    //           onUserSave();
    //         }
    //         else if(!checkAddPart)
    //         {
    //           onUserSave();
    //         }
    //       }
    //   }

    // else if(warrantyIsTaken == 'true' &&
    // name && !name.match(emptySpace) && name.match(alphaNumaricWithSpace) &&
    // brand && !brand.match(emptySpace) && brand.match(alphaNumaricWithSpace) &&
    // model && !model.match(emptySpace) && model.match(alphaNumaricWithSpace) &&
    // type &&
    // description && !description.match(emptySpace) && description.match(alphaNumaricWithSpace) &&
    // floor && !floor.match(emptySpace) && floor.match(alphaNumaricWithSpace) &&
    // department && !department.match(emptySpace) && department.match(alphaNumaricWithSpace) &&
    // tenant &&
    // systemCategory && 
    // classCategory && 
    // typeCategory && 
    // subTypeCategory &&
    // moment(installationDate).format('X') >= moment().format('X') &&
    // warrantyIsTaken &&
    // lifeExpectancy && !lifeExpectancy.toString().match(emptySpace) && lifeExpectancy.toString().match(numberOnly2) && !(lifeExpectancy<1) &&
    // strategicAsset && 
    // clientCriticalAsset && 
    // replacementCost && !replacementCost.match(emptySpace) && !(replacementCost<1) && replacementCost.match(numberOnly2) &&
    // addPartDataWithCorrectDateFormat?.length || true && serviceTypeValue?.length &&
    // warrantTakenOn &&
    // moment(warrantTakenOn).format('x') >= moment(moment().format('YYYY-MM-DD')).format('x') &&
    // warrantyExpirationDate &&
    // moment(warrantyExpirationDate).format('x') >= moment(moment().format('YYYY-MM-DD')).format('x') &&
    // warrantyReferenceNumber && !warrantyReferenceNumber.match(emptySpace) && warrantyReferenceNumber.match(alphaNumaricWithSpace) &&
    // warrantyDetails && !warrantyDetails.match(emptySpace) && warrantyDetails.match(alphaNumaricWithSpace))
    //     {
    //       const storeData = [];
    //       listFinal.forEach((finalData)=>{
    //         if(!finalData?.item_id) storeData.push(1);
    //         if(!finalData?.serial_number) storeData.push(1);
    //         if(finalData?.serial_number.match(emptySpace)) storeData.push(1);
    //       })

    //       if(!currentUser)
    //       {
    //         if(checkAddPart && addPartDataWithCorrectDateFormat?.length && !storeData?.length)
    //         {
    //           onUserSave();
    //         }
    //         else if(!checkAddPart)
    //         {
    //           onUserSave();
    //         }
    //       }

    //       else if(currentUser)
    //       {
    //         if(checkAddPart && addPartDataWithCorrectDateFormat?.length && !storeData?.length)
    //         {
    //           onUserSave();
    //         }
    //         else if(!checkAddPart)
    //         {
    //           onUserSave();
    //         }
    //       }
    //     }
  };

  const onUserSave = () => {
    const fname = capitalizeFLetter(name);
    const departmentname = capitalizeFLetter(department);
    const formData = new FormData();
    const serviceTypes = serviceTypeValue?.map(item => item?._id)

    const userDetail2 =
    {
      is_taken: warrantyIsTaken,
      taken_on: Number(moment(warrantTakenOn).format('X')),
      expiration_date: Number(moment(warrantyExpirationDate).format('X')),
      reference_number: warrantyReferenceNumber,
      details: warrantyDetails
    }

    const userDetail1 =
    {
      is_taken: warrantyIsTaken
    }

    formData.append('asset_id', assetId);
    formData.append('name', fname);
    formData.append('floor', floor);
    formData.append('department', departmentname);
    formData.append('brand', brand);
    formData.append('model', model);
    formData.append('type', type);
    formData.append('assetImage', assetImage);
    formData.append('tenantId', tenant);
    formData.append('serviceTypes', serviceTypes);
    formData.append('system_category_id', systemCategory);
    formData.append('class_category_id', classCategory);
    formData.append('type_category_id', typeCategory);
    formData.append('subtype_category_id', subTypeCategory);
    formData.append('description', description);
    formData.append('installation_date', Number(moment(installationDate).format('X')));
    formData.append('life_expectancy', lifeExpectancy);
    if (warrantyIsTaken) {
      formData.append('warranty', JSON.stringify(userDetail2));
    }
    else {
      formData.append('warranty', JSON.stringify(userDetail1));
    }
    formData.append('strategic_asset', strategicAsset);
    formData.append('client_critical_asset', clientCriticalAsset);
    formData.append('replacement_cost', replacementCost);
    if (checkAddPart) {
      formData.append('items', JSON.stringify(addPartDataWithCorrectDateFormat));
    }
    else {
      formData.append('items', JSON.stringify([]));
    }

    if (currentUser) {
      dispatch(
        updateASSETLOCATION(siteId, currentUser?._id, formData, () => {
          onCloseDialog();
          dispatch(getASSETLOCATIONList(id, orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
        }),
      );
    } else {
      dispatch(
        addNewASSETLOCATION(siteId, formData, () => {
          onCloseDialog();
          dispatch(getASSETLOCATIONList(id, orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
        }),
      );
    }
  };

  async function handleImageUpload(event) {
    const imageFile = event.target.files[0];
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      setAssetImage(compressedFile);
    } catch (error) {
    }
  }

  const handleConfirmUpdate = () => {
    onUserSave();
  }

  const handleInstallationDateChange = date => {
    setInstallationDate(date);
    setInstallationDateError('');
  };

  const handleWarrantTakenOnDateChange = date => {
    setWarrantTakenOn(date);
    setWarrantTakenOnError('');
  };

  const handleWarrantyExpirationDateChange = date => {
    setWarrantyExpirationDate(date);
    setWarrantyExpirationDateError('');
  };

  const handleCancelUpdate = () => {
    setOpenConfirmDeleteDialog(false)
  }

  const handleChangeCheck = (checkValue) => {
    setCheckAddPart(checkValue.target.checked);
  }

  const handleClassCategories = (value) => {
    dispatch(getClassCategoriesList(value, -1))
  }

  const handleTypeCategories = (value) => {
    dispatch(getTypeCategoriesList(value, -1))
  }

  const handleSubTypeCategories = (value) => {
    dispatch(getSubTypeCategoriesList(value, -1))
  }
console.log('selectedServiceType',selectedServiceType)
  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>{currentUser ? 'Edit Asset Details' : 'Add New Asset'}</DialogTitle>
      <DialogContent dividers>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
          <GridContainer>

            <Grid item xs={3} sm={3}>
              <AppTextInput
                className={classes.root}
                fullWidth
                variant="outlined"
                label="Asset Name"
                value={name}
                onChange={e => {
                  setName(e.target.value);
                  setNameError('');
                }}
                helperText={nameError}
              />
            </Grid>

            <Grid item xs={3} sm={3}>
              <AppTextInput
                className={classes.root}
                fullWidth
                variant="outlined"
                label="Brand"
                value={brand}
                onChange={e => {
                  setBrand(e.target.value);
                  setBrandError('');
                }}
                helperText={brandError}
              />
            </Grid>

            <Grid item xs={3} sm={3}>
              <AppTextInput
                className={classes.root}
                fullWidth
                variant="outlined"
                label="Model"
                value={model}
                onChange={e => {
                  setModel(e.target.value);
                  setModelError('');
                }}
                helperText={modelError}
              />
            </Grid>

            <Grid item xs={3} sm={3} className={currentUser ? classes.appSelectBox : classes.root}>
              <AppSelectBox
                className={currentUser ? classes.appSelectBox : classes.root}
                fullWidth
                data={assetType}
                disabled={currentUser ? true : false}
                label="Asset Type"
                valueKey="_id"
                variant="outlined"
                labelKey="name"
                value={type}
                onChange={e => {
                  setType(e.target.value);
                  setTypeError('');
                }}
                helperText={typeError}
              />
            </Grid>

            <Grid item xs={3} sm={3}>
              <AppTextInput
                className={classes.root}
                fullWidth
                variant="outlined"
                label="Description"
                value={description}
                onChange={e => {
                  setDescription(e.target.value.slice(0, 250));
                  setDescriptionError('');
                }}
                // onKeyPress={event => {
                //   if ([' '].includes(event.key)) {
                //     event.preventDefault();
                //   }
                // }}
                helperText={descriptionError}
              />
            </Grid>

            <Grid item xs={3} sm={3}>
              <AppTextInput
                className={classes.root}
                fullWidth
                variant="outlined"
                label="Floor"
                value={floor}
                onChange={e => {
                  setFloor(e.target.value);
                  setFloorError('');
                }}
                helperText={floorError}
              />
            </Grid>

            <Grid item xs={3} sm={3}>
              <AppTextInput
                className={classes.root}
                fullWidth
                variant="outlined"
                label="Department"
                value={department}
                onChange={e => {
                  setDepartment(e.target.value);
                  setDepartmentError('');
                }}
                helperText={departmentError}
              />
            </Grid>

            <Grid item xs={3} sm={3} className={currentUser ? "" : classes.root}>
              <UserSelectBox
                fullWidth
                data={tenantList?.data}
                label="Tenant"
                valueKey="_id"
                variant="outlined"
                labelKey="first_name"
                value={tenant}
                onChange={e => {
                  setTenant(e.target.value);
                  setTenantError('');
                }}
                helperText={tenantError}
              />
            </Grid>

            <Grid item xs={3} sm={3} className={classes.root}>
              <AppSelectBox
                fullWidth
                data={systemCategories?.data}
                label="System Category"
                valueKey="_id"
                variant="outlined"
                labelKey="name"
                value={systemCategory}
                onChange={e => {
                  setSystemCategory(e.target.value);
                  setSystemCategoryError('');
                  handleClassCategories(e.target.value)
                }}
                helperText={systemCategoryError}
              />
            </Grid>

            <Grid item xs={3} sm={3} className={classes.root}>
              <AppSelectBox
                fullWidth
                disabled={systemCategory ? false : true}
                data={classCategories?.data}
                label="Class Category"
                valueKey="_id"
                variant="outlined"
                labelKey="name"
                value={classCategory}
                onChange={e => {
                  setClassCategory(e.target.value);
                  setClassCategoryError('');
                  handleTypeCategories(e.target.value)
                }}
                helperText={classCategoryError}
              />
            </Grid>

            <Grid item xs={3} sm={3} className={classes.root}>
              <AppSelectBox
                fullWidth
                disabled={classCategory ? false : true}
                data={typeCategories?.data}
                label="Type Category"
                valueKey="_id"
                variant="outlined"
                labelKey="name"
                value={typeCategory}
                onChange={e => {
                  setTypeCategory(e.target.value);
                  setTypeCategoryError('');
                  handleSubTypeCategories(e.target.value)
                }}
                helperText={typeCategoryError}
              />
            </Grid>

            <Grid item xs={3} sm={3} className={classes.root}>
              <AppSelectBox
                fullWidth
                disabled={typeCategory ? false : true}
                data={subTypeCategories?.data}
                label="Sub-Type Category"
                valueKey="_id"
                variant="outlined"
                labelKey="name"
                value={subTypeCategory}
                onChange={e => {
                  setSubTypeCategory(e.target.value);
                  setSubTypeCategoryError('');
                }}
                helperText={subTypeCategoryError}
              />
            </Grid>

            <Grid item xs={6} sm={6} alignItems="center">
              <Box display='flex'>
                <KeyboardDatePicker
                  id="date-picker-dialog"
                  label="Installation Date"
                  format="DD/MM/YYYY"
                  value={installationDate}
                  // minDate={currentUser ? "" : new Date()}
                  onChange={handleInstallationDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  className={classes.textField}
                  helperText={installationDateError}
                />
              </Box>
            </Grid>

            <Grid item xs={6} sm={6}>
              <AppTextInput
                className={classes.root}
                InputProps={{
                  pattern: "[1-9]",
                  inputProps: { min: 1, pattern: "[1-9]" },
                }}
                fullWidth
                variant="outlined"
                label="Life Expectancy (Months)"
                value={lifeExpectancy}
                onChange={e => {
                  setLifeExpectancy(e.target.value.slice(0, 2));
                  setLifeExpectancyError('');
                }}
                onKeyPress={event => {
                  if (['-', '+', 'e', '.', '/', '*'].includes(event.key)) {
                    event.preventDefault();
                  }
                }}
                onPaste={(e) => {
                  e.preventDefault()
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault()
                  return false;
                }}
                helperText={lifeExpectancyError}
              />
            </Grid>

              <Grid item xs={3} sm={3}>
              <div className={classes.content}>
                <Typography style={{ marginLeft: "10px" }}>Warranty :</Typography>
                <FormControl component="fieldset">
                  <RadioGroup row onChange={(e) => { setWarrantyIsTaken(e.target.value); setWarrantyError('') }} value={warrantyIsTaken}>
                    <FormControlLabel style={{ marginLeft: "5px" }} value={"true"} control={<Radio color="primary" />} label="Yes" />
                    <FormControlLabel value={"false"} control={<Radio color="primary" />} label="No" />
                  </RadioGroup>
                </FormControl>
              </div>
              <p
                className="MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-marginDense"
                style={{ color: 'red' }}>
                {warrantyError}
              </p>
            </Grid>

            <Grid item xs={4} sm={4}>
              <div className={classes.content}>
                <Typography style={{ marginLeft: "10px" }}>Strategic Asset :</Typography>
                <FormControl component="fieldset">
                  <RadioGroup row onChange={(e) => { setStrategicAsset(e.target.value); setStrategicAssetError('') }} value={strategicAsset}>
                    <FormControlLabel style={{ marginLeft: "5px" }} value={"true"} control={<Radio color="primary" />} label="Yes" />
                    <FormControlLabel value={"false"} control={<Radio color="primary" />} label="No" />
                  </RadioGroup>
                </FormControl>
              </div>
              <p
                className="MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-marginDense"
                style={{ color: 'red' }}>
                {strategicAssetError}
              </p>
            </Grid>

            <Grid item xs={5} sm={5}>
              <div className={classes.content}>
                <Typography style={{ marginLeft: "10px" }}>Client Critical Asset :</Typography>
                <FormControl component="fieldset">
                  <RadioGroup row onChange={(e) => { setClientCriticalAsset(e.target.value); setClientCriticalAssetError('') }} value={clientCriticalAsset}>
                    <FormControlLabel style={{ marginLeft: "5px" }} value={"true"} control={<Radio color="primary" />} label="Yes" />
                    <FormControlLabel value={"false"} control={<Radio color="primary" />} label="No" />
                  </RadioGroup>
                </FormControl>
              </div>
              <p
                className="MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-marginDense"
                style={{ color: 'red' }}>
                {clientCriticalAssetError}
              </p>
            </Grid>

            {warrantyIsTaken === 'true' ?
              <>
                <Grid item xs={3} sm={3}>
                  <KeyboardDatePicker
                    // disabled={warranty?.is_taken === true ? false : true}
                    id="date-picker-dialog"
                    label="Warranty Take On"
                    format="DD/MM/YYYY"
                    value={warrantTakenOn}
                    // minDate={moment(installationDate, "DD/MM/YYYY").add(1, "d")}
                    onChange={handleWarrantTakenOnDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    // style={{ marginLeft: '20px', marginRight: "20px" }}
                    className={classes.textField}
                    helperText={warrantTakenOnError}
                  />
                </Grid>

                <Grid item xs={3} sm={3}>
                  <KeyboardDatePicker
                    // disabled={warranty?.is_taken === true ? false : true}
                    disabled={warrantTakenOn ? false : true}
                    id="date-picker-dialog"
                    label="Warranty Expiration"
                    format="DD/MM/YYYY"
                    value={warrantyExpirationDate}
                    minDate={moment(warrantTakenOn, "DD/MM/YYYY").add(1, "d")}
                    onChange={handleWarrantyExpirationDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    // style={{ marginLeft: "30px", color: "black" }}
                    className={classes.textField}
                    helperText={warrantyExpirationDateError}
                  />
                </Grid>

                <Grid item xs={3} sm={3}>
                  <AppTextInput
                    className={classes.root}
                    fullWidth
                    variant="outlined"
                    label="Warranty Ref. No."
                    value={warrantyReferenceNumber}
                    onChange={e => {
                      setWarrantyReferenceNumber(e.target.value);
                      setWarrantyReferenceNumberError('');
                    }}
                    helperText={warrantyReferenceNumberError}
                  />
                </Grid>

                <Grid item xs={3} sm={3}>
                  <AppTextInput
                    className={classes.root}
                    fullWidth
                    variant="outlined"
                    label="Warranty Details"
                    value={warrantyDetails}
                    onChange={e => {
                      setWarrantyDetails(e.target.value.slice(0, 250));
                      setWarrantyDetailsError('');
                    }}
                    helperText={warrantyDetailsError}
                  />
                </Grid>
              </>
              : null}

            <Grid item xs={5} sm={5}>
              <AppTextInput
                className={classes.root}
                InputProps={{
                  pattern: "[1-9]",
                  inputProps: { min: 0, pattern: "[1-9]" },
                  startAdornment: replacementCost ? <InputAdornment position="start">$</InputAdornment> : '',
                }}
                fullWidth
                variant="outlined"
                label="Replacement Cost"
                value={replacementCost}
                onChange={e => {
                  setReplacementCost(e.target.value);
                  setReplacementCostError('');
                }}
                onKeyPress={event => {
                  if (['-', '+', 'e', '/', '*'].includes(event.key)) {
                    event.preventDefault();
                  }
                }}
                onPaste={(e) => {
                  e.preventDefault()
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault()
                  return false;
                }}
                helperText={replacementCostError}
              />
            </Grid>

            <Grid item xs={7} sm={7}>
              <Box display="flex">
                <Box><Typography className={classes.serviceTypeView}>Service Type :</Typography></Box>
                <Box ml={5}><Multiselect
                  options={locationDetail} // Options to display in the dropdown
                  selectedValues={selectedServiceType} // Preselected value to persist in dropdown
                  
                  onSelect={(selectedList) => { handleServiceTypesSelectForItem(selectedList) }} // Function will trigger on select event
                  onRemove={(selectedList, selectedItem) => { setServiceTypeValue(selectedList) }} // Function will trigger on remove event
                  displayValue="name" // Property name to display in the dropdown options
                  placeholder='Select Service Type'
                  className={classes.serviceTypeSearchView}
                />
                  <p className="MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-marginDense" style={{ color: 'red' }}>
                    {serviceTypeValueError}
                  </p></Box>
              </Box>
            </Grid>

            <Grid item xs={6} sm={6}>
              {currentUser ? (<img src={assetImage ? assetImage : process.env.PUBLIC_URL + '/placeholder1.jpeg'}
                alt="assetImage" width="200px" />) : null}
              <br />
              Asset Image :{' '}
              <Button variant="contained" component="label" className={classes.imageButtonWrapper}>
                <input type="file" hidden accept="image/png, image/gif, image/jpeg" onChange={handleImageUpload} />
                <CloudUploadIcon />
                <p className={classes.imageButton}>{assetImage?.size ? assetImage?.name : 'Choose Image'}</p>
              </Button>
            </Grid>

          </GridContainer>
        </Box>

        <Grid item xs={4} sm={4}>
          <FormControlLabel
            checked={checkAddPart}
            onChange={(event) => { handleChangeCheck(event) }}
            control={<Checkbox color="primary" />}
            label="Add Part"
            labelPlacement="end"
          />
        </Grid>
        {checkAddPart === true ? <AddPart
          setAddPartData={setAddPartData}
          serialNumberError={serialNumberError}
          setSerialNumberError={setSerialNumberError}
          expirationDateError={expirationDateError}
          setExpirationDateError={setExpirationDateError}
          partNameError={partNameError}
          setPartNameError={setPartNameError}
          setSameSerialNumber={setSameSerialNumber}
          setListFinal={setListFinal}
        /> : null}

        <Box display="flex" justifyContent="flex-end" mb={4} mt={5}>
          <Button variant="outlined" onClick={onCloseDialog}>
            Cancel
          </Button>
          <Box ml={2}>
            <Button variant="contained" style={{ minWidth: '100px' }} color="primary" onClick={onSubmitClick}>
              {currentUser ? 'UPDATE' : 'ADD'}
            </Button>
          </Box>
        </Box>

        <ConfirmDialog
          open={openConfirmDeleteDialog}
          title={`Confirm Edit`}
          content={'By changing tenant any service request or planned maintenance of this asset will be deleted, do you want to change tenant in this asset? '}
          onClose={handleCancelUpdate}
          onConfirm={handleConfirmUpdate} />

      </DialogContent>
    </Dialog>
  );
};

export default AddEditUser;

AddEditUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
