import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '../../../../../@jumbo/components/Common/formElements/AppTextInput';
import Button from '@material-ui/core/Button';
import { requiredMessage } from '../../../../../@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { alpha } from '@material-ui/core/styles';
import AppSelectBox from '../../../../../@jumbo/components/Common/formElements/AppSelectBox';
import {emptySpace} from '../../../../../@jumbo/constants/ValidationRegex'
import { addNewIssueManagement, getIssueManagement, UpdateIssue } from '../../../../../redux/actions/IssueManagement';
import { capitalizeFLetter } from '../../../../../@jumbo/utils/commonHelper';
import Multiselect from 'multiselect-react-dropdown';
import Select from 'react-select';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
    '& .MuiDialog-paper': {
      // margin: '32px',
      // position: 'relative',
      overflow: 'inherit',
      // backgroundColor: '#FFFFFF',
  },
    '& .MuiDialogContent-root': {
      // flex: '1 1 auto',
      // padding: '8px 24px',
      overflow: 'inherit',
      // webkitOverflowScrolling: 'touch',
  },
  //   '& .MuiDialog-paperWidthSm': {
  //     minHeight: '325px',
  // },
  },
  root: {
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    "& .MuiFormLabel-root": {
      color: '#909090'
    },
    "& .MuiInputLabel-shrink":{
color:theme.palette.text.primary
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink':{
      color: theme.palette.text.primary
    },
  },
  appSelectRoot: {
    "& .MuiFormLabel-root": {
      color: '#c7c3bf'
    }
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.text.primary,
    },
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.text.primary),
    },
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    "& .MuiFormLabel-root": {
      color: '#909090'
    },
    "& .MuiInputLabel-shrink":{
color:theme.palette.text.primary
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink':{
      color: theme.palette.text.primary
    },
  },
  assignInventorySiteView: {
    zIndex: 99,
    minHeight: '40px',
    minWidth: '200px',
    fontSize: '15px',
    '& .chip':{
      background:`${theme.palette.primary.main} !important`
    },
    '& .highlightOption':{
      background:`${theme.palette.primary.main} !important`
    },
    '& .multiSelectContainer li':{
        '&:hover': {
          background:`${theme.palette.primary.main} !important`,
       },   
    },
    '& li':{
      fontSize:'14px',
      '&:hover': {
        background:`${theme.palette.primary.main} !important`,
     },
    },
  '& input':{
    fontSize:'16px',
    paddingLeft:'5px',
    minHeight: '28px',
  },
  '& .optionContainer':{
    border: '1px solid #ccc',
    borderRadius: '4px',
    display: 'block',
    margin: 0,
    maxHeight: '125px',
    overflowY: 'auto',
    padding: 0,
},
  },
  errText: {
    color: 'red',
    marginLeft: '14px',
    marginRight: '14px',
    fontSize: '0.75rem',
    marginTop: '3px',
    textAlign: 'left',
    fontFamily: 'Normal',
    fontWeight: 400,
    lineHeight: 1.66,
  },
}));

const AddEditUser = ({ open, onCloseDialog, getIssueManagementParams }) => {
  const classes = useStyles();
  const { users } = useSelector(({ SERVICETYPE }) => SERVICETYPE);
  const { currentUser } = useSelector(({ ISSUEMANAGEMENT })=> ISSUEMANAGEMENT);
  const { orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions } = getIssueManagementParams;
  const [serviceTypeValueError, setServiceTypeValueError] = useState('');
  const [issue, setIssue] = useState('');
  const [nameError, setNameError] = useState('');
  const [value, setValue] = useState('1');
  const [tiffin, setTiffin] = useState('');
  const [serviceTypeValue, setServiceTypeValue] = useState('');
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const dispatch = useDispatch();
  const p_color=window.localStorage.getItem('p_c') ?? '#FC8119'
  const s_color=window.localStorage.getItem('s_c') ?? '#F0511E'
  const t_color=window.localStorage.getItem('t_c') ?? '#3B1A45'

  console.log("users",users)
  console.log("serviceTypeValue",serviceTypeValue)
  console.log("options",options)
  

  const options = users?.data?.map(item=>{
    return{ value:item?._id, label:item?.name}
  })

  const selectServiceType = (data) =>{
    console.log("data",data)
   const store =  data?.map(item=>{
      setServiceTypeValue(item) 
    })
  }

  // const selectServiceType = (data) =>{
  //     console.log("data",data)
  //    const store =  data?.map(item=>{
  //       setTiffin(item)
  //       serviceTypeValue.replace(tiffin, item) 
  //     })
  //   }

  // const removeServiceType = (data) =>{
  //   console.log("data 2",data)
  //  const store =  data?.map(item=>{
  //     setServiceTypeValue(item) 
  //   })
  // }

  useEffect(() => {
    if (currentUser) {
      setServiceTypeValue(currentUser?.service_type_id);
      setValue(currentUser?.fm_issue_status);
      setIssue(currentUser?.name);
    }
  }, [currentUser]);

  const handleChange = event => {
    setValue(event.target.value);
  };
  const onSubmitClick = () => {
    
    if (issue.match(emptySpace)) {
      setNameError(requiredMessage);
    }
     if (!serviceTypeValue[0]) {
      setServiceTypeValueError(requiredMessage);
    } 
    if(!issue.match(emptySpace) && serviceTypeValue[0]){
      onUserSave();
    }
  };

  const onUserSave = () => {
    const iname = capitalizeFLetter(issue)
    const issueManagementData = {
      name:iname,
      status: value,
      service_type_id: serviceTypeValue,
    };

    if (currentUser) {
      dispatch(
        UpdateIssue(issueManagementData,currentUser._id, () => {
          onCloseDialog();
          dispatch(getIssueManagement(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
        }),
      );
    } else {
      dispatch(
        addNewIssueManagement(issueManagementData, () => {
          onCloseDialog();
          dispatch(getIssueManagement(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
        }),
      );
    }
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>
        {currentUser ? 'Edit Issue Details' : 'Add New Issue'}
      </DialogTitle>
      <DialogContent dividers>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }} style={{maxWidth:'300px'}}>
          <GridContainer>
            <Grid item xs={12} sm={12} className={currentUser?.is_vcg_creation?classes.appSelectRoot:classes.root}>
              <AppTextInput
              // className={classes.textFieldRoot}
                fullWidth
                variant="outlined"
                label="Issue"
                value={issue}
                onChange={e => {
                  setIssue(e.target.value);
                  setNameError('');
                }}
                disabled={currentUser?.is_vcg_creation?true:false}
                helperText={nameError}
              />
            </Grid>
            {/* <Grid item xs={12} sm={12} className={currentUser?.is_vcg_creation?classes.appSelectRoot:classes.root}>
              <AppSelectBox
                //  className={classes.root}
                fullWidth
                data={users?.data}
                label="Service Type"
                valueKey="_id"
                variant="outlined"
                labelKey="name"
                value={serviceTypeValue}
                onChange={e => {setServiceTypeValue(e.target.value)
                  setServiceTypeValueError('')
                }}
                disabled={currentUser?.is_vcg_creation?true:false}
                helperText={serviceTypeValueError}
              />
            </Grid> */}
                    <Grid item xs={12} sm={12}>
                    <Select
                      // className="style"
                      className={classes.assignInventorySiteView}
                      // style={{backgroundColor: 'white'}}
                      placeholder="Service Type"
                      // menuIsOpen={true}
                      isDisabled={currentUser?.is_vcg_creation ? true : false}
                      value={users?.data?.map(item=>{
                        return{ value:item?._id, label:item?.name}
                      }).filter(obj => obj?.value === serviceTypeValue)}
                      onChange={e=>{setServiceTypeValue(e?.value ?? ''); setServiceTypeValueError('')}}
                      isClearable={isClearable}
                      isSearchable={isSearchable}
                      options={users?.data?.map(item=>{
                        return{ value:item?._id, label:item?.name}
                      })}
                      // unstyled
                      // styles={{
                      //   control: (baseStyles, state) => ({
                      //     ...baseStyles,
                      //     border: '1px solid grey',
                      //   }),
                      //   menuList: (baseStyles, state) => ({
                      //     ...baseStyles,
                      //     background: state.isFocused ? 'yellow' : 'red',
                      //   }),
                      //   ':hover': {
                      //     backgroundColor: 'black',
                      //     color: 'white',
                      //   },
                      // }}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                        ...theme.colors,
                          text: t_color,
                          // primary25: s_color,
                          primary: p_color,
                        },
                      })}
                    />
                    <p className={classes.errText} style={{ color: 'red' }}>{serviceTypeValueError}</p>
                    
                    {/* <Multiselect
                    options={users?.data} // Options to display in the dropdown
                    selectedValues={serviceTypeValue} // Preselected value to persist in dropdown
                    selectionLimit={1}
                    onSelect={(selectedList, selectedItem)=>{setServiceTypeValue(selectedList); setServiceTypeValueError("")}}
                    onRemove={(selectedList, selectedItem)=>setServiceTypeValue(selectedList)} // Function will trigger on remove event
                    displayValue="name" // Property name to display in the dropdown options
                    placeholder="Service Type"
                    disablePreSelectedValues={currentUser?.is_vcg_creation ? true : false}
                    className={classes.assignInventorySiteView}
                  />
                  <p className={classes.errText} style={{ color: 'red' }}>{serviceTypeValueError}</p> */}
                    </Grid>
          </GridContainer>
        </Box>

        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button variant='outlined' onClick={onCloseDialog}>Cancel</Button>
          <Box ml={2}>
            <Button variant="contained" style={{minWidth:'100px'}} color="primary" onClick={onSubmitClick} disabled={currentUser?.is_vcg_creation?true:false}>
            {currentUser?'Update':'Add'} 
            </Button>
          </Box>
        </Box>

      </DialogContent>
    </Dialog>
  );
};

export default AddEditUser;

AddEditUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};