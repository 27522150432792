import React, { useEffect, useState } from "react";
import GridContainer from "../../../@jumbo/components/GridContainer";
import { Grid, TableBody } from "@material-ui/core";
import PageContainer from "../../../@jumbo/components/PageComponents/layouts/PageContainer";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDashboardCardData,
  fetchDashboardContractorApplication,
} from "../../../redux/actions/Dashboard";
import CmtCard from "../../../@coremat/CmtCard";
import CmtCardHeader from "../../../@coremat/CmtCard/CmtCardHeader";
import CmtCardContent from "../../../@coremat/CmtCard/CmtCardContent";
import PerfectScrollbar from "react-perfect-scrollbar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import { TableRow, TableCell } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import FMAdminDemo from "FMAdminDemo";
import { Visibility } from "@material-ui/icons";
import UserDetailView from "../../modules/ItemsManagement/ItemsList/UserDetailView/index";
import { getItemListDetails } from "redux/actions/ItemsList";
import NoRecordFound from "../../modules/Settings/IssueManagement/NoRecordFound";
import { Typography } from "@material-ui/core";
import { StatisticsCard } from "../../../@jumbo/components/Common";
import CardGraph from "./CardGraph";
import CitiesGraph from "./CitiesGraph";
import VisitsGraph from "./VisitsGraph";
import ContractorApplicationCard from "./ContractorApplicationCard/index";
import StatisticsModernCard from "@jumbo/components/Common/StatisticsModernCard";
import GroupIcon from "@material-ui/icons/Group";
import SubscribersGraph from "./SubscribersGraph";
import StarsIcon from "@material-ui/icons/Stars";
import AuthorsChart from "./AuthorsChart";
import StatisticsCardWithBg from "@jumbo/components/Common/StatisticsCardWithBg";
import TimelineIcon from "@material-ui/icons/Timeline";
import TrafficGraph from "./TrafficGraph";
import CmtAdvCardContent from "@coremat/CmtAdvCard/CmtAdvCardContent";
import CmtAdvCard from "@coremat/CmtAdvCard";
import SalesReportGraph from "./SalesReportGraph";
import { eCommerce } from "./eCommerce";
// import {
//   ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, ChartTheme,
//   Legend, Category, Tooltip, ColumnSeries, ILoadedEventArgs, DataLabel, Highlight
// } from '@syncfusion/ej2-react-charts';
// import { PropertyPane } from '../common/property-pane';
// import { EmitType } from '@syncfusion/ej2-base';
// import { Browser } from '@syncfusion/ej2-base';
// import { updateSampleSection } from '../common/sample-base';
// import Chart from "react-apexcharts";
// import ReactApexChart from "react-apexcharts";
import ApexCharts from "apexcharts";
// import 'rsuite/dist/styles/rsuite-default.css';
// import { BarChart } from '@rsuite/icon-font/components/charts';

const useStyles = makeStyles((theme) => ({
  linkColor: {
    color: theme.palette.secondary.main,
    fontSize: 14,
    letterSpacing: 0.25,
    textOverflow: "ellipsis",
    maxWidth: "250px",
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontWeight: 300,
  },
  cardRoot: {
    [theme.breakpoints.down("xs")]: {
      "& .Cmt-header-root": {
        flexDirection: "column",
      },
      "& .Cmt-action-default-menu": {
        marginLeft: 0,
        marginTop: 10,
      },
    },
  },
  cardContentRoot: {
    padding: 0,
  },
  cmtCardSize: {
    minHeight: "350px",
  },
  scrollbarRoot: {
    height: 275,
  },
  cardRoot: {
    color: theme.palette.common.white,

    fontWeight: 400,
    "& .MuiTypography-subtitle1": {
      fontSize: 16,
    },
  },
  cardTitle: {
    fontWeight: 600,
  },
  cardRowData: {
    fontWeight: 300,
  },
  titleRoot: {
    fontSize: 24,
    fontWeight: 600,
    textTransform: "uppercase",
    letterSpacing: 0.4,
  },
}));

// const Series = [
//   {
//   name: 'Net Profit',
//   data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
//   },
//   {
//   name: 'Revenue',
//   data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
//   },
//   {
//   name: 'Free Cash Flow',
//   data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
//   }]

//   const Options = {
//     chart: {
//       type: 'bar',
//       height: 350
//     },
//     plotOptions: {
//       bar: {
//         horizontal: false,
//         columnWidth: '55%',
//         endingShape: 'rounded'
//       },
//     },
//     xaxis: {
//       categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
//     },
//     yaxis: {
//       title: {
//         text: '$ (thousands)'
//       }
//     },
//   }

const Home = () => {
  const classes = useStyles();
  const { authUser } = useSelector(({ auth }) => auth);
  const { users, contractorList } = useSelector(({ dashboard }) => dashboard);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  // const [setChartData] = useState(ApexOptions);
  // const ApexOptions
  const dispatch = useDispatch();
  const { saleHistory } = eCommerce;

  // const sampleData = [
  //   ['1 Time', 10000],
  //   ['2 Time', 20000],
  //   ['3 Time', 30000],
  // ];

  // const chartData = {
  //   chart: {
  //     type: "line",
  //     id: "apexchart-example",
  //     // foreColor: theme.palette.primary.main
  //   },
  //   xaxis: {
  //     categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
  //   },
  //   fill: {
  //     type: "gradient",
  //     gradient: {
  //       shade: "light",
  //       type: "horizontal",
  //       shadeIntensity: 0.5,
  //       gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
  //       inverseColors: true,
  //       opacityFrom: 1,
  //       opacityTo: 1,
  //       stops: [0, 50, 100]
  //       // colorStops: []
  //     }
  //   },
  //   legend: {
  //     // position: '',
  //     width: 400
  //     // position: 'top',
  //   },
  //   series: [
  //     {
  //       name: "Distance Traveled",
  //       type: "column",
  //       data: [440, 505, 414, 571, 227, 413, 201, 352, 652, 320, 257, 160]
  //     },
  //     {
  //       name: "Time Traveled",
  //       type: "line",
  //       data: [23, 42, 35, 27, 43, 22, 17, 31, 42, 22, 12, 16]
  //     }
  //   ]
  // };

  useEffect(() => {
    dispatch(fetchDashboardCardData());
    if ((authUser?.user_type === 3) & 4) {
      dispatch(fetchDashboardContractorApplication());
    }
  }, []);

  const viewDialog = () => {
    setOpenViewDialog(true);
  };
  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
  };
  const getUserActions = (user) => {
    const actions = [{ action: "view", label: "View", icon: <Visibility /> }];
  };
  const userActions = getUserActions(users);
  const history = useHistory();
  const p_color = window.localStorage.getItem("p_c") ?? "#FC8119";
  const s_color = window.localStorage.getItem("s_c") ?? "#F0511E";

  const ServiceRequest = () => {
    history.push("/service-requests");
  };
  const PaymentToVendors = () => {
    history.push("/payments-to-vendors");
  };
  const LocationDetails = () => {
    history.push(
      `/location-management/${users?.mostServiceRequestByTenants?._id}`
    );
  };
  return (
    <>
      {authUser?.user_type !== 5 ? (
        <PageContainer heading="Dashboard">
          <GridContainer>
            {/* <BarChart name="BarChart" data={sampleData} /> */}
            {/* <ApexCharts options={chartData} series={chartData.series} type="bar" height={350} /> */}

            {/* <Grid item xs={12} sm={6} md={4}>
    <span onClick={ServiceRequest} style={{ cursor: 'pointer' }}>
      <CmtAdvCard>
        <CmtCardHeader title={'This Year Sale Report'} />
        <CmtAdvCardContent
          className={classes.cardContentRoot}
          title="$685K+"
          titleProps={{
            variant: 'h1',
            component: 'div',
          }}
          subTitle={'Post 9 month data'}
          subTitleProps={{
            variant: 'body2',
            component: 'p',
            className: classes.subTitleRoot,
          }}
          reverseDir>
          <SalesReportGraph saleHistory={saleHistory} />
        </CmtAdvCardContent>
      </CmtAdvCard>
    </span>
  </Grid>

  <Grid item xs={12} sm={6} md={4}>
    <span onClick={ServiceRequest} style={{ cursor: 'pointer' }}>
      <StatisticsCardWithBg
        backgroundColor="#0795F4"
        icon={<TimelineIcon style={{ color: '#fff' }} />}
        title="756+"
        subTitle="Avg Daily Traffic">
        <TrafficGraph />
      </StatisticsCardWithBg>
    </span>
  </Grid>

  <Grid item xs={12} sm={6} md={4}>
    <span onClick={ServiceRequest} style={{ cursor: 'pointer' }}>
      <StatisticsModernCard
        backgroundColor="#6200EE"
        titleIcon={<GroupIcon style={{ color: '#fff' }} />}
        title="85K+"
        subTitle="New Subscribers">
        <SubscribersGraph />
      </StatisticsModernCard>
    </span>
  </Grid>

  <Grid item xs={12} sm={6} md={4}>
    <span onClick={ServiceRequest} style={{ cursor: 'pointer' }}>
      <StatisticsModernCard
        backgroundColor="#E00930"
        titleIcon={<StarsIcon style={{ color: '#fff' }} />}
        title="232"
        subTitle="New Authors">
        <Box mt={-7.5}>
          <AuthorsChart />
        </Box>
      </StatisticsModernCard>
    </span>
  </Grid> */}

            <Grid item xs={12} sm={6} md={4}>
              <span onClick={ServiceRequest} style={{ cursor: "pointer" }}>
                <StatisticsCard
                  className={classes.cardRoot}
                  backgroundColor="#7D38DF"
                  title={users?.totalServiceRequestsSoFar}
                  titleProps={{
                    variant: "inherit",
                    component: "h2",
                    className: classes.titleRoot,
                  }}
                  description="Total Service Requests"
                >
                  <CardGraph />
                </StatisticsCard>
              </span>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <span onClick={ServiceRequest} style={{ cursor: "pointer" }}>
                <StatisticsCard
                  className={classes.cardRoot}
                  backgroundColor="#3B9FE2"
                  title={users?.totalCompletedRequestsSoFar}
                  titleProps={{
                    variant: "inherit",
                    component: "h2",
                    className: classes.titleRoot,
                  }}
                  description="Total Completed Requests"
                >
                  <CitiesGraph />
                </StatisticsCard>
              </span>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <span onClick={LocationDetails} style={{ cursor: "pointer" }}>
                <StatisticsCard
                  className={classes.cardRoot}
                  backgroundColor="#BE4ED0"
                  title={users?.mostServiceRequestByTenants?.count}
                  titleProps={{
                    variant: "inherit",
                    component: "h2",
                    className: classes.titleRoot,
                  }}
                  description={`Frequently Requested Services by Tenant ${users?.mostServiceRequestByTenants?.location_name}`}
                >
                  <VisitsGraph />
                </StatisticsCard>
              </span>
            </Grid>
          </GridContainer>
          <Box mt={10}>
            <CmtCard>
              <CmtCardHeader
                className="pt-4"
                color="black"
                title="Low Stock Parts"
                titleProps={{
                  variant: "h4",
                  component: "div",
                }}
              ></CmtCardHeader>
              <CmtCardContent className={classes.cardContentRoot}>
                <PerfectScrollbar className={classes.scrollbarRoot}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead style={{ position: "sticky" }}>
                      <TableRow>
                        <TableCell className={classes.cardTitle}>
                          Part
                        </TableCell>
                        <TableCell className={classes.cardTitle}>
                          Inventory Site
                        </TableCell>
                        <TableCell className={classes.cardTitle}>
                          Available Parts
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    {!users?.lowStockItems?.length ? (
                      <TableBody>
                        <TableRow style={{ height: 43 * 5 }}>
                          <TableCell colSpan={7} rowSpan={10} align="center">
                            <NoRecordFound>
                              There are no records found.
                            </NoRecordFound>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ) : (
                      users?.lowStockItems?.map((item) =>
                        item?.item_list.map((list) => (
                          <TableBody>
                            <TableRow>
                              <TableCell
                                style={{ cursor: "pointer" }}
                                onClick={(e) =>
                                  dispatch(
                                    getItemListDetails(
                                      list?.item_id,
                                      viewDialog
                                    )
                                  )
                                }
                              >
                                <Typography className={classes.linkColor}>
                                  {list?.name}
                                </Typography>
                              </TableCell>
                              <TableCell
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  history.push(
                                    `/location-management/${item?._id}`
                                  );
                                  window.localStorage.setItem("L_S_W_t", "2");
                                }}
                              >
                                <Typography className={classes.linkColor}>
                                  {item?.name}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography className={classes.cardRowData}>
                                  {list?.remaining_quantity}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        ))
                      )
                    )}
                  </Table>
                </PerfectScrollbar>
              </CmtCardContent>
            </CmtCard>
            {openViewDialog && (
              <UserDetailView
                open={openViewDialog}
                onCloseDialog={handleCloseViewDialog}
              />
            )}
          </Box>

          <Box mt={10}>
            <CmtCard>
              <CmtCardHeader
                className="pt-4"
                title="Budget Threshold Alert"
                titleProps={{
                  variant: "h4",
                  component: "div",
                }}
              ></CmtCardHeader>
              <CmtCardContent className={classes.cardContentRoot}>
                <FMAdminDemo />
              </CmtCardContent>
            </CmtCard>
          </Box>

          <Box mt={10}>
            <CmtCard>
              <CmtCardHeader
                className="pt-4"
                title="Completed Work Orders"
                titleProps={{
                  variant: "h4",
                  component: "div",
                }}
              ></CmtCardHeader>
              <CmtCardContent className={classes.cardContentRoot}>
                <FMAdminDemo />
              </CmtCardContent>
            </CmtCard>
          </Box>

          <Box mt={10}>
            <ContractorApplicationCard />
          </Box>
        </PageContainer>
      ) : (
        <PageContainer heading="Dashboard">
          <GridContainer>
            <Grid item xs={12} sm={6} md={4}>
              <span style={{ cursor: "pointer" }}>
                <StatisticsCard
                  className={classes.cardRoot}
                  backgroundColor="#7D38DF"
                  title={users?.totalServiceRequestSoFar}
                  titleProps={{
                    variant: "inherit",
                    component: "h4",
                    className: classes.titleRoot,
                  }}
                  description="Total Service Requests"
                >
                  <CardGraph />
                </StatisticsCard>
              </span>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <span onClick={PaymentToVendors} style={{ cursor: "pointer" }}>
                <StatisticsCard
                  className={classes.cardRoot}
                  backgroundColor="#3B9FE2"
                  title={users?.paymentRequests}
                  titleProps={{
                    variant: "inherit",
                    component: "h4",
                    className: classes.titleRoot,
                  }}
                  description="Payment Requests"
                >
                  <CitiesGraph />
                </StatisticsCard>
              </span>
            </Grid>
          </GridContainer>

          <Box mt={10}>
            <CmtCard>
              <CmtCardHeader
                className="pt-4"
                title="Budget Threshold Alert"
                titleProps={{
                  variant: "h4",
                  component: "div",
                }}
              ></CmtCardHeader>
              <CmtCardContent className={classes.cardContentRoot}>
                <FMAdminDemo />
              </CmtCardContent>
            </CmtCard>
          </Box>
        </PageContainer>
      )}
    </>
  );
};
export default Home;
