import { alpha, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: alpha(theme.palette.secondary.light, 0.15),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  chipsRoot: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  assignInventorySiteView: {
    zIndex: 99,
    minHeight: '40px',
    minWidth: '200px',
    fontSize: '14px',
    '& .chip': {
      background: `${theme.palette.primary.main} !important`,
    },
    '& .highlightOption': {
      background: `${theme.palette.primary.main} !important`,
    },
    '& .multiSelectContainer li': {
      '&:hover': {
        // background:`${theme.palette.primary.main} !important`,
        background: `"red" !important`,
      },
    },
    '& li': {
      fontSize: '14px',
      '&:hover': {
        // background:`${theme.palette.primary.main} !important`,
        background: 'red',
      },
    },
    '& input': {
      fontSize: '16px',
      paddingLeft: '5px',
    },
    '& .optionContainer': {
      border: '1px solid #ccc',
      borderRadius: '4px',
      display: 'block',
      margin: 0,
      maxHeight: '125px',
      overflowY: 'auto',
      padding: 0,
      background: 'red',
    },
  },
  menuItem1: {
    marginLeft: '8px',
    // marginRight:"5px",
    fontSize: '16px',
    fontWeight: 600,
    marginBottom: '8px',
    backgroundColor: 'red',
  },
  menu: {
    // backgroundColor:"red",
    top: '68px',
    marginTop: '90px',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  dropdown: {
    width: '294px',
    textOverflow: 'ellipsis',
  },
  textField:{
    borderBottom:"2px solid red",
    // border:"2px solid red"

  }
}));

export default useStyles;
