// import React, { useState } from 'react';
// import Toolbar from '@material-ui/core/Toolbar';
// import clsx from 'clsx';
// import Typography from '@material-ui/core/Typography';
// import Tooltip from '@material-ui/core/Tooltip';
// import IconButton from '@material-ui/core/IconButton';
// import DeleteIcon from '@material-ui/icons/Delete';
// import FilterListIcon from '@material-ui/icons/FilterList';
// import PropTypes from 'prop-types';
// import { Button, Chip, Menu, MenuItem } from '@material-ui/core';
// import { useDispatch } from 'react-redux';
// import { deleteBulkUsers } from '../../../../redux/actions/Users';
// import ConfirmDialog from '../../../../@jumbo/components/Common/ConfirmDialog';
// import CmtSearch from '../../../../@coremat/CmtSearch';
// import useStyles from './index.style';
// import Checkbox from '@material-ui/core/Checkbox';

// const filterOptionsList = [
//   { label: 'Vendor Not Assigned', value: 1 },
//   { label: 'Vendor Assigned', value: 2 },
//   { label: 'Service Started', value: 3 },
//   { label: 'Service Request Completed', value: 4 },
//   { label: 'Work Approved', value: 5 },
//   { label: 'Payment Sent', value: 6 },
// ];

// const UserTableToolbar = ({
//   selected,
//   setSelected,
//   searchTerm,
//   setSearchTerm,
//   setListView,
//   setPage,
//   setSearchedData,
//   setFilterOptionsStatus,
//   filterOptionsStatus,
// }) => {
//   const classes = useStyles();
//   const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
//   const [anchorEl, setAnchorEl] = React.useState(null);

//   const dispatch = useDispatch();

//   const handleClick = event => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   const onDeleteCLick = () => {
//     setOpenConfirmDialog(true);
//   };

//   const handleConfirmDelete = () => {
//     setOpenConfirmDialog(false);
//     dispatch(deleteBulkUsers(selected, () => setSelected([])));
//   };

//   const handleCancelDelete = () => {
//     setOpenConfirmDialog(false);
//   };

//   const onFilterOptionClick = option => {
//     setFilterOptionsStatus(prevState => {
//       if (prevState.includes(option.value)) {
//         return prevState.filter(item => item !== option.value);
//       } else {
//         setPage(0);

//         return [...prevState, option.value];
//       }
//     });
//   };

//   const onChipDelete = option => {
//     setFilterOptionsStatus(filterOptionsStatus.filter(item => item !== option.value));
//   };

//   const onSearchChipDelete = () => {
//     setSearchTerm('');
//     setSearchedData('debouncedSearchPagevalidation');
//   };

//   const numSelected = selected.length;

//   return (
//     <React.Fragment>
//       <Toolbar
//         className={clsx(classes.root, {
//           [classes.highlight]: numSelected > 0,
//         })}>
//         <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
//           <Button
//             color="primary"
//             variant="contained"
//             onClick={e => {
//               setListView(1);
//             }}>
//             List
//           </Button>{' '}
//           <Button color="primary" variant="contained" onClick={e => setListView(0)}>
//             Map
//           </Button>
//           <Button color="primary" variant="contained" onClick={e => setListView(3 )} style={{marginLeft:"5px"}}>
//           Calender
//           </Button>
//         </Typography>

//         {numSelected > 0 ? (
//           <Tooltip title="Delete">
//             <IconButton aria-label="delete" onClick={onDeleteCLick}>
//               <DeleteIcon />
//             </IconButton>
//           </Tooltip>
//         ) : (
//           <React.Fragment>
//             <CmtSearch onChange={e => setSearchTerm(e.target.value)} value={searchTerm} border={false} onlyIcon />
//             <div className={classes.chipsRoot}>
//               {searchTerm && <Chip label={searchTerm?.substring(0, 8) + '...'} onDelete={onSearchChipDelete} />}
//               {filterOptionsList.map(
//                 (option, index) =>
//                   filterOptionsStatus.includes(option.value) && (
//                     <Chip key={index} label={option.label?.substring(0, 8) + '...'} onDelete={() => onChipDelete(option)} />
//                   ),
//               )}
//             </div>
//             <Tooltip title="Filter list">
//               <IconButton aria-label="filter list" onClick={handleClick}>
//                 <FilterListIcon />
//               </IconButton>
//             </Tooltip>
//             <Menu
//               transformOrigin={{
//                 vertical: 'top',
//                 horizontal: 'right',
//               }}
//               anchorEl={anchorEl}
//               open={Boolean(anchorEl)}
//               onClose={handleClose}>
//               {filterOptionsList.map((option, index) => (
//                 <MenuItem key={index} onClick={() => onFilterOptionClick(option)}>
//                   <Checkbox
//                     checked={filterOptionsStatus.includes(option.value)}
//                     inputProps={{ 'aria-labelledby': option.label }}
//                   />
//                   {option.label}
//                 </MenuItem>
//               ))}
//             </Menu>
//           </React.Fragment>
//         )}
//       </Toolbar>

//       <ConfirmDialog
//         open={openConfirmDialog}
//         title={`Confirm delete users`}
//         content={'Are you sure, you want to  delete selected users?'}
//         onClose={handleCancelDelete}
//         onConfirm={handleConfirmDelete}
//       />
//     </React.Fragment>
//   );
// };

// UserTableToolbar.propTypes = {
//   selected: PropTypes.array,
//   setSelected: PropTypes.func,
//   filterOptions: PropTypes.array,
//   setFilterOptions: PropTypes.func,
//   searchTerm: PropTypes.string,
//   setSearchTerm: PropTypes.func,
//   onUserAdd: PropTypes.func,
//   setFilterOptionsStatus: PropTypes.func,
//   filterOptionsStatus: PropTypes.array,
// };

// export default React.memo(UserTableToolbar);

import React, { useState, useEffect } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import PropTypes from 'prop-types';
import { Box, Button, Chip, Menu, MenuItem } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { deleteBulkUsers } from '../../../../redux/actions/Users';
import ConfirmDialog from '../../../../@jumbo/components/Common/ConfirmDialog';
import CmtSearch from '../../../../@coremat/CmtSearch';
import useStyles from './index.style';
import Checkbox from '@material-ui/core/Checkbox';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Multiselect from 'multiselect-react-dropdown';
import { KeyboardDatePicker } from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import Select from 'react-select';
import { getTenantList } from 'redux/actions/Tenant';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

const filterOptionsList = [
  { label: 'Vendor Not Assigned', value: 1 },
  { label: 'Vendor Assigned', value: 2 },
  { label: 'Service Started', value: 3 },
  { label: 'Service Request Completed', value: 4 },
  { label: 'Work Approved', value: 5 },
  { label: 'Payment Sent', value: 6 },
];
// const filterOptionsList = [
//   { label: 'New Issue', value: 1 },
//   { label: 'Rework', value: 2 },
// ];
const Priority = [
  { label: 'P1', value: 1 },
  { label: 'P2', value: 2 },
  { label: 'P3', value: 3 },
  { label: 'P4', value: 4 },
  { label: 'P5', value: 5 },
];

const UserTableToolbar = ({
  selected,
  setSelected,
  searchTerm,
  setSearchTerm,
  setListView,
  setPage,
  setSearchedData,
  setFilterOptionsStatus,
  filterOptionsStatus,
  setLocationId,
  locationId,
  dueDate,
  setDueDate,
  tenantId,
  setTenantId,
  vendorId,
  setVendorId,
  serviceTypeId,
  setServiceTypeId,
  assetTypeID,
  setAssetTypeID,
  startRange,
  setStartRange,
  endRange,
  setEndRange,
}) => {
  const classes = useStyles();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { users } = useSelector(({ LOCATION }) => LOCATION);
  const VENDORMANAGEMENT = useSelector(({ VENDORMANAGEMENT }) => VENDORMANAGEMENT.users);
  // const TENANT = useSelector(({ TENANT }) => TENANT.users);
  const { tenant } = useSelector(({ SERVICEREQUEST }) => SERVICEREQUEST);
  const TENANTLIST = useSelector(({ TENANT }) => TENANT.tenant);
  const SERVICETYPE = useSelector(({ SERVICETYPE }) => SERVICETYPE.users);
  const ASSETLOCATION = useSelector(({ ASSETLOCATION }) => ASSETLOCATION.assetType);
  // const [location, setLocation] = useState('');

  // const [isClearable, setIsClearable] = useState(true);
  // const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);

  console.log("start",startRange)
  console.log("end",endRange)
  console.log("con",startRange && startRange<endRange)
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getTenantList(locationId,'name', 'asc', '', 0, -1, [1]))
  // }, [locationId])

  const handleDateChange = date => {
    setDueDate(date);
    // setDueDateError('');
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDeleteCLick = () => {
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    dispatch(deleteBulkUsers(selected, () => setSelected([])));
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const onFilterOptionClick = option => {
    setFilterOptionsStatus(prevState => {
      if (prevState.includes(option.value)) {
        return prevState.filter(item => item !== option.value);
      } else {
        setPage(0);

        return [...prevState, option.value];
      }
    });
  };

  const onChipDelete = option => {
    setFilterOptionsStatus(filterOptionsStatus.filter(item => item !== option.value));
  };

  const onSearchChipDelete = () => {
    setSearchTerm('');
    setSearchedData('debouncedSearchPagevalidation');
  };

  const numSelected = selected.length;

  return (
    <React.Fragment>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}>
        <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
          <Button
            color="primary"
            variant="contained"
            onClick={e => {
              setListView(1);
            }}>
            List
          </Button>{' '}
          <Button color="primary" variant="contained" onClick={e => setListView(0)}>
            Map
          </Button>
          {/* <Button color="primary" variant="contained" onClick={e => setListView(3)} style={{ marginLeft: '5px' }}>
            Calender
          </Button> */}
        </Typography>

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="delete" onClick={onDeleteCLick}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <React.Fragment>
            <CmtSearch onChange={e => setSearchTerm(e.target.value)} value={searchTerm} border={false} onlyIcon />
            <div className={classes.chipsRoot}>
              {searchTerm && <Chip label={searchTerm?.substring(0, 8) + '...'} onDelete={onSearchChipDelete} />}
              {filterOptionsList.map(
                (option, index) =>
                  filterOptionsStatus.includes(option.value) && (
                    <Chip key={index} label={option.label?.substring(0, 8) + '...'} onDelete={() => onChipDelete(option)} />
                  ),
              )}
            </div>
            <Tooltip title="Filter list">
              <IconButton aria-label="filter list" onClick={handleClick}>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
            <Menu
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              className={classes.menu}>
              {filterOptionsList.map((option, index) => (
                <MenuItem key={index} onClick={() => onFilterOptionClick(option)}>
                  <Checkbox
                    checked={filterOptionsStatus.includes(option.value)}
                    inputProps={{ 'aria-labelledby': option.label }}
                  />
                  {option.label}
                </MenuItem>
              ))}
              {/* <span className={classes.menuItem}>{'Filter List'}</span>
              <Box style={{ width: '307px', backgroundColo: 'green' }}>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography className={classes.heading}>Location</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                    className={classes.dropdown}
                      // style={{
                      //   width: '294px',
                      //   textOverflow: 'ellipsis',
                      //   // maxWidth: '150px',
                      //   // display: 'block',
                      //   // overflow: 'hidden',
                      //   // whiteSpace: 'nowrap',
                      // }}
                      >
                      <Select
                        className={classes.assignInventorySiteView}
                        style={{ backgroundColor: 'white' }}
                        placeholder="Location"
                        ismenuOpen={true}
                        value={users?.data
                          ?.map(item => {
                            return { value: item?._id, label: item?.name };
                          })

                          .filter(obj => obj?.value === locationId)}
                        onChange={e => {
                          setLocationId(e?.value ?? '');
                        }}
                        isClearable={isClearable}
                        isSearchable={isSearchable}
                        options={users?.data?.map(item => {
                          return { value: item?._id, label: item?.name };
                        })}
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography className={classes.heading}>Day/Date</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <KeyboardDatePicker
                        placeholder="DD/MM/YYYY"
                        margin="normal"
                        id="date-picker-dialog"
                        label="Date"
                        format="DD/MM/YYYY"
                        value={dueDate}
                        // minDate={new Date()}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        className={classes.helperText}
                        // helperText={dueDateError}
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography className={classes.heading}>Tenant</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className={classes.dropdown}>
                      <Select
                        className={classes.assignInventorySiteView}
                        style={{ backgroundColor: 'white' }}
                        placeholder="Tenant"
                        value={tenant?.data
                          ?.map(item => {
                            return { value: item?._id, label: `${item?.first_name} ${item?.last_name}` };
                          })

                          .filter(obj => obj?.value === tenantId)} tenant_details
                        onChange={e => {
                          setTenantId(e?.value ?? '');
                        }}
                        isClearable={isClearable}
                        isSearchable={isSearchable}
                        options={tenant?.data?.map(item => {
                          return { value: item?._id, label: `${item?.first_name} ${item?.last_name}` };
                        })}
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography className={classes.heading}>Vendor</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className={classes.dropdown}>
                      <Select
                        className={classes.assignInventorySiteView}
                        style={{ backgroundColor: 'white' }}
                        placeholder="Vendor"
                        value={VENDORMANAGEMENT?.data
                          ?.map(item => {
                            return { value: item?._id, label: `${item?.first_name} ${item?.last_name}` };
                          })

                          .filter(obj => obj?.value === vendorId)}
                        onChange={e => {
                          setVendorId(e?.value ?? '');
                        }}
                        isClearable={isClearable}
                        isSearchable={isSearchable}
                        options={VENDORMANAGEMENT?.data?.map(item => {
                          return { value: item?._id, label: `${item?.first_name} ${item?.last_name}` };
                        })}
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography className={classes.heading}>Service Type</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      className={classes.dropdown}
                      >
                      <Select
                        className={classes.assignInventorySiteView}
                        style={{ backgroundColor: 'white' }}
                        placeholder="Service Type"
                        value={SERVICETYPE?.data
                          ?.map(item => {
                            return { value: item?._id, label: item?.name };
                          })

                          .filter(obj => obj?.value === serviceTypeId)}
                        onChange={e => {
                          setServiceTypeId(e?.value ?? '');
                        }}
                        isClearable={isClearable}
                        isSearchable={isSearchable}
                        options={SERVICETYPE?.data?.map(item => {
                          return { value: item?._id, label: item?.name };
                        })}
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography className={classes.heading}>Asset Type</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      className={classes.dropdown}
                      >
                      <Select
                        className={classes.assignInventorySiteView}
                        style={{ backgroundColor: 'white' }}
                        placeholder="Asset Type"
                        value={ASSETLOCATION?.map(item => {
                          return { value: item?._id, label: item?.name };
                        })
                        .filter(obj => obj?.value === assetTypeID)}
                        onChange={e => {
                          setAssetTypeID(e?.value ?? '');
                        }}
                        isClearable={isClearable}
                        isSearchable={isSearchable}
                        options={ASSETLOCATION?.map(item => {
                          return { value: item?._id, label: item?.name };
                        })}
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography className={classes.heading}>Priority Level</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {Priority.map((option, index) => (
                        <MenuItem key={index} onClick={() => onFilterOptionClick(option)}>
                          <Checkbox
                            checked={filterOptionsStatus.includes(option.value)}
                            inputProps={{ 'aria-labelledby': option.label }}
                          />
                          {option.label}
                        </MenuItem>
                      ))}
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography className={classes.heading}>Quote Value</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{width:"133%"}}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Typography>
                          <TextField id="date" label="Start Range" type="number"  value={startRange}
                            // className={classes.textField}

                            onChange={e => {
                              setStartRange(e.target.value);
                              
                            }}/>
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography style={{ marginTop: '25px', marginLeft: '2px' }}>to</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography style={{ marginLeft: '2px' }}>
                          <TextField id="date" label="End Range" type="number" value={endRange}
                              
                              // className={(endRange) && (startRange>endRange) && (startRange)?classes.textField:null}
                              // error={(endRange) && (startRange>endRange) && (startRange)? true : true}
                              error={Math.min(startRange> Math.max(0, endRange)) && endRange?true:false}
                              onChange={e => {
                                setEndRange(e.target.value);
                              }} />
                        </Typography>
                      </Grid>
                    </Grid> */}
                    {/* <GridContainer spacing={3}>
                      <Grid item xs={4}>
                        <Typography>
                          <TextField
                            id="date"
                            label="Start Range"
                            type="number"
                            value={startRange}
                            // className={classes.textField}

                            onChange={e => {
                              setStartRange(e.target.value);
                              // setStartDateError('');
                              // // getNextServiceDate(durationValue, e.target.value);
                              // // setStartDateValue(e.target.value)
                            }}
                            // helperText={startDateError}
                          />
                        </Typography>

                        <Grid item xs={1}>
                          <span>to</span>
                        </Grid>

                        <Grid item xs={4}>
                          <Typography>
                            <TextField
                              id="date"
                              label="End Range"
                              type="number"
                              value={endRange}
                              // InputProps={{ inputProps: { min: 600} }}
                              // className={classes.textField}
                              error={startRange < endRange ? true : false}
                              onChange={e => {
                                setEndRange(e.target.value);
                                // setStartDateError('');
                                // getNextServiceDate(durationValue, e.target.value);
                                // setStartDateValue(e.target.value)
                              }}
                              // helperText={startDateError}
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    </GridContainer> */}
                  {/* </AccordionDetails>
                </Accordion> */}
              {/* </Box> */}
            </Menu>
          </React.Fragment>
        )}
      </Toolbar>

      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm delete users`}
        content={'Are you sure, you want to  delete selected users?'}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </React.Fragment>
  );
};

UserTableToolbar.propTypes = {
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  filterOptions: PropTypes.array,
  setFilterOptions: PropTypes.func,
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func,
  onUserAdd: PropTypes.func,
  setFilterOptionsStatus: PropTypes.func,
  filterOptionsStatus: PropTypes.array,
};

export default React.memo(UserTableToolbar);
