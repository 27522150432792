// import React, { useEffect, useState } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
// import Paper from '@material-ui/core/Paper';
// import { useSelector } from 'react-redux';
// import Typography from '@material-ui/core/Typography';

// const useStyles = makeStyles(theme => ({
//   table: {
//     minWidth: 650,
//     "& .MuiTableCell-head": {
//       fontWeight:"600",
//   },
//   },
//   tableRow: {
//     fontWeight:"300",
//   },

//   border: {
//     color: theme.palette.text.primary,
//     '&:focus': {
//       border: `2px solid ${theme.palette.text.primary}`,
//     },
//   },
//   titleRoot: {
//     fontSize: 14,
//     letterSpacing: 0.25,
//     color: theme.palette.text.primary,
//     textOverflow: 'ellipsis',
//     maxWidth: '250px',
//     display: 'block',
//     overflow: 'hidden',
//     whiteSpace: 'nowrap',
//   },
// }));

// export default function AssignItemTable({ setGetData, setNodataError }) {
//   const classes = useStyles();
//   const { addUser } = useSelector(({ ASSIGNITEM }) => ASSIGNITEM);
//   const [selectedItem, setSelectedItem] = useState([]);
//   const [newItem, setNewItem] = useState([]);

//   useEffect(() => {
//     const localData = addUser?.map(item => {
//       return { item_id: item.item_id, item_name: item.item_name, quantity: item.quantity, check: false };
//     });
//     setSelectedItem(localData);
//   }, [addUser]);

//   const onFilterOptionClick = (e, option) => {
//     if (!e.target.value || e.target.value === 0 || e.target.value > option.quantity) {
//       const data = newItem?.filter(item => item.item_id !== option.item_id);
//       setNewItem(data);
//       setGetData(data);
//     }
//     if (e.target.value === '0' || e.target.value > option.quantity) {
//       document.getElementById(option.item_id).style.borderColor = 'red';
//     }

//     let itemInclude = true;
//      newItem.forEach(items => {
//       if (items.item_id === option.item_id) {
//         return (itemInclude = false);
//       }
//     });
//     if (e.target.value > 0 && e.target.value <= option.quantity && itemInclude) {
//       setNewItem([...newItem, { item_id: option.item_id, quantity: e.target.value }]);
//       setGetData([...newItem, { item_id: option.item_id, quantity: e.target.value }]);
//     } else if (e.target.value > 0 && e.target.value <= option.quantity) {
//       const assignArray = newItem?.map(item => {
//         if (item.item_id === option.item_id) {
//           return { item_id: item.item_id, quantity: e.target.value };
//         } else {
//           return item;
//         }
//       });
//       setNewItem(assignArray);
//       setGetData(assignArray);
//     }
//   };
//   return (
//     <TableContainer component={Paper}>
//       <Table className={classes.table} size="small" aria-label="a dense table">
//         <TableHead>
//           <TableRow>
//             <TableCell>#</TableCell>
//             <TableCell>Part</TableCell>
//             <TableCell>Quantity</TableCell>
//             <TableCell>Required</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {selectedItem?.map((row, index) => (
//             <TableRow className={classes.tableRow}>
//               <TableCell component="th" scope="row">
//                 {index + 1}
//               </TableCell>
//               <TableCell>
//                 <Typography className={classes.titleRoot}>{row.item_name}</Typography>
//               </TableCell>
//               <TableCell>{row.quantity}</TableCell>
//               <TableCell>
//                 <Typography className={classes.border}>
//                   <input
//                     id={row.item_id}
//                     onPaste={e => {
//                       e.preventDefault();
//                       return false;
//                     }}
//                     className={classes.border}
//                     onCopy={e => {
//                       e.preventDefault();
//                       return false;
//                     }}
//                     disabled={row.check}
//                     min="0"
//                     onKeyPress={event => {
//                       if (['-', '+', 'e', '.'].includes(event.key)) {
//                         event.preventDefault();
//                       }
//                     }}
//                     type="number"
//                     style={{ borderColor: '#bfbfbf' }}
//                     onChange={e => {
//                       document.getElementById(row.item_id).style.borderColor = '#bfbfbf';
//                       onFilterOptionClick(e, row);
//                       setNodataError('');
//                     }}
//                   />
//                 </Typography>
//               </TableCell>
//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>
//     </TableContainer>
//   );
// }

import React from "react";
import PropTypes from "prop-types";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Checkbox from "@material-ui/core/Checkbox";
import { useSelector } from "react-redux";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
    "& .MuiTableCell-sizeSmall": {
      padding: "1px 0px 0px 40px",
    },
    "& .makeStyles-root-149 .MuiTableCell-head": {
      fontWeight: "600",
      // backgroundColor:theme.palette.primary.main,
      // color:"white"
    },
  },
  table: {
    "& .MuiTableCell-head": {
      fontWeight: "600",
      backgroundColor: "#ececec !important",
      color: theme.palette.text.primarary,
    },
  },
}));

function Row(props) {
  const {
    row,
    setCheckData,
    checkData,
    setGetData,
    setNodataError,
    setCheckedSerialArray,
    checkedSerialArray,
  } = props;
  const [open, setOpen] = React.useState(false);

  const classes = useRowStyles();

  // console.log("check",checkData)
  const handelCheckData = (e) => {
    let flag = false;
    let find = false;
    let partIndex;
    let serialNumber = e.target.value;
    let partId = e.target.id;

    setCheckedSerialArray((prevState) => {
      if (prevState.includes(serialNumber)) {
        return prevState.filter((item) => item !== serialNumber);
      } else {
        return [...checkedSerialArray, serialNumber];
      }
    });

    if (!checkData?.length) {
      return (
        setCheckData([
          { item_id: partId, items_serial_number: [serialNumber] },
        ]),
        setNodataError("")
      );
    }

    const newarr = checkData?.forEach((item, index) => {
      if (item.item_id === partId) {
        find = true;
        partIndex = index;
      }
    });
    if (find) {
      if (checkData[partIndex].items_serial_number.includes(serialNumber)) {
        const copyOfCheckData = [...checkData];
        const updatedData = copyOfCheckData[
          partIndex
        ].items_serial_number.filter((item) => item !== serialNumber);
        copyOfCheckData[partIndex].items_serial_number = updatedData;
        setCheckData(copyOfCheckData);
      } else {
        const updatedData = [...checkData];
        updatedData[partIndex].items_serial_number.push(serialNumber);
        setCheckData(updatedData);
      }
    } else {
      setCheckData([
        ...checkData,
        { item_id: partId, items_serial_number: [serialNumber] },
      ]);
    }
  };

  if (checkData?.length) {
    //  let newData = checkData?.filter(item => item.items_serial_number.length)
    setGetData(checkData);
  }

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell>
          <Typography>{row.item_name}</Typography>
        </TableCell>
        <TableCell></TableCell>
      </TableRow>
      <TableRow style={{ background: "#ececec" }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table
                size="small"
                aria-label="purchases"
                className={classes.table}
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{ width: "144px", border: "none" }}
                    ></TableCell>
                    <TableCell
                      style={{ width: "498px", border: "none" }}
                    ></TableCell>
                    <TableCell style={{ border: "none" }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row?.remaining_items_serial_number?.map((item) => (
                    <TableRow style={{ background: "#ececec" }}>
                      <TableCell>
                        <Typography>
                          <Checkbox
                            checked={checkedSerialArray.includes(item)}
                            value={item}
                            id={row.item_id}
                            onChange={handelCheckData}
                          />
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{row.item_name}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{item}</Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
    "& .MuiTableCell-head": {
      fontWeight: "600",
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
    "& .MuiTableCell-root": {
      padding: "6px",
      borderBottom: "-1px",
    },
  },
}));

export default function AssignItemTable({ setGetData, setNodataError }) {
  const classes = useStyles();
  const { addUser } = useSelector(({ ASSIGNITEM }) => ASSIGNITEM);
  const [checkData, setCheckData] = React.useState([]);
  const [checkedSerialArray, setCheckedSerialArray] = React.useState([]);

  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography></Typography>
            </TableCell>
            <TableCell>
              <Typography>Part Name</Typography>
            </TableCell>
            <TableCell>
              <Typography>Serial No</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!addUser ? (
            <TableRow>
              <TableCell>
                <Typography>-</Typography>
              </TableCell>
              <TableCell>
                <Typography>-</Typography>
              </TableCell>
              <TableCell>
                <Typography>-</Typography>
              </TableCell>
            </TableRow>
          ) : null}
          {addUser?.map((row) => (
            <Row
              setCheckedSerialArray={setCheckedSerialArray}
              checkedSerialArray={checkedSerialArray}
              setGetData={setGetData}
              setNodataError={setNodataError}
              setCheckData={setCheckData}
              checkData={checkData}
              key={row._id}
              row={row}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
