import {
  ADD_ITEMSLIST,
  DELETE_BULK_ITEMSLIST,
  DELETE_ITEMSLIST,
  EDIT_ITEMSLIST,
  GET_ITEMSLIST,
  SET_ITEM,
  GET_ITEM,
ADD_ITEM,
UPDATE_ITEM,
DELETE_ITEM
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  users: [],
  currentUser: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ITEM: {
      return {
        ...state,
        users: action.payload,
      };
    }
    case SET_ITEM: {
      return {
        ...state,
        currentUser: action.payload,
      };
    }
    case ADD_ITEM: {
      return {
        ...state,
        users: [action.payload, ...state.users],
      };
    }
    case DELETE_ITEM: {
      return {
        ...state,
        users: state.users.filter(user => user.id !== action.payload),
      };
    }
    // case EDIT_ITEM: {
    //   return {
    //     ...state,
    //     users: state.users.map(user => (user.id === action.payload.id ? action.payload : user)),
    //   };
    // }
    // case DELETE_BULK_ITEMSLIST: {
    //   return {
    //     ...state,
    //     users: state.users.filter(user => !action.payload.includes(user.id)),
    //   };
    // }
    default:
      return state;
  }
};
