import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';
import {

  DELETE_BULK_INDUCTION,
  DELETE_INDUCTION,
  EDIT_INDUCTION,
  GET_INDUCTION,
  SET_INDUCTION_DETAILS,
} from '../../@jumbo/constants/ActionTypes';
import {   assestUpdateLoaction, inductionAdd, inductionDelete, inductionDetailView, inductionList, inductionStatus } from '../../@jumbo/constants/ApiConstatnt';
import { setAuthUser } from './Auth';
import { history } from 'redux/store';


// get Induction list
export const getInduction = (siteId,sortBy, sort, searchTerm, page, limit, filterOptions, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());

    axios
      .get(inductionList+siteId, {
        params: { sortBy: `${sortBy}:${sort}`, search: searchTerm, page: page + 1, limit: limit,status: filterOptions[0],type:1 },
      })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_INDUCTION, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch({ type: GET_INDUCTION, payload: [] });
          dispatch(fetchError());
        }
      })
      .catch(error => {
        if(error?.response?.status===401 ){
          dispatch(setAuthUser(null));
          history.push('/signin')
        } 
        else {
         
          dispatch(fetchError(error?.response?.data?.message));
        }
      });

  };
};

// set current user for view tab
export const setCurrentInduction = user => {
  return dispatch => {
    dispatch({ type: SET_INDUCTION_DETAILS, payload: user });
  };
};

// add new FM provider 
export const addNewInduction = (siteId,user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(inductionAdd+siteId, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('New Induction content added successfully.'));
          }, 2000);

          // dispatch({ type:  ADD_INDUCTION, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if(error?.response?.status===401 ){
          dispatch(setAuthUser(null));
          history.push('/signin')
        } else{
  
        
          dispatch(fetchError(error?.response?.data?.message));}
      });

  };
};
export const updateInduction = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(inductionStatus, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Induction content status updated successfully.'));
          }, 2000);

          // dispatch({ type:  ADD_INDUCTION, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if(error?.response?.status===401 ){
          dispatch(setAuthUser(null));
          history.push('/signin')
        } else{
  
        
          dispatch(fetchError(error?.response?.data?.message));}
      });

  };
};
export const updateINDUCTION= (id,data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(assestUpdateLoaction+id, data)
      .then(response => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('INDUCTION status updated successfully.'));
          }, 2000);
          // dispatch({ type: EDIT_USER, payload: response.data });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if(error?.response?.status===401 ){
          dispatch(setAuthUser(null));
          history.push('/signin')
        } else{
  
        
          dispatch(fetchError(error?.response?.data?.message));}
      });

  };
};

// all function below are not in use currntly
export const deleteBulkUsers = (userIds, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/users/bulk-delete', { userIds })
      .then(response => {
        if (response.status === 200) {
          dispatch(fetchSuccess('Selected users were deleted successfully.'));
          dispatch({ type: DELETE_BULK_INDUCTION, payload: userIds });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const deleteInductionContent = (Id, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete(inductionDelete+Id)
      .then(data => {
        if (data.status === 200) {
          setTimeout(() => {
            dispatch(fetchSuccess('Induction content deleted successfully.'));   
          }, 2000);
         
          // dispatch({ type: DELETE_INDUCTION, payload: userId });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const sentMailToUser = () => {
  return dispatch => {
    dispatch(fetchSuccess('Email has been sent to user successfully'));
  };
};

export const getInductionViewDetail = (id,callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());

    axios
      .get(inductionDetailView+id )
      .then(data => {
        // if(searchTerm && !data?.data?.data?.providers?.length && data?.data?.data?.page>1) {dispatch(getIssueManagement(sortBy, sort, searchTerm, 0, limit, filterOptions, callbackFun))}
        if (data.status === 200) {
          dispatch(fetchSuccess());
         
          dispatch({ type: SET_INDUCTION_DETAILS, payload: data.data.data });
          // history.push(`/vendor-management/vendor-management-details`)
          if (callbackFun) callbackFun();
        } else {
          dispatch({ type: SET_INDUCTION_DETAILS, payload: [] });
          dispatch(fetchError());
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Induction not found.') {
          dispatch(fetchError(error?.response?.data?.message));
          dispatch({ type: SET_INDUCTION_DETAILS, payload: [] });
        } else {
          dispatch(fetchError());
        }
      });
  };
};

export const updateUser = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/users', user)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('Selected user was updated successfully.'));
          dispatch({ type: EDIT_INDUCTION, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};
