import {
  GET_ASSIGNEDSITE_LIST,
  SET_CURRENT_ASSIGNED_SITE,
 
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  users: [],
  currentUser: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ASSIGNEDSITE_LIST: {
      return {
        ...state,
        users: action.payload,
      };
    }
    case SET_CURRENT_ASSIGNED_SITE: {
      return {
        ...state,
        currentUser: action.payload,
      };
    }
    
    default:
      return state;
  }
};
