import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';
import {
  DELETE_BULK_SELFHELPCONTENT,
  EDIT_SELFHELPCONTENT,
  GET_SELFHELPCONTENT,
  SET_SELFHELPCONTENT_DETAILS,
} from '../../@jumbo/constants/ActionTypes';
import {
  assestUpdateLoaction,
  selfHelpContentAdd,
  selfHelpContentDelete,
  selfHelpContentList,
  selfHelpContentStatus,
} from '../../@jumbo/constants/ApiConstatnt';
import { setAuthUser } from './Auth';
import { history } from 'redux/store';

// get FM provider list
export const getSelfHelpContent = (siteId, assetId, sortBy, sort, searchTerm, page, limit, filterOptions, callbackFun) => {
  return dispatch => {
  
    dispatch(fetchStart());

    axios
      .get(selfHelpContentList + siteId + '/' + assetId, {
        params: { sortBy: `${sortBy}:${sort}`, search: searchTerm, page: page + 1, limit: limit, status: filterOptions[0] },
      })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_SELFHELPCONTENT, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch({ type: GET_SELFHELPCONTENT, payload: [] });
          dispatch(fetchError());
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

// set current user for view tab
export const setCurrentSELFHELPCONTENT = user => {
  return dispatch => {
    dispatch({ type: SET_SELFHELPCONTENT_DETAILS, payload: user });
  };
};

// add new FM provider
export const addNewSelfHelpContent = (siteId, assetId, user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(selfHelpContentAdd + siteId + '/' + assetId, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('New Self help content added successfully.'));
          }, 2000);

          // dispatch({ type:  ADD_SELFHELPCONTENT, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};
export const updateSelfHelpContent = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(selfHelpContentStatus, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Self help content status updated successfully.'));
          }, 2000);

          // dispatch({ type:  ADD_SELFHELPCONTENT, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};
export const updateSELFHELPCONTENT = (id, data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(assestUpdateLoaction + id, data)
      .then(response => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Self help content updated successfully.'));
          }, 2000);
          // dispatch({ type: EDIT_USER, payload: response.data });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

// all function below are not in use currntly
export const deleteBulkUsers = (userIds, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/users/bulk-delete', { userIds })
      .then(response => {
        if (response.status === 200) {
          dispatch(fetchSuccess('Selected users were deleted successfully.'));
          dispatch({ type: DELETE_BULK_SELFHELPCONTENT, payload: userIds });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const deleteSelfhelpcontent = (Id, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete(selfHelpContentDelete+Id)
      .then(data => {
        if (data.status === 200) {
          setTimeout(() => {
            dispatch(fetchSuccess('Self help content deleted successfully.'));  
          }, 2000);
          
          // dispatch({ type: DELETE_SELFHELPCONTENT, payload: userId });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const sentMailToUser = () => {
  return dispatch => {
    dispatch(fetchSuccess('Email has been sent to user successfully'));
  };
};

export const updateUser = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/users', user)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('Selected user was updated successfully.'));
          dispatch({ type: EDIT_SELFHELPCONTENT, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};
