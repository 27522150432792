import React, { useEffect, useState } from 'react';
import { Paper, Table, TableCell, TableContainer, TableRow } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import UserListRow from './UserListRow';
import UserTableHead from './UserTableHead';
import UserTableToolbar from './UserTableToolbar';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentFMProvider } from '../../../../../redux/actions/UserManagement';
import ConfirmDialog from '@jumbo/components/Common/ConfirmDialog';
import { useDebounce } from '@jumbo/utils/commonHelper';
import useStyles from './index.style';
import UserDetailView from './UserDetailView';
import UserDetailViewPending from './UserDetailViewPending';
import UserDetailViewApproved from './UserDetailViewApproved';
import ContractorPriority from './ContractorPriority';
import NoRecordFound from './NoRecordFound';
import { getContractorVendorList } from '../../../../../redux/actions/ContractorVendorManagment';
import { getContractorDetails } from '../../../../../redux/actions/ContractorVendorManagment';

const UsersModule = ({ vendorId }) => {
  const classes = useStyles();
  const { users } = useSelector(({ CONTRACTOR }) => CONTRACTOR);
  const [orderBy, setOrderBy] = React.useState('first_name');
  const [order, setOrder] = React.useState('asc');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openViewDialogPending, setOpenViewDialogPending] = useState(false);
  const [openViewDialogApproved, setOpenViewDialogApproved] = useState(false);
  const [priorityDialog, setPriorityDialog] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState({ name: '' });
  const [usersFetched, setUsersFetched] = useState(false);
  const [isFilterApplied, setFilterApplied] = useState(false);
  const [contractorId, setContractorId] = useState('');
  const [filterOptions, setFilterOptions] = React.useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchedData, setSearchedData] = useState('debouncedSearchPagevalidation')
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const dispatch = useDispatch();
  // calling FM Provider list api when component mount , with callback function
  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm !== searchedData) {
      dispatch(
        getContractorVendorList(vendorId, 2, orderBy, order, debouncedSearchTerm, 0, rowsPerPage, filterOptions, () => {
          setFilterApplied(!!debouncedSearchTerm);
          setUsersFetched(true);
        }),
      );
      setSearchedData(debouncedSearchTerm)
      setPage(0)
    } else {
      dispatch(
        getContractorVendorList(vendorId, 2, orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions, () => {
          setFilterApplied(!!debouncedSearchTerm);
          setUsersFetched(true);
        }),
      );
    }

  }, [dispatch, filterOptions, debouncedSearchTerm, page, rowsPerPage, order, orderBy]);

  const handleCloseUserDialog = () => {
    setOpenUserDialog(false);
    dispatch(setCurrentFMProvider(null));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = users.map(n => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleRowClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUserView = user => {
    dispatch(getContractorDetails(user._id, 1, () => setOpenViewDialog(true)));
  };
  const handleUserPendingView = user => {
    dispatch(getContractorDetails(user._id, 2, () => setOpenViewDialogPending(true)));
  };
  const handleUserApprovedView = user => {
    dispatch(getContractorDetails(user._id, 2, () => setOpenViewDialogApproved(true)));
  };
  const handleSetPriorityView = user => {
    setContractorId(user);
    setPriorityDialog(true);
  }
  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
    dispatch(setCurrentFMProvider(null));
  };
  const handleCloseViewDialogPending = () => {
    setOpenViewDialogPending(false);
    dispatch(setCurrentFMProvider(null));
  };
  const handleCloseViewDialogApproved = () => {
    setOpenViewDialogApproved(false);
    dispatch(setCurrentFMProvider(null));
  };
  const handleCloseSetPriorityView = () => {
    setPriorityDialog(false);
  };
  const handleUserUpdate = user => {
    dispatch(setCurrentFMProvider(user));
    setOpenUpdateDialog(true);
  };
  const handleCloseUpdateDialog = () => {
    setOpenUpdateDialog(false);
    dispatch(setCurrentFMProvider(null));
  };

  const handleUserEdit = user => {
    dispatch(setCurrentFMProvider(user));
    setOpenUserDialog(true);
  };

  const handleUserDelete = user => {
    setSelectedUser(user);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const isSelected = id => selected.indexOf(id) !== -1;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <UserTableToolbar
          selected={selected}
          setSelected={setSelected}
          onUserAdd={setOpenUserDialog}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          setSearchedData={setSearchedData}
          setPage={setPage}
        />
        <TableContainer className={classes.container}>
          <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" aria-label="sticky enhanced table">
            <UserTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={users?.total ?? 1}
            />
            <TableBody>
              {!!users?.data?.length ? (
                users?.data?.slice(0 * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <UserListRow
                      key={index}
                      row={row}
                      onRowClick={handleRowClick}
                      onUserEdit={handleUserEdit}
                      onUserDelete={handleUserDelete}
                      onUserView={handleUserView}
                      onPendingContractorView={handleUserPendingView}
                      onApprovedContractorView={handleUserApprovedView}
                      setPriorityDialog={handleSetPriorityView}
                      onUserUpdate={handleUserUpdate}
                      isSelected={isSelected}
                      getFMProviderParams={{ vendorId, orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions }}
                    />
                  ))
              ) : (
                <TableRow style={{ height: 53 * 6 }}>
                  <TableCell colSpan={7} rowSpan={10}>
                    {isFilterApplied ? (
                      <NoRecordFound>There are no records found.</NoRecordFound>
                    ) : (
                      <NoRecordFound>There are no records found.</NoRecordFound>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={users?.total ?? 1}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Paper>

      {openViewDialog && <UserDetailView open={openViewDialog} vendorId={vendorId} onCloseDialog={handleCloseViewDialog} />}
      {openViewDialogPending && <UserDetailViewPending open={openViewDialogPending} vendorId={vendorId} onCloseDialog={handleCloseViewDialogPending} />}
      {priorityDialog && <ContractorPriority contractorId={contractorId} getContractorParams={{ vendorId, orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions }} open={priorityDialog} onCloseDialog={handleCloseSetPriorityView} />}
      {openViewDialogApproved && <UserDetailViewApproved open={openViewDialogApproved} vendorId={vendorId} onCloseDialog={handleCloseViewDialogApproved} />}
  
      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm delete ${selectedUser.name}`}
        content={'Are you sure, you want to delete this user?'}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
};

export default UsersModule;
