import React from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import useStyles from './index.style';
import { Block, CheckCircleOutline } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import ListAltIcon from '@material-ui/icons/ListAlt';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { Active, Inactive } from '@jumbo/constants/stringConstant';
const UserDetailView = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const { currentUser } = useSelector(({ INDUCTION }) => INDUCTION);
  const { doc_type, title, status, link } = currentUser;

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.userInfoRoot}>
        <Box mr={3} display="flex" alignItems="center">
          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.titleRoot}>Induction Detail</Typography>
              <Box ml={1}></Box>
            </Box>
          </Box>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}></Box>
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box px={6} py={5}>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <ListAltIcon />
          <Box ml={5} display='flex'>
          <Typography className={classes.labelField}> Title :</Typography>
            <Typography className={classes.textField}> {title}</Typography>
           
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <InsertDriveFileIcon />
           <Box ml={5} color="primary" display='flex'>
          <Typography className={classes.labelField}> Type :</Typography>
            <Typography className={classes.textField}> {doc_type === 1 ? 'Document' : 'Video'}</Typography>
           
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <InsertDriveFileIcon />
          <Box color="primary" component="p" ml={5} className={classes.labelField}>
            {doc_type === 1 ? (
              <>
                View Document:{' '}
                <Button
                  variant="contained"
                  style={{ minWidth: '100px', marginLeft: '5px' }}
                  color="primary"
                  onClick={() => window.open(link, '_self')}>
                  VIEW
                </Button>
              </>
            ) : (
              <>
                Video Link :{' '}
                <Button
                  variant="contained"
                  style={{ minWidth: '100px', marginLeft: '5px' }}
                  color="primary"
                  onClick={() => window.open(link)}>
                  VIEW
                </Button>
              </>
            )}
          </Box>
        </Box>

        <Box display="flex" alignItems="center" mt={5} mb={{ xs: 4, sm: 5 }}>
          {status === 1 ? <CheckCircleOutline /> : <Block />}
          <Box ml={5} display='flex'>
          <Typography className={classes.labelField}> Status (Active/Inactive) :</Typography>
            <Typography className={classes.textField}> {status === 1 ? Active : Inactive}</Typography>
        </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default UserDetailView;

UserDetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
