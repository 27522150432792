import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '../../../../../@jumbo/components/Common/formElements/AppTextInput';
import Button from '@material-ui/core/Button';
import { requiredMessage } from '../../../../../@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import AppSelectBox from '../../../../../@jumbo/components/Common/formElements/AppSelectBox';
import { addNewServiceActivities, getServiceActivities, updateServiceActivity } from '../../../../../redux/actions/ServiceActivities';
import { emptySpace, numberOnly, numberOnly2 } from '@jumbo/constants/ValidationRegex';
import { status } from 'nprogress';
import InputAdornment from '@material-ui/core/InputAdornment';
import { capitalizeFLetter } from '@jumbo/utils/commonHelper';
import Multiselect from 'multiselect-react-dropdown';
import Select from 'react-select';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
    '& .MuiDialog-paper': {
      // margin: '32px',
      // position: 'relative',
      overflow: 'inherit',
      // backgroundColor: '#FFFFFF',
  },
    '& .MuiDialogContent-root': {
      // flex: '1 1 auto',
      // padding: '8px 24px',
      overflow: 'inherit',
      // webkitOverflowScrolling: 'touch',
  },
  },
  root: {
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    
    "& .MuiFormLabel-root": {
      color: '#909090'
    },
    "& .MuiInputLabel-shrink":{
color:theme.palette.text.primary
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink':{
      color: theme.palette.text.primary
    },
  },
  appSelectRoot: {
    "& .MuiFormLabel-root": {
      color: '#c7c3bf'
    }
  },
  appSelectBox: {
    '& .MuiFormLabel-root': {
      color: '#c7c3bf',
    },
    "& .MuiFormLabel-root": {
      color: '#909090'
    },
    "& .MuiInputLabel-shrink":{
color:theme.palette.text.primary
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink':{
      color: theme.palette.text.primary
    },
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.text.primary,
    },
  },
  assignInventorySiteView: {
    zIndex: 99,
    minHeight: '40px',
    minWidth: '200px',
    fontSize: '15px',

    '& .chip':{
      background:`${theme.palette.primary.main} !important`
    },
    '& .highlightOption':{
      background:`${theme.palette.primary.main} !important`
    },
    '& .multiSelectContainer li':{
        '&:hover': {
          background:`${theme.palette.primary.main} !important`,
       },   
    },
    '& li':{
      fontSize:'14px',
      '&:hover': {
        background:`${theme.palette.primary.main} !important`,
     },
    },
  '& input':{
    fontSize:'16px',
    paddingLeft:'5px',
    minHeight: '28px',
  },
  '& .optionContainer':{
    border: '1px solid #ccc',
    borderRadius: '4px',
    display: 'block',
    margin: 0,
    maxHeight: '125px',
    overflowY: 'auto',
    padding: 0,
},
  },
  errText: {
    color: 'red',
    marginLeft: '14px',
    marginRight: '14px',
    fontSize: '0.75rem',
    marginTop: '3px',
    textAlign: 'left',
    fontFamily: 'Normal',
    fontWeight: 400,
    lineHeight: 1.66,
  },
}));

const AddEditUser = ({ open, onCloseDialog, getServiceActivitiesParams }) => {
  const classes = useStyles();
  const { users } = useSelector(({ SERVICETYPE }) => SERVICETYPE);
  const { currentUser } = useSelector(({ SERVICEACTIVITIES }) => SERVICEACTIVITIES);
  const { orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions } = getServiceActivitiesParams;
  const [serviceTypeValueError, setServiceTypeValueError] = useState('');
  const [description, setDescription] = useState('');
  const [charges, setCharges] = useState('');
  const [status, setStatus] = useState(1);
  const [descriptionError, setDescriptionError] = useState('');
  const [chargesError, setChargesError] = useState('');
  // const [serviceTypeValue, setServiceTypeValue] = useState('');
  const [serviceTypeValue, setServiceTypeValue] = useState('');
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const dispatch = useDispatch();
  const p_color=window.localStorage.getItem('p_c') ?? '#FC8119'
  const s_color=window.localStorage.getItem('s_c') ?? '#F0511E'
  const t_color=window.localStorage.getItem('t_c') ?? '#3B1A45'
  console.log("serviceTypeValue",serviceTypeValue);

  const options = users?.data?.map(item=>{
    return{ value:item?._id, label:item?.name}
  })

  const selectServiceType = (data) =>{
   const store =  data?.map(item=>{
    setServiceTypeValue(item) 
      // setServiceTypeValue({_id:item?._id, name:item?.name}) 
    })
  }

  useEffect(() => {
    if (currentUser) {
      setServiceTypeValue(currentUser?.service_type_id);
      setCharges(currentUser?.fm_service_activity_charge?currentUser?.fm_service_activity_charge:'');
      setDescription(currentUser?.name);
      setStatus(currentUser?.fm_service_activity_status);
      
    }
  }, [currentUser]);

  const onSubmitClick = () => {
    if (!serviceTypeValue[0]) {
      setServiceTypeValueError(requiredMessage);
    }
    if (description.match(emptySpace)) {
      setDescriptionError(requiredMessage);
    }
    if (!charges.toString().match(numberOnly2) || Number(charges) < 0) {
      setChargesError('Enter positive number only.');
    }

    if (!charges) {
      setChargesError(requiredMessage);
    }
    if (charges && Number(charges) < 1) {
      setChargesError('Charges value should not be 0 .');
    }
    if (!description.match(emptySpace) && serviceTypeValue[0] && charges && charges > 0) {
      onUserSave();
    }
  };

  const onUserSave = () => {
    const descriptionCaps = capitalizeFLetter(description);
    const serviceActivityData = {
      name: descriptionCaps,
      charge: charges,
      status: status,
      service_type_id: serviceTypeValue,
    };

    if (currentUser) {
      dispatch(
        updateServiceActivity({ ...serviceActivityData }, currentUser?._id, () => {
          onCloseDialog();
          dispatch(getServiceActivities(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
        }),
      );
    } else {
      dispatch(
        addNewServiceActivities(serviceActivityData, () => {
          onCloseDialog();
          dispatch(getServiceActivities(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
        }),
      );
    }
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>
        {currentUser ? 'Edit Service Activity Details' : 'Add New Service Activity'}
      </DialogTitle>
      <DialogContent dividers>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
          <GridContainer>
            <Grid item xs={12} sm={12}>
            {/* className={currentUser?.is_vcg_creation ? classes.appSelectRoot : classes.root} */}
                      <Select
                      className={classes.assignInventorySiteView}
                      placeholder="Service Type"
                      isDisabled={currentUser?.is_vcg_creation ? true : false}
                      value={users?.data?.map(item=>{
                        return{ value:item?._id, label:item?.name}
                      }).filter(obj => obj?.value === serviceTypeValue)}
                      onChange={e=>{setServiceTypeValue(e?.value ?? ''); setServiceTypeValueError('')}}
                      isClearable={isClearable}
                      isSearchable={isSearchable}
                      options={users?.data?.map(item=>{
                        return{ value:item?._id, label:item?.name}
                      })}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                        ...theme.colors,
                          text: t_color,
                          // primary25: s_color',
                          primary: p_color,
                        },
                      })}
                    />
                  <p className={classes.errText} style={{ color: 'red' }}>{serviceTypeValueError}</p>
                  </Grid>

                  {/* <Multiselect
                  options={users?.data} // Options to display in the dropdown
                  selectedValues={serviceTypeValue} // Preselected value to persist in dropdown
                  selectionLimit={1}
                  onSelect={(selectedList, selectedItem)=>{setServiceTypeValue(selectedList); setServiceTypeValueError("")}}
                  onRemove={(selectedList, selectedItem)=>setServiceTypeValue(selectedList)} // Function will trigger on remove event
                  displayValue="name" // Property name to display in the dropdown options
                  placeholder="Service Type"
                  disablePreSelectedValues={currentUser?.is_vcg_creation ? true : false}
                  className={classes.assignInventorySiteView}
                />
                <p className={classes.errText} style={{ color: 'red' }}>{serviceTypeValueError}</p> */}
              
              {/* <AppSelectBox
                // className={classes.root}
                fullWidth
                data={users?.data}
                label="Service Type"
                valueKey="_id"
                variant="outlined"
                labelKey="name"
                value={serviceTypeValue}
                disabled={currentUser?.is_vcg_creation ? true : false}
                onChange={e => {
                  setServiceTypeValue(e.target.value);
                  setServiceTypeValueError('');
                }}
                helperText={serviceTypeValueError}
              /> */}

            <Grid item xs={12} sm={12} className={currentUser?.is_vcg_creation?classes.appSelectRoot:classes.root}>
              <AppTextInput
                className={currentUser?.is_vcg_creation?classes.appSelectRoot:classes.root}
                fullWidth
                variant="outlined"
                label="Description"
                value={description}
                disabled={currentUser?.is_vcg_creation ? true : false}
                onChange={e => {
                  setDescription(e.target.value);
                  setDescriptionError('');
                }}
                helperText={descriptionError}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <AppTextInput
                fullWidth
                className={classes.root}
                InputProps={{
                  inputProps: { min: 0 },
                  startAdornment:charges? <InputAdornment position="start">$</InputAdornment>:'',
                }}
                variant="outlined"
                label="Charges"
                value={charges}
                onKeyPress={event => {
                  if (['-', '+', 'e', '.','/','*'].includes(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={e => {
                  setCharges(e.target.value);
                  setChargesError('');
                }}
                helperText={chargesError}
              />
            </Grid>
          </GridContainer>
        </Box>

        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button variant="outlined" onClick={onCloseDialog}>
            Cancel
          </Button>

          {currentUser ? (
            <Box ml={2}>
              <Button variant="contained" color="primary" onClick={onSubmitClick}>
                Update
              </Button>
            </Box>
          ) : (
            <Box ml={2}>
              <Button variant="contained" color="primary" style={{ minWidth: '100px' }} onClick={onSubmitClick}>
                Add
              </Button>
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditUser;

AddEditUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
