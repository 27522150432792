import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';
import {
  DELETE_BULK_TENANT,
  DELETE_TENANT,
  EDIT_TENANT,
  GET_TENANT,
  SET_TENANT_DETAILS,
} from '../../@jumbo/constants/ActionTypes';
import {  tenantAdd, tenantDelete, tenantList, tenantStatus, tenantUpdate } from '../../@jumbo/constants/ApiConstatnt';
import { setAuthUser } from './Auth';
import { history } from 'redux/store';


export const getTenantList = (id,sortBy, sort, searchTerm, page, limit, filterOptions, callbackFun) => {
  return dispatch => {
   
    dispatch(fetchStart());

    axios
      .get(tenantList+id, {
        params: { sortBy: `${sortBy}:${sort}`, search: searchTerm, page: page + 1, limit: limit,status: filterOptions[0] },
      })
      .then(data => {
        
       
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_TENANT, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch({ type: GET_TENANT, payload: [] });
          dispatch(fetchError());
        
        }
      })
      .catch(error => {
        if(error?.response?.status===401 ){
          dispatch(setAuthUser(null));
          history.push('/signin')
        } 
        else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });

  };
};

// set current user for view tab
export const setCurrentTENANT = user => {
  return dispatch => {
    dispatch({ type: SET_TENANT_DETAILS, payload: user });
  };
};

// add new FM provider 
export const addNewTenant = (id,user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(tenantAdd+id, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('New Tenant added successfully.'));
          }, 2000);

          // dispatch({ type:  ADD_TENANT, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if(error?.response?.status===401 ){
          dispatch(setAuthUser(null));
          history.push('/signin')
        } else{
  
        
          dispatch(fetchError(error?.response?.data?.message));}
      });

  };
};
export const updateTenantStatus = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(tenantStatus, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Tenant status updated successfully.'));
          }, 2000);

          // dispatch({ type:  ADD_TENANT, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if(error?.response?.status===401 ){
          dispatch(setAuthUser(null));
          history.push('/signin')
        } else{
  
        
          dispatch(fetchError(error?.response?.data?.message));}
      });

  };
};
export const updateTenant= (id,data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(tenantUpdate+id, data)
      .then(response => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Tenant updated successfully.'));
          }, 2000);
          // dispatch({ type: EDIT_USER, payload: response.data });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if(error?.response?.status===401 ){
          dispatch(setAuthUser(null));
          history.push('/signin')
        } else{
  
        
          dispatch(fetchError(error?.response?.data?.message));}
      });

  };
};

// all function below are not in use currntly
export const deleteBulkUsers = (userIds, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/users/bulk-delete', { userIds })
      .then(response => {
        if (response.status === 200) {
          dispatch(fetchSuccess('Selected users were deleted successfully.'));
          dispatch({ type: DELETE_BULK_TENANT, payload: userIds });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const deleteTenant = (Id, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete(tenantDelete+Id)
      .then(data => {
        if (data.status === 200) {
          setTimeout(() => {
            dispatch(fetchSuccess('Tenant deleted successfully.'));   
          }, 2000);
         
          // dispatch({ type: DELETE_TENANT, payload: userId });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const sentMailToUser = () => {
  return dispatch => {
    dispatch(fetchSuccess('Email has been sent to user successfully'));
  };
};

export const updateUser = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/users', user)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('Selected user was updated successfully.'));
          dispatch({ type: EDIT_TENANT, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};
