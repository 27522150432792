import React from 'react';
import { Area, AreaChart, ResponsiveContainer, Tooltip } from 'recharts';
import { statisticsGraphData } from '@fake-db';

const CardGraph = () => {
  return (
    <ResponsiveContainer className="card-img-bottom overflow-hidden" width="100%" height={95}>
      <AreaChart data={statisticsGraphData} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
        <defs>
          <linearGradient id="color3" x1="0" y1="0" x2="1" y2="0">
            <stop offset="5%" stopColor="#3f51b5" stopOpacity={1} />
            <stop offset="95%" stopColor="#1fb6fc" stopOpacity={1} />
          </linearGradient>
        </defs>
        <Area dataKey="price" strokeWidth={2} stroke="#8D59D8" fill="#7538CC" fillOpacity={1} />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default CardGraph;
