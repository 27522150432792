import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import axios from 'services/auth/jwt/config';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import { history } from 'redux/store';
import { setAuthUser } from 'redux/actions/Auth';
import UserDetailView from 'routes/modules/UserManagement/UserDetailView';
import { setCurrentFMProvider } from 'redux/actions/UserManagement';
import { capitalizeFLetter, logsData } from '@jumbo/utils/commonHelper';
const useStyles = makeStyles(theme=>({
  table: {
    minWidth: 650,
    "& .MuiTableCell-head": {
      fontWeight:600,
      backgroundColor:theme.palette.primary.main,
        color:"white"

  },
  },
  tableRow: {
    "& .MuiTableCell-root":{
    fontWeight:300,
    }
  },
}));

export default function UserTable() {
  const classes = useStyles();
  const { authUser } = useSelector(({ auth }) => auth);
  const { details } = useSelector(({ ITEMSLISTMANAGEMENT }) => ITEMSLISTMANAGEMENT);
  const [openViewDialog, setOpenViewDialog] = useState(false);

  const dispatch = useDispatch();
  const wordRegex = /[A-Z]?[a-z]+|[0-9]+|[A-Z]+(?![a-z])/g;
  async function fetchData(fmUserId) {
    let response = await axios(`/fm-provider/user-management/fm-user-details/${fmUserId}`)
      .then(response => dispatch(setCurrentFMProvider(response?.data?.data)))
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
    let user = await response?.data;

    setOpenViewDialog(true);
    dispatch(fetchSuccess());
  }
  const handleUserDetail = row => {
    if (authUser?.fm_module_access[0]?.is_view) {
      dispatch(fetchStart());
      fetchData(row.user_id);
    }
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
    dispatch(setCurrentFMProvider(null));
  };
  return (
    
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Logs</TableCell>
            <TableCell>Date-Time</TableCell>
            <TableCell>Done by</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {details?.logs?.map((row, index) => (
            <TableRow key={index} className={classes.tableRow}>
              <TableCell component="th" scope="row" >
                {row?.field_name == 'status'
                  ? `'${capitalizeFLetter(row?.field_name)}' updated  `
                  : row?.field_name
                  ? row?.field_name.split(',').length > 1
                    ? `'${logsData(row?.field_name)}' fields changed`
                    : `'${capitalizeFLetter(
                        row?.field_name == 'name' ? 'Parts' : row?.field_name == 'charge' ? 'Price' : row?.field_name,
                      )}' field changed`
                  : 'Part Created'}
              </TableCell>
              <TableCell>{moment(row?.created_at * 1000).format('hh:mm A DD/MM/YYYY')}</TableCell>
              <TableCell
                onClick={e => handleUserDetail(row)}
                className="pointer"
                aria-readonly={authUser?.fm_module_access[0]?.is_view}>
                <u>
                  {row?.first_name} {row?.last_name}
                </u>
              </TableCell>
            </TableRow>
            
          ))}
        </TableBody>
      </Table>
      {openViewDialog && <UserDetailView open={openViewDialog} onCloseDialog={handleCloseViewDialog} />}
    </TableContainer>
  );
}
