import {
    GET_THRESHOLD_AMOUNT,
    SET_THRESHOLD_AMOUNT,
  } from '../../@jumbo/constants/ActionTypes';
  
  const INIT_STATE = {
    users: [],
    currentUser: null,
  };

  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_THRESHOLD_AMOUNT: {
        return {
          ...state,
          users: action.payload,
        };
      }
      case SET_THRESHOLD_AMOUNT: {
        return {
          ...state,
          currentUser: action.payload,
        };
      }
      default:
        return state;
    }
  };