import React from 'react';

function FMAdminDemo() {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50vh' }}>
      <h1>Coming Soon...</h1>
    </div>
  );
}

export default FMAdminDemo;
