import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';
import { GET_VENDORS, SET_VENDORS_DETAILS } from '../../@jumbo/constants/ActionTypes';
import { AccountUserVendorDetails, AccountUserVendorList, AccountUserVendorUpdate } from '../../@jumbo/constants/ApiConstatnt';
import { setAuthUser } from './Auth';
import { history } from 'redux/store';
import JWTAuth from 'services/auth/jwt';

// get account vendor list
export const getAccountUserVendor = (sortBy, sort, searchTerm, page, limit, filterOptions, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(AccountUserVendorList, {
        params: { sortBy: `${sortBy}:${sort}`, search: searchTerm, page: page + 1, limit: limit, status: filterOptions[0] },
      })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_VENDORS, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch({ type: GET_VENDORS, payload: [] });
          dispatch(fetchError());
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

// set current user for view tab
export const setCurrentVendors = user => {
  return dispatch => {
    dispatch({ type: SET_VENDORS_DETAILS, payload: user });
  };
};
export const getAccountUserDetail = (id, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(AccountUserVendorDetails+id)
      .then(data => {
        if (data) {
          dispatch({ type: SET_VENDORS_DETAILS, payload: data.data.data });
          dispatch(fetchSuccess());
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

export const updateAccountUserVendor = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(AccountUserVendorUpdate, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Vendor status updated successfully.'));
          }, 2000);
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};
