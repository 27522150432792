import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Block, CheckCircleOutline, Delete, Edit } from '@material-ui/icons';
import CmtDropdownMenu from '../../../../../@coremat/CmtDropdownMenu';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { sentMailToUser } from '../../../../../redux/actions/Users';
import { getSERVICETYPE, updateSERVICETYPEStatus } from '../../../../../redux/actions/ServiceType';
import { Activate, Deactivate } from '../../../../../@jumbo/constants/stringConstant';
const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    maxWidth:'150px',
    display: 'block',
    overflow: 'hidden',
    fontWeight:"300",
    whiteSpace: 'nowrap',
  },
  textSize:{
    fontWeight:300
  }
}));



const UserListRow = ({ row, isSelected, onRowClick, onUserEdit, onUserDelete, onUserView, getSERVICETYPEParams ,indexId}) => {
  const { orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions } = getSERVICETYPEParams;
  const { authUser } = useSelector(({ auth }) => auth);
  const classes = useStyles();
  const dispatch = useDispatch();
  const getUserActions = user => {
    const actions = [ ];
   
    if (user.fm_service_type_status === 1&& authUser?.fm_module_access[1]?.is_enable) {
      actions.push({ action: 'suspend', label: Deactivate, icon: <Block /> });
    } else if (authUser?.fm_module_access[1]?.is_enable){
      actions.push({
        action: 'activate',
        label: Activate,
        icon: <CheckCircleOutline />,
      });
    }
    // actions.push({ action: 'delete', label: 'Delete', icon: <Delete /> });
    return actions;
  };
  const onUserMenuClick = menu => {
    if (menu.action === 'view') {
      onUserView(row);
    } else if (menu.action === 'edit') {
      onUserEdit(row);
    } else if (menu.action === 'email') {
      dispatch(sentMailToUser());
    } else if (menu.action === 'suspend') {
      dispatch(updateSERVICETYPEStatus({ _id: row._id, status: 2 }, refreshContactList));
    } else if (menu.action === 'activate') {
      dispatch(updateSERVICETYPEStatus({ _id: row._id, status: 1 }, refreshContactList));
    } else if (menu.action === 'delete') {
      onUserDelete(row);
    }
  };

  function refreshContactList() {
    dispatch(getSERVICETYPE(orderBy, order, filterOptions,debouncedSearchTerm, page, rowsPerPage));
  }
  const labelId = `enhanced-table-checkbox-${row?._id}`;
  const isItemSelected = isSelected(row?._id);
  const userActions = getUserActions(row);

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row?._id}
      selected={isItemSelected}
      >
      <TableCell padding="checkbox">
      
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Typography className={classes.titleRoot} component="div" variant="h4">
        {indexId+1}
        </Typography>
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Box display="flex" alignItems="center">
          
          <div>
            <Typography className={classes.titleRoot} component="div" variant="h4">
              {row?.name}
            </Typography>
          </div>
        </Box>
      </TableCell>
      <TableCell className={classes.textSize}>{row?.fm_service_type_status === 1 ? `Active` : 'Inactive'}</TableCell>
      <TableCell align="center" onClick={event => event.stopPropagation()} >
        {authUser?.fm_module_access[1]?.is_enable?<CmtDropdownMenu items={userActions} onItemClick={onUserMenuClick} TriggerComponent={<Edit />} disabled={!authUser?.fm_module_access[1]?.is_enable}/>:<Edit />}
      </TableCell>
    </TableRow>
  );
};

export default React.memo(UserListRow);
