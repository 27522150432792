import React from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import moment from 'moment';
import TodayIcon from '@material-ui/icons/Today';
import ScheduleIcon from '@material-ui/icons/Schedule';
import BusinessIcon from '@material-ui/icons/Business';
import useStyles from './index.style';
import { Block, CheckCircleOutline } from '@material-ui/icons';
import BuildIcon from '@material-ui/icons/Build';
const UserDetailView = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const { details } = useSelector(({ PLANNEDMAINTENANCE }) => PLANNEDMAINTENANCE);
  return (
    <>
      <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
        <Box className={classes.userInfoRoot}>
          <Box mr={3} display="flex" alignItems="center">
            <Box mt={-2}>
              <Box display="flex" alignItems="center">
                <Typography className={classes.titleRoot}>{'Planned Maintenance Service Details'}</Typography>
                <Box ml={1}></Box>
              </Box>
            </Box>
          </Box>
          <Box ml="auto" mt={-2} display="flex" alignItems="center">
            <Box ml={1}></Box>
            <Box ml={1}>
              <IconButton onClick={onCloseDialog}>
                <ClearIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <Box px={6} py={5}>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
            <BusinessIcon />
            <Box ml={5}  display="flex">
            <Typography className={classes.titleRoot1}>Job Location :</Typography>
            <Typography className={classes.titleRoot2}>{details?.site_name}</Typography>
               
            </Box>{' '}
          </Box>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
            <BuildIcon />
            <Box ml={5} color="primary"  display="flex">
            <Typography className={classes.titleRoot1}>Service Type :</Typography>
            <Typography className={classes.titleRoot2}>{details?.service_type_name}</Typography>
               
            </Box>
          </Box>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
            <BuildIcon />
            <Box ml={5} color="primary"  display="flex">
            <Typography className={classes.titleRoot1}>Asset :</Typography>
            <Typography className={classes.titleRoot2}>{details?.asset_name}</Typography>
               
            </Box>
          </Box>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
            <ScheduleIcon />
            <Box ml={5} color="primary"  display="flex">

            <Typography className={classes.titleRoot1}>Duration : </Typography>
            <Typography className={classes.titleRoot2}>{details?.duration === 1 ? 'Every month' : `Every ${details?.duration} months`}</Typography>
              
            </Box>
          </Box>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
            <TodayIcon />
            <Box ml={5} color="primary"  display="flex">
            <Typography className={classes.titleRoot1}>Start Date :</Typography>
            <Typography className={classes.titleRoot2}>{moment(details?.start_date * 1000).format('DD/MM/YYYY')}</Typography>
               
            </Box>
          </Box>

          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
            <TodayIcon />
            <Box ml={5} color="primary"  display="flex">
            <Typography className={classes.titleRoot1}>Installation Date :</Typography>
            <Typography className={classes.titleRoot2}>{moment(details?.installation_date * 1000).format('DD/MM/YYYY')}</Typography>
               
            </Box>
          </Box>

          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
            <TodayIcon />
            <Box ml={5} color="primary"  display="flex">
            <Typography className={classes.titleRoot1}>Next Service Date :</Typography>
            <Typography className={classes.titleRoot2}>{moment(details?.next_service_date * 1000).format('DD/MM/YYYY')}</Typography>
               
            </Box>
          </Box>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }}>
            {details?.status === 1 ? <CheckCircleOutline /> : <Block />}

            <Box ml={5} display="flex">
            <Typography className={classes.titleRoot1}>Status (Active/Inactive):</Typography>
            <Typography className={classes.titleRoot2}>{details?.status === 1 ? 'Active' : 'Inactive'}</Typography>
             </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default UserDetailView;

UserDetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
