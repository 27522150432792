import React from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import useStyles from './index.style';
import { Block, CheckCircleOutline } from '@material-ui/icons';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import BuildIcon from '@material-ui/icons/Build';

const UserDetailView = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const { currentUser } = useSelector(({ ISSUEMANAGEMENT }) => ISSUEMANAGEMENT);
  const { name, status, fm_issue_status, service_type_name } = currentUser;
  
  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.userInfoRoot}>
        <Box mr={3} display="flex" alignItems="center">
        <Box mt={-2}>
            <Box display="flex" alignItems="center">
        <Typography className={classes.titleRoot}>Issue Detail</Typography>
            </Box>
          </Box>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}></Box>
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}><ClearIcon /></IconButton>
          </Box>
        </Box>
      </Box>
      <Box px={6} py={5}>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <BuildIcon />
          <Box ml={5} color="primary" component="p" className="pointer" style={{
fontSize: 14,
letterSpacing: 0.25,
textOverflow: 'ellipsis',
maxWidth:'350px',
display: 'block',
overflow: 'hidden',
whiteSpace: 'nowrap',}}>
            Service Type : {service_type_name}
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <MonetizationOnIcon />
          <Box ml={5} color="primary" component="p" className="pointer" style={{
fontSize: 14,
letterSpacing: 0.25,

textOverflow: 'ellipsis',
maxWidth:'350px',
display: 'block',
overflow: 'hidden',
whiteSpace: 'nowrap',}}>
            Issue : {name}
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }}>
          {fm_issue_status ===1 ? <CheckCircleOutline /> : <Block />}
          <Box ml={5}>Status (Active/Inactive) : {fm_issue_status===1 ? 'Active' : 'Inactive'}</Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default UserDetailView;

UserDetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};