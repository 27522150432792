import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Block, CheckCircleOutline, Visibility, Edit } from "@material-ui/icons";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { sentMailToUser } from "../../../../../../../redux/actions/Users";
import { updateFMProviderStatus } from "../../../../../../../redux/actions/UserManagement";
import { getVendorManagement } from "../../../../../../../redux/actions/VendorManagement";
import { Activate, Deactivate } from "@jumbo/constants/stringConstant";
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    color: theme.palette.common.dark,
    letterSpacing: 0.25,
    textOverflow: "ellipsis",
    maxWidth: "150px",
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontWeight: 300,
  },
  
  tableCell: {
    fontWeight: 300,
  }
}));

const getUserActions = (user) => {
  const actions = [{ action: "view", label: "View", icon: <Visibility /> }, { action: "edit", label: "Edit", icon: < Edit /> }];

  if (user.status === 1) {
    actions.push({ action: "suspend", label: Deactivate, icon: <Block /> });
  } else {
    actions.push({
      action: "activate",
      label: Activate,
      icon: <CheckCircleOutline />,
    });
  }

  return actions;
};

const UserListRow = ({
  row,
  isSelected,
  onRowClick,
  onUserEdit,
  onUserDelete,
  onUserView,
  getFMProviderParams,
  onUserUpdate
}) => {
  const {
    orderBy,
    order,
    debouncedSearchTerm,
    page,
    rowsPerPage,
    filterOptions,
  } = getFMProviderParams;

  const classes = useStyles();
  const dispatch = useDispatch();

  function refreshContactList() {
    dispatch(
      getVendorManagement(
        orderBy,
        order,
        debouncedSearchTerm,
        page,
        rowsPerPage,
        filterOptions
      )
    );
  }
  const labelId = `enhanced-table-checkbox-${row._id}`;

  const userActions = getUserActions(row);

  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
      <TableCell component="th" id={labelId} scope="row">
        <Typography component="div" className={classes.titleRoot}>
          {row?.title}
        </Typography>
      </TableCell>
      <TableCell component="th" id={labelId} scope="row">
        <Typography component="div" className={classes.titleRoot}>
          {row?.doc_type == 1 ? "Document" : "Video"}
        </Typography>
      </TableCell>
      <TableCell component="th" id={labelId} scope="row">
        <Typography component="div" className={classes.tableCell}>
          {moment(row?.created_at * 1000).format('hh:mm A DD/MM/YYYY')}
        </Typography>
      </TableCell>
      <TableCell component="th" id={labelId} scope="row">
        <Typography component="div" className={classes.tableCell}>
          {moment(row?.acknowledgement_provided_on * 1000).format('hh:mm A DD/MM/YYYY')}
        </Typography>
      </TableCell>
      <TableCell component="th" id={labelId} scope="row">
        <Typography component="div" className={classes.titleRoot}>
          {row?.site_name}
        </Typography>
      </TableCell>
      <TableCell align="center" style={{ cursor: 'pointer' }} onClick={() => onUserView(row)}>
        <Visibility />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(UserListRow);
