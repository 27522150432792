import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Delete, Edit, Visibility } from '@material-ui/icons';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { sentMailToUser } from '../../../../../../../redux/actions/Users';
import { updateItemListStatus } from '../../../../../../../redux/actions/ItemsList';
import { deleteItemLocation, getItemLocation } from '../../../../../../../redux/actions/LocationsItems';
import { useParams } from 'react-router';

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    maxWidth: '150px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontWeight: 300,
  },
}));

const UserListRow = ({ row, isSelected, onRowClick, onUserEdit, onUserDelete, onUserView, getSERVICETYPEParams, indexId }) => {
  const { orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions } = getSERVICETYPEParams;
  const { authUser } = useSelector(({ auth }) => auth);
  let { id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  
  const getUserActions = user => {
    const actions = authUser?.fm_module_access[4]?.is_edit ? [
      { action: 'view', label: 'View', icon: <Visibility /> },
      { action: 'edit', label: 'Edit', icon: <Edit /> },
      { action: 'remove', label: 'Remove', icon: <Delete /> }
    ] : []
    return actions;
  };

  const onUserMenuClick = menu => {
    if (menu.action === 'view') {
      onUserView(row);
    } else if (menu.action === 'edit') {
      onUserEdit(row);
    } else if (menu.action === 'email') {
      dispatch(sentMailToUser());
    } else if (menu.action === 'suspend') {
      dispatch(updateItemListStatus({ _id: row._id, status: 2 }, refreshContactList));
    } else if (menu.action === 'activate') {
      dispatch(updateItemListStatus({ _id: row._id, status: 1 }, refreshContactList));
    } else if (menu.action === 'remove') {
      dispatch(deleteItemLocation(row.item_id, id, refreshContactList));
    }
  };

  function refreshContactList() {
    dispatch(getItemLocation(id, orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
  }
  
  const labelId = `enhanced-table-checkbox-${row?._id}`;
  const isItemSelected = isSelected(row?._id);
  const userActions = getUserActions(row);

  return (
    <TableRow hover role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={row?._id} selected={isItemSelected}>
      <TableCell padding="checkbox"></TableCell>
      <TableCell>
        <Typography className={classes.titleRoot} component="div" variant="h4">
        {Number(indexId) + 1}
        </Typography>
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {row?.name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.titleRoot} component="div" variant="h4">
        {row?.remaining_quantity}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.titleRoot} component="div" variant="h4">
        {row?.threshold ? row?.threshold : "-"}
        </Typography>
      </TableCell>
      <TableCell align="center" onClick={event => event.stopPropagation()}>
        {authUser?.fm_module_access[4]?.is_edit ? <CmtDropdownMenu items={userActions} onItemClick={onUserMenuClick} TriggerComponent={<Edit />} disabled={!authUser?.fm_module_access[4]?.is_edit} /> : <Edit />}
      </TableCell>
    </TableRow>
  );
};

export default React.memo(UserListRow);
