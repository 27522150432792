import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';
import {
  DELETE_BULK_FMPROVIDER,
  DELETE_FMPROVIDER,
  EDIT_FMPROVIDER,
  GET_ALLASSIGNUSERLIST,
  GET_FMPROVIDER,
  SET_FMPROVIDER_DETAILS,
} from '../../@jumbo/constants/ActionTypes';
import { addUser, updateFMProvider, updateUserManagement, userDelete, userList } from '../../@jumbo/constants/ApiConstatnt';
import { setAuthUser } from './Auth';
import { history } from 'redux/store';
import JWTAuth from 'services/auth/jwt';

// get FM provider list
export const getUserList = (sortBy, sort, searchTerm, page, limit, filterOptions, callbackFun, user_type, allFmUser) => {
  return dispatch => {
    // dispatch(JWTAuth.getAuthUser(true));
    dispatch(fetchStart());

    axios
      .get(userList, {
        params: {
          sortBy: `${sortBy}:${sort}`,
          search: searchTerm,
          page: page + 1,
          limit: limit,
          status: filterOptions[0],
          user_type: user_type,
          allFmUser: allFmUser,
        },
      })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_FMPROVIDER, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch({ type: GET_FMPROVIDER, payload: [] });
          dispatch(fetchError());
          if (callbackFun) callbackFun();
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

// set current user for view tab
export const setCurrentFMProvider = user => {
  return dispatch => {
    dispatch({ type: SET_FMPROVIDER_DETAILS, payload: user });
  };
};

// add new FM provider
export const addNewUser = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(addUser, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('New User added successfully.'));
          }, 2000);

          // dispatch({ type:  ADD_FMPROVIDER, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};
export const updateUserData = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(updateUserManagement, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('User updated successfully.'));
          }, 2000);

          // dispatch({ type:  ADD_FMPROVIDER, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};
export const updateFMProviderStatus = (data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(updateFMProvider, data)
      .then(response => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('User status updated successfully.'));
          }, 2000);
          // dispatch({ type: EDIT_USER, payload: response.data });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

// all function below are not in use currntly
export const deleteBulkUsers = (userIds, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/users/bulk-delete', { userIds })
      .then(response => {
        if (response.status === 200) {
          dispatch(fetchSuccess('Selected users were deleted successfully.'));
          dispatch({ type: DELETE_BULK_FMPROVIDER, payload: userIds });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const deleteFMProviderUser = (Id, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete(userDelete+Id)
      .then(data => {
        if (data.status === 200) {
          setTimeout(() => {
            dispatch(fetchSuccess('User deleted successfully.'));   
          }, 2000);
         
          // dispatch({ type: DELETE_FMPROVIDER, payload: userId });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const sentMailToUser = () => {
  return dispatch => {
    dispatch(fetchSuccess('Email has been sent to user successfully'));
  };
};

export const updateUser = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/users', user)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('Selected user was updated successfully.'));
          dispatch({ type: EDIT_FMPROVIDER, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};
// AssignUserList
export const getUserListAssignUser = (
  sortBy,
  sort,
  searchTerm,
  page,
  limit,
  filterOptions,
  callbackFun,
  user_type,
  allFmUser,
) => {
  return dispatch => {
    dispatch(fetchStart());

    axios
      .get(userList, {
        params: {
          sortBy: `${sortBy}:${sort}`,
          search: searchTerm,
          page: page + 1,
          limit: limit,
          status: filterOptions[0],
          user_type: user_type,
          allFmUser: allFmUser,
        },
      })
      .then(data => {
        if (data.status === 200) {
          if (user_type == 4) {
            const user = data?.data?.data?.data?.filter(item => item.user_type === 4 || item.user_type === 3);
            dispatch({ type: GET_ALLASSIGNUSERLIST, payload: user });
          } else {
            const user = data?.data?.data?.data?.filter(item => item.user_type === 5);
            dispatch({ type: GET_ALLASSIGNUSERLIST, payload: user });
          }

          dispatch(fetchSuccess());
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch({ type: GET_FMPROVIDER, payload: [] });
          dispatch(fetchError());
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'FM users not found.') {
          if (callbackFun) callbackFun();
          dispatch(fetchError());
          dispatch({ type: GET_FMPROVIDER, payload: [] });
        } else if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};
