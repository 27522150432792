import {
  ADD_SERVICEREQUEST,
  DELETE_BULK_SERVICEREQUEST,
  DELETE_SERVICEREQUEST,
  EDIT_SERVICEREQUEST,
  GET_SERVICEREQUEST,
  SET_SERVICEREQUEST_DETAILS,
  GET_SERVICEREQUESTDETAILS,
  GET_SERVICEREQUESTADD,
  GET_SERVICEREQUESTQUOTATIONLIST,GET_SERVICEREQUESTQUOTATIOCHECKNLIST,
  GET_TENANT_LIST
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  users: [],
  currentUser: null,
  details:null,
  addUser:null,
  quotationList:null,
  checkQuotationList:null,
  tenant:[]

};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SERVICEREQUEST: {
      return {
        ...state,
        users: action.payload,
      };
    }

    case GET_TENANT_LIST: {
      return {
        ...state,
        tenant: action.payload,
      };
    }


    case GET_SERVICEREQUESTADD: {
      return {
        ...state,
        addUser: action.payload,
      };
    }



    case GET_SERVICEREQUESTDETAILS: {
      return {
        ...state,
        details: action.payload,
      };
    }

    case GET_SERVICEREQUESTQUOTATIONLIST: {
      return {
        ...state,
        quotationList: action.payload,
      };
    }


    case GET_SERVICEREQUESTQUOTATIOCHECKNLIST: {
      return {
        ...state,
        checkQuotationList: action.payload,
      };
    }

    case SET_SERVICEREQUEST_DETAILS: {
      return {
        ...state,
        currentUser: action.payload,
      };
    }
    case ADD_SERVICEREQUEST: {
      return {
        ...state,
        users: [action.payload, ...state.users],
      };
    }
    case EDIT_SERVICEREQUEST: {
      return {
        ...state,
        users: state.users.map(user => (user.id === action.payload.id ? action.payload : user)),
      };
    }
    case DELETE_SERVICEREQUEST: {
      return {
        ...state,
        users: state.users.filter(user => user.id !== action.payload),
      };
    }
    case DELETE_BULK_SERVICEREQUEST: {
      return {
        ...state,
        users: state.users.filter(user => !action.payload.includes(user.id)),
      };
    }
    default:
      return state;
  }
};
