import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';
import {
  DELETE_BULK_SERVICETYPE,
  DELETE_SERVICETYPE,
  EDIT_SERVICETYPE,
  GET_SERVICETYPE,
  SET_SERVICETYPE_DETAILS,
} from '../../@jumbo/constants/ActionTypes';
import {listServiceType , addServiceType,updateServiceTypeStatus} from './../../@jumbo/constants/ApiConstatnt'




export const getSERVICETYPE = (sortBy, sort, filterOptions, searchTerm, page, limit, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(listServiceType, {
        params: { sortBy: `${sortBy}:${sort}`, search: searchTerm, page: page + 1, limit: limit, status: filterOptions[0] },
      })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          console.log("data",data.data.data)
          dispatch({ type: GET_SERVICETYPE, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError());
          dispatch({ type: GET_SERVICETYPE, payload: [] }); 
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'FM Provider not found.') {
          dispatch(fetchError(error?.response?.data?.message));
          dispatch({ type: GET_SERVICETYPE, payload: [] });
        } else {
          dispatch(fetchError());
        }
      });
  };
};

export const setCurrentSERVICETYPE = user => {
  return dispatch => {
    dispatch({ type: SET_SERVICETYPE_DETAILS, payload: user });
  };
};

export const addNewSERVICETYPE = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(addServiceType, user)
      .then(data => {
        console.log('add data', data);
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('New Service type added successfully.'));
          }, 2000);
if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const sentMailToUser = () => {
  return dispatch => {
    dispatch(fetchSuccess('Email has been sent to user successfully'));
  };
};

export const updateUser = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/users', user)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('Selected Service type updated successfully .'));
          dispatch({ type: EDIT_SERVICETYPE, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const updateSERVICETYPEStatus = (data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(updateServiceTypeStatus, data)
      .then(response => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Service Type status updated successfully.'));
          }, 2000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};


export const deleteBulkUsers = (userIds, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/users/bulk-delete', { userIds })
      .then(response => {
        if (response.status === 200) {
          dispatch(fetchSuccess('Service type  deleted successfully.'));
          dispatch({ type: DELETE_BULK_SERVICETYPE, payload: userIds });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const deleteUser = (userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete('/users', { params: { id: userId } })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('Selected user was deleted successfullyhgkhfkgjlkfjgkljfkghlkfj.'));
          dispatch({ type: DELETE_SERVICETYPE, payload: userId });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.gfhgdhfghdghfgdghj'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server jdgfyjgdjkhgfkjhfdjkhh'));
      });
  };
};
