import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';
import { GET_ITEM, SET_ITEM } from '../../@jumbo/constants/ActionTypes';
import { listItem, addItem, updateItem } from '../../@jumbo/constants/ApiConstatnt';

// get FM provider list
export const getItemLocation = (id,sortBy, sort, searchTerm, page, limit, filterOptions, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(listItem+id, {
        params: { sortBy: `${sortBy}:${sort}`, search: searchTerm, page: page + 1, limit: limit, status: filterOptions[0] },
      })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_ITEM, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch({ type: GET_ITEM, payload: [] });
          dispatch(fetchError());
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Items not found in warehouse.') {
          dispatch({ type: GET_ITEM, payload: [] });
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

// set current user for view tab
export const setItemLocation = user => {
  return dispatch => {
    dispatch({ type:SET_ITEM, payload: user });
  };
};

// add new FM provider 
export const addItemsLocation = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(addItem, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('New Part added successfully.'));
          }, 2000);
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const updateItemLocation = (data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(updateItem, data)
      .then(response => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Part updated successfully.'));
          }, 3000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const deleteItemLocation = (itemId,warehouseId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete(`locations/items/delete/${warehouseId}/${itemId}`)
      .then(data => {
        if (data.status === 200) {
          setTimeout(() => {
            dispatch(fetchSuccess('Part removed successfully.'));    
          }, 2000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};
