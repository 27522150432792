import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import Button from '@material-ui/core/Button';
import { emailNotValid, lettersOnlyMessage, requiredMessage } from '@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { capitalizeFLetter, isValidEmail } from '@jumbo/utils/commonHelper';
import { updateUser } from 'redux/actions/Users';
import { getUserList, addNewUser } from 'redux/actions/UserManagement';
import { stringOnly } from '@jumbo/constants/ValidationRegex';

import { alpha } from '@material-ui/core/styles';
import { addNewTenant, getTenantList, updateTenant } from 'redux/actions/Tenant';
import { alphaNumaricWithSpace } from '../../../../../@jumbo/constants/ValidationRegex';
import { alphaNumaricMessage } from '../../../../../@jumbo/constants/ErrorMessages';
import { addNewCertificate, getCertificateList, updateCertificate } from '../../../../../redux/actions/CertificateType';

const useStyles = makeStyles(theme => ({
  root: {
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    "& .MuiFormLabel-root": {
      color: '#909090'
    },
    "& .MuiInputLabel-shrink":{
color:theme.palette.text.primary
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink':{
      color: theme.palette.text.primary
    },
  },
  dialogRoot: {
    position: 'relative',
    '& .MuiDialog-paperWidthSm': {
      width: '100%',
      // maxWidth: '1000px',
    },
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.text.primary,
    },
  },
  cardRoot: {
    position: 'relative',
    '& .Cmt-card-content': {
      padding: 0,
      paddingBottom: 24,
    },
  },
  scrollbarRoot: {
    height: 340,
  },
  badgeRoot: {
    fontSize: 14,
    letterSpacing: 0.25,
    backgroundColor: alpha(theme.palette.warning.main, 0.15),
    color: theme.palette.warning.main,
    padding: '2px 10px',
    borderRadius: 30,
  },
  tableRowCellRoot: {
    fontSize: 14,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 16,
    letterSpacing: 0.4,
    textAlign: 'center',
    color: theme.palette.common.dark,
    borderBottom: '0 none',
    '&:first-child': {
      paddingLeft: 24,
    },
    '&:last-child': {
      // textAlign: 'right',
      paddingRight: 24,
    },
  },
  tableRowRoot: {
    position: 'relative',
    transition: 'all .2s',
    borderTop: `solid 1px ${theme.palette.borderColor.main}`,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
      transform: 'translateY(-4px)',
      boxShadow: `0 3px 10px 0 ${alpha(theme.palette.common.dark, 0.2)}`,
      borderTopColor: 'transparent',
      '& $tableCellRoot': {
        color: theme.palette.text.primary,

        '&.success': {
          color: theme.palette.success.main,
        },
      },
    },
  },
  tableCellRoot: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: 14,
    letterSpacing: 0.25,
    textAlign: 'center',
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    borderBottom: '0 none',
    '&:first-child': {
      paddingLeft: 24,
      textAlign: 'left',
    },

    '& .Cmt-avatar-more': {
      fontSize: 10,
      color: theme.palette.primary.main,
    },
  },
  tableCellSecondaryColor: {
    color: theme.palette.text.secondary,
  },
  blockRoot: {
    display: 'block',
    fontSize: 14,
  },
}));



const AddEditUser = ({ open, onCloseDialog, getFMProviderParams }) => {
  const classes = useStyles();
  const currentUser = useSelector(({ CERTIFICATETYPE }) => CERTIFICATETYPE.currentUser);
  const {id, orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions } = getFMProviderParams;
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  const [nameError, setNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [emailError, setEmailError] = useState('');
const [status,setStatus]=useState(1)
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (currentUser) {
      
      setName(currentUser?.name);
      setLastName(currentUser?.last_name);
      setEmail(currentUser.email);
      setStatus(currentUser?.status)
    }
  }, [currentUser]);

  const onSubmitClick = () => {
    if (!name) {
      setNameError(requiredMessage);
    } else if (!name.match(alphaNumaricWithSpace)) {
      setNameError(alphaNumaricMessage);
    }
   
    if (name && name.match(alphaNumaricWithSpace)) {
      onUserSave();
    }
  };

  const onUserSave = () => {
    const fname = capitalizeFLetter(name);
    const userDetail = {
      
      name: fname,
      
    };

    if (currentUser) {
      dispatch(
        updateCertificate(currentUser?._id,userDetail, () => {
          onCloseDialog();
          dispatch(getCertificateList());
        }),
      );
    } else {
      dispatch(
        addNewCertificate(userDetail, () => {
          onCloseDialog();
          dispatch(getCertificateList());
        }),
      );
    }
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>{currentUser ? 'Edit Certificate Type Details' : 'Add New Certificate Type'}</DialogTitle>
      <DialogContent dividers>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
          <GridContainer>
            <Grid item xs={12} sm={12}>
              <AppTextInput
                className={classes.root}
                fullWidth
                variant="outlined"
                label="Name"
                value={name}
                onChange={e => {
                  setName(e.target.value);
                  setNameError('');
                }}
                helperText={nameError}
              />
            </Grid>
            
          </GridContainer>
        </Box>

        {/* <Box >
  <UserAccessADDEdit userAccess={userAccess} setUserAccess={setUserAccess} setUserModuleAccess={setUserModuleAccess} userModuleAccess={userModuleAccess}/>
</Box> */}

        <Box display="flex" justifyContent="flex-end" mb={4} mt={5}>
          <Button variant="outlined" onClick={onCloseDialog}>
            Cancel
          </Button>
          <Box ml={2}>
            <Button variant="contained" style={{ minWidth: '100px' }} color="primary" onClick={onSubmitClick}>
              {currentUser?'UPDATE':'ADD'}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditUser;

AddEditUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
