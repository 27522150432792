export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const UPDATE_AUTH_USER = 'update_auth_user';
export const UPDATE_LOAD_USER = 'update_load_user';
export const SEND_FORGET_PASSWORD_EMAIL = 'send_forget_password_email';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'signin_google_user_success';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'signin_facebook_user_success';
export const SIGNIN_TWITTER_USER_SUCCESS = 'signin_twitter_user_success';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_SUCCESS';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';

export const SET_DASHBOARD_DATA = 'set_dashboard_data';

export const SET_TASK_CURRENT_USER = 'set_task_current_user';
export const SET_TASKS_DATA = 'set_tasks_data';
export const SET_TASK_LIST_DATA = 'set_task_list_data';
export const ADD_TASK = 'add_task';
export const DELETE_TASK = 'delete_task';
export const UPDATE_TASK = 'update_task';
export const SET_FILTER_DATA = 'set_filter_data';
export const ADD_TASK_LIST = 'add_task_list';
export const UPDATE_TASK_LIST = 'update_task_list';
export const DELETE_TASK_LIST = 'delete_task_list';
export const SET_TASK_DETAIL = 'set_task_detail';
export const SEND_MESSAGE = 'send_message';
export const TOGGLE_SIDEBAR_COLLAPSED = 'toggle_sidebar_collapsed';
export const GET_TASKS_COUNTS = 'get_tasks_counts';

//mail app
export const GET_LABELS_LIST = 'get_labels_list';
export const GET_CONNECTIONS_LIST = 'get_connections_list';
export const GET_MAILS_LIST = 'get_mails_list';
export const UPDATE_MAIL_FOLDER = 'update_mail_folder';
export const UPDATE_MAIL_LABEL = 'upade_mail_label';
export const UPDATE_FAVORITE_STATUS = 'update_favorite_status';
export const UPDATE_READ_STATUS = 'update_read_status';
export const UPDATE_IMPORTANT_STATUS = 'update_important_status';
export const COMPOSE_MAIL = 'compose_mail';
export const SET_FILTER_TYPE = 'set_filter_type';
export const GET_SELECTED_MAIL = 'GET_SELECTED_MAIL';
export const UPDATE_SELECTED_MAIL = 'update_selected_mail';
export const NULLIFY_SELECTED_MAIL = 'nullify_selected_mail';
export const REPLY_TO_MAIL = 'reply_to_mail';
export const GET_MAIL_COUNTS = 'get_mail_count';
export const ADD_LABEL = 'add_label';
export const ADD_CONNECTION = 'add_connection';
export const REMOVE_CONNECTION = 'remove_connection';

export const SET_CHAT_USERS = 'set_chat_users';
export const SET_CONTACT_USERS = 'set_contact_users';
export const SET_CURRENT_USER = 'set_current_user';
export const SET_CONVERSATION_DATA = 'set_conversation_data';
export const SEND_NEW_CHAT_MESSAGE = 'send_new_chat_message';
export const SEND_NEW_MEDIA_MESSAGE = 'send_new_media_message';

//Contact App
export const GET_CONTACTS_LIST = 'get_contacts_list';
export const SET_CURRENT_CONTACT = 'set_current_contact';
export const CREATE_CONTACT = 'create_contact';
export const UPDATE_STARRED_STATUS = 'update_starred_status';
export const DELETE_CONTACT = 'delete_contact';
export const UPDATE_CONTACT_LABEL = 'update_contact_label';
export const UPDATE_CONTACT = 'update_contact';
export const GET_CONTACT_COUNTS = 'get_contact_counts';
export const UPDATE_LABEL_ITEM = 'update_label_item';
export const DELETE_LABEL_ITEM = 'delete_label_item';

export const GET_USER_DETAIL = 'get_user_detail';
export const GET_FEED_POSTS = 'get_feed_posts';
export const CREATE_POST = 'create_post';
export const UPDATE_POST = 'update_post';

// Users Module
export const GET_USERS = 'GET_USERS';
export const ADD_USER = 'ADD_USER';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const EDIT_USER = 'EDIT_USER';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_BULK_USERS = 'DELETE_BULK_USERS';

// FM Provider Module
export const GET_FMPROVIDER = 'GET_FMPROVIDER';
export const ADD_FMPROVIDER = 'ADD_FMPROVIDER';
export const SET_FMPROVIDER_DETAILS = 'SET_FMPROVIDER_DETAILS';
export const EDIT_FMPROVIDER = 'EDIT_FMPROVIDER';
export const DELETE_FMPROVIDER = 'DELETE_FMPROVIDER';
export const DELETE_BULK_FMPROVIDER = 'DELETE_BULK_FMPROVIDER';

// Service type Module
export const GET_SERVICETYPE = 'GET_SERVICETYPE';
export const ADD_SERVICETYPE = 'ADD_SERVICETYPE';
export const SET_SERVICETYPE_DETAILS = 'SET_SERVICETYPE_DETAILS';
export const EDIT_SERVICETYPE = 'EDIT_SERVICETYPE';
export const DELETE_SERVICETYPE = 'DELETE_SERVICETYPE';
export const DELETE_BULK_SERVICETYPE = 'DELETE_BULK_SERVICETYPE';

// ACCOUNT DETAILS

export const GET_ACCOUNTDETAILS = 'GET_ACCOUNTDETAILS';
export const GET_ACCOUNTDETAILSLIST = 'GET_ACCOUNTDETAILSLIST';

// Sub Admin Management Module
export const GET_SUBADMIN = 'GET_SUBADMIN';
export const ADD_SUBADMIN = 'ADD_SUBADMIN';
export const SET_SUBADMIN_DETAILS = 'SET_SUBADMIN_DETAILS';
export const EDIT_SUBADMIN = 'EDIT_SUBADMIN';
export const DELETE_SUBADMIN = 'DELETE_SUBADMIN';
export const DELETE_BULK_SUBADMIN = 'DELETE_BULK_SUBADMIN';

// Service Activites
export const GET_SERVICEACTIVITIES = 'GET_SERVICEACTIVITIES';
export const ADD_SERVICEACTIVITIES = 'ADD_SERVICEACTIVITIES';
export const SET_SERVICEACTIVITIES_DETAILS = 'SET_SERVICEACTIVITIES_DETAILS';
export const EDIT_SERVICEACTIVITIES = 'EDIT_SERVICEACTIVITIES';
export const DELETE_SERVICEACTIVITIES = 'DELETE_SERVICEACTIVITIES';
export const DELETE_BULK_SERVICEACTIVITIES = 'DELETE_BULK_SERVICEACTIVITIES';

// Items List
export const GET_ITEMSLIST = 'GET_ITEMSLIST';
export const ADD_ITEMSLIST = 'ADD_ITEMSLIST';
export const SET_ITEMSLIST_DETAILS = 'SET_ITEMSLIST_DETAILS';
export const EDIT_ITEMSLIST = 'EDIT_ITEMSLIST';
export const DELETE_ITEMSLIST = 'DELETE_ITEMSLIST';
export const DELETE_BULK_ITEMSLIST = 'DELETE_BULK_ITEMSLIST';
export const GET_ITEMSLISTDETAILS = 'GET_ITEMSLISTDETAILS';
export const GET_MANUFACTURERLIST = 'GET_MANUFACTURERLIST';
export const GET_WAREHOUSELIST = 'GET_WAREHOUSELIST';

// Assign Items
export const GET_ASSIGNITEM = 'GET_ASSIGNITEM';
export const ADD_ASSIGNITEM = 'ADD_ASSIGNITEM';
export const SET_ASSIGNITEM_DETAILS = 'SET_ASSIGNITEM_DETAILS';
export const EDIT_ASSIGNITEM = 'EDIT_ASSIGNITEM';
export const DELETE_ASSIGNITEM = 'DELETE_ASSIGNITEM';
export const DELETE_BULK_ASSIGNITEM = 'DELETE_BULK_ASSIGNITEM';
export const GET_ASSIGNITEMDETAILS = 'GET_ASSIGNITEMDETAILS';
export const GET_ASSIGNITEMADD = 'GET_ASSIGNITEMADD';
export const GET_WAREHOUSE = 'GET_WAREHOUSE';

export const SET_ASSET_SERVICETYPE = 'SET_ASSET_SERVICETYPE';
export const GET_CATEGORIES_LIST = 'GET_CATEGORIES_LIST';

// Return Items
export const GET_RETURNITEM = 'GET_RETURNITEM';
export const ADD_RETURNITEM = 'ADD_RETURNITEM';
export const SET_RETURNITEM_DETAILS = 'SET_RETURNITEM_DETAILS';
export const EDIT_RETURNITEM = 'EDIT_RETURNITEM';
export const DELETE_RETURNITEM = 'DELETE_RETURNITEM';
export const DELETE_BULK_RETURNITEM = 'DELETE_BULK_RETURNITEM';
export const GET_RETURNITEMDETAILS = 'GET_RETURNITEMDETAILS';
export const GET_RETURNITEMADD = 'GET_RETURNITEMADD';
export const GET_RETURNWAREHOUSELIST = 'GET_RETURNWAREHOUSELIST';

// Srevice Request
export const GET_SERVICEREQUEST = 'GET_SERVICEREQUEST';
export const GET_TENANT_LIST = 'GET_TENANT_LIST';
export const ADD_SERVICEREQUEST = 'ADD_SERVICEREQUEST';
export const SET_SERVICEREQUEST_DETAILS = 'SET_SERVICEREQUEST_DETAILS';
export const EDIT_SERVICEREQUEST = 'EDIT_SERVICEREQUEST';
export const DELETE_SERVICEREQUEST = 'DELETE_SERVICEREQUEST';
export const DELETE_BULK_SERVICEREQUEST = 'DELETE_BULK_SERVICEREQUEST';
export const GET_SERVICEREQUESTDETAILS = 'GET_SERVICEREQUESTDETAILS';
export const GET_SERVICEREQUESTADD = 'GET_SERVICEREQUESTADD';
export const GET_SERVICEREQUESTQUOTATIONLIST = 'GET_SERVICEREQUESTQUOTATIONLIST';
export const GET_SERVICEREQUESTQUOTATIOCHECKNLIST = 'GET_SERVICEREQUESTQUOTATIOCHECKNLIST';

//Work Order
export const ADD_WORK_ORDER = 'ADD_WORK_ORDER';
export const GET_WORK_ORDER = 'GET_WORK_ORDER';
export const GET_WORK_ORDER_DETAILS = 'GET_WORK_ORDER_DETAILS';
export const SET_WORK_ORDER_DETAILS = 'SET_WORK_ORDER_DETAILS';

// Issue Management
export const GET_ISSUEMANAGEMENT = 'GET_ISSUEMANAGEMENT';
export const ADD_ISSUEMANAGEMENT = 'ADD_ISSUEMANAGEMENT';
export const SET_ISSUEMANAGEMENT_DETAILS = 'SET_ISSUEMANAGEMENT_DETAILS';
export const EDIT_ISSUEMANAGEMENT = 'EDIT_ISSUEMANAGEMENT';
export const DELETE_ISSUEMANAGEMENT = 'DELETE_ISSUEMANAGEMENT';
export const DELETE_BULK_ISSUEMANAGEMENT = 'DELETE_BULK_ISSUEMANAGEMENT';

// Vendor Management
export const GET_VENDORMANAGEMENT = 'GET_VENDORMANAGEMENT';
export const ADD_VENDORMANAGEMENT = 'ADD_VENDORMANAGEMENT';
export const SET_VENDORMANAGEMENT_DETAILS = 'SET_VENDORMANAGEMENT_DETAILS';
export const EDIT_VENDORMANAGEMENT = 'EDIT_VENDORMANAGEMENT';

//Contractor (Vendor)
// export const ADD_CONTRACTOR = 'ADD_CONTRACTOR';
// export const GET_CONTRACTOR = 'GET_CONTRACTOR';
export const APPROVED_CONTRACTOR = 'APPROVED_CONTRACTOR';

// Budget Management
export const GET_BUDGETMANAGEMENT = 'GET_BUDGETMANAGEMENT';
export const UPDATE_BUDGETMANAGEMENT = 'UPDATE_BUDGETMANAGEMENT';

// Locations(Items)
export const GET_ITEM = 'GET_ITEM';
export const ADD_ITEM = 'ADD_ITEM';
export const UPDATE_ITEM = 'UPDATE_ITEM';
export const DELETE_ITEM = 'DELETE_ITEM';
export const SET_ITEM = 'SET_ITEM';

// Dashboard
export const GET_DASHBOARD = 'GET_DASHBOARD';
export const GET_DASHBOARD_CONTRACTORAPPLICATION = 'GET_DASHBOARD_CONTRACTORAPPLICATION';
export const GET_ACCOUNT_DASHBOARD = 'GET_ACCOUNT_DASHBOARD';

// Content Manager
export const GET_ABOUTUS = 'GET_ABOUTUS';
export const ADD_ABOUTUS = 'ADD_ABOUTUS';
export const GET_PRIVACYPOLICY = 'GET_PRIVACYPOLICY';
export const ADD_PRIVACYPOLICY = 'ADD_PRIVACYPOLICY';
export const GET_TERMSANDCONDITIONS = 'GET_TERMSANDCONDITIONS';
export const ADD_TERMSANDCONDITIONS = 'ADD_TERMSANDCONDITIONS';
export const GET_FAQ = 'GET_FAQ';
export const ADD_FAQ = 'ADD_FAQ';

// Contact Us Query Module
export const GET_CONTACTUSQUERY = 'GET_CONTACTUSQUERY';
export const ADD_CONTACTUSQUERY = 'ADD_CONTACTUSQUERY';
export const SET_CONTACTUSQUERY_DETAILS = 'SET_CONTACTUSQUERY_DETAILS';
export const EDIT_CONTACTUSQUERY = 'EDIT_CONTACTUSQUERY';
export const DELETE_CONTACTUSQUERY = 'DELETE_CONTACTUSQUERY';
export const DELETE_BULK_CONTACTUSQUERY = 'DELETE_BULK_CONTACTUSQUERY';
export const GET_CONTACTUSDETAILS = 'GET_CONTACTUSDETAILS';

// setting
export const GET_VIRSION = 'GET_VIRSION';

export const GET_CUSTOMIZATION = 'GET_CUSTOMIZATION';
// Contractor vendor

export const GET_CONTRACTOR = 'GET_CONTRACTOR';
export const ADD_CONTRACTOR = 'ADD_CONTRACTOR';
export const SET_CONTRACTOR_DETAILS = 'SET_CONTRACTOR_DETAILS';
export const GET_CONTRACTOR_DETAILS = 'GET_CONTRACTOR_DETAILS';
export const EDIT_CONTRACTOR = 'EDIT_CONTRACTOR';
export const DELETE_CONTRACTOR = 'DELETE_CONTRACTOR';
export const DELETE_BULK_CONTRACTOR = 'DELETE_BULK_CONTRACTOR';
export const PRIORITY_CONTRACTOR = 'PRIORITY_CONTRACTOR';

// Location
export const GET_LOCATIONLIST = 'GET_LOCATIONLIST';
export const GET_WAREHOUSE_LIST = 'GET_WAREHOUSE_LIST';

// planned maintenance
export const GET_PLANNEDMAINTENANCE = 'GET_PLANNEDMAINTENANCE';
export const ADD_PLANNEDMAINTENANCE = 'ADD_PLANNEDMAINTENANCE';
export const SET_PLANNEDMAINTENANCE_DETAILS = 'SET_PLANNEDMAINTENANCE_DETAILS';
export const EDIT_PLANNEDMAINTENANCE = 'EDIT_PLANNEDMAINTENANCE';
export const DELETE_PLANNEDMAINTENANCE = 'DELETE_PLANNEDMAINTENANCE';
export const DELETE_BULK_PLANNEDMAINTENANCE = 'DELETE_BULK_PLANNEDMAINTENANCE';
export const GET_PLANNEDMAINTENANCEDETAILS = 'GET_PLANNEDMAINTENANCEDETAILS';

// Tenant user
export const GET_TENANT = 'GET_TENANT';
export const ADD_TENANT = 'ADD_TENANT';
export const SET_TENANT_DETAILS = 'SET_TENANT_DETAILS';
export const EDIT_TENANT = 'EDIT_TENANT';
export const DELETE_TENANT = 'DELETE_TENANT';
export const DELETE_BULK_TENANT = 'DELETE_BULK_TENANT';

// Location: Assigned User
export const GET_ASSIGNEDUSERLOCATION = 'GET_ASSIGNEDUSERLOCATION';
// Assets
export const GET_SYSTEMCATEGORIESLIST_ASSETLOCATION = 'GET_SYSTEMCATEGORIESLIST_ASSETLOCATION';
export const GET_CLASSCATEGORIESLIST_ASSETLOCATION = 'GET_CLASSCATEGORIESLIST_ASSETLOCATION';
export const GET_TYPECATEGORIESLIST_ASSETLOCATION = 'GET_TYPECATEGORIESLIST_ASSETLOCATION';
export const GET_SUBTYPECATEGORIESLIST_ASSETLOCATION = 'GET_SUBTYPECATEGORIESLIST_ASSETLOCATION';
export const GET_ASSETLOCATION = 'GET_ASSETLOCATION';
export const ADD_ASSETLOCATION = 'ADD_ASSETLOCATION';
export const SET_ASSETLOCATION_DETAILS = 'SET_ASSETLOCATION_DETAILS';
export const EDIT_ASSETLOCATION = 'EDIT_ASSETLOCATION';
export const DELETE_ASSETLOCATION = 'DELETE_ASSETLOCATION';
export const DELETE_BULK_ASSETLOCATION = 'DELETE_BULK_ASSETLOCATION';
export const GET_ASSETTYPE = 'GET_ASSETTYPE';
export const GET_PARTS_LIST = 'GET_PARTS_LIST';
export const DOWNLOAD_BARCODE_QRCODE = 'DOWNLOAD_BARCODE_QRCODE';

// SelfhelpContent
export const GET_SELFHELPCONTENT = 'GET_SELFHELPCONTENT';
export const ADD_SELFHELPCONTENT = 'ADD_SELFHELPCONTENT';
export const SET_SELFHELPCONTENT_DETAILS = 'SET_SELFHELPCONTENT_DETAILS';
export const EDIT_SELFHELPCONTENT = 'EDIT_SELFHELPCONTENT';
export const DELETE_SELFHELPCONTENT = 'DELETE_SELFHELPCONTENT';
export const DELETE_BULK_SELFHELPCONTENT = 'DELETE_BULK_SELFHELPCONTENT';

// Induction
export const GET_INDUCTION = 'GET_INDUCTION';
export const GET_INDUCTION_DETAILVIEW = 'GET_INDUCTION_DETAILVIEW';
export const ADD_INDUCTION = 'ADD_INDUCTION';
export const SET_INDUCTION_DETAILS = 'SET_INDUCTION_DETAILS';
export const EDIT_INDUCTION = 'EDIT_INDUCTION';
export const DELETE_INDUCTION = 'DELETE_INDUCTION';
export const DELETE_BULK_INDUCTION = 'DELETE_BULK_INDUCTION';

// ConfirmDocument
export const GET_CONFIRMDOCUMENT = 'GET_CONFIRMDOCUMENT';
export const ADD_CONFIRMDOCUMENT = 'ADD_CONFIRMDOCUMENT';
export const SET_CONFIRMDOCUMENT_DETAILS = 'SET_CONFIRMDOCUMENT_DETAILS';
export const EDIT_CONFIRMDOCUMENT = 'EDIT_CONFIRMDOCUMENT';
export const DELETE_CONFIRMDOCUMENT = 'DELETE_CONFIRMDOCUMENT';
export const DELETE_BULK_CONFIRMDOCUMENT = 'DELETE_BULK_CONFIRMDOCUMENT';

// AssignUserList
export const GET_ALLASSIGNUSERLIST = 'GET_ALLASSIGNUSERLIST';

// Certificate
export const GET_CERTIFICATELIST = 'GET_CERTIFICATELIST';
export const SET_CURRENT_CERTIFICATE = 'SET_CURRENT_CERTIFICATE';

// Assigned Site
export const GET_ASSIGNEDSITE_LIST = 'GET_ASSIGNEDSITE_LIST';
export const SET_CURRENT_ASSIGNED_SITE = 'SET_CURRENT_ASSIGNED_SITE';

// Account User Vendor
export const GET_VENDORS = 'GET_VENDORS';
export const ADD_VENDORS = 'ADD_VENDORS';
export const SET_VENDORS_DETAILS = 'SET_VENDORS_DETAILS';
export const EDIT_VENDORS = 'EDIT_VENDORS';
export const DELETE_VENDORS = 'DELETE_VENDORS';
export const DELETE_BULK_VENDORS = 'DELETE_BULK_VENDORS';

// Payment To Vendors
export const GET_PAYMENTTOVENDORS = 'GET_PAYMENTTOVENDORS';
export const SET_PAYMENT_TO_VENDORS_DETAILS = 'SET_PAYMENT_TO_VENDORS_DETAILS';

// CallCenter ServiceRequest
export const GET_CALLCENTER_SERVICE_REQUEST_LIST = 'GET_CALLCENTER_SERVICE_REQUEST_LIST';
export const SET_CURRENT_CALLCENTER_SERVICE_REQUEST = 'SET_CURRENT_CALLCENTER_SERVICE_REQUEST';
export const GET_CALL_CENTER_SITE_LIST = 'GET_CALL_CENTER_SITE_LIST';
export const GET_CALL_CENTER_SERVICE_TYPE_LIST = 'GET_CALL_CENTER_SERVICE_TYPE_LIST';
export const GET_CALL_CENTER_ISSUE_LIST = 'GET_CALL_ISSUE_TYPE_LIST';
export const GET_CALL_CENTER_ASSET_TABLE_LIST = 'GET_CALL_CENTER_ASSET_TABLE_LIST';

export const SET_SERVICE_TYPE = 'SET_SERVICE_TYPE';

// Threshold Amount
export const GET_THRESHOLD_AMOUNT = 'GET_THRESHOLD_AMOUNT';
export const SET_THRESHOLD_AMOUNT = 'SET_THRESHOLD_AMOUNT';
