import React, { useEffect, useState } from 'react';
import { Paper, Table, TableCell, TableContainer, TableRow ,Typography} from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import UserListRow from './UserListRow';
import UserTableHead from './UserTableHead';
import UserTableToolbar from './UserTableToolbar';
import { getComparator, stableSort } from '../../../@jumbo/utils/tableHelper';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser, getUsers } from '../../../redux/actions/Users';
import AddEditUser from './AddEditUser';
import ConfirmDialog from '../../../@jumbo/components/Common/ConfirmDialog';
import { useDebounce } from '../../../@jumbo/utils/commonHelper';
import useStyles from './index.style';
import UserDetailView from './UserDetailView';
import NoRecordFound from './NoRecordFound';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { getContactUsDetails, getContactUsQuery ,setCurrentQuery, updateContactUsQueryStatus} from '../../../redux/actions/ContactUsQuery';

const breadcrumbs = [
  { label: 'Home', link: '/' },

  { label: 'Contact Us Queries', isActive: true },
];

const UsersModule = () => {
  const classes = useStyles();
  const { authUser } = useSelector(({ auth }) => auth);
  const { users } = useSelector(({ CONTACTUSQUERY }) => CONTACTUSQUERY);
  const [orderBy, setOrderBy] = React.useState('created_at');
  const [order, setOrder] = React.useState('desc');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState({ name: '' });
  const [usersFetched, setUsersFetched] = useState(false);
  const [isFilterApplied, setFilterApplied] = useState(false);
  const [filterOptions, setFilterOptions] = React.useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchedData,setSearchedData]= useState('debouncedSearchPagevalidation')
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  // console.log('contactusquery details', users);

  const dispatch = useDispatch();

  useEffect(() => {
    if(debouncedSearchTerm && debouncedSearchTerm!==searchedData){
     
      dispatch(
        getContactUsQuery(orderBy, order, filterOptions, debouncedSearchTerm, 0, rowsPerPage, () => {
          setFilterApplied(!!filterOptions.length || !!debouncedSearchTerm);
          setUsersFetched(true);
        }),
      );
      setSearchedData(debouncedSearchTerm)
      setPage(0)
    }
    else{
      dispatch(
        getContactUsQuery(orderBy, order, filterOptions, debouncedSearchTerm, page, rowsPerPage, () => {
          setFilterApplied(!!filterOptions.length || !!debouncedSearchTerm);
          setUsersFetched(true);
        }),
      );
    }
   
  }, [dispatch, filterOptions, debouncedSearchTerm, orderBy, order, page, rowsPerPage]);

  const handleCloseUserDialog = () => {
    setOpenUserDialog(false);
    dispatch(setCurrentQuery(null));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = users.map(n => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleRowClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUserView = user => {
    dispatch(
      getContactUsDetails( user._id)
    )
    dispatch(setCurrentQuery(user));
    setOpenViewDialog(true);
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
    dispatch(setCurrentQuery(null));
  };

  const handleUserEdit = user => {
    dispatch(setCurrentQuery(user));
    setOpenUserDialog(true);
  };

  const handleUserDelete = user => {
    setSelectedUser(user);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    dispatch(updateContactUsQueryStatus({ _id: selectedUser._id, status: 2 }, ()=>dispatch(getContactUsQuery(orderBy, order, filterOptions, debouncedSearchTerm, page, rowsPerPage))));
    // dispatch(deleteUser(selectedUser.id));
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const isSelected = id => selected.indexOf(id) !== -1;

  return (
    <PageContainer heading="Contact Us Queries" breadcrumbs={breadcrumbs}>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <UserTableToolbar
            selected={selected}
            setSelected={setSelected}
            onUserAdd={setOpenUserDialog}
            filterOptions={filterOptions}
            setFilterOptions={setFilterOptions}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            setSearchedData={setSearchedData}
            setPage={setPage}
          />
          <TableContainer className={classes.container}>
            <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" aria-label="sticky enhanced table">
              <UserTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={users.length}
              />
              <TableBody>
                {!!users?.data?.length ? (
                  // stableSort(users?.data, getComparator(order, orderBy))
                  users?.data
                    ?.slice(0 * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <UserListRow
                        key={index}
                        row={row}
                        onRowClick={handleRowClick}
                        onUserEdit={handleUserEdit}
                        onUserDelete={handleUserDelete}
                        onUserView={handleUserView}
                        isSelected={isSelected}
                        getContactUsQueryParams={{ orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions }}
                      />
                    ))
                ) : (
                  <TableRow style={{ height: 53 * 6 }}>
                    <TableCell colSpan={7} rowSpan={10}>
                      {isFilterApplied ? (
                        <NoRecordFound>There are no records found.</NoRecordFound>
                      ) : (
                        <NoRecordFound>{ 'There are no records found.' }</NoRecordFound>
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={users?.total ?? 1}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Paper>
        {authUser?.user_type===6?null:
        <Typography className={classes.noteText} >Note : Please create new call center user in user management for 'Not assigned' contact us queries.</Typography>}
        {openUserDialog && (
          <AddEditUser
            open={openUserDialog}
            onCloseDialog={handleCloseUserDialog}
            getSERVICETYPEParams={{ undefined, order, undefined, debouncedSearchTerm, page, rowsPerPage }}
          />
        )}
        {openViewDialog && <UserDetailView open={openViewDialog} onCloseDialog={handleCloseViewDialog} />}

        <ConfirmDialog
          open={openConfirmDialog}
          title={`Close Contact Us Query?`}
          content={'Query will be submitted and user will be notified.'}
          btnLabels={
            {
              cancel: 'Cancel',
              confirm: 'Close',
            }
          }
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete}
        />
        {/* <ConfirmDialog
          open={openConfirmDialog}
          title={`Confirm delete ${selectedUser.name}`}
          content={'Are you sure, you want to  delete this user?'}
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete}
        /> */}
      </div>
    </PageContainer>
  );
};

export default UsersModule;
