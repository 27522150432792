import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Block, CheckCircleOutline, Edit, Visibility,Delete } from '@material-ui/icons';
import CmtDropdownMenu from '../../../../@coremat/CmtDropdownMenu';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { sentMailToUser } from '../../../../redux/actions/Users';
import { getPlannedMaintenanceList, updatePlannedMaintenanceStatus } from '../../../../redux/actions/PlannedMaintenance';
import { Activate, Deactivate } from '../../../../@jumbo/constants/stringConstant';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    fontWeight:"300",
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  titleRoot2: {
    marginLeft:'15px',
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    fontWeight:"300",
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

const UserListRow = ({
  row,
  onUserEdit,
  onUserDelete,
  onUserView,
  getServiceActivitiesParams,
  indexId,
}) => {
  const { orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions } = getServiceActivitiesParams;
  const { authUser } = useSelector(({ auth }) => auth);
  const classes = useStyles();
  const dispatch = useDispatch();
  const getUserActions = user => {
  const actions = !authUser?.fm_module_access[11]?.is_edit?[{ action: "view", label: "View", icon: <Visibility /> }]:[{ action: "view", label: "View", icon: <Visibility /> }, { action: "edit", label: "Edit", icon: < Edit /> }];
  
  if (user.status === 1&&authUser?.fm_module_access[11]?.is_enable) {
    actions.push({ action: "suspend", label: Deactivate, icon: <Block /> });
  } else if(authUser?.fm_module_access[11]?.is_enable){
    actions.push({
      action: "activate",
      label: Activate,
      icon: <CheckCircleOutline />,
    });
  }
  actions.push({ action: 'delete', label: 'Delete', icon: <Delete /> });
  return actions;
};
  const onUserMenuClick = menu => {
    if (menu.action === 'view') {
      onUserView(row);
    } else if (menu.action === 'edit') {
      onUserEdit(row);
    } else if (menu.action === 'email') {
      dispatch(sentMailToUser());
    } else if (menu.action === 'suspend') {
      dispatch(updatePlannedMaintenanceStatus({ _id: row._id, status: 2 }, refreshContactList));
    } else if (menu.action === 'activate') {
      dispatch(updatePlannedMaintenanceStatus({ _id: row._id, status: 1 }, refreshContactList));
    } else if (menu.action === 'delete') {
      onUserDelete(row);
    }
  };
  function refreshContactList() {
    dispatch(getPlannedMaintenanceList(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
  }
  const labelId = `enhanced-table-checkbox-${row._id}`;

  const userActions = getUserActions(row);

  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
      <TableCell padding="checkbox"></TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Typography className={classes.titleRoot2} component="div" variant="h4">
          {indexId + 1}
        </Typography>
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {row?.site_name}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {row?.service_type_details}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {row?.duration === 1 ? 'Every month' : `Every ${row?.duration} months`}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {moment(row?.next_service_date * 1000).format('DD/MM/YYYY')}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {moment(row?.installation_date * 1000).format('DD/MM/YYYY')}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {moment(row?.start_date * 1000).format('DD/MM/YYYY')}
        </Typography>
      </TableCell>

      <TableCell>{row?.status === 1 ? `Active` : 'Inactive'}</TableCell>

      <TableCell align="center" onClick={event => event.stopPropagation()}>
        <CmtDropdownMenu items={userActions} onItemClick={onUserMenuClick} TriggerComponent={<Edit />} />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(UserListRow);
