import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';
import {
  DELETE_BULK_SERVICEACTIVITIES,
  GET_SERVICEACTIVITIES,
  SET_SERVICEACTIVITIES_DETAILS,
} from '../../@jumbo/constants/ActionTypes';
import {
  addServiceActivities,
  listServiceActivities,
  updateServiceActivities,
  updateServiceActivitiesDetails,
  deleteServiceActivitiesDetails,
} from '../../@jumbo/constants/ApiConstatnt';

// get FM provider list
export const getServiceActivities = (sortBy, sort, searchTerm, page, limit, filterOptions, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(listServiceActivities, {
        params: { sortBy: `${sortBy}:${sort}`, search: searchTerm, page: page + 1, limit: limit, status: filterOptions[0] },
      })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_SERVICEACTIVITIES, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError());
          dispatch({ type: GET_SERVICEACTIVITIES, payload: [] });
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Service activity list not found.') {
          dispatch({ type: GET_SERVICEACTIVITIES, payload: [] });
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

// set current user for view tab
export const setCurrentServiceActivities = user => {
  return dispatch => {
    dispatch({ type: SET_SERVICEACTIVITIES_DETAILS, payload: user });
  };
};

// add new FM provider
export const addNewServiceActivities = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(addServiceActivities, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('New Service activity added successfully.'));
          }, 2000);
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const updateServiceActivitiesStatus = (data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(updateServiceActivities, data)
      .then(response => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess(' Service activity status updated successfully.'));
          }, 2000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

// all function below are not in use currntly
export const deleteBulkUsers = (userIds, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/users/bulk-delete', { userIds })
      .then(response => {
        if (response.status === 200) {
          dispatch(fetchSuccess('Selected users were deleted successfully.'));
          dispatch({ type: DELETE_BULK_SERVICEACTIVITIES, payload: userIds });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const deleteUser = (userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete(deleteServiceActivitiesDetails + userId)
      .then(data => {
        if (data.status === 200) {
          setTimeout(() => {
            dispatch(fetchSuccess('Service activity deleted successfully.'));   
          }, 2000);
         
          // dispatch({ type: DELETE_SERVICEACTIVITIES, payload: userId });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const sentMailToUser = () => {
  return dispatch => {
    dispatch(fetchSuccess('Email has been sent to user successfully'));
  };
};

export const updateServiceActivity = (user, id, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(updateServiceActivitiesDetails + id, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Service activity updated successfully.'));
          }, 3000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};
