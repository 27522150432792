import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {
  Block,
  CheckCircleOutline,
  MoreHoriz,
  Visibility,
  Edit
} from "@material-ui/icons";
import CmtDropdownMenu from "@coremat/CmtDropdownMenu";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { sentMailToUser } from "../../../../../../../redux/actions/Users";
import {
  getUserList,
  updateFMProviderStatus,
} from "../../../../../../../redux/actions/UserManagement";
import {
  getVendorManagement,
} from "../../../../../../../redux/actions/VendorManagement";
import {
  Activate,
  Deactivate,
} from "@jumbo/constants/stringConstant";
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme) => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark,
    textOverflow: "ellipsis",
    maxWidth: "150px",
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
}));

const getUserActions = (user) => {
  const actions = [{ action: "view", label: "View", icon: <Visibility /> }, { action: "edit", label: "Edit", icon: < Edit /> }];

  if (user.status === 1) {
    actions.push({ action: "suspend", label: Deactivate, icon: <Block /> });
  } else {
    actions.push({
      action: "activate",
      label: Activate,
      icon: <CheckCircleOutline />,
    });
  }

  return actions;
};

const UserListRow = ({
  row,
  isSelected,
  onRowClick,
  onUserEdit,
  onUserDelete,
  onUserView,
  getFMProviderParams,
  onUserUpdate
}) => {
  const {
    orderBy,
    order,
    debouncedSearchTerm,
    page,
    rowsPerPage,
    filterOptions,
  } = getFMProviderParams;
console.log("row",row);
  const classes = useStyles();
  const dispatch = useDispatch();

  const onUserMenuClick = (menu) => {
    if (menu.action === "view") {
      onUserView(row);
    } else if (menu.action === "edit") {
      onUserUpdate(row);
    }
    else if (menu.action === "edit") {
      onUserEdit(row);
    } else if (menu.action === "email") {
      dispatch(sentMailToUser());
    } else if (menu.action === "suspend") {
      dispatch(
        updateFMProviderStatus({ _id: row._id, status: 2 }, refreshContactList)
      );
    } else if (menu.action === "activate") {
      dispatch(
        updateFMProviderStatus({ _id: row._id, status: 1 }, refreshContactList)
      );
    } else if (menu.action === "delete") {
      onUserDelete(row);
    }
  };
  
  function refreshContactList() {
    dispatch(
      getVendorManagement(
        orderBy,
        order,
        debouncedSearchTerm,
        page,
        rowsPerPage,
        filterOptions
      )
    );
  }
  const labelId = `enhanced-table-checkbox-${row._id}`;

  const userActions = getUserActions(row);

  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
      <TableCell padding="checkbox"></TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="1">
        <Typography className={classes.titleRoot} component="div" variant="h4">
        {row?.name}
        </Typography>
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="1">
        <Typography component="div">
        {row?.name}
        </Typography>
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="1">
        <Typography component="div">
          {"08/11/1997"}
        </Typography>
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="1">
        <Typography component="div">
          {"12/11/1997"}
        </Typography>
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="1">
        <Typography component="div">
          {"Ahm Site"}
        </Typography>
      </TableCell>
      {/* <TableCell>{"12/11/1997"}</TableCell> */}
      {/* <TableCell>{row?.email}</TableCell> */}
      {/* <TableCell>{row?.user_type===4 ? 'Sub-Admin': row.user_type === 5? "Account User" : 'Call Center User'  }</TableCell> */}
      {/* <TableCell>{row?.status === 1 ? `Active` : "Inactive"}</TableCell> */}
 
      <TableCell align="center" onClick={() => window.open(row.certificate,"_self")}>
      <Visibility />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(UserListRow);
