import React, { useEffect, useState } from 'react';
import { Grid, Box, Button, Typography } from '@material-ui/core';
import GridContainer from '@jumbo/components/GridContainer';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CmtCard from '@coremat/CmtCard';
import { useDispatch, useSelector } from 'react-redux';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import { emptySpace } from '@jumbo/constants/ValidationRegex';
import { requiredMessage } from '@jumbo/constants/ErrorMessages';
import { loctionDetail } from '@jumbo/constants/ApiConstatnt';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import axios from '../../../../../../services/auth/jwt/config';
import { setAuthUser } from 'redux/actions/Auth';
import { history } from 'redux/store';
import { useParams } from 'react-router';
import { capitalizeFLetter } from '@jumbo/utils/commonHelper';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import { getSERVICETYPE } from '../../../../../../redux/actions/ServiceType';
import { updateLocation, setAssetServiceType } from 'redux/actions/Locations';
import { GET_ITEM } from '../../../../../../@jumbo/constants/ActionTypes';
import LocationSearch from '../../LocationSearch/index';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ConfirmDialog from '@jumbo/components/Common/ConfirmDialog';
import imageCompression from 'browser-image-compression';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Multiselect from 'multiselect-react-dropdown';
import FaceIcon from '@material-ui/icons/Face';
import { Block, CheckCircleOutline } from '@material-ui/icons';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import BuildIcon from "@material-ui/icons/Build";

const useStyles = makeStyles(theme => ({
  root: {
    '& .Mui-disabled': {
      color: theme.palette.text.primary,
    },
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    maxWidth: '350px',
  },
  switch: {
    '& .Mui-disabled': {
      color: theme.palette.secondary.main,
    },
    maxWidth: '400px',
  },
  Wrap: {
    padding: '10px',
  },
  titleRoot: {
    fontSize: '18px',
    marginLeft: '10px',
    fontWeight: 600,
    marginTop: '14',
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    marginRight: '10px',
  },
  gridMargin: {
    marginTop: '20px',
  },
  gridMarginTop: {
    marginTop: '5px',
  },
  serviceType: {
    overflow: 'auto',
    maxHeight: '300px',
    background: '#f4f4f7',
  },
  serviceTypeList: {
    fontSize: 14,
    letterSpacing: 0.25,
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
    display: 'block',
    // overflow: 'hidden',
  },
  imageButton: {
    fontSize: '10px',
    marginLeft: '5px',
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  serviceTypeView: {
    marginLeft: 5,
    color: theme.palette.text.primary,
    fontSize: 16,
    letterSpacing: 0.25,
    textOverflow: 'ellipsis',
    maxWidth:'350px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  titleRoot2: {
    fontWeight: '300',
    marginLeft: '5px',
    fontSize: 14,
    textOverflow: 'ellipsis',
    maxWidth: '350px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  info1: {
    fontWeight: '600',
    fontSize: 14,
    whiteSpace: 'nowrap',
  },
  info2: {
    fontWeight: '300',
    marginLeft: '5px',
    fontSize: 14,
  },
  jobLocationType: {
    fontWeight: '300',
    marginLeft: '5px',
    fontSize: 16,
  },
  textField: {
    fontSize: 16,
    fontWeight: 300,
    marginTop: '14',
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    marginRight: '10px',
  },
//   selectedChips: {
//     '& .chip, .singleChip': {
//       // textOverflow: 'ellipsis',  
//       maxWidth: '300px',
//       overflow: 'hidden',
//       whiteSpace: 'nowrap',
//   },
// },
serviceTypeSearchView: {
  minHeight: '40px',
  minWidth: '350px',
  fontSize: '18px',
  '& .chip':{
    background:`${theme.palette.primary.main} !important`
  },
  '& .highlightOption':{
    background:`${theme.palette.primary.main} !important`
  },
  '& .multiSelectContainer li':{
    
      '&:hover': {
        background:`${theme.palette.primary.main} !important`,
     },
    
    
  },
  '& li':{
    fontSize:'14px',
    '&:hover': {
      background:`${theme.palette.primary.main} !important`,
   },
  },
'& input':{
  fontSize:'16px',
  paddingLeft:'5px'
}
      // paddingLeft: '5px',
    // fontSize: '16px',
},
  errText:{
    color: 'red',
    marginLeft: '14px',
    marginRight: '14px',
    fontSize: '0.75rem',
    marginTop: '3px',
    textAlign: 'left',
    fontFamily: 'Normal',
    fontWeight: 400,
    lineHeight: 1.66,
},
}));

const Index = () => {
  const classes = useStyles();
  const { authUser } = useSelector(({ auth }) => auth);
  const allServiceTypeList = useSelector(({ SERVICETYPE }) => SERVICETYPE.users);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [type, setType] = useState(null);
  const [siteType, setSiteType] = useState('');
  const [siteTypeError, setSiteTypeError] = useState('');
  const [address, setAddress] = useState('');
  const [addressError, setAddressError] = useState('');
  const [serviceType, setServiceType] = useState([]);
  const [serviceTypeValue, setServiceTypeValue] = useState([]);
  const [locationImage, setLocationImage] = useState(null);
  const [locationImageUpdate, setLocationImageUpdate] = useState(null);
  const [locationObject, setLocationObject] = useState(null);
  const [latLang, setLatLang] = useState({});
  const [selectedServiceType, setSelectedServiceType] = useState([]);
  const [edit, setEdit] = useState(false);
  const [status, setStatus] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [serviceTypeError, setServiceTypeError] = useState('');
  const [tempUpdatedServiceType, setTempUpdatedServiceType] = useState(null);
  const [isForceUpdate, setIsForceUpdate] = useState(null);
  const dispatch = useDispatch();
  let { id } = useParams();
  async function fetchData() {
    let response = await axios(loctionDetail + id).catch(error => {
      if (error?.response?.status === 401) {
        dispatch(setAuthUser(null));
        history.push('/signin');
      } else {
        dispatch(fetchError(error?.response?.data?.message));
      }
    });
    let user = await response?.data;
    setName(response?.data?.data?.name);
    setLocationImage(response?.data?.data?.image);
    setAddress(response?.data?.data?.address);
    setType(response?.data?.data?.type);
    setSiteType(response?.data?.data?.site_type?.toString());
    setServiceTypeValue(response?.data?.data?.service_types);
    dispatch(setAssetServiceType(response?.data?.data?.service_types))
    setStatus(response?.data?.data?.status);
    setLocationObject(response?.data?.data?.location);
    setLatLang({
      lng: response?.data?.data?.location?.geometry?.location?.lng,
      lat: response?.data?.data?.location?.geometry?.location?.lat,
    });
    setIsForceUpdate(response?.data?.data?.is_force_update);
    dispatch(fetchSuccess());
  }
  useEffect(() => {
    dispatch({ type: GET_ITEM, payload: [] }); //clearing warehouse item
    dispatch(fetchStart());
    fetchData();
    dispatch(getSERVICETYPE('name', 'asc', [1], '', 0, -1));
  }, []);

  const handleEdit = () => {
    // const filterSelectedServiceTypeArray = allServiceTypeList?.data?.map(item => {
    //   let data = false;
    //   data = serviceType?.find(service => (item._id === service ? true : false));
    //   return data ? { name: item.name, _id: item._id, select: true } : { name: item.name, _id: item._id, select: false };
    // });

    // setSelectedServiceType(filterSelectedServiceTypeArray?.filter(item=>item?.select));  
    setEdit(true);
    console.log("serviceTypeValue",serviceTypeValue)

  };
  // const onFilterOptionClick = option => {
  //   const checkData = selectedServiceType?.map(item => {
  //     if (item._id === option._id) {
  //       return item.select
  //         ? { name: item.name, _id: item._id, select: false }
  //         : { name: item.name, _id: item._id, select: true };
  //     } else {
  //       return item;
  //     }
  //   });

  //   setSelectedServiceType(checkData);
  //   setServiceTypeError('');
  // };
  const toggleChecked = () => {
    setStatus(status ? 0 : 1);
  };

  const onSubmitClick = () => {
    const updateServiceType = serviceTypeValue?.map(item => item?._id);
    if (!name || name.match(emptySpace)) {
      setNameError(requiredMessage);
    }
    if (!address) {
      setAddressError(requiredMessage);
    } else if (!latLang) {
      setAddressError('Please select one address from suggestion.');
    }
    if (!updateServiceType?.length) {
      setServiceTypeError(requiredMessage);
    }
    console.log("updateServiceType",updateServiceType)
    if (!siteType?.length) {
      setSiteTypeError(requiredMessage);
    }
    if (type === 1) {
      if (name && !name.match(emptySpace) && address && latLang && updateServiceType.length && isForceUpdate && !status && siteType) {
        setTempUpdatedServiceType(updateServiceType);
        setOpenConfirmDialog(true);
      } else if (name && !name.match(emptySpace) && address && latLang && updateServiceType?.length && siteType) {
        onUserSave(updateServiceType);
      }
    } else {
      if (name && address && latLang && !name.match(emptySpace)) {
        onUserSave(updateServiceType);
      }
    }
  };

  const onUserSave = updateServiceType => {
    const fname = capitalizeFLetter(name);
    const formData = new FormData();
    if (isForceUpdate) formData.append('is_force_update', true);
    formData.append('name', fname);
    formData.append('type', type);
    formData.append('address', address);
    formData.append('site_type', siteType);
    formData.append('location', JSON.stringify(locationObject));
    formData.append('latitude', latLang?.lat);
    formData.append('longitude', latLang?.lng);
    formData.append('status', status ? 1 : 2);
    formData.append('siteImage', locationImage);
    formData.append('service_types', JSON.stringify(updateServiceType));
    dispatch(
      updateLocation(id, formData, () => {
        dispatch(fetchStart());
        fetchData();
        setEdit(false);
      }),
    );
  };
  const handleConfirmForceInactive = () => {
    setOpenConfirmDialog(false);
    onUserSave(tempUpdatedServiceType);
  };
  const handleCancelInactive = () => {
    setOpenConfirmDialog(false);
  };
  async function handleImageUpload(event) {
    const imageFile = event.target.files[0];
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      setLocationImage(compressedFile);
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <CmtCard className={classes.Wrap}>
      <CmtCardContent>
        <GridContainer>
          <Grid item xs={5} sm={6} md={5}>
            <Box ml={5} display={'flex'}>
              <FaceIcon />
              <Typography className={classes.titleRoot}>Name :</Typography>
              <AppTextInput
                className={classes.root}
                multiline
                value={name}
                disabled={!edit}
                onChange={e => {
                  setName(e.target.value.slice(0,40));
                  setNameError('');
                }}
                helperText={nameError}
              />
            </Box>
            <Box ml={5} mt={10} display="flex">
            <LocationOnIcon />
              <Typography className={classes.titleRoot}>Address :</Typography>
              {edit ? (
                <LocationSearch
                  className={classes.root}
                  setAddress={setAddress}
                  setLatLang={setLatLang}
                  setLocationObject={setLocationObject}
                  address={address}
                  variantType={1}
                  addressError={addressError}
                  setAddressError={setAddressError}
                />
              ) : (
                <AppTextInput
                  className={classes.root}
                  multiline
                  value={address}
                  disabled={!edit}
                  onChange={e => {
                    setAddress(e.target.value);
                    setAddressError('');
                  }}
                  placeholder="data"
                  helperText={addressError}
                />
              )}
            </Box>
            {type === 1 ? (
            <Box ml={5} mt={10} display={'flex'}>
              <LocationCityIcon />
              <Typography className={classes.titleRoot}>Job Location Type :</Typography>
              {edit ? (
                <div className={classes.content}>
                <Box ml={3} mb={5}><FormControl component="fieldset">
                  <RadioGroup
                  row
                    onChange={e => {
                      setSiteType(e.target.value);
                      setSiteTypeError('');
                    }}
                    // name="Device Type"
                    
                    value={siteType}>
                    <FormControlLabel value="1" control={<Radio color="primary" />} label="Metro" />
                    <FormControlLabel value="2" control={<Radio color="primary" />} label="Regional" />
                    <FormControlLabel value="3" control={<Radio color="primary" />} label="Remote" />
                    <FormControlLabel value="4" control={<Radio color="primary" />} label="Remote +" />
                  </RadioGroup>
                </FormControl>
                <p
                className="MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-marginDense"
                style={{ color: 'red' }}>
                {siteTypeError}
              </p>
                </Box>
              </div>
              ) : (
                <Box mt={1}><Typography className={classes.jobLocationType}>{siteType == 1 ? "Metro" 
                : siteType == 2 ? "Regional" 
                : siteType == 3 ? "Remote" 
                : "Remote +" }</Typography></Box>
              )}

            </Box>
            ) : null }

          {type === 1 ? (
            <Box ml={5} mt={3} display='flex'>

              {siteType === '1' ? 
              <>
              <Typography className={classes.info1}> Note : </Typography>
              <Typography className={classes.info2}>Sites that are within 0 - 50 km of the General Post Office should be defined as 'Metro' areas.</Typography>
              </>

            : siteType === '2' ? 
            <>
              <Typography className={classes.info1}> Note : </Typography>
              <Typography className={classes.info2}>Sites that are within 50 - 100 km of the General Post Office should be defined as 'Regional' areas.</Typography>
              </>

            : siteType === '3' ? 
            <>
              <Typography className={classes.info1}> Note : </Typography>
              <Typography className={classes.info2}>Sites that are within 50 - 100 km of the General Post Office should be defined as 'Remote' areas.</Typography>
              </>
            
              :
              <> 
              <Typography className={classes.info1}> Note : </Typography>
            <Typography className={classes.info2}>Sites that are more than 100 km from the General Post Office are defined as 'Remote +' areas.</Typography>
            </>
          }
            </Box>
          ) : null }

            {type === 1 ? (
              <Box ml={5} mt={10}>
                <img
                  src={locationImage ? locationImage : process.env.PUBLIC_URL + '/placeholder1.jpeg'}
                  width="400px"
                  alt="You can see this image after you save changes."
                />
                <br />
                {edit ? (
                  <Button variant="contained" component="label" mt={5}>
                    <input
                      type="file"
                      hidden
                      name="file"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={handleImageUpload}
                    />
                    <CloudUploadIcon />
                    <p className={classes.imageButton}>{locationImage?.size ? locationImage?.name : 'Choose Image'}</p>
                  </Button>
                ) : null}
              </Box>
            ) : null}
          </Grid>
          <Grid item xs={5} sm={4} md={5}>
            <Box ml={5} display="flex">
            {status === 1 ? <CheckCircleOutline /> : <Block />}
              <Typography className={classes.titleRoot}>
                Status (Active/Inactive) :{' '}
                <Switch
                  checked={status === 1 ? true : false}
                  className={classes.switch}
                  disabled={!edit || !authUser?.fm_module_access[4]?.is_enable}
                  onChange={toggleChecked}
                />
              </Typography>
            </Box>
            <Box ml={5} mt={10} display="flex">
              <HomeWorkIcon />
              <Typography className={classes.titleRoot}>Type : </Typography>{' '}
              <Typography className={classes.textField}>{type === 1 ? 'Job Location' : 'Inventory Site'}</Typography>
            </Box>

            {type === 1 ? (
            <Box ml={5} mt={10}>
            <Box display="flex">
            <Box><BuildIcon /></Box>
            <Box><Typography className={classes.titleRoot}>Service Type</Typography></Box>
            </Box>
            {edit ? 
              <Box ml={8} mt={3}> <Multiselect
                options={allServiceTypeList?.data} // Options to display in the dropdown
                selectedValues={serviceTypeValue} // Preselected value to persist in dropdown
                onSelect={(selectedList, selectedItem)=>{setServiceTypeValue(selectedList);setServiceTypeError("")}} // Function will trigger on select event
                onRemove={(selectedList, selectedItem)=>setServiceTypeValue(selectedList)} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options
                placeholder="Select Service Type"
                className={classes.serviceTypeSearchView}
              />
              <p className={classes.errText} style={{ color: 'red' }}>{serviceTypeError}</p>
                </Box>
               : 
               <Box ml={6}>
               <Typography className={classes.titleRoot2}>{serviceTypeValue?.map((item,index)=><li className={classes.serviceTypeView} key={index} style={{ listStyleType: "none" }}>{item?.name}</li>)}</Typography>
               </Box>
               } 
              </Box>
            ) : null }
            

            {/* {type === 1 ? (
              <Box ml={5} mt={10}>
                <Typography className={classes.titleRoot}>Service Type</Typography>

                {!edit ? (
                  <Box className={classes.serviceType} mt={2}>
                    {filterSelectedServiceTypeArray?.map((value, index) => {
                      const labelId = `transfer-list-all-item-${value}-label`;

                      return (
                        <ListItem key={index} role="listitem" button>
                          <ListItemIcon>
                            <Checkbox checked={value.select} tabIndex={-1} disabled={true} />
                          </ListItemIcon>
                          <ListItemText id={labelId} primary={` ${value.name}`} className={classes.serviceTypeList} />
                        </ListItem>
                      );
                    })}
                  </Box>
                ) : (
                  <Box className={classes.serviceType} mt={2}>
                    {selectedServiceType?.map((value, index) => {
                      const labelId = `transfer-list-all-item-${value}-label`;

                      return (
                        <ListItem key={index} role="listitem" button onClick={e => onFilterOptionClick(value)}>
                          <ListItemIcon>
                            <Checkbox checked={value.select} tabIndex={-1} />
                          </ListItemIcon>
                          <ListItemText id={labelId} primary={` ${value.name}`} className={classes.serviceTypeList} />
                        </ListItem>
                      );
                    })}
                  </Box>
                )}
                <p
                 className={classes.errText}
                  style={{ color: 'red' }}>
                  {serviceTypeError}
                </p>
              </Box>
            ) : null} */}
          </Grid>
          <Grid item xs={2} sm={2} md={1}>
            <Box ml={4}>
              {edit ? (
                <Button variant="contained" color="primary" onClick={onSubmitClick}>
                  Save
                </Button>
              ) : authUser?.fm_module_access[4]?.is_edit ? (
                <Button variant="contained" color="primary" onClick={handleEdit}>
                  Edit
                </Button>
              ) : null}
            </Box>
          </Grid>
        </GridContainer>
      </CmtCardContent>
      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm Inactive `}
        content={'If you Inactive this job location, all the service request and planned maintenance created for this job location will be deleted, do you want to  inactive this location?'}
        onClose={handleCancelInactive}
        onConfirm={handleConfirmForceInactive}
      />
    </CmtCard>
  );
};

export default Index;
