import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';
import { DELETE_BULK_ISSUEMANAGEMENT, DELETE_ISSUEMANAGEMENT, GET_ISSUEMANAGEMENT, SET_ISSUEMANAGEMENT_DETAILS,
} from '../../@jumbo/constants/ActionTypes';
import { addIssue, listIssue, updateIssue, deleteIssue, statusUpdate } from '../../@jumbo/constants/ApiConstatnt';
import { history } from 'redux/store';
import { setAuthUser } from './Auth';

// get FM provider list
export const getIssueManagement = (sortBy, sort, searchTerm, page, limit, filterOptions, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(listIssue, {
        params: { sortBy: `${sortBy}:${sort}`, search: searchTerm, page: page + 1, limit: limit, status: filterOptions[0] },
      })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_ISSUEMANAGEMENT, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch({ type: GET_ISSUEMANAGEMENT, payload: [] });
          dispatch(fetchError());
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

// set current user for view tab
export const setCurrentIssueManagement = user => {
  return dispatch => {
    dispatch({ type: SET_ISSUEMANAGEMENT_DETAILS, payload: user });
  };
};

// add new FM provider 
export const addNewIssueManagement = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(addIssue, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('New Issue added successfully.'));
          }, 2000);
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const updateIssueManagementStatus = (data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(statusUpdate, data)
      .then(response => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Issue status updated successfully.'));
          }, 2000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

// all function below are not in use currntly
export const deleteBulkUsers = (userIds, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/users/bulk-delete', { userIds })
      .then(response => {
        if (response.status === 200) {
          dispatch(fetchSuccess('Selected users were deleted successfully.'));
          dispatch({ type: DELETE_BULK_ISSUEMANAGEMENT, payload: userIds });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const deleteUser = (userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete(deleteIssue+userId)
      .then(data => {
        if (data.status === 200) {
          setTimeout(() => {
            dispatch(fetchSuccess('Issues deleted successfully.'));
          }, 2000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const sentMailToUser = () => {
  return dispatch => {
    dispatch(fetchSuccess('Email has been sent to user successfully'));
  };
};

export const UpdateIssue = (user, id, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(updateIssue+id, user)
      .then(data => {
        if (data.status === 200) {
          setTimeout(() => {
            dispatch(fetchSuccess('Issue was updated successfully.'));
          }, 2000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};