import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import CmtCard from '../../../@coremat/CmtCard';
import { alpha, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { getBudgetManagement, updateBudgetManagement } from '../../../redux/actions/BudgetManagement';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import AppTextInput from '../../../@jumbo/components/Common/formElements/AppTextInput';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import NoRecordFound from './NoRecordFound';

const variant = 'default';
const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  card: {
    marginBottom: '10px'
  },
  card2: {
    paddingTop: 175,
    paddingBottom: 175
  },
  authContent: {
    padding: 30,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    fontSize: 16,
    fontStyle: 'bold',
    marginLeft: 5,
    marginTop: 10,
    // marginBottom: 14,
    color: theme.palette.text.primary,

  },
  root: {
    '& .MuiTypography-body1': {
      fontWeight: "900",
      fontSize: "20px"
    },
    '& .MuiInputBase-root': {
      fontWeight: "900",
      fontSize: "20px"
    },
    marginLeft: 5,
    width: 'auto',
  },

  cardTitle: {
    fontSize: 20,
    fontStyle: 'bold',

  },
  ellipsisClass: {
    fontSize: 16,
    marginBottom: 10,
    color: theme.palette.text.primary,
    letterSpacing: 0.25,
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  headerTitle1: {
    marginLeft: 35,
    whiteSpace: 'nowrap',
    display: 'block',
    overflow: 'hidden',
    fontSize: 25,
    fontWidth: 600,
    textOverflow: "ellipsis",
    maxWidth: 400,
  },
  headerTitle2: {
    // marginLeft: 20,
    whiteSpace: 'nowrap',
    fontWidth: 600,
    display: 'block',
    overflow: 'hidden',
    fontSize: 25,
    textOverflow: "ellipsis",
    maxWidth: 500,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  formcontrolLabelRoot: {
    '& .MuiFormControlLabel-label': {
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
  },
}));
const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'Budget Management', isActive: true },
];

const BudgetManagement = () => {
  const classes = useStyles({ variant });
  const { authUser } = useSelector(({ auth }) => auth);
  const { users } = useSelector(({ BUDGETMANAGEMENT }) => BUDGETMANAGEMENT);
  const [buttonText, setButtonText] = useState('EDIT');
  const [text, setText] = useState(false);
  const [budgetList, setBudegetList] = useState([]);
  const [serviceError, setServiceError] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBudgetManagement());
  }, []);
  const handleSiteBudget = (e, id) => {
    const newBudgetList = budgetList?.map(item => (item._id === id ? { ...item, site_budget: e.target.value } : item));
    setBudegetList(newBudgetList);
  };
  const handleServiceTypeBudget = (e, siteId, serviceTypeId, siteBudget, index) => {

    const newBudgetList = budgetList?.map(item => {
      if (item._id === siteId) {
        const serviceType = item?.service_type_details?.map(service =>
          service.service_type_id === serviceTypeId ? { ...service, budget: e.target.value } : service,
        );
        return { ...item, service_type_details: serviceType };
      } else {
        return item;
      }
    });
    setBudegetList(newBudgetList);
    document.getElementById(`${siteId}1`).style.display = "none";
    let total = 0;
    const extradata = budgetList[index]?.service_type_details?.forEach(element => {
      return total += Number(element.budget ?? 0)
    });
    setServiceError(false)

  };
  const onUserSave = () => {
    const budgets = budgetList?.map(item => ({
      site_id: item._id,
      site_budget: item.site_budget ? Number(item.site_budget) : 0,
      service_budget: item.service_type_details.map(list => ({
        service_type_id: list.service_type_id,
        budget: list.budget ? Number(list.budget) : 0,
      })),
    }));

    const BudgetDetail = {
      budgets,
    };
    dispatch(
      updateBudgetManagement(BudgetDetail, () => {
        dispatch(getBudgetManagement());
      }),
    );
  };
  const handleValidation = () => {
    let errorCheck = 0
    const extradata = budgetList?.forEach(service => {

      let total = 0;
      let siteId = service?._id;
      let siteBudget = service?.site_budget

      const check = service?.service_type_details?.forEach(element => total += Number(element.budget ?? 0))
      if (siteBudget < total) {
        document.getElementById(`${siteId}1`).style.display = "block";
        setServiceError(prev => true)
        errorCheck = 1
      } else {
        document.getElementById(`${siteId}1`).style.display = "none";
      }
    })

    if (!errorCheck) {
      onUserSave()
      setText(false);
      setButtonText('EDIT');
    }
  }

  const handleEdit = () => {
    if (buttonText === 'EDIT') {
      setBudegetList(users?.data);
      setText(true);
      setButtonText('SAVE');
    } else {
      handleValidation()
    }
  };

  return (
    <>
      <PageContainer heading="Budget Management" breadcrumbs={breadcrumbs}>
        {users?.data?.length ? <>
          <CmtCard alignitems="center" className={classes.card} mb={5} mt={5}>

            <GridContainer>
              <Grid item xs={6} sm={6} md={6} className={classes.headerTitle1}>
                <Box mt={6}>
                  <Typography style={{ fontWeight: "600", fontSize: "16px" }}>ANNUAL BUDGET</Typography>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6} md={6} className={classes.headerTitle2}>
                <Box ml={'80%'} mt={5}>
                  {authUser?.fm_module_access[5]?.is_edit ? (
                    <Button variant="contained" onClick={handleEdit} color="primary">
                      {buttonText}
                    </Button>
                  ) : null}
                </Box>
              </Grid>
            </GridContainer>

            <GridContainer style={{ marginBottom: "10px" }}>
              <Grid item xs={4} sm={4} md={4} className={classes.headerTitle1}>
                <Box>
                  <Typography style={{ fontWeight: "900", fontSize: "20px" }}> $ {users?.totalAnnualBudget?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</Typography>
                  <Typography style={{ fontWeight: "300", fontSize: "16px" }}>Total Annual Budget</Typography>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6} md={6} className={classes.headerTitle2}>
                <Box>
                  <Typography style={{ fontWeight: "900", fontSize: "20px" }}> $ {users?.totalRemainingAnnualBudget?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</Typography>
                  <Typography style={{ fontWeight: "300", fontSize: "16px" }}>Total Remaining Annual Budget</Typography>
                </Box>
              </Grid>
            </GridContainer>
          </CmtCard>

          {text === false
            ? users?.data?.map((item,index) => (
              <CmtCard alignitems="center" mt={5} key={index} className={classes.card}>
                <Grid item xs={6} sm={6} mt={10}>
                  <Box mt={7} ml={10}>
                    <Typography className={classes.cardTitle}>{item?.name}</Typography>
                  </Box>
                </Grid>

                <GridContainer>
                  <Grid item xs={4} sm={4} mt={10}>
                    <Box mt={5} ml={10}>
                      <Typography className={classes.titleRoot} style={{ fontWeight: "900", fontSize: "20px" }}> $ {item?.site_budget ? item?.site_budget?.toLocaleString(undefined, { maximumFractionDigits: 2 }) : 0}</Typography>
                      <Typography className={classes.titleRoot} style={{ fontWeight: "300", fontSize: "14px" }}>Job Location Budget</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={6} mt={13}>
                    <Box mt={5} ml={15}>
                      <Typography className={classes.titleRoot} style={{ fontWeight: "900", fontSize: "20px" }}> $ {item?.remaining_site_budget ? item?.remaining_site_budget?.toLocaleString(undefined, { maximumFractionDigits: 2 }) : 0}</Typography>
                      <Typography className={classes.titleRoot} style={{ fontWeight: "300", fontSize: "14px" }}>Remaining Budget</Typography>
                    </Box>
                  </Grid>
                </GridContainer>

                <GridContainer style={{ backgroundColor: "rgba(221, 221, 221, 0.33)", margin: "20px", width: "80%", borderRadius: "15px" }}>
                  <Grid item xs={4} sm={4}>
                    {item?.service_type_details?.map((list,index) => (
                      <Box ml={10} key={index}>
                        <Typography className={classes.titleRoot} style={{ fontWeight: "900", fontSize: "20px", marginTop: "15px" }}> $ {list?.budget ? list?.budget?.toLocaleString(undefined, { maximumFractionDigits: 2 }) : 0}</Typography>
                        <Typography className={classes.ellipsisClass} style={{ fontWeight: "300", fontSize: "14px" }}>{list?.service_type_name}</Typography>
                      </Box>
                    ))}
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    {item?.service_type_details?.map((list,index) => (
                      <Box ml={20} key={index}>
                        <Typography className={classes.titleRoot} style={{ fontWeight: "900", fontSize: "20px", marginTop: "15px" }}> ${' '}{list?.remaining_service_budget ? list?.remaining_service_budget?.toLocaleString(undefined, { maximumFractionDigits: 2 }) : 0}</Typography>
                        <Typography className={classes.ellipsisClass} style={{ fontWeight: "300", fontSize: "14px" }}>Remaining {list?.service_type_name}</Typography>
                      </Box>
                    ))}
                  </Grid>
                </GridContainer>
              </CmtCard>
            ))
            : budgetList?.map((item, index) => (
              <CmtCard alignitems="center" key={index} className={classes.card} mb={5}>
                <Grid item xs={6} sm={6} mt={10}>
                  <Box mt={7} ml={10}>
                    <Typography className={classes.cardTitle}>{item?.name}</Typography>
                  </Box>
                </Grid>
                <GridContainer>
                  <Grid item xs={4} sm={4} mt={10}>
                    <Box mt={3} ml={10}>
                      <AppTextInput
                        InputProps={{
                          inputProps: { min: 0 },
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        id={item?._id}
                        className={classes.root}
                        onPaste={(e) => {
                          e.preventDefault()
                          return false;
                        }}
                        onCopy={(e) => {
                          e.preventDefault()
                          return false;
                        }}
                        // style={{fontWeight: "900", fontSize:"20px"}}
                        defaultValue={item?.site_budget ? item?.site_budget : 0}
                        type="number"
                        onKeyPress={event => {
                          if (['-', '+', 'e', '.'].includes(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onBlur={e => handleSiteBudget(e, item?._id)}
                      />
                      <Typography className={classes.titleRoot} style={{ fontWeight: "300", fontSize: "14px" }}>Job Location Budget</Typography>
                    </Box>

                  </Grid>
                  <Grid item xs={6} sm={6} mt={10}>
                    <Box mt={3} ml={10}>
                      <Typography className={classes.titleRoot} style={{ fontWeight: "900", fontSize: "20px" }}>$ {item?.remaining_site_budget ? item?.remaining_site_budget : 0}</Typography>
                      <Typography className={classes.titleRoot} style={{ marginTop: "5px", fontWeight: "300", fontSize: "14px" }}>Remaining Budget</Typography>
                    </Box>
                  </Grid>
                </GridContainer>

                <GridContainer style={{ backgroundColor: "rgba(221, 221, 221, 0.33)", margin: "20px", width: "80%", borderRadius: "15px" }}>
                  <Grid item xs={4} sm={4} mt={15}>
                    {item?.service_type_details?.map((list,index) => (
                      <Box mt={2} ml={15} key={index}>
                        <AppTextInput
                          InputProps={{
                            inputProps: { min: 0 },
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                          onPaste={(e) => {
                            e.preventDefault()
                            return false;
                          }} onCopy={(e) => {
                            e.preventDefault()
                            return false;
                          }}
                          // style={{fontWeight: "900", fontSize:"20px"}}
                          type='number'
                          id={list?.service_type_id}
                          className={classes.root}
                          onKeyPress={event => {
                            if (['-', '+', 'e', '.'].includes(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          // label={list?.service_type_name}
                          defaultValue={list?.budget ? list?.budget : 0}
                          onBlur={e => handleServiceTypeBudget(e, item?._id, list?.service_type_id, item.site_budget, index)}
                        />
                        <Typography className={classes.ellipsisClass} style={{ fontWeight: "300", fontSize: "14px" }}>{list?.service_type_name}</Typography>
                      </Box>
                    ))}
                  </Grid>
                  <Grid item xs={6} sm={6} mt={7}>
                    {item?.service_type_details?.map((list,index) => (
                      <Box mt={1} ml={15} key={index}>
                        <Typography className={classes.titleRoot} style={{ fontWeight: "900", fontSize: "20px" }}>$ {list?.remaining_service_budget}</Typography>
                        <Typography className={classes.ellipsisClass} style={{ marginTop: "5px", fontWeight: "300", fontSize: "14px" }}>Remaining {list?.service_type_name}</Typography>
                      </Box>
                    ))}

                  </Grid>

                </GridContainer>
                <GridContainer style={{ marginBottom: '30px', marginLeft: '50px' }}>
                  <Grid>
                    <p id={`${item._id}1`} style={{ display: 'none', marginTop: '10', color: 'red' }} className="MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-marginDense" >
                      The total budget for all services must not exceed the total Job Location budget.
                    </p>
                  </Grid>
                </GridContainer>

              </CmtCard>
            ))}
        </> :
          <CmtCard alignitems="center" className={classes.card2}><NoRecordFound>There are no records found</NoRecordFound></CmtCard>}

      </PageContainer>
    </>
  );
};

export default BudgetManagement;
