import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import Button from '@material-ui/core/Button';
import { requiredMessage } from '@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { emptySpace } from '@jumbo/constants/ValidationRegex';
import {
  getCallCenterSiteList,
  getCallCenterServiceType,
  getCallCenterIssueList,
  getCallCenterAssetList,
  addNewCertificate,
  getCallCenterServiceRequestList,
  addNewServiceRequest,
} from 'redux/actions/CallCenterServiceRequest';
import AppSelectBox from '@jumbo/components/Common/formElements/AppSelectBox';
import UserSelectBox from '../../../Settings/Locations/LocationDetails/AssignedUser/UserSelectBox';
import { getAssetType } from 'redux/actions/AssetLocation';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCard from '@coremat/CmtCard';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import { Checkbox, TableCell, TableRow } from '@material-ui/core';
// import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles(theme => ({
  root: {
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    "& .MuiFormLabel-root": {
      color: '#909090'
    },
    "& .MuiInputLabel-shrink":{
color:theme.palette.text.primary
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink':{
      color: theme.palette.text.primary
    },
  },
  dialogRoot: {
    position: 'relative',
    '& .MuiDialog-paperWidthSm': {
      width: '100%',
      maxWidth: '1000px',
    },
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.text.primary,
    },
  },
  cardRoot: {
    position: 'relative',
    '& .Cmt-card-content': {
      padding: 0,
      paddingBottom: 24,
    },
  },
  scrollbarRoot: {
    height: 340,
  },

  tableHeadRowCellRoot: {
    fontSize: 14,
    fontWeight: 600,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 16,
    letterSpacing: 0.4,
    textAlign: 'center',
    color: theme.palette.common.dark,
    borderBottom: '0 none',
    '&:first-child': {
      paddingLeft: 24,
    },
    '&:last-child': {
      paddingRight: 24,
    },
  },

  tableRowCellRoot: {
    fontSize: 14,
    fontWeight: 300,
    // letterSpacing: 0.25,
    // color: theme.palette.common.dark,
    textOverflow: 'ellipsis',
    maxWidth:'150px',
    // display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 16,
    letterSpacing: 0.4,
    textAlign: 'center',
    color: theme.palette.common.dark,
    borderBottom: '0 none',
    '&:first-child': {
      paddingLeft: 24,
    },
    '&:last-child': {
      paddingRight: 24,
    },
  },

  tableCellRoot: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: 14,
    letterSpacing: 0.25,
    textAlign: 'center',
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    borderBottom: '0 none',
    '&:first-child': {
      paddingLeft: 24,
      textAlign: 'left',
    },

    '& .Cmt-avatar-more': {
      fontSize: 10,
      color: theme.palette.primary.main,
    },
  },
}));

const AddEditUser = ({ open, onCloseDialog, getParams }) => {
  const classes = useStyles();

  const { currentUser, siteList, serviceTypeList, issueList, assetTable } = useSelector(({ CALLCENTER }) => CALLCENTER);
  const assetTypeList = useSelector(({ ASSETLOCATION }) => ASSETLOCATION.assetType);
  const {  orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions } = getParams;
  const [site, setSite] = useState('');
  const [siteError, setSiteError] = useState('');
  const [serviceType, setServiceType] = useState(null);
  const [serviceTypeError, setServiceTypeError] = useState('');
  const [assetType, setAssetType] = useState(null);
  const [assetTypeError, setAssetTypeError] = useState(null);
  const [issue, setIssue] = useState(null);
  const [issueError, setIssueError] = useState('');
  const [tenant, setTenant] = useState('');
  const [tenantError, setTenantError] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [asset, setAsset] = useState('');
  const [assetError, setAssetError] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCallCenterSiteList());
    dispatch(getAssetType());
  }, []);
  useEffect(() => {
    if (assetType && site && tenant && serviceType && issue) {
      dispatch(getCallCenterAssetList(assetType, site, tenant, serviceType));
    }
  }, [assetType, site, tenant, serviceType, issue]);
  useEffect(() => {
    setAsset(null);
  }, [assetType, site]);
  const onSubmitClick = () => {
    if (!site) setSiteError(requiredMessage);

    if (!tenant) setTenantError(requiredMessage);

    if (!serviceType) setServiceTypeError(requiredMessage);

    if (!description || description.match(emptySpace)) setDescriptionError(requiredMessage);

    if (!assetType) setAssetTypeError(requiredMessage);

    if (!issue) setIssueError(requiredMessage);

    if (!asset) setAssetError(requiredMessage);

    if (site && tenant && serviceType && asset && issue && description && !description.match(emptySpace) && assetType) onUserSave();
  };

  const onUserSave = () => {
    const userDetail = {
      service_type_id: serviceType,
      issue_id: issue,
      asset_id: asset,
      location_id: site,
      tenant_id: tenant,
      description: description,
    };
    dispatch(
      addNewServiceRequest(userDetail, () => {
        onCloseDialog();
        dispatch(getCallCenterServiceRequestList(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
      }),
    );
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>
        {currentUser ? 'Edit Service Request Details' : 'Add New Service Request'}
      </DialogTitle>
      <DialogContent dividers>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
          <GridContainer>
            <Grid item xs={12} sm={6} className={classes.root}>
              <AppSelectBox
                className={classes.root}
                fullWidth
                data={siteList}
                label="Job Location"
                valueKey="_id"
                variant="outlined"
                labelKey="name"
                value={site}
                onChange={e => {
                  setTenant('')
                  setServiceType(null)
                  setIssue('s')
                  setSite(e.target.value);
                  console.log("e.target.value",e.target.value)
                  dispatch(getCallCenterServiceType(e.target.value));
                  setSiteError('');
                }}
                helperText={siteError}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.root}>
              <UserSelectBox
                className={classes.root}
                fullWidth
                data={serviceTypeList?.userData}
                label="Tenant"
                valueKey="_id"
                variant="outlined"
                labelKey="first_name"
                value={tenant}
                onChange={e => {
                  setTenant(e.target.value);
                  setTenantError('');
                }}
                helperText={tenantError}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.root}>
              <AppSelectBox
                className={classes.root}
                fullWidth
                data={serviceTypeList?.locationData[0]?.service_type_details}
                label="Service Type"
                valueKey="_id"
                variant="outlined"
                labelKey="name"
                value={serviceType}
                onChange={e => {
                  setIssue('')
                  setServiceType(e.target.value);
                  dispatch(getCallCenterIssueList(e.target.value));
                  setServiceTypeError('');
                }}
                helperText={serviceTypeError}
              />
            </Grid>
            <Grid item xs={12} sm={6} >
              <AppTextInput
                className={classes.root}
                fullWidth
                variant="outlined"
                label="Description"
                value={description}
                onChange={e => {
                  setDescription(e.target.value);
                  setDescriptionError('');
                }}
                helperText={descriptionError}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.root}>
              <AppSelectBox
                className={classes.root}
                fullWidth
                data={assetTypeList}
                label="Asset Type Problem Area"
                valueKey="_id"
                variant="outlined"
                labelKey="name"
                value={assetType}
                onChange={e => {
                  setAssetType(e.target.value);
                  setAssetTypeError('');
                }}
                helperText={assetTypeError}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.root}>
              <AppSelectBox
                className={classes.root}
                fullWidth
                data={issueList}
                label="Problem Details"
                valueKey="_id"
                variant="outlined"
                labelKey="name"
                value={issue}
                onChange={e => {
                  setIssue(e.target.value);
                  setIssueError('');
                }}
                helperText={issueError}
              />
            </Grid>
          </GridContainer>
        </Box>

        <CmtCard className={classes.cardRoot}>
          <CmtCardHeader title="Asset"></CmtCardHeader>
          <CmtCardContent>
            <PerfectScrollbar className={classes.scrollbarRoot}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHeadRowCellRoot}></TableCell>
                    <TableCell className={classes.tableHeadRowCellRoot}>Asset Name</TableCell>
                    <TableCell className={classes.tableHeadRowCellRoot}>Floor</TableCell>
                    <TableCell className={classes.tableHeadRowCellRoot}>Department</TableCell>
                    <TableCell className={classes.tableHeadRowCellRoot}>ID</TableCell>
                    <TableCell className={classes.tableHeadRowCellRoot}>Brand</TableCell>
                    <TableCell className={classes.tableHeadRowCellRoot}>Model</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!assetTable?.length ? (
                    <>
                      {/* {' '} */}
                      <TableRow>
                      <TableCell className={classes.tableRowCellRoot}></TableCell>
                      <TableCell className={classes.tableRowCellRoot}>-</TableCell>
                      <TableCell className={classes.tableRowCellRoot}>-</TableCell>
                      <TableCell className={classes.tableRowCellRoot}>-</TableCell>
                      <TableCell className={classes.tableRowCellRoot}>-</TableCell>
                      <TableCell className={classes.tableRowCellRoot}>-</TableCell>
                      <TableCell className={classes.tableRowCellRoot}>-</TableCell>
                      </TableRow>
                    </>
                  ) : null}
                  {assetTable?.map(item => (
                    <TableRow>
                      <TableCell className={classes.tableCellRoot}>
                        <Box>
                          {' '}
                          <Checkbox
                            checked={item?._id === asset ? true : false}
                            onClick={e => {
                              setAsset(item._id);
                              setAssetError('');
                            }}
                          />
                        </Box>
                      </TableCell>

                      <TableCell className={classes.tableRowCellRoot}>{item?.name}</TableCell>
                      <TableCell className={classes.tableRowCellRoot}>{item?.floor}</TableCell>
                      <TableCell className={classes.tableRowCellRoot}>{item?.department}</TableCell>
                      <TableCell className={classes.tableRowCellRoot}>{item?.asset_id}</TableCell>
                      <TableCell className={classes.tableRowCellRoot}>{item?.brand}</TableCell>
                      <TableCell className={classes.tableRowCellRoot}>{item?.model}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </PerfectScrollbar>
          </CmtCardContent>
        </CmtCard>
        <p className="MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-marginDense" style={{ color: 'red' }}>
          {assetError}
        </p>
        <Box display="flex" justifyContent="flex-end" mb={4} mt={5}>
          <Button variant="outlined" onClick={onCloseDialog}>
            Cancel
          </Button>
          <Box ml={2}>
            <Button variant="contained" style={{ minWidth: '100px' }} color="primary" onClick={onSubmitClick}>
              ADD
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditUser;

AddEditUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
