import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BuildIcon from '@material-ui/icons/Build';
import SettingsIcon from '@material-ui/icons/Settings';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PersonIcon from '@material-ui/icons/Person';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ApartmentIcon from '@material-ui/icons/Apartment';
import React from 'react';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import LocationOnIcon from '@material-ui/icons/LocationOn';
export const Dashboard  = {
    name: 'Dashboard',
    icon: <DashboardIcon />,
    type: 'item',
    link: '/dashboard',
  }
 export const userManagement={
    name: 'User Management',
    icon: <SupervisedUserCircleIcon />,
    type: 'item',
    link: '/user-management',
  }

  export const settings={
    name: 'Settings',
    icon: <SettingsIcon />,
    type: 'item',
    link: '/settings',
  }
  export const budgetManagement={
    name: 'Budget Management',
    icon: <MonetizationOnIcon />,
    type: 'item',
    link: '/budget-management',
  }
  export const itemManagement={
    name: 'Parts Management',
    icon: <BuildIcon />,
    type: 'item',
    link: '/items-management',
  }
  export const vendorManagement={
    name: 'Vendor Management',
    icon: <PersonIcon />,
    type: 'item',
    link: '/vendor-management',
  }

  export const serviceRequests={
    name: 'Service Requests',
    icon: <AssignmentIcon />,
    type: 'item',
    link: '/service-requests',
  }
  export const plannedMaintenance={
    name: 'Planned Maintenance',
    icon: <AvTimerIcon />,
    type: 'item',
    link: '/planned-maintenance',
  }
  export const accountDetails={
    name: 'Account Details',
    icon: <AvTimerIcon />,
    type: 'item',
    link: '/account-details',
  }


  export const accountDetails1={
    name: 'Account Detailshjgfdbjfhj',
    icon: <AvTimerIcon />,
    type: 'item',
    link: '/account-details',
  }
  export const Compliance={
    name: 'Compliance',
    icon: <AssignmentTurnedInIcon />,
    type: 'item',
    link: '/compliance',
  }
  export const ContactUs={
    name: `'Contact Us' queries`,
    icon: <LiveHelpIcon />,
    type: 'item',
    link: '/contactusqueries',
  }
  export const Vendors= {
    name: 'Vendors',
    icon: <PersonIcon />,
    type: 'item',
    link: '/vendors',
  }
  export const AssignedSite={
    name: 'Assigned Job Locations',
    icon: <ApartmentIcon />,
    type: 'item',
    link: '/assigned-sites',
  }
  
  export const PaymentsToVendors={
    name: 'Payments to Vendors',
    icon: <AttachMoneyIcon />,
    type: 'item',
    link: '/payments-to-vendors',
  }
  export const serviceRequestCallCenter={
    name: 'Service Requests',
    icon: <AssignmentIcon />,
    type: 'item',
    link: '/service-requests-call-center',
  }
  export const locationManagement={
    name: 'Portfolio Location',
    icon: <LocationOnIcon />,
    type: 'item',
    link: '/location-management',
  }