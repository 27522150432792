import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableHeading from './TableHeading';
import TableBody from '@material-ui/core/TableBody';
import TableItem from './TableItem';
import Box from '@material-ui/core/Box';

const ProjectTable = ({ data }) => {
  return (
    
      <Table stickyHeader aria-label="sticky table">
        <TableHead >
          <TableHeading />
        </TableHead>
        <TableBody>
          {data?.map((row,index) => (
            <TableItem row={row} key={index} />
          ))}
        </TableBody>
      </Table>
  
  );
};

export default ProjectTable;
