import { fetchError, fetchStart, fetchSuccess } from './Common';
// import axios from 'axios';
import axios from '../../services/auth/jwt/config';
import {
  DELETE_BULK_FMPROVIDER,
  DELETE_FMPROVIDER,
  EDIT_FMPROVIDER,
  GET_LOCATIONLIST,
  GET_WAREHOUSE_LIST,
  SET_ASSET_SERVICETYPE,
  GET_ASSIGNEDUSERLOCATION,
} from '../../@jumbo/constants/ActionTypes';
import {
  locationList,
  locationAdd,
  locationUpdate,
  assignedAddLocation,
  assignedListLoaction,
  locationStatus,
  locationDelete,
} from '../../@jumbo/constants/ApiConstatnt';
import { setAuthUser } from './Auth';
import { history } from 'redux/store';
import JWTAuth from 'services/auth/jwt';

// get Locaton list
export const geLocationList = (sortBy, sort, searchTerm, page, limit, filterOptions, locationOptions, callbackFun) => {
  return dispatch => {
    
    dispatch(fetchStart());

    axios
      .get(locationList, {
        params: { sortBy: `${sortBy}:${sort}`, search: searchTerm, page: page + 1, limit: limit, status: filterOptions?.length ? filterOptions[0] : null, type: locationOptions?.length ? locationOptions[0] : null},
      })
      .then(data => {
        if (data.status === 200) {
         
          dispatch({ type: GET_LOCATIONLIST, payload: data.data.data });
          
          if (callbackFun) callbackFun(data.data);
          dispatch(fetchSuccess());
        } else {
          dispatch({ type: GET_LOCATIONLIST, payload: [] });
          dispatch(fetchError());
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

//Warehouse Listing for Vendors
export const getWarehouseList = (sortBy, sort, searchTerm, page, limit, filterOptions, warehouseFilter, callbackFun) => {
  return dispatch => {
    
    dispatch(fetchStart());

    axios
      .get(locationList, {
        params: { sortBy: `${sortBy}:${sort}`, search: searchTerm, page: page + 1, limit: limit, status: filterOptions[0], type:warehouseFilter },
      })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_WAREHOUSE_LIST, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch({ type: GET_WAREHOUSE_LIST, payload: [] });
          dispatch(fetchError());
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

// set current user for view tab
export const setAssetServiceType = user => {
  return dispatch => {
    dispatch({ type: SET_ASSET_SERVICETYPE, payload: user });
  };
};

// add new Location
export const addNewLocation = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(locationAdd, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('New Location added successfully.'));
          }, 2000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};
export const updateLocation = (id, user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(locationUpdate + id, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Location updated successfully.'));
          }, 2000);
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};
export const updateLocationStatus = (data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(locationStatus, data)
      .then(response => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Location status updated successfully.'));
          }, 2000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};
export const deleteLocation = (id, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete(locationDelete+ id)
      .then(response => {
        if (response) {
          setTimeout(() => {
            dispatch(fetchSuccess('Location deleted successfully.'));
          }, 2000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

// Location Assigned user
export const assignUser = (user, userData, createdById, logedUserId, logedUserType, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(assignedAddLocation + user.siteId + '/' + user.userId, userData)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('New User assigned successfully.'));
          }, 2000);
          console.log('test', createdById,logedUserId,userData._id, userData)
          if (createdById !== logedUserId && logedUserType !== 3 && logedUserId !== userData._id && createdById!==undefined && userData._id!==undefined) {
            history.push('/settings');
          }
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};
export const getAssignUser = Id => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(assignedListLoaction + Id)
      .then(data => {
        if (data) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_ASSIGNEDUSERLOCATION, payload: data.data });
        } else {
          dispatch(fetchError());
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          if (error?.response?.data?.message === 'User assigned not found.') return dispatch(fetchError());
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};
// all function below are not in use currntly
export const deleteBulkUsers = (userIds, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/users/bulk-delete', { userIds })
      .then(response => {
        if (response.status === 200) {
          dispatch(fetchSuccess('Selected users were deleted successfully.'));
          dispatch({ type: DELETE_BULK_FMPROVIDER, payload: userIds });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const deleteUser = (userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete('/users', { params: { id: userId } })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('Selected user was deleted successfully.'));
          dispatch({ type: DELETE_FMPROVIDER, payload: userId });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const sentMailToUser = () => {
  return dispatch => {
    dispatch(fetchSuccess('Email has been sent to user successfully'));
  };
};

export const updateUser = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/users', user)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('Selected user was updated successfully.'));
          dispatch({ type: EDIT_FMPROVIDER, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};
