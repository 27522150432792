import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';
import {
  GET_WORK_ORDER,
  GET_WORK_ORDER_DETAILS,
  SET_WORK_ORDER_DETAILS,
} from '../../@jumbo/constants/ActionTypes';
import { addWorkOrder, workOrderList, workOrderDetails } from '../../@jumbo/constants/ApiConstatnt';
import { setAuthUser } from './Auth';
import { history } from 'redux/store';

// add work order
export const addNewWorkOrder = (id, user, callbackFun) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post(addWorkOrder + id, user)
        .then(data => {
          if (data) {
            setTimeout(() => {
              dispatch(fetchSuccess('Work order created successfully.'));
            }, 2000);
            // dispatch({ type:  ADD_TENANT, payload: data.data });
            if (callbackFun) callbackFun(data.data);
          } else {
            dispatch(fetchError('There was something issue in responding server.'));
          }
        })
        .catch(error => {
          if(error?.response?.status===401 ){
            dispatch(setAuthUser(null));
            history.push('/signin')
          } else{
            dispatch(fetchError(error?.response?.data?.message));}
        });
    };
  };

  // get work order list
  export const getWorkOrderList = ( id, sortBy, sort, searchTerm, page, limit, callbackFun) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .get(workOrderList+id, {
          params: { sortBy: `${sortBy}:${sort}`, search: searchTerm, page: page + 1, limit: limit },
        })
        .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess());
            dispatch({ type: GET_WORK_ORDER, payload: data.data.data });
            if (callbackFun) callbackFun(data.data);
          } else {
            dispatch({ type: GET_WORK_ORDER, payload: [] });
            dispatch(fetchError());
          
          }
        })
        .catch(error => {
          if(error?.response?.status===401 ){
            dispatch(setAuthUser(null));
            history.push('/signin')
          } 
          else {
            dispatch(fetchError(error?.response?.data?.message));
          }
        });
    };
  };

// set current user for view tab
export const setCurrentWorkOrder = user => {
  return dispatch => {
    dispatch({ type: GET_WORK_ORDER_DETAILS, payload: user });
  };
};

  export const getWorkOrderDetails = id => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .get(workOrderDetails + id)
        .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess());
            dispatch({ type: GET_WORK_ORDER_DETAILS, payload: data.data.data });
          } else {
            dispatch(fetchError('There was something issue in responding server.'));
          }
        })
        .catch(error => {
          if (error?.response?.data?.message === 'Work Order not found.') {
            dispatch(fetchError(error?.response?.data?.message));
            dispatch({ type: GET_WORK_ORDER_DETAILS, payload: [] });
          } else {
            dispatch(fetchError(error?.response?.data?.message));
          }
        });
    };
  };