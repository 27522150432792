import React, { useState } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Block, CheckCircleOutline, Delete, Edit, Mail, MoreHoriz, Visibility } from '@material-ui/icons';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { sentMailToUser, updateUserStatus } from '../../../../../../../redux/actions/Users';
import { getItemsList ,updateItemListStatus} from '../../../../../../../redux/actions/ItemsList';
import { Activate, Deactivate } from '@jumbo/constants/stringConstant';
import moment from 'moment';
import UserDetailView from 'routes/modules/UserManagement/UserDetailView';
import { fetchError, fetchStart, fetchSuccess } from '../../../../../../../redux/actions/Common';
import { setAuthUser } from 'redux/actions/Auth';
import { setCurrentFMProvider } from 'redux/actions/UserManagement';
import axios from 'services/auth/jwt/config'
import { history } from 'redux/store';

const useStyles = makeStyles(theme => ({
  linkColor: {
    color: theme.palette.secondary.main,
    fontWeight: 300,
  },
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    maxWidth:'250px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontWeight: 300,
  },
}));

const getUserActions = user => {
  const actions = [
    { action: 'view', label: 'View', icon: <Visibility /> },
    { action: 'edit', label: 'Edit', icon: <Edit /> },
  ];

  if (user.status === 1) {
    actions.push({ action: 'suspend', label: Deactivate, icon: <Block /> });
  } else {
    actions.push({
      action: 'activate',
      label: Activate,
      icon: <CheckCircleOutline />,
    });
  }
  return actions;
};

const UserListRow = ({ row, isSelected, onRowClick, onUserEdit, onUserDelete, onUserView, getSERVICETYPEParams }) => {
  const { orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions } = getSERVICETYPEParams;
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const classes = useStyles();
  const { authUser } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();

  function refreshContactList() {
    dispatch(getItemsList(orderBy, order,debouncedSearchTerm, page, rowsPerPage,filterOptions));
  }
  const labelId = `enhanced-table-checkbox-${row?._id}`;
  const isItemSelected = isSelected(row?._id);
  const userActions = getUserActions(row);
  async function fetchData(fmUserId) {
    let response = await axios(
      `/fm-provider/user-management/fm-user-details/${fmUserId}`
    ).then(response=>dispatch(setCurrentFMProvider(response?.data?.data))).catch(error => {
      if (error?.response?.status === 401) {
        dispatch(setAuthUser(null));
        history.push('/signin');
      } else {
        dispatch(fetchError(error?.response?.data?.message));
      }
    });
    let user = await response?.data;

    setOpenViewDialog(true);
    dispatch(fetchSuccess());
   
  }
  const handleUserDetail=(row)=>{
    if(authUser?.fm_module_access[0]?.is_view)
    {
      dispatch(fetchStart());
      fetchData(row.user_id);
    }
  }
  
  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
    dispatch(setCurrentFMProvider(null));
  };

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row?._id}
      selected={isItemSelected}>
      <TableCell padding="checkbox">
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
            <Typography className={classes.titleRoot} component="div" variant="h4">
              {row?.field_name ? row?.field_name+' ' +row?.operation : 'Inventory Site Created'}
            </Typography>
      </TableCell>
      <TableCell><Typography className={classes.titleRoot} component="div" variant="h4">
              {moment(row?.created_at * 1000).format('hh:mm A DD/MM/YYYY')}
            </Typography></TableCell>
      <TableCell className={classes.linkColor} onClick={e=>handleUserDetail(row)} style={{cursor:'pointer'}}>{row?.first_name+" "+row?.last_name}</TableCell>
      {openViewDialog && <UserDetailView open={openViewDialog} onCloseDialog={handleCloseViewDialog} />}
    </TableRow>
  );
};

export default React.memo(UserListRow);
