import React from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
// import CmtAvatar from '../../../../@coremat/CmtAvatar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import { useSelector } from 'react-redux';
// import CmtList from '../../../../@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import useStyles from './index.style';
import { Grid, ListItem, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import GridContainer from '@jumbo/components/GridContainer';

const UserDetailView = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const { currentUser } = useSelector(({ ASSIGNEDSITE }) => ASSIGNEDSITE);
  // const { first_name, last_name, email, status,user_type } = currentUser;

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.userInfoRoot}>
        <Box mr={3} display="flex" alignItems="center">
          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.titleRoot}>Job Location Details</Typography>
              <Box ml={1}></Box>
            </Box>
          </Box>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}></Box>
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <CmtCard className={classes.WrapSite}>
        <CmtCardContent>
          <GridContainer>
            <Grid item xs={8} sm={6} md={6}>
              <Box ml={5} display={'flex'}>
                <Typography className={classes.titleRoot1}>Name : </Typography>
                <Typography className={classes.titleRoot3}>{currentUser?.name}</Typography>
              </Box>
              <Box ml={5}  mt={5} display="flex">
                <Typography className={classes.titleRoot1}>Address : </Typography>
                <Typography className={classes.titleRoot3}>{currentUser?.address}</Typography>
              </Box>

              <Box ml={5} mt={5}>
                <img
                  src={currentUser?.image ? currentUser?.image : process.env.PUBLIC_URL + '/placeholder1.jpeg'}
                  width="200px"
                  alt="You can see this image after you save changes."
                />
                <br />
              </Box>
            </Grid>
            <Grid item xs={8} sm={5} md={5}>
              
            <Box ml={5} display="flex">
              <Typography className={classes.titleRoot1}>Account User : </Typography>
              <Typography className={classes.titleRoot3}>{currentUser?.first_name} {currentUser?.last_name}</Typography>
            </Box>

              <Box ml={5} mt={5} display="flex">
                <Typography className={classes.titleRoot1}>Service Type : </Typography>

                <Box className={classes.serviceType}>
                  {currentUser?.service_type_details?.map((value, index) => {
                    const labelId = `transfer-list-all-item-${value}-label`;

                    return (
                      // <ListItem key={index} role="listitem" >
                      //   {/* <ListItemIcon> */}
                      //     {/* <Checkbox checked={true} tabIndex={-1} disabled={true} /> */}
                      //   {/* </ListItemIcon> */}
                      //   <ListItemText id={labelId} primary={`${value.name}`} className={classes.serviceTypeList} />
                      // </ListItem>
                      <Typography className={classes.titleRoot2} display="flex"><p> <li className={classes.serviceTypeView} style={{ listStyleType: "none" }}>{value.name}</li></p></Typography>
                    );
                  })}
                </Box>
              </Box>
            </Grid>
          </GridContainer>
        </CmtCardContent>
      </CmtCard>
      <CmtCard className={classes.wrapBudget}>
        <CmtCardContent>
          <Box ml={5}>
          <GridContainer style={{ marginBottom: '20px' }}>
            <Grid item xs={8} sm={6} md={6}>
              <Typography className={classes.titleRoot}>
              Job Location Budget : {currentUser ? (currentUser?.site_budget?.site_budget ? `$${currentUser?.site_budget?.site_budget?.toLocaleString(undefined, { maximumFractionDigits: 2 })}` : '$0') : '$0'}
              </Typography>
            </Grid>
            <Grid item xs={8} sm={5} md={5}>
              <Typography className={classes.titleRoot}>
                Remaining Budget : {currentUser ? (currentUser?.site_budget?.remaining_site_budget ? `$${currentUser?.site_budget?.remaining_site_budget?.toLocaleString(undefined, { maximumFractionDigits: 2 })}` : '$0') : '$0'}
              </Typography>
            </Grid>
          </GridContainer>
          
            {currentUser
              ? currentUser?.site_budget?.service_budget?.map((item,index) => (
                <GridContainer key={index}>
                    <Grid item xs={8} sm={6} md={6} >
                      <Box ml={5}>
                        <Typography className={classes.titileroot2}>
                          {' '}
                          <span className={classes.serviceTypeList}>{item?.service_type_name}</span> Budget :{' '}
                          {item?.budget ? `$${item?.budget?.toLocaleString(undefined, { maximumFractionDigits: 2 })}` : '$0'}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={8} sm={6} md={6}>
                      <Box ml={5}>
                        <Typography className={classes.titileroot2}>
                          Remaining <span className={classes.serviceTypeList}>{item?.service_type_name}</span> Budget :{' '}
                          {item?.remaining_service_budget ? `$${item?.remaining_service_budget?.toLocaleString(undefined, { maximumFractionDigits: 2 })}` : '$0'}
                        </Typography>
                      </Box>
                    </Grid>
                    </GridContainer>
                ))
              : null}{' '}
          
          </Box>
        </CmtCardContent>
      </CmtCard>
    </Dialog>
  );
};

export default UserDetailView;

UserDetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
