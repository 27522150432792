import React from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import useStyles from './index.style';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import ReturnTable from './ReturnTable';
import HomeIcon from '@material-ui/icons/Home';
const UserDetailView = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const { details } = useSelector(({ RETURNITEM }) => RETURNITEM);

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.userInfoRoot}>
        <Box mr={3} display="flex" alignItems="center">
          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.titleRoot}>Part Detail</Typography>
              <Box ml={1}></Box>
            </Box>
          </Box>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box px={6} py={5}>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <LocalActivityIcon />
          <Box ml={5}  display="flex">
            <Typography className={classes.titleRoot1}>Vendor : </Typography>
            <Typography className={classes.titleRoot2}>{!details?.first_name?"-":`${details?.first_name}  ${details?.last_name}`}</Typography>
          </Box>{' '}
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <HomeIcon />
          <Box ml={5}  display="flex">
            <Typography className={classes.titleRoot1}>Inventory Site : </Typography>
            <Typography className={classes.titleRoot2}>{!details?.warehouse_name?"-":details?.warehouse_name}</Typography>
          </Box>{' '}
        </Box>
        <ReturnTable details={details} />
      </Box>
    </Dialog>
  );
};

export default UserDetailView;

UserDetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
