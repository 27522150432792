import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import AppSelectBox from '@jumbo/components/Common/formElements/AppSelectBox';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { numberOnly, numberOnly2, emptySpace } from '@jumbo/constants/ValidationRegex';
import { Typography } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles'
import { getAssetPartsList } from 'redux/actions/AssetLocation';
import moment from 'moment';
import { requiredMessage } from '@jumbo/constants/ErrorMessages';

const useStyles = makeStyles(theme => ({
  root: {
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 200,
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
      marginLeft:"8px"
    },
    "& .MuiFormLabel-root": {
      color: '#909090'
    },
    "& .MuiInputLabel-shrink":{
color:theme.palette.text.primary
    },
  },
  datePicker: {
    '& .MuiFormHelperText-root': {
      color: 'red',
    }
  }
}));

const AddPart = ({setAddPartData, serialNumberError, setSerialNumberError, expirationDateError, setExpirationDateError, partNameError, setPartNameError, setSameSerialNumber, setListFinal}) => {
  const classes = useStyles();
  const { partData, currentUser } = useSelector(({ ASSETLOCATION }) => ASSETLOCATION);
  const dispatch = useDispatch();
  const [itemIdError, setItemIdError] = useState('');
  const [installationDateError, setInstallationDateError] = useState('');
  const [checkValidationData, setCheckValidationData] = useState('');
  const [partError, setPartError] = useState('');
  const [inputList, setInputList] = useState([{item_id:'', serial_number:'', installation_date:new Date(), expiration_date:null}])

  useEffect(() => {
    if (currentUser) {
      const showPartData = currentUser?.items?.map(data=>{
        return (   
        {item_id:data?.item_id?._id, 
        serial_number:data?.serial_number, 
        installation_date: moment(data?.installation_date * 1000).format('YYYY-MM-DD'),
        expiration_date: data?.expiration_date==null ? null : moment(data?.expiration_date * 1000).format('YYYY-MM-DD'),
      }
        )}
      )

      if(showPartData.length)
      {
        setInputList(showPartData);
      }
      else
      {
        setInputList([{item_id:'', serial_number:'', installation_date:new Date(), expiration_date:null}]);
      }
      setAddPartData(showPartData)
    }
  }, [currentUser]);

  let serialNumberArray = inputList.map((item)=>{ 
    return( item?.serial_number ) })

  let FindMatchSerial = serialNumberArray.filter((el, index) => serialNumberArray.indexOf(el) !== index)
  if(FindMatchSerial.length >= 1) 
  {  
    setSerialNumberError("Please enter unique serial number.")
    setSameSerialNumber(true)
  }

  const handleInputChange = (date, index, name)=>{
    const list = [...inputList];
    const dateList = [...addPartData?.items];
    if(name!=="item_id" && name!=="serial_number")
    {
      const dateFormatX = date
      const dateFormat = date
      list[index][name]=moment(dateFormat)
      dateList[index][name]=moment(dateFormatX)
    }
    else if(name==="serial_number")
    {
      setSerialNumberError('')
      list[index][name]=date.target.value.slice(0, 24)
      dateList[index][name]=date.target.value.slice(0, 24)
    }
    else
    {
      // setPartNameError('')
      list[index][name]=date.target.value
      dateList[index][name]=date.target.value
    }
    console.log("dateList",dateList)
    setInputList(list);
    // setAddPartData(dateList)
    setListFinal(list)
    if(dateList?.length) setAddPartData(dateList)
  }

  const handleAddMoreRow = ()=>{
    
    setPartNameError('')
    setSerialNumberError('')
    setExpirationDateError('')

    setInputList([...inputList,{item_id:'', serial_number:'', installation_date:new Date(), expiration_date:null}])
    setListFinal([...inputList,{item_id:'', serial_number:'', installation_date:new Date(), expiration_date:null}])
  }

  const handleRemovePart =(index)=>{
    const dateList = [...addPartData?.items];
    const list = [...inputList];
    // console.log("addPartData",addPartData)
    list.splice(index, 1)
    const addPartDataWithXDateFormat = list?.map(finalData=>{
      return (
      {item_id:finalData?.item_id,
      serial_number:finalData?.serial_number,
      installation_date:Number(moment(finalData?.installation_date).format('X')),
      expiration_date:Number(moment(finalData?.expiration_date).format('X'))}
      )}
    )
    // console.log("addPartDataWithXDateFormat",addPartDataWithXDateFormat)
    setAddPartData(addPartDataWithXDateFormat)
    setInputList(list)
    setListFinal(list)
  }

    const addPartData = {
    items: inputList
}
console.log("currentUser",currentUser)


  return (
    <>
      <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
        <GridContainer>
          {
          inputList?.map( (x, i)=>{
            console.log("x?.installation_date",x?.installation_date)
            return(
              <>
                <Grid item xs={2} sm={2} className={classes.appSelectBox}>
                <AppSelectBox
                data={partData?.data?.data}
                label="Select Part"
                id="item_id"
                valueKey="_id"
                variant="outlined"
                labelKey="name"
                // value={users?.data}
                value={x.item_id}
                name="item_id"
                onChange={e => {
                  handleInputChange(e, i, "item_id")
                }}
                helperText={!x.item_id ? partNameError : ''}
              />
                </Grid>

                <Grid item xs={3} sm={3} className={classes.root}>
                <AppTextInput
                disabled={x?.item_id ? false : true}
                id="serial_number"
                fullWidth
                variant="outlined"
                label="Serial Number"
                value={x?.serial_number || ""}
                name="serial_number"
                onChange={e => {
                  handleInputChange(e, i, "serial_number")
                }}
                // helperText={FindMatchSerial.includes(x?.serial_number) && !x?.serial_number?.match(emptySpace) && x?.serial_number ? serialNumberError : 
                //   (!x?.serial_number && serialNumberError) || (x?.serial_number.match(emptySpace) && serialNumberError) ? serialNumberError : ''}
                // helperText={FindMatchSerial.includes(x?.serial_number) || x?.serial_number?.match(emptySpace) ? serialNumberError : x?.serial_number ? "" : serialNumberError}
                helperText={x?.item_id ? FindMatchSerial.includes(x?.serial_number) && !x?.serial_number?.match(emptySpace) && !x?.serial_number ? serialNumberError : "" : ""}
                // && !x?.serial_number?.match(emptySpace) && !x?.serial_number 
                // !x?.serial_number && serialNumberError ? "This field is Required!" :
                // helperText={x?.item_id ? FindMatchSerial.includes(x?.serial_number) && !x?.serial_number?.match(emptySpace) && !x?.serial_number ? serialNumberError : !x?.serial_number && serialNumberError ? "This field is Required!" : "" : ""}

                />
                </Grid>

                <Grid item xs={3} sm={3}>
                <KeyboardDatePicker
                // disabled={x?.serial_number ? false : true}
                placeholder='DD/MM/YYYY'
                id="date-picker-dialog"
                label="Installation Date"
                format="DD/MM/YYYY"
                // minDate={currentUser ? "" : new Date()}
                value={x?.installation_date}
                name="installation_date"
                onChange={date => { handleInputChange(date, i, "installation_date")
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change Date',
                }}
                className={classes.textField}
                />
                </Grid>

                <Grid item xs={3} sm={3}>
                <KeyboardDatePicker
                // disabled={x?.serial_number ? false : true}
                placeholder='DD/MM/YYYY'
                id="date-picker-dialog"
                label="Expiration Date"
                format="DD/MM/YYYY"
                minDate={currentUser ? moment(x?.installation_date, "YYYY-MM-DD").add(1, "d") : moment(x?.installation_date, "DD/MM/YYYY").add(1, "d")}
                value={x?.expiration_date}
                name="expiration_date"
                onChange={date => {
                  handleInputChange(date, i, "expiration_date")
                  setExpirationDateError('')
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change Date',
                }}
                className={classes.textField}
                helperText={(moment(x?.expiration_date) < moment(x?.installation_date)) ? expirationDateError : ""}
                />
                </Grid>
                
                {inputList?.length > 1 ? (
                <Grid item xs={1} sm={1}>
                  <IconButton aria-label="delete" className={classes.margin} onClick={()=>handleRemovePart(i)}>
                    <DeleteIcon />
                  </IconButton>
                  </Grid>
                   )
                  : <Grid item xs={1} sm={1}></Grid>
                  }
              </>
           );
          })}
        </GridContainer>
      </Box>
      <GridContainer>
        <Box ml={4}><Button variant="outlined" onClick={handleAddMoreRow}>+ Add More</Button></Box>
        </GridContainer>
    </>
  );
};

export default AddPart;
