import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Box } from '@material-ui/core';
import CmtCard from '../../../@coremat/CmtCard';
import CmtCardHeader from '../../../@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '../../../@coremat/CmtCard/CmtCardContent';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { AuhMethods } from '../../../services/auth';
import { CurrentAuthMethod } from '../../../@jumbo/constants/AppConstants';
import { useDispatch, useSelector } from 'react-redux';
import { lettersOnlyMessage, requiredMessage } from '../../../@jumbo/constants/ErrorMessages';
import axios from '../../../services/auth/jwt/config';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import { capitalizeFLetter } from '../../../@jumbo/utils/commonHelper';
import { setAuthUser } from 'redux/actions/Auth';
import { history } from 'redux/store';
const variant = 'default';
const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
  },
  formcontrolLabelRoot: {
    '& .MuiFormControlLabel-label': {
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
  },
}));

const EditUser = ({ method = CurrentAuthMethod }) => {
  const authUser = useSelector(({ auth }) => auth);
  const classes = useStyles({ variant });
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const dispatch = useDispatch();

  async function fetchData() {
    let response = await axios('users/profile/').catch(error => {
      if (error?.response?.status === 401) {
        dispatch(setAuthUser(null));
        history.push('/signin');
      } else {
        dispatch(fetchError(error?.response?.data?.message));
      }
    });
    let user = await response.data;
    setFirstName(response?.data?.data?.first_name);
    setLastName(response?.data?.data?.last_name);

    dispatch(fetchSuccess());
  }

  useEffect(() => {
    dispatch(fetchStart());
    fetchData();
  }, []);

  const nameRegex = /^[a-zA-Z]+$/;

  const Validation = () => {
    if (firstName === '') {
      setFirstNameError(requiredMessage);
    } else if (!firstName.match(nameRegex)) {
      setFirstNameError(lettersOnlyMessage);
    }
    if (lastName === '') {
      setLastNameError(requiredMessage);
    } else if (!lastName.match(nameRegex)) {
      setLastNameError(lettersOnlyMessage);
    }
    if (firstName !== '' && lastName !== '' && firstName.match(nameRegex) && lastName.match(nameRegex)) {
      const fname = capitalizeFLetter(firstName);
      const lname = capitalizeFLetter(lastName);
      setFirstNameError('');
      setLastNameError('');
      dispatch(AuhMethods[method].editProfile(fname, lname));
    }
  };

  const handelonKeyDown = e => {
    if (e.keyCode === 13) {
      //passwordFeild.current.focus();
      Validation();
    }
  };

  return (
    <>
      <CmtCard>
        <CmtCardHeader title="Edit User Profile">
          <Box className={classes.textUppercase} fontSize={5} color="text.secondary"></Box>
        </CmtCardHeader>
        <CmtCardContent>
          <Box mb={2}>
            <TextField
              placeholder="Enter Firstname"
              //label={<IntlMessages id="appModule.email" />}
              fullWidth
              onChange={event => {
                setFirstName(event.target.value);
                if (event.target.value !== '' && event.target.value.match(nameRegex)) {
                  setFirstNameError('');
                }
              }}
              //onChange={event => setFirstName(event.target.value)}
              value={firstName}
              helperText={firstNameError}
              margin="normal"
              variant="outlined"
              id="firstName"
              className={classes.textFieldRoot}
            />
          </Box>
          <Box mb={2}>
            <TextField
              //label={<IntlMessages id="appModule.email" />}
              placeholder="Enter Lastname"
              fullWidth
              onChange={event => {
                setLastName(event.target.value);
                if (event.target.value !== '' && event.target.value.match(nameRegex)) {
                  setLastNameError('');
                }
              }}
              //onChange={event => setLastName(event.target.value)}
              value={lastName}
              helperText={lastNameError}
              margin="normal"
              variant="outlined"
              id="lastName"
              onKeyDown={handelonKeyDown}
              className={classes.textFieldRoot}
            />
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
            <Button variant="contained" onClick={Validation} color="primary">
              Save
              {/* <IntlMessages id="appModule.signIn" /> */}
            </Button>
          </Box>
        </CmtCardContent>
      </CmtCard>
    </>
  );
};

export default EditUser;
