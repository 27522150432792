import React , { useState }from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import CmtAvatar from '@coremat/CmtAvatar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { useSelector } from 'react-redux';
import CmtList from '@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import useStyles from './index.style';
import { Block, CheckCircleOutline } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import FaceIcon from '@material-ui/icons/Face';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import UserAccessModule from '.././UserAccessModule/index'
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import moment from 'moment';
import DescriptionIcon from '@material-ui/icons/Description';
import AssignmentIcon from '@material-ui/icons/Assignment';
import TodayIcon from '@material-ui/icons/Today';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
// import ApprovedContractor from '../'
// import ContractorVerification from '../VendorDetails/ContractorVerification/index'

const UserDetailView = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { currentUser } = useSelector(({ FMPROVIDER }) => FMPROVIDER);
  const { name, title, certified_on, valid_till,description } = currentUser;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.userInfoRoot}>
        <Box mr={3} display="flex" alignItems="center">
          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.titleRoot}>Certificate Detail</Typography>
              <Box ml={1}></Box>
            </Box>
          </Box>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}></Box>
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box px={6} py={5}>
        {name==='Other' ?
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <AssignmentIcon />
          <Box ml={5} display="flex" color="primary" component="p">
          {/* Certification Name : {title} */}
          <Typography className={classes.titleRoot1}>Certification Name :</Typography>
          <Typography className={classes.titleRoot2}>{title}</Typography>
          </Box>
        </Box>
        : null}

        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <AssignmentIcon />
          <Box ml={5} display="flex" color="primary" component="p">
          {/* Certification Type : {name} */}
          <Typography className={classes.titleRoot1}>Certification Type :</Typography>
          <Typography className={classes.titleRoot2}>{name}</Typography>
          </Box>
        </Box>
        <Box display="flex"  mb={{ xs: 4, sm: 7 }}>
          <DescriptionIcon />
          <Box ml={5} display="flex" color="primary">
          {/* <Box>Description</Box> : <Box ml={1} className={classes.description} >{description?description:'-'}</Box> */}
          <Typography className={classes.titleRoot1}>Description</Typography>
          <Typography className={classes.description}>{description?description:'-'}</Typography>
          </Box>
        </Box>
        {/* <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <TodayIcon />
          <Box ml={5} color="primary" component="p" className="pointer">
            Certified On : {moment(certified_on * 1000).format('hh:mm A DD/MM/YYYY')}
          </Box>
        </Box> */}
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <EventAvailableIcon />
          <Box ml={5} display="flex" color="primary" component="p">
            {/* Expiry Date : {moment(valid_till * 1000).format('hh:mm A DD/MM/YYYY')} */}
            <Typography className={classes.titleRoot1}>Expiry Date :</Typography>
          <Typography className={classes.titleRoot2}>{moment(valid_till * 1000).format('hh:mm A DD/MM/YYYY')}</Typography>
          </Box>
        </Box>
       
       
        
        {/* <Paper className={classes.root} >
      <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" >
        <Tab label="Approved Contractors" />
        <Tab label="Contractor Verification Requests" />
      </Tabs>
    </Paper>
    {value === 0 ? <ApprovedContractor/>  :null}
{value === 1 ? <ContractorVerification/>  :null} */}
        </Box>
    </Dialog>
  );
};

export default UserDetailView;

UserDetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
