import React from "react";
import moment from "moment";
import "./index.css";
import CalendarPopup from "./CalendarPopup";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import useStyles from "./index.style";

const EventWrapper = ({ event, children,onChange }) => {
  const [open, setOpen] = React.useState(false);
  const [serviceRequset, setServiceRequset] = React.useState(null);
  const { title, className } = children.props;
  const customClass = `${className} ${event?.priority}`;
  const priority = event?.priority;
  const status = event?.status;
  const classes = useStyles();
  console.log("even",onChange)

  return (
    <>
    <div
      title={title}
      className={priority ? priority : "na"}
      style={{ display: "flex", minHeight: "35px", cursor: "pointer" ,fontWeight:900, }}
      onClick={() => {
        setServiceRequset(event);
        setOpen(true);
      }}

    >
      {status === 1 || status === 5 ? (
        <HourglassEmptyIcon
          style={{ width: "20px", height: "20px", marginTop: "2px" }}
        />
        // {eventColor:"rgb(49,116,173)"}
      ) : status === 2 ? (
        <img
          src={
            process.env.PUBLIC_URL + "/images/status_icon/status-varifying.png"
          }
          width="20px"
          height="20px"
          style={{ marginTop: "3px", marginRight: "2px" }}
        />
      ) : status === 3 ? (
        <img
          src={
            process.env.PUBLIC_URL + "/images/status_icon/status-waiting.png"
          }
          width="20px"
          height="20px"
          style={{ marginTop: "3px", marginRight: "2px" }}
        />
      ) : status === 4 || status === 6 || status === 7 || status === 8 ? (
        <img
          src={process.env.PUBLIC_URL + "/images/status_icon/status-done.png"}
          width="20px"
          height="20px"
          style={{ marginTop: "3px", marginRight: "2px" }}
        />
      ) : status === 9 ? (
        <img
          src={process.env.PUBLIC_URL + "/images/status_icon/status-info.png"}
          width="20px"
          height="20px"
          style={{ marginTop: "3px", marginRight: "2px" }}
        />
      ) : null}

      {/* {priority ? (
        <span style={{ marginTop: "3px" ,maxHeight:"10px"}}>{priority} - </span>
      ) : null} */}
      <span className={classes.eventTitle}>
        {children.props.children}
      </span>
      
    </div>
    {open ? (
      <CalendarPopup
        open={open}
        setOpen={setOpen}
        events={event}
        serviceRequset={serviceRequset}
        onUserView={onChange}
      />
    ) : null}
    </>
  );
};

export default EventWrapper;
