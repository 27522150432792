import React from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import EmailIcon from '@material-ui/icons/Email';
import useStyles from './index.style';
import { Block, CheckCircleOutline } from '@material-ui/icons';
import FaceIcon from '@material-ui/icons/Face';
import { Active, Inactive } from '@jumbo/constants/stringConstant';
import moment from 'moment';
const UserDetailView = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const { currentUser } = useSelector(({ TENANT }) => TENANT);
  const { first_name, last_name, email, status, user_type ,lease_expiry_date} = currentUser;

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.userInfoRoot}>
        <Box mr={3} display="flex" alignItems="center">
          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.titleRoot}>Tenant Detail</Typography>
              <Box ml={1}></Box>
            </Box>
          </Box>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}></Box>
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box px={6} py={5}>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <FaceIcon />

          <Box ml={5} display="flex">
            <Typography className={classes.labelField}>First Name :</Typography>
            <Typography className={classes.textField}> {first_name}</Typography>
          </Box>
          <Box ml={5} display="flex">
            <Typography className={classes.labelField}> Last Name :</Typography>
            <Typography className={classes.textField}> {last_name}</Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <EmailIcon />
          <Box ml={5} color="primary" display="flex">
            <Typography className={classes.labelField}>Email :</Typography>
            <Typography className={classes.textField}>{email}</Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <EmailIcon />
          <Box ml={5} color="primary" display="flex">
            <Typography className={classes.labelField}>Lease Expiry Date :</Typography>
            <Typography className={classes.textField}>{!lease_expiry_date?"-":moment(lease_expiry_date * 1000).format('DD/MM/YYYY ')}</Typography>
          </Box>
        </Box>

        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }}>
          {status === 1 ? <CheckCircleOutline /> : <Block />}
          <Box ml={5} display="flex">
            <Typography className={classes.labelField}> Status (Active/Inactive) :</Typography>
            <Typography className={classes.textField}> {status === 1 ? Active : Inactive}</Typography>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default UserDetailView;

UserDetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
