/* eslint-disable */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme=>({
  table: {
    minWidth: 650,
    "& .MuiTableCell-head": {
      fontWeight:"600",
      backgroundColor:theme.palette.primary.main,
      color:"white"
  },
  },
  tableRow: {
    fontWeight:"300",
  },
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    textOverflow: 'ellipsis',
    maxWidth: '250px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

export default function ReturnTable({ details }) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Part</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>Serial No</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            !details?.item_data?
            <TableRow  className={classes.tableRow}>
              <TableCell component="th" scope="row" >
                -
              </TableCell>
              <TableCell>
                <Typography className={classes.titleRoot}>-</Typography>
              </TableCell>
              <TableCell>-</TableCell>
              <TableCell>-</TableCell>
              {/* <TableCell></TableCell> */}
            </TableRow>:null
          }
          {details?.item_data.map((row, index) => (
            <TableRow key={index} className={classes.tableRow}>
              <TableCell component="th" scope="row" >
                {index + 1}
              </TableCell>
              <TableCell>
                <Typography className={classes.titleRoot}>{row?.item_name}</Typography>
              </TableCell>
              <TableCell>{row?.required_quantity}</TableCell>
              <TableCell>
                {row?.items_serial_number.join(", ")}
              </TableCell>
              
              
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
