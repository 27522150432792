import {
    UPDATE_BUDGETMANAGEMENT,
    // EDIT_BUDGETMANAGEMENT,
    GET_BUDGETMANAGEMENT,
  } from '../../@jumbo/constants/ActionTypes';
  
  const INIT_STATE = {
    users: [],
    currentUser: null,
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_BUDGETMANAGEMENT: {
        return {
          ...state,
          users: action.payload,
        };
      }
      case UPDATE_BUDGETMANAGEMENT: {
        return {
          ...state,
          users: [action.payload, ...state.users],
        };
      }
      default:
        return state;
    }
  };
  