import { fetchError, fetchStart, fetchSuccess } from "../../../redux/actions";
import {
  setAuthUser,
  setForgetPassMailSent,
  updateLoadUser,
} from "../../../redux/actions/Auth";
import React from "react";
import axios from "./config";
import { AuhMethods } from "../index";
import { history } from "redux/store";
import { FETCH_DATA_SUCCESS } from "@jumbo/constants/ActionTypes";
import { getCustomization } from "redux/actions/Customization";
import { refreshTokenApi } from "@jumbo/constants/ApiConstatnt";
// import { setAuthUser } from './Auth';

const JWTAuth = {
  onRegister: ({ name, email, password }) => {
    return (dispatch) => {
      dispatch(fetchStart());
      axios
        .post("auth/register", {
          email: email,
          password: password,
          name: name,
        })
        .then(({ data }) => {
          if (data.result) {
            localStorage.setItem("token", data.token.access_token);
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + data.token.access_token;
            dispatch(fetchSuccess());
            dispatch(JWTAuth.getAuthUser(true, data.token.access_token));
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },

  onLogin: ({ email, password }) => {
    return (dispatch) => {
      try {
        dispatch(fetchStart());
        axios
          .post("users/login", {
            email: email,
            password: password,
            user_type: [3, 4, 5, 6],
          })
          .then(({ data }) => {
            localStorage.setItem("q_r_t", data.data.refresh_tokens);
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + data.data.auth_token;
            dispatch(fetchSuccess("Welcome to AssetBoz."));
            dispatch(JWTAuth.getAuthUser(true, data.data.auth_token));
          })
          .catch(function(error) {
            setTimeout(() => {
              dispatch(fetchError(error?.response?.data?.message), 3000);
            }, 2000);
          });
      } catch (error) {
        dispatch(fetchError(error?.response?.data?.message), 3000);
      }
    };
  },
  onLogout: () => {
    return (dispatch) => {
      dispatch(fetchStart());
      axios
        .delete("users/logout")
        .then(({ data }) => {
          if (data) {
            dispatch(fetchSuccess());
            localStorage.removeItem("q_r_t");
            dispatch(setAuthUser(null));
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            dispatch(setAuthUser(null));
            history.push("/signin");
          } else {
            dispatch(fetchError(error?.response?.data?.message));
          }
        });
    };
  },

  getAuthUser: (loaded = false, token) => {
    return (dispatch) => {
      if (!token) {
        axios
          .post(refreshTokenApi, {
            refresh_tokens: localStorage.getItem("q_r_t"),
          })
          .then(({ data }) => {
            if (data.data) {
              // dispatch(fetchSuccess());
              axios.defaults.headers.common["Authorization"] =
                "Bearer " + data.data.token;
              dispatch(fetchStart());
              // dispatch(updateLoadUser(false));
              axios("users/profile/")
                .then(({ data }) => {
                  if (data.data) {
                    // dispatch(fetchSuccess());

                    dispatch({ type: FETCH_DATA_SUCCESS });

                    dispatch(setAuthUser(data.data));
                    dispatch(getCustomization());
                  } else {
                    dispatch(updateLoadUser(true));
                  }
                })
                .catch(function(error) {
                  dispatch(updateLoadUser(true));
                });
            } else {
              dispatch(updateLoadUser(true));
            }
          })
          .catch(function(error) {
            dispatch(updateLoadUser(true));
          });
      } else {
        dispatch(fetchStart());
        dispatch(updateLoadUser(loaded));
        axios("users/profile/")
          .then(({ data }) => {
            if (data.data) {
              // dispatch(fetchSuccess());

              dispatch({ type: FETCH_DATA_SUCCESS });

              dispatch(setAuthUser(data.data));
              dispatch(getCustomization());
            } else {
              dispatch(updateLoadUser(true));
            }
          })
          .catch(function(error) {
            dispatch(updateLoadUser(true));
          });
      }
    };
  },

  onForgotPassword: (email) => {
    return (dispatch) => {
      console.log(email);
      dispatch(fetchStart("checking"));
      axios
        .post("users/forgot-password", {
          email: email.email,
          user_type: [3, 4, 5, 6],
        })
        .then(({ data }) => {
          if (data) {
            setTimeout(() => {
              dispatch(setForgetPassMailSent(true));
              dispatch(fetchSuccess());
            }, 300);
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch((error) => {
          dispatch(fetchError(error?.response?.data?.message));
        });
    };
  },

  onChangePassword: (oldPassword, newPassword, confirmPassword) => {
    return (dispatch) => {
      dispatch(fetchStart("checking"));
      axios
        .post("users/change-password", {
          old_password: oldPassword,
          new_password: newPassword,
          confirm_password: confirmPassword,
        })
        .then(({ data }) => {
          if (data) {
            setTimeout(() => {
              dispatch(fetchSuccess("Changed password successfully."));
            }, 300);
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            dispatch(setAuthUser(null));
            history.push("/signin");
          } else {
            dispatch(fetchError(error?.response?.data?.message));
          }
        });
    };
  },

  editProfile: (firstName, lastName) => {
    return (dispatch) => {
      dispatch(fetchStart(""));
      axios
        .put("users/", {
          first_name: firstName,
          last_name: lastName,
        })
        .then(({ data }) => {
          if (data) {
            setTimeout(() => {
              dispatch(fetchSuccess("Profile updated successfully."));
            }, 2000);
            dispatch(JWTAuth.getAuthUser(true));
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            dispatch(setAuthUser(null));
            history.push("/signin");
          } else {
            dispatch(fetchError(error?.response?.data?.message));
          }
        });
    };
  },

  getSocialMediaIcons: () => {
    return <React.Fragment> </React.Fragment>;
  },
};

export default JWTAuth;
