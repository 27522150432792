import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import Button from '@material-ui/core/Button';
import { requiredMessage } from '@jumbo/constants/ErrorMessages';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { capitalizeFLetter } from '@jumbo/utils/commonHelper';
import { emptySpace, linkRegexp } from '@jumbo/constants/ValidationRegex';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { Typography } from '@material-ui/core';
import { addNewInduction, getInduction } from '../../../../../../../../redux/actions/Induction';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const useStyles = makeStyles(theme => ({
  root: {
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    "& .MuiFormLabel-root": {
      color: '#909090'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink':{
      color: theme.palette.text.primary
    }
  },
  dialogRoot: {
    position: 'relative',
    '& .MuiDialog-paperWidthSm': {
      width: '100%',
    },
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.text.primary,
    },
  },

  imageButton: {
    fontSize: '10px',
    marginLeft: '5px',
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    marginRight: '5px',
  },
  errText:{
      color: 'red',
      marginLeft: '14px',
      marginRight: '14px',
      fontSize: '0.75rem',
      marginTop: '3px',
      textAlign: 'left',
      fontFamily: 'Normal',
      fontWeight: 400,
      lineHeight: 1.66,
  }
}));

const AddEditUser = ({ open, onCloseDialog, getFMProviderParams, siteId }) => {
  const classes = useStyles();
  const { id, orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions } = getFMProviderParams;

  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState('');
  const [type, setType] = useState(null);
  const [videoLink, setVideoLink] = useState('');
  const [videoLinkError, setVideoLinkError] = useState('');
  const [document, setDocument] = useState('');
  const [typeError, setTypeError] = useState('');
  const [documentError, setDocumentError] = useState('');
  const dispatch = useDispatch();

  const onSubmitClick = () => {
    if (!title || title.match(emptySpace)) setTitleError(requiredMessage);

    if (!type) setTypeError(requiredMessage);
    if (type === 1) {
      if (!document) setDocumentError(requiredMessage);
    } else {
      if (!videoLink) setVideoLinkError(requiredMessage);
      else if (!linkRegexp.test(videoLink)) {
        setVideoLinkError('Please enter valid video link.');
      }
    }
    if (title && !title.match(emptySpace) && type) {
      if (type === 1 && document) onUserSave();
      else if (type === 2 && linkRegexp.test(videoLink) && !title.match(emptySpace)) onUserSave();
    }
  };

  const onUserSave = () => {
    const titleCap = capitalizeFLetter(title);
    const formData = new FormData();

    formData.append('title', titleCap);
    formData.append('type', 1);

    formData.append('doc_type', type);
    if (type === 1) {
      formData.append('inductionContentDoc', document);
    } else {
      formData.append('link', videoLink);
    }

    formData.append('status', 1);

    dispatch(
      addNewInduction(siteId, formData, () => {
        onCloseDialog();
        dispatch(getInduction(siteId, orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
      }),
    );
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>Add New Induction Content</DialogTitle>
      <DialogContent dividers>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
          <GridContainer>
            <Grid item xs={12} sm={12}>
              <AppTextInput
                className={classes.root}
                fullWidth
                variant="outlined"
                label="Title"
                value={title}
                onChange={e => {
                  setTitle(e.target.value);
                  setTitleError('');
                }}
                helperText={titleError}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <div className={classes.content}>
                <Typography className={classes.title}>Type : </Typography>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    onChange={e => {
                      setType(Number(e.target.value));
                      setTypeError('');
                    }}
                    name="Device Type"
                    value={type}>
                    <FormControlLabel value={1} control={<Radio color="primary" />} label="Document" />
                    <FormControlLabel value={2} control={<Radio color="primary" />} label="Video" />
                  </RadioGroup>
                </FormControl>
              </div>
              <p
                className="MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-marginDense"
                style={{ color: 'red' }}>
                {typeError}
              </p>
            </Grid>
            {type === 2 ? (
              <Grid item xs={12} sm={12}>
                <AppTextInput
                  className={classes.root}
                  fullWidth
                  variant="outlined"
                  label="Video Link"
                  value={videoLink}
                  onChange={e => {
                    setVideoLink(e.target.value);
                    setVideoLinkError('');
                  }}
                  helperText={videoLinkError}
                />
              </Grid>
            ) : null}
            {type === 1 ? (
              <Grid item xs={12} sm={6}>
                <Button variant="contained" component="label" mt={5}>
                  <input
                    type="file"
                    hidden
                    accept=".pdf"
                    onChange={e => {
                      setDocument(e.target.files[0]);
                      setDocumentError('');
                    }}
                  />
                  <CloudUploadIcon />
                  <p className={classes.imageButton}>{document ? document?.name : 'Choose file'}</p>
                </Button>
                <p
                  className={classes.errText}
                  style={{ color: 'red' }}>
                  {documentError}
                </p>
              </Grid>
            ) : null}
            <Grid item xs={12} sm={12}></Grid>
          </GridContainer>
        </Box>

        <Box display="flex" justifyContent="flex-end" mb={4} mt={5}>
          <Button variant="outlined" onClick={onCloseDialog}>
            Cancel
          </Button>
          <Box ml={2}>
            <Button variant="contained" style={{ minWidth: '100px' }} color="primary" onClick={onSubmitClick}>
              Add
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditUser;

AddEditUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
