import {
  ADD_ASSETLOCATION,
  DELETE_BULK_ASSETLOCATION,
  DELETE_ASSETLOCATION,
  EDIT_ASSETLOCATION,
  GET_ASSETLOCATION,
  SET_ASSETLOCATION_DETAILS,
  GET_ASSETTYPE,
  GET_SYSTEMCATEGORIESLIST_ASSETLOCATION,
  GET_CLASSCATEGORIESLIST_ASSETLOCATION,
  GET_TYPECATEGORIESLIST_ASSETLOCATION,
  GET_SUBTYPECATEGORIESLIST_ASSETLOCATION,
  GET_PARTS_LIST,
  DOWNLOAD_BARCODE_QRCODE,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  users: [],
  partData: [],
  downloadBarcodeQRCode: [],
  currentUser: null,
  assetType:[],
  systemCategories:[],
  classCategories:null,
  typeCategories:null,
  subTypeCategories:null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ASSETLOCATION: {
      return {
        ...state,
        users: action.payload,
      };
    }
    case GET_SYSTEMCATEGORIESLIST_ASSETLOCATION: {
      return {
        ...state,
        systemCategories: action.payload,
      };
    }
    case GET_CLASSCATEGORIESLIST_ASSETLOCATION: {
      return {
        ...state,
        classCategories: action.payload,
      };
    }
    case GET_TYPECATEGORIESLIST_ASSETLOCATION: {
      return {
        ...state,
        typeCategories: action.payload,
      };
    }
    case GET_SUBTYPECATEGORIESLIST_ASSETLOCATION: {
      return {
        ...state,
        subTypeCategories: action.payload,
      };
    }
    case GET_ASSETTYPE: {
      return {
        ...state,
        assetType: action.payload,
      };
    }
    case SET_ASSETLOCATION_DETAILS: {
      return {
        ...state,
        currentUser: action.payload,
      };
    }
    case ADD_ASSETLOCATION: {
      return {
        ...state,
        users: [action.payload, ...state.users],
      };
    }
    case GET_PARTS_LIST: {
      return {
        ...state,
        partData: action.payload,
      };
    }
    case DOWNLOAD_BARCODE_QRCODE: {
      return {
        ...state,
        downloadBarcodeQRCode: action.payload,
      };
    }
    case EDIT_ASSETLOCATION: {
      return {
        ...state,
        users: state.users.map(user => (user.id === action.payload.id ? action.payload : user)),
      };
    }
    case DELETE_ASSETLOCATION: {
      return {
        ...state,
        users: state.users.filter(user => user.id !== action.payload),
      };
    }
    case DELETE_BULK_ASSETLOCATION: {
      return {
        ...state,
        users: state.users.filter(user => !action.payload.includes(user.id)),
      };
    }
    default:
      return state;
  }
};
