import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { requiredMessage } from '@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';

import {
  createNewToken,
  updateSecretToken,
  getAccountDetails,
  getAccountDetailsUnderstading,
} from 'redux/actions/AccountDetails';
import TextField from '@material-ui/core/TextField';

import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import CmtCard from '../../../@coremat/CmtCard';
import CmtCardHeader from '../../../@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '../../../@coremat/CmtCard/CmtCardContent';
import { getUserDetail } from 'redux/actions/ProfileApp';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import { Typography } from '@material-ui/core';
import { emptySpace } from '@jumbo/constants/ValidationRegex';

const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'Account Details', isActive: true },
];
const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  cardTitle: {
    '& .MuiTypography-h4': {
      marginLeft:'10px',
      fontSize: 18,
      fontFamily: 'Normal',
      fontWeight: 600,
      lineHeight: 1.235,
  },
  },
  titleRoot11: {
    fontWeight: '600',
    whiteSpace: 'nowrap',
    // paddingBottom: '0px',
    '&.MuiTypography-body1': {
      fontSize: 18,
    },
    marginBottom: 'revert',
  },
  titleRoot1: {
    fontWeight: '600',
    whiteSpace: 'nowrap',
    paddingBottom: '0px',
    '&.MuiTypography-body1': {
      fontSize: 16,
    },
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.text.primary,
    },
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: '400 px',
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    '& .MuiFormLabel-root': {
      color: '#909090',
    },
    '& .MuiInputLabel-shrink': {
      color: theme.palette.text.primary,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      color: theme.palette.text.primary,
    },
  },
  titleRoot2: {
    marginTop: '-20px',
  },
}));

const duration = [
  { name: 'Every month ', id: 1 },
  { name: 'Every 2 months', id: 2 },
  { name: 'Every 3 months', id: 3 },
  { name: 'Every 6 months', id: 6 },
  { name: 'Every 12 months', id: 12 },
];

const AddEditUser = ({ open, onCloseDialog, getServiceActivitiesParams }) => {
  const classes = useStyles();
  const { users, listData } = useSelector(({ ACCOUNTDEAILS }) => ACCOUNTDEAILS);
  const authUser = useSelector(({ auth }) => auth);
  const [keyData, setKeyData] = useState('');
  const [keyError, setKeyError] = useState('');
  const dispatch = useDispatch();
  const [open1, setOpen1] = React.useState(false);

  useEffect(() => {
    dispatch(getUserDetail());
    dispatch(getAccountDetailsUnderstading());
    dispatch(getAccountDetails(false));
  }, []);

  useEffect(() => {
    if (users) {
      setKeyData(users?.secret_key);
    }
  }, [users]);

  const onSubmitClick = () => {
    if (!keyData || keyData.match(emptySpace)) {
      setKeyError(requiredMessage);
    }

    if (keyData && !keyData.match(emptySpace)) {
      onUserSave();
    }
  };
  const handleClose = () => {
    const serviceActivityData = {
      secret_key: keyData,
    };
    dispatch(
      updateSecretToken(serviceActivityData, () => {
        setOpen1(false);
      }),
    );
  };

  const onUserSave = () => {
    const serviceActivityData = {
      secret_key: keyData,
    };

    if (authUser?.authUser?.is_stripe_key_added) {
      setOpen1(true);
    } else {
      dispatch(createNewToken(serviceActivityData, () => {}));
    }
  };

  const onHandelPdf = data => {
    dispatch(getAccountDetails(true));
    window.location.href = data;
  };
  return (
    <>
      <PageContainer heading="Account Details" breadcrumbs={breadcrumbs}>
        <CmtCard>
          <CmtCardHeader className={classes.cardTitle} title={authUser?.authUser?.is_stripe_key_added ? 'Update Stripe Key' : 'Add Stripe Key'}>
            {/* <Box className={classes.textUppercase} fontSize={5} color="text.secondary"></Box> */}
          </CmtCardHeader>
          {!authUser?.authUser?.is_stripe_key_added ? (
            <>
              {' '}
              <Box ml={10}>
                {' '}
                <Typography>
                  {' '}
                  Note : You need to create and add your stripe key in your account to access vendor management or payment
                  module <a href="https://dashboard.stripe.com/test/settings/connect">Click here to create new key.</a>
                </Typography>
              </Box>
            </>
          ) : null}
          <Box>
            <Dialog
              open={open1}
              onClose={() => setOpen1(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to change stripe key ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpen1(false)} color="primary" variant="outlined">
                  Cancel
                </Button>
                <Button onClick={handleClose} variant="contained" color="primary">
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
          <CmtCardContent>
            <Box mb={2}>
              <TextField
                label="Stripe Key"
                fullWidth
                value={keyData}
                margin="normal"
                variant="outlined"
                className={classes.textField}
                onChange={e => {
                  setKeyData(e.target.value);
                  setKeyError('');
                }}
                helperText={keyError}
              />
            </Box>
            <Box display={'flex'} ml={2} mb={{ xs: 4, sm: 7 }}>
              <Box>
                <Button variant="contained" color="primary" onClick={onSubmitClick} ml={5}>
                  {' '}
                  {authUser?.authUser?.is_stripe_key_added ? 'Update' : 'Submit'}
                </Button>
              </Box>
            </Box>
            <Box>
              <Grid item xs={6} sm={6}>
                <Box alignItems="center" mb={{ xs: 2, sm: 2 }}>
                  {/* <Box ml={2} display="flex">
                  <Typography className={classes.titleRoot1}>Stripe Key Document : </Typography>
                  <Typography style={{ marginLeft: '5px' }}>
                    {listData?.data?.document.map(item => (
                      <Button variant="contained" color="primary" onClick={() => onHandelPdf(item)} ml={5}>
                        {'Download'}
                      </Button>
                    ))}
                  </Typography>
                </Box> */}
                  {/* </Box> */}
                  {/* <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}> */}

                  <Box ml={2} mb={10}>
                    <Typography className={classes.titleRoot11}>How to Create a Stripe Key</Typography>
                    <Box display="flex">
                      <Typography className={classes.titleRoot1}>Stripe Key Document : </Typography>
                      {/* <Typography style={{ marginLeft: '5px' }}> */}
                        {listData?.data?.document.map(item => (
                          <Button variant="contained" color="primary" onClick={() => onHandelPdf(item)} ml={5} style={{zIndex:999,marginLeft: '5px'}}>
                            {'Download'}
                          </Button>
                        ))}
                      {/* </Typography> */}
                    </Box>
                    <Typography className={classes.titleRoot2}>
                      {listData?.data?.video.map(item => (
                        <video src={item} width="750" height="500" controls />
                      ))}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Box>
            {/* <Box display={'flex'} ml={2}>
              <Box>
                <Button variant="contained" color="primary" onClick={onSubmitClick} ml={5}>
                  {' '}
                  {authUser?.authUser?.is_stripe_key_added ? 'Update' : 'Submit'}
                </Button>
              </Box>
            </Box> */}
          </CmtCardContent>
        </CmtCard>
      </PageContainer>
    </>
  );
};

export default AddEditUser;

AddEditUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
