import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { useDispatch, useSelector } from 'react-redux';
import CmtList from '../../../../@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import useStyles from './index.style';
import { Block, CheckCircleOutline } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import FaceIcon from '@material-ui/icons/Face';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import GridContainer from '../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ApprovedContractor from '../VendorDetails/ApprovedContractor/index'
import ContractorVerification from '../VendorDetails/ContractorVerification/index'
import BuildIcon from "@material-ui/icons/Build";
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { useParams } from 'react-router';
import { getVendorManagementDetail } from 'redux/actions/VendorManagement';
import HomeIcon from '@material-ui/icons/Home';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
// import HomeWorkIcon from '@material-ui/icons/HomeWork';

const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'Vendor Management', link: '/vendor-management' },
  { label: 'Vendor Management Details', isActive: true }
];

const UserDetailView = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  let { id } = useParams();
  const dispatch = useDispatch()
  const [value, setValue] = React.useState(0);
  const { currentUser } = useSelector(({ VENDORMANAGEMENT }) => VENDORMANAGEMENT);

  useEffect(() => {
    dispatch(getVendorManagementDetail(id))
  }, [])
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <PageContainer heading="Vendor Management Details" breadcrumbs={breadcrumbs}>
        <Paper className={classes.root} >
          <Box className={classes.userInfoRoot}>
            <Box component="h3">
              Vendor Detail
            </Box>
          </Box>
          <Box px={6} py={5}>

          <GridContainer>
            <Grid item xs={6} sm={6}>
            <Box display="flex" alignItems="center">
              <FaceIcon />
              <Typography className={classes.titleRoot1}>First Name :</Typography>
              <Typography className={classes.titleRoot2}>{currentUser?.first_name}</Typography>
            </Box>
            </Grid>

            <Grid item xs={6} sm={6}>
            <Box display="flex" alignItems="center">
            <FaceIcon />
              <Typography className={classes.titleRoot1}>Last Name :</Typography>
              <Typography className={classes.titleRoot2}>{currentUser?.last_name}</Typography>
            </Box>
            </Grid>
            </GridContainer>

            <GridContainer>
            <Grid item xs={6} sm={6}>
            <Box display="flex" alignItems="center">
              <EmailIcon />
              <Typography className={classes.titleRoot1}>Email :</Typography>
              <Typography className={classes.titleRoot2}>{currentUser?.email}</Typography>
            </Box>
            </Grid>

            <Grid item xs={6} sm={6}>
            <Box display="flex" alignItems="center">
              {currentUser?.status === 1 ? <CheckCircleOutline /> : <Block />}
              <Typography className={classes.titleRoot1}>Status (Active/Inactive) :</Typography>
              <Typography className={classes.titleRoot2}>{currentUser?.status === 1 ? 'Active' : 'Inactive'}</Typography>
            </Box>
            </Grid>
            </GridContainer>

            <GridContainer>
            <Grid item xs={6} sm={6}>
            <Box display="flex" alignItems="center">
              <BusinessCenterIcon />
              <Typography className={classes.titleRoot1}>ABN Details :</Typography>
              <Typography className={classes.titleRoot2}>{' '}{currentUser?.abn_details ? currentUser?.abn_details : "-"}</Typography>
            </Box>
            </Grid>
            
            <Grid item xs={6} sm={6}>
            <Box display="flex">
              <HomeIcon />
              <Typography className={classes.titleRoot1}>Assigned Inventory Site :</Typography>
              <Typography className={classes.titleRoot2}>{currentUser?.warehouse_details.map((item,index) => <li className={classes.serviceTypeView} key={index} style={{ listStyleType: "none" }}>{item?.name}</li>)}</Typography>
            </Box>
            </Grid>
            </GridContainer>

            <GridContainer>
            <Grid item xs={6} sm={6}>
            <Box display="flex" mb={{ xs: 4, sm: 7 }}>
              <BuildIcon />
              <Typography className={classes.titleRoot1}>Service Type :</Typography>
              <Typography className={classes.titleRoot2}>{currentUser?.service_type_details.map((item,index) => <li className={classes.serviceTypeView} key={index} style={{ listStyleType: "none" }}>{item?.name}</li>)}</Typography>
            </Box>
            {/* </Box> */}
            </Grid>
            </GridContainer>
            

            {/* <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
              {currentUser?.status === 1 ? <CheckCircleOutline /> : <Block />}
              <Typography className={classes.titleRoot1}>Status (Active/Inactive) :</Typography>
              <Typography className={classes.titleRoot2}>{currentUser?.status === 1 ? 'Active' : 'Inactive'}</Typography>
            </Box> */}

            <Box mb={3} component="h3">
              Contact Detail
            </Box>
            {/* <GridContainer>
            <Grid item xs={6} sm={6}> */}
            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
              <PhoneIcon />
              <Typography className={classes.titleRoot1}>Contact No. :</Typography>
              <Typography className={classes.titleRoot2}>(+{currentUser?.contact_details?.dial_code}){' '}{currentUser?.contact_details?.mobile_number}</Typography>
            </Box>
            {/* </Grid> */}
            {/* </GridContainer> */}
            <Box mb={3} component="h3">
              Insurance Detail
            </Box>
            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
              <Box ml={5} color="primary" component="p" className="pointer">
              </Box>
            </Box>
            <Box mb={3} component="h3">
              Contractors :
            </Box>

            <Paper className={classes.root}>
              <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" >
                <Tab label="Approved Contractors" />
                <Tab label="Contractor Verification Requests" />
              </Tabs>
            </Paper>
            {value === 0 && currentUser?._id ? <ApprovedContractor vendorId={currentUser?._id} /> : null}
            {value === 1 && currentUser?._id ? <ContractorVerification vendorId={currentUser?._id} /> : null}

          </Box>
        </Paper>
      </PageContainer>
    </>
  );
};

export default UserDetailView;

UserDetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
