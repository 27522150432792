import React from "react";
import Hidden from "@material-ui/core/Hidden";
import { Box } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import CmtImage from "../../../../@coremat/CmtImage";
import { useSelector } from "react-redux";

const Logo = ({ color, ...props }) => {
  const { Customization } = useSelector(({ CUSTOMIZATION }) => CUSTOMIZATION);
  // console.log('customization',Customization)
  // const logoUrl = "/logoWhite.png";
  // const logoUrl = color === 'white' ? process.env.PUBLIC_URL+ '/logoWhite.png' : process.env.PUBLIC_URL+ '/logo.png';
  const logoUrl = Customization?.logo ? Customization?.logo : "/logo.png";
  return (
    <Box className="pointer" {...props}>
      <Hidden xsDown>
        <NavLink to="/">
          <CmtImage src={logoUrl} alt="logo" />
        </NavLink>
      </Hidden>
      <Hidden smUp>
        <NavLink to="/">
          <CmtImage src={logoUrl} alt="logo" />
        </NavLink>
      </Hidden>
    </Box>
  );
};

export default Logo;
