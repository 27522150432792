import React, { useEffect, useState } from 'react';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import fetch from 'isomorphic-fetch';
import { GoogleMap, Marker, withGoogleMap } from 'react-google-maps';
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import EmailIcon from '@material-ui/icons/Email';
import { history } from 'redux/store';
import { geLocationList } from 'redux/actions/Locations';
const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: '10px',
    marginBottom: '10px',
    maxWidth: 345,
    // margin: '0 auto',
    // backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },

  title: {
    fontSize: 16,
    fontWeight: 600,
  },
  address: {
    fontSize: 14,
    fontWeight: 400,
  },
  pos: {
    marginBottom: 12,
    textOverflow: "ellipsis",
    maxWidth: "150px",
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  locationHeight: {
    marginTop: '10px',
    maxHeight: '400px',
    overflow: 'auto',
  },
}));
const p_color = window.localStorage.getItem('p_c') ?? '#FC8119';
const MarkerClustererExampleGoogleMap = withGoogleMap(props => (
  <GoogleMap defaultZoom={3} defaultCenter={{ lat: 25.0391667, lng: 121.525 }}>
    {/* <MarkerClusterer averageCenter enableRetinaIcons gridSize={60}> */}
    {props.markers?.map((marker, index) => (
      <Marker
        position={{ lat: marker?.location?.geometry?.location?.lat, lng: marker?.location?.geometry?.location?.lng }}
        key={marker?.location?.geometry?.location?.lat}
        title={marker.name}
        onClick={e => {
          document.getElementById(marker._id).scrollIntoView();

          document.getElementById(marker._id).style.borderColor = p_color;
          setTimeout(() => {
            document.getElementById(marker._id).style.borderColor = '';
          }, 1000);
        }}
        icon={
          marker?.type === 1
            ? process.env.PUBLIC_URL + '/images/icons/jobLocation.png'
            : process.env.PUBLIC_URL + '/images/icons/invetory.png'
        }
      />
    ))}
    {/* </MarkerClusterer> */}
  </GoogleMap>
));

const MarkerClustererExample = () => {
  const classes = useStyles();
  const { users } = useSelector(({ LOCATION }) => LOCATION);
  const [markers, setMarkers] = useState([]);
  // console.log('user', users);
  const dispatch = useDispatch();
  useEffect(() => {
    fetch(
      `https://gist.githubusercontent.com/farrrr/dfda7dd7fccfec5474d3/raw/758852bbc1979f6c4522ab4e92d1c92cba8fb0dc/data.json`,
    )
      .then(res => res.json())
      .then(data => {
        setMarkers(data.photos);
      });
    dispatch(geLocationList('name', 'asc', '', 0, -1, []));
  }, []);

  return (
    <GridContainer>
      <Grid item xs={3} sm={3} className={classes.locationHeight}>
        <Box>
          {users?.data?.map(item => (
            <Card className={classes.root} variant="outlined" key={item._id} id={item._id}>
              <CardContent>
                <Typography className={classes.pos} color="textSecondary">
                  <img src={item?.image} width="200px" />
                </Typography>
                <Typography variant="body2" className={classes.title} component="h2">
                  {item?.type === 1 ? 'Job Location' : 'Inventory Site'}
                </Typography>
                <Typography className={classes.pos} color="textSecondary">
                  {item?.name}
                </Typography>

                <Typography variant="body2" className={classes.title} component="p">
                  Address : <span className={classes.address}> {item?.address}</span>
                </Typography>
                <Button
                  color="primary"
                  style={{ marginTop: '10px' }}
                  variant="contained"
                  size="small"
                  onClick={e => {
                    window.localStorage.setItem('L_S_W_t', item.type);
                    history.push(`/location-management/${item._id}`);
                  }}>
                  View
                </Button>
              </CardContent>
            </Card>
          ))}
        </Box>
      </Grid>
      <Grid item xs={4} sm={8}>
        <MarkerClustererExampleGoogleMap
          loadingElement={<Box height={1} />}
          containerElement={<Box height={{ xs: 300, sm: 400 }} />}
          mapElement={<Box height={1} />}
          markers={users?.data}
        />
      </Grid>
    </GridContainer>
  );
};

export default MarkerClustererExample;
