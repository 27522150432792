import React, { useEffect, useState } from 'react';
import { Paper, Table, TableCell, TableContainer, TableRow } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import UserListRow from './UserListRow';
import UserTableHead from './UserTableHead';
import UserTableToolbar from './UserTableToolbar';
import { useDispatch, useSelector } from 'react-redux';
import { deleteSelfhelpcontent, getSelfHelpContent, setCurrentSELFHELPCONTENT } from 'redux/actions/SelfHelpContent';
import AddEditUser from './AddEditUser';
import ConfirmDialog from '@jumbo/components/Common/ConfirmDialog';
import { useDebounce } from '@jumbo/utils/commonHelper';
import useStyles from './index.style';
import UserDetailView from './UserDetailView';
import NoRecordFound from './NoRecordFound';
import { useParams } from 'react-router';
import { getIssueManagement } from 'redux/actions/IssueManagement';

const UsersModule = ({ siteId, assetId }) => {
  const classes = useStyles();
  const { users } = useSelector(({ SELFHELPCONTENT }) => SELFHELPCONTENT);
  const [orderBy, setOrderBy] = React.useState('title');
  const [order, setOrder] = React.useState('asc');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState({ name: '' });
  const [usersFetched, setUsersFetched] = useState(false);
  const [isFilterApplied, setFilterApplied] = useState(false);
  const [filterOptions, setFilterOptions] = React.useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchedData, setSearchedData] = useState('debouncedSearchPagevalidation');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const dispatch = useDispatch();
  let { id } = useParams();
  // calling self help content list api when component mount , with callback function
  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm !== searchedData) {
      dispatch(
        getSelfHelpContent(siteId, assetId, orderBy, order, debouncedSearchTerm, 0, rowsPerPage, filterOptions, () => {
          setFilterApplied(!!debouncedSearchTerm);
          setUsersFetched(true);
        }),
      );
      setSearchedData(debouncedSearchTerm);
      setPage(0);
    } else {
      dispatch(
        getSelfHelpContent(siteId, assetId, orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions, () => {
          setFilterApplied(!!debouncedSearchTerm);
          setUsersFetched(true);
        }),
      );
    }
    dispatch(getIssueManagement('asc', 'name', '', 0, -1, [1]));
  }, [dispatch, filterOptions, debouncedSearchTerm, page, rowsPerPage, order, orderBy]);
  const handleCloseUserDialog = () => {
    setOpenUserDialog(false);
    dispatch(setCurrentSELFHELPCONTENT(null));
  };

  const handleRequestSort = ( property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = users.map(n => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleRowClick = ( id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handlePageChange = ( newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUserView = user => {
    dispatch(setCurrentSELFHELPCONTENT(user));
    setOpenViewDialog(true);
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
    dispatch(setCurrentSELFHELPCONTENT(null));
  };

  const handleUserUpdate = user => {
    dispatch(setCurrentSELFHELPCONTENT(user));
    setOpenUpdateDialog(true);
  };
  const handleCloseUpdateDialog = () => {
    setOpenUpdateDialog(false);
    dispatch(setCurrentSELFHELPCONTENT(null));
  };

  const handleUserEdit = user => {
    dispatch(setCurrentSELFHELPCONTENT(user));
    setOpenUserDialog(true);
  };

  const handleUserDelete = user => {
    setSelectedUser(user);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    dispatch(
      deleteSelfhelpcontent(selectedUser._id, () =>
        dispatch(getSelfHelpContent(siteId, assetId, orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions)),
      ),
    );
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const isSelected = id => selected.indexOf(id) !== -1;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <UserTableToolbar
          selected={selected}
          setSelected={setSelected}
          onUserAdd={setOpenUserDialog}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          setSearchedData={setSearchedData}
          setPage={setPage}
        />
        <TableContainer className={classes.container}>
          <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" aria-label="sticky enhanced table">
            <UserTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={users?.total ?? 1}
            />
            <TableBody>
              {!!users?.data?.length ? (
                users?.data
                  ?.slice(0 * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <UserListRow
                      siteId={siteId}
                      assetId={assetId}
                      indexId={page * rowsPerPage + index}
                      key={index}
                      row={row}
                      onRowClick={handleRowClick}
                      onUserEdit={handleUserEdit}
                      onUserDelete={handleUserDelete}
                      onUserView={handleUserView}
                      onUserUpdate={handleUserUpdate}
                      isSelected={isSelected}
                      getFMProviderParams={{ id, orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions }}
                    />
                  ))
              ) : (
                <TableRow style={{ height: 53 * 6 }}>
                  <TableCell colSpan={10} rowSpan={10}>
                    {isFilterApplied ? (
                      <NoRecordFound>There are no records found.</NoRecordFound>
                    ) : (
                      <NoRecordFound>There are no records found.</NoRecordFound>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={users?.total ?? 1}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Paper>

      {openUserDialog && (
        <AddEditUser
          open={openUserDialog}
          onCloseDialog={handleCloseUserDialog}
          getParams={{ id, orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions }}
          siteId={siteId}
          assetId={assetId}
        />
      )}
      {openViewDialog && <UserDetailView open={openViewDialog} onCloseDialog={handleCloseViewDialog} />}

      <ConfirmDialog
        open={openConfirmDialog}
        title={`Delete Self Help Content?`}
        content={'You will not be able to recover records.'}
        btnLabels={
          {
            cancel: 'Cancel',
            confirm: 'Delete',
          }
        }
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
};

export default UsersModule;
