import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Button from '@material-ui/core/Button';
import { emailNotValid, lettersOnlyMessage, requiredMessage } from '@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { capitalizeFLetter, isValidEmail } from '@jumbo/utils/commonHelper';
import { getVendorManagement, addVendorManagement, UpdateVendor } from '../../../../../../redux/actions/VendorManagement';
// import { getWarehouseList } from '../../../../redux/actions/Locations';
import { stringOnly } from '@jumbo/constants/ValidationRegex';
import { alpha } from '@material-ui/core/styles';
import { Checkbox } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Multiselect from 'multiselect-react-dropdown';
import { KeyboardDatePicker } from '@material-ui/pickers';
import AppSelectBox from '@jumbo/components/Common/formElements/AppSelectBox';
import Select from 'react-select';
import axios from 'services/auth/jwt/config';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import { history } from 'redux/store';
import { setAuthUser } from 'redux/actions/Auth';
import { getCallCenterServiceType } from '../../../../../../redux/actions/CallCenterServiceRequest';
import { getContractorListUnderVendorForWO } from '../../../../../../redux/actions/ContractorVendorManagment';
import { addNewWorkOrder } from '../../../../../../redux/actions/WorkOrder';
import moment from 'moment';
import { addNewServiceRequestData } from '../../../../../../redux/actions/ServiceRequest'

const useStyles = makeStyles(theme => ({
  root: {
    
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    "& .MuiFormLabel-root": {
      color: '#909090',
      zIndex: '0 !important',
    },
  //   '& .MuiInputLabel-outlined': {
  //     zIndex: 0,
  //     // transform: translate('14px , 20px'), scale('1'),
  //     pointerEvents: 'none',
  // }
    // '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
    //   color: theme.palette.text.primary,
    //   zIndex: -1,
    // },
  //   '& .MuiInputLabel-outlined': {
  //     zIndex: 0,
  //     // Transform: translate(14px, 20px) scale(1),
  //     PointerEvents: 'none',
  // }
  },
  dueDate: {
    '& .MuiFormControl-marginNormal': {
      marginBottom: '8px',
      marginLeft: '5px',
    },
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    "& .MuiFormLabel-root": {
      color: '#909090'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      color: theme.palette.text.primary
    },
  },
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.text.primary,
    },
  },
  titleRoot: {
    padding: 0,
    paddingBottom: 5,
    paddingTop: 15,
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.text.primary,
    }
  },
  assignInventoryTitle: {
    minWidth: 175,
    padding: 0,
    paddingBottom: 5,
    paddingTop: 15,
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.text.primary,
    },
  },
  vendorTitleRoot: {
    padding: 0,
    paddingBottom: 5,
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.text.primary,
    }
  },
  typeList: {
    width: 535,
    fontSize: 14,
    height: 300,
    backgroundColor: theme.palette.background.default,
    overflow: 'auto',
  },
  cardRoot: {
    position: 'relative',
    '& .Cmt-card-content': {
      padding: 0,
      paddingBottom: 24,
    },
  },
  scrollbarRoot: {
    height: 340,
  },
  badgeRoot: {
    fontSize: 14,
    letterSpacing: 0.25,
    backgroundColor: alpha(theme.palette.warning.main, 0.15),
    color: theme.palette.warning.main,
    padding: '2px 10px',
    borderRadius: 30,
  },
  tableRowCellRoot: {
    fontSize: 14,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 16,
    letterSpacing: 0.4,
    textAlign: 'center',
    color: theme.palette.common.dark,
    borderBottom: '0 none',
    '&:first-child': {
      paddingLeft: 24,
    },
    '&:last-child': {
      paddingRight: 24,
    },
  },
  tableRowRoot: {
    position: 'relative',
    transition: 'all .2s',
    borderTop: `solid 1px ${theme.palette.borderColor.main}`,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
      transform: 'translateY(-4px)',
      boxShadow: `0 3px 10px 0 ${alpha(theme.palette.common.dark, 0.2)}`,
      borderTopColor: 'transparent',
      '& $tableCellRoot': {
        color: theme.palette.text.primary,
        '&.success': {
          color: theme.palette.success.main,
        },
      },
    },
  },
  serviceTypeList: {
    fontSize: 14,
    letterSpacing: 0.25,
    textOverflow: 'ellipsis',

    wordWrap: 'break-word'
  },
  tableCellRoot: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: 14,
    letterSpacing: 0.25,
    textAlign: 'center',
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    borderBottom: '0 none',
    '&:first-child': {
      paddingLeft: 24,
    },
    '& .Cmt-avatar-more': {
      fontSize: 10,
      color: theme.palette.primary.main,
    },
  },
  tableCellSecondaryColor: {
    color: theme.palette.text.secondary,
  },
  blockRoot: {
    display: 'block',
    fontSize: 14,
  },
  contactColor: {
    color: theme.palette.primary.main,
  },
  vendorTitle: {
    padding: 0,
    paddingBottom: 5,
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.text.primary,
    },
  },
  errText: {
    color: 'red',
    marginLeft: '14px',
    marginRight: '14px',
    fontSize: '0.75rem',
    marginTop: '3px',
    textAlign: 'left',
    fontFamily: 'Normal',
    fontWeight: 400,
    lineHeight: 1.66,
  },
  assignInventorySiteView: {
    // zIndex: 70,
    minHeight: '40px',
    minWidth: '200px',
    fontSize: '15px',
    '& .chip': {
      background: `${theme.palette.primary.main} !important`
    },
    '& .highlightOption': {
      background: `${theme.palette.primary.main} !important`
    },
    '& .multiSelectContainer li': {
      '&:hover': {
        background: `${theme.palette.primary.main} !important`,
      },
    },
    '& li': {
      fontSize: '14px',
      '&:hover': {
        background: `${theme.palette.primary.main} !important`,
      },
    },
    '& input': {
      fontSize: '16px',
      paddingLeft: '5px',
      minHeight: '28px',
    },
    '& .optionContainer': {
      border: '1px solid #ccc',
      borderRadius: '4px',
      display: 'block',
      margin: 0,
      maxHeight: '125px',
      overflowY: 'auto',
      padding: 0,
    },
  },
}));

const splitName = user => {
  if (user) {
    const [fName, mName, lName] = user.name.split(' ');
    return [fName, lName ? mName + ' ' + lName : mName];
  }
  return ['', ''];
};

const priorityList = [
  { label: 'P1', value: 'P1' },
  { label: 'P2', value: 'P2' },
  { label: 'P3', value: 'P3' },
  { label: 'P4', value: 'P4' },
  { label: 'P5', value: 'P5' },
];

const AddEditUser = ({ open, onCloseDialog, getFMProviderParams }) => {
  const classes = useStyles();
  const servicetype = useSelector(({ CALLCENTER }) => CALLCENTER);
  const contractorlist = useSelector(({ CONTRACTOR }) => CONTRACTOR);
  const { details } = useSelector(({ SERVICEREQUEST }) => SERVICEREQUEST);
  const vendors = useSelector(({ VENDORMANAGEMENT }) => VENDORMANAGEMENT);
  const { currentUser } = useSelector(({ WORKORDER }) => WORKORDER);
  const { orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions } = getFMProviderParams;
  const [serviceTypeValue, setServiceTypeValue] = useState('');
  const [serviceTypeValueError, setServiceTypeValueError] = useState('');
  const [assignVendor, setAssignVendor] = useState('');
  const [assignVendorError, setAssignVendorError] = useState('');
  const [contractor, setContractor] = useState('');
  const [priority, setPriority] = useState('');
  const [priorityError, setPriorityError] = useState('');
  const [dueDate, setDueDate] = useState(null);
  const [dueDateError, setDueDateError] = useState('');
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const dispatch = useDispatch();
  console.log("currentUser",currentUser)

  useEffect(() => {
      dispatch(getCallCenterServiceType(details?.location_id));
  }, [!currentUser]);

  useEffect(() => {
    if (currentUser) {
      dispatch(getContractorListUnderVendorForWO(currentUser?.vendor_details?._id, 2, 'name', 'asc', '', 0, -1, '', currentUser?.service_type_id));
      setServiceTypeValue(currentUser?.service_type_id);
      setAssignVendor(currentUser?.vendor_details?._id);
      setContractor(currentUser?.contractor_details?._id);
      setPriority(currentUser?.priority);
      setDueDate(moment(currentUser?.due_date * 1000));
    }
  }, [currentUser]);

  const handleSelectServiceType = e => {
    setServiceTypeValue(e?.value ?? '');
    dispatch(getVendorManagement('name', 'asc', '', 0, -1, '', e?.value));
    setServiceTypeValueError("");
  }

  const handleSelectVendor = (e) => {
    setAssignVendor(e?.value ?? '');
    if(currentUser){
      dispatch(getContractorListUnderVendorForWO(e?.value, 2, 'name', 'asc', '', 0, -1, '', currentUser?.service_type_id));
    }
    else{
      dispatch(getContractorListUnderVendorForWO(e?.value, 2, 'name', 'asc', '', 0, -1, '', serviceTypeValue));
    }
    setAssignVendorError("");
  }

  const onSubmitClick = () => {
    if (!serviceTypeValue?.length) {
      setServiceTypeValueError(requiredMessage)
    }
    if (!assignVendor?.length) {
      setAssignVendorError(requiredMessage)
    }
    if (!priority?.length) {
      setPriorityError(requiredMessage)
    }
    if (!dueDate) {
      setDueDateError(requiredMessage)
    }
    if (serviceTypeValue?.length && assignVendor?.length && priority?.length && dueDate) {
      onUserSave();
    }
  };

  const onUserSave = () => {
    const formData = new FormData();
    formData.append('service_type_id', serviceTypeValue);
    formData.append('asset_id', details?.asset_id);
    formData.append('tenant_id', details?.tenant_id);
    formData.append('location_id', details?.location_id);
    formData.append('description', details?.description);
    formData.append('vendor_id', assignVendor);
    formData.append('contractor_id', contractor);
    formData.append('priority', priority);
    formData.append('due_date', Number(moment(dueDate).format('X')));

    const userDetail = {
      priority: priority,
      due_date: Number(moment(dueDate).format('X')),
    };


    if(currentUser){
      dispatch(addNewServiceRequestData(currentUser?._id, assignVendor, contractor, userDetail, () => {
        onCloseDialog();
      }),
      );
    }
    else{
      dispatch(addNewWorkOrder(details?._id, formData, () => {
          onCloseDialog();
          // dispatch(getVendorManagement(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
        }),
      );
    }
  };

  const p_color = window.localStorage.getItem('p_c') ?? '#FC8119'
  const t_color = window.localStorage.getItem('t_c') ?? '#F0511E'

  const handleDateChange = date => {
    setDueDate(date);
    setDueDateError('');
  };

  console.log("priorityList",priorityList)
  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>{'Generate WO'}</DialogTitle>
      <DialogContent dividers>

        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
          <GridContainer>

            <Grid item xs={12} sm={12}>
              <Box>
              {/* currentUser ? { value: currentUser?.service_type_id, label: currentUser?.service_type } :  */}
                <Select
                  className={classes.assignInventorySiteView}
                  placeholder="Service Type"
                  value={servicetype?.serviceTypeList?.locationData[0]?.service_type_details?.map(item => {
                    return { value: item?._id, label: item?.name }
                  }).filter(obj => obj?.value === serviceTypeValue)}
                  onChange={e => { handleSelectServiceType(e) }}
                  // onChange={e=>{setServiceTypeValue(e?.value ?? '')}}
                  isClearable={isClearable}
                  isSearchable={isSearchable}
                  isDisabled={currentUser ? true : false}
                  options={servicetype?.serviceTypeList?.locationData[0]?.service_type_details?.map(item => {
                    return { value: item?._id, label: item?.name }
                  })}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      text: t_color,
                      // primary25: s_color,
                      primary: p_color,
                    },
                  })}
                />
                {/* <Multiselect
                    options={users?.data} // Options to display in the dropdown
                    selectedValues={serviceTypeValue} // Preselected value to persist in dropdown
                    onSelect={(selectedList, selectedItem) => { setServiceTypeValue(selectedList); setServiceTypeValueError("") }} // Function will trigger on select event
                    onRemove={(selectedList, selectedItem) => setServiceTypeValue(selectedList)} // Function will trigger on remove event
                    displayValue="name" // Property name to display in the dropdown options
                    placeholder="Service Type"
                    className={classes.assignInventorySiteView}
                  /> */}
                <p className={classes.errText} style={{ color: 'red' }}>{serviceTypeValueError}</p>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Box>
              {/* currentUser ? { value: currentUser?.vendor_details?._id, label: currentUser?.vendor_details?.first_name+" "+currentUser?.vendor_details?.last_name }  */}
                <Select
                  className={classes.assignInventorySiteView}
                  placeholder="Assign Vendor"
                  value={vendors?.users?.data?.map(item => { return { value: item?._id, label: item?.first_name+" "+item?.last_name }})
                  .filter(obj => obj?.value === assignVendor)}
                  onChange={e => { handleSelectVendor(e) }}
                  isClearable={isClearable}
                  isSearchable={isSearchable}
                  options={currentUser ? 
                  vendors?.users?.data?.map(item => { return { value: item?._id, label: item?.first_name+" "+item?.last_name } }) 
                  : serviceTypeValue ? vendors?.users?.data?.map(item => {return { value: item?._id, label: item?.first_name+" "+item?.last_name }}) 
                  : []}

                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      text: t_color,
                      // primary25: s_color,
                      primary: p_color,
                    },
                  })}
                />
                {/* <Multiselect
                    options={users?.data} // Options to display in the dropdown
                    selectedValues={serviceTypeValue} // Preselected value to persist in dropdown
                    onSelect={(selectedList, selectedItem) => { setServiceTypeValue(selectedList); setServiceTypeValueError("") }} // Function will trigger on select event
                    onRemove={(selectedList, selectedItem) => setServiceTypeValue(selectedList)} // Function will trigger on remove event
                    displayValue="name" // Property name to display in the dropdown options
                    placeholder="Vendor"
                    className={classes.assignInventorySiteView}
                  /> */}
                <p className={classes.errText} style={{ color: 'red' }}>{assignVendorError}</p>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Box>
              {/* currentUser ? { value: currentUser?.contractor_details?._id, label: currentUser?.contractor_details?.first_name+" "+currentUser?.contractor_details?.last_name } :  */}
                <Select
                  className={classes.assignInventorySiteView}
                  placeholder="Assign Contractor"
                  value={contractorlist?.users?.data?.map(item => {
                    return { value: item?._id, label: item?.first_name+" "+item?.last_name }
                  }).filter(obj => obj?.value === contractor)}
                  onChange={e => { setContractor(e?.value ?? '') }}
                  isClearable={isClearable}
                  isSearchable={isSearchable}
                  options={assignVendor ? contractorlist?.users?.data?.map(item => {
                    return { value: item?._id, label: item?.first_name+" "+item?.last_name }
                  }) : []}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      text: t_color,
                      // primary25: s_color,
                      primary: p_color,
                    },
                  })}
                />
                {/* <Multiselect
                    options={warehouse?.data} // Options to display in the dropdown
                    selectedValues={selectedWarehouse} // Preselected value to persist in dropdown
                    onSelect={(selectedList, selectedItem) => { setWarehouseValue(selectedList); setWarehouseValueError("") }} // Function will trigger on select event
                    onRemove={(selectedList, selectedItem) => setWarehouseValue(selectedList)} // Function will trigger on remove event
                    displayValue={"name"} // Property name to display in the dropdown options
                    placeholder="Contractor"
                    className={classes.assignInventorySiteView}
                  /> */}
              </Box>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Box>
              <Select
                  className={classes.assignInventorySiteView}
                  placeholder="Priority"
                  value={priorityList?.map(item => {
                    return { value: item?.value, label: item?.label }
                  }).filter(obj => obj?.value === priority)}
                  onChange={e => { setPriority(e?.value ?? '') }}
                  options={assignVendor ? priorityList?.map(item => {
                    return { value: item?.value, label: item?.label }
                  }) : []}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      text: t_color,
                      // primary25: s_color,
                      primary: p_color,
                    },
                  })}
                />
                <p className={classes.errText} style={{ color: 'red' }}>{priorityError}</p>
              {/* <AppSelectBox
                className={classes.root}
                fullWidth
                data={priorityList}
                label="Priority"
                valueKey="value"
                variant="outlined"
                labelKey="label"
                value={priority}
                onChange={e => {
                  console.log("e.target.value :",e.target.value)
                  setPriority(e.target.value)
                  setPriorityError('')
                }}
                helperText={priorityError}
              /> */}
              </Box>
            </Grid>

            <Grid item xs={12} sm={12}>
            {/* currentUser ? moment(currentUser?.due_date * 1000) :  */}
              <KeyboardDatePicker
                className={classes.dueDate}
                placeholder='DD/MM/YYYY'
                margin="normal"
                id="date-picker-dialog"
                label="Due Date"
                format="DD/MM/YYYY"
                value={dueDate}
                minDate={new Date()}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                helperText={dueDateError}
              />
            </Grid>

          </GridContainer>
        </Box>

        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button variant="outlined" onClick={onCloseDialog}>
            Cancel
          </Button>
          <Box ml={2}>
            <Button variant="contained" style={{ minWidth: '100px' }} color="primary" onClick={onSubmitClick}>
              Confirm
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditUser;

AddEditUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
