import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import Button from '@material-ui/core/Button';
import { emailNotValid, lettersOnlyMessage, requiredMessage } from '@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { capitalizeFLetter, isValidEmail } from '@jumbo/utils/commonHelper';
import { stringOnly } from '@jumbo/constants/ValidationRegex';
import { addNewTenant, getTenantList, updateTenant } from 'redux/actions/Tenant';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";


const useStyles = makeStyles(theme => ({
  root: {
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    "& .MuiFormLabel-root": {
      color: '#909090'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink':{
      color: theme.palette.text.primary
    }
  },
  dialogRoot: {
    position: 'relative',
    '& .MuiDialog-paperWidthSm': {
      width: '100%',
      maxWidth: '1000px',
    },
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 200,
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
      marginLeft:"8px"
    },
    "& .MuiFormLabel-root": {
      color: '#909090'
    },
    "& .MuiInputLabel-shrink":{
color:theme.palette.text.primary
    },
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.text.primary,
    },
  },
}));

const AddEditUser = ({ open, onCloseDialog, getFMProviderParams }) => {
  const classes = useStyles();
  const currentUser = useSelector(({ TENANT }) => TENANT.currentUser);
  const { id, orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions } = getFMProviderParams;
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [status, setStatus] = useState(1);
  const [leaseExpirydateValue, setLeaseExpiryDateValue] = useState(null);
  
  const [dateValueError, setDateValueError] = useState('');
  const [checkValue, setCheckValue] = useState(false);
  const dispatch = useDispatch();
  console.log("current",currentUser)
  console.log("stte",checkValue)

  useEffect((e) => {
    if (currentUser) {
      setFirstName(currentUser?.first_name);
      setLastName(currentUser?.last_name);
      setEmail(currentUser.email);
      setLeaseExpiryDateValue( moment(currentUser?.lease_expiry_date * 1000).format('YYYY-MM-DD'))
      setStatus(currentUser?.status);
      setCheckValue(currentUser?.can_be_deleted)
      // setCheckValue( ...checkValue,currentUser?.can_be_deleted);

    }
  }, [currentUser]);

  const onSubmitClick = () => {
    if (!firstName) setFirstNameError(requiredMessage);
    else if (!firstName.match(stringOnly)) setFirstNameError(lettersOnlyMessage);

    if (!lastName) setLastNameError(requiredMessage);
    else if (!lastName.match(stringOnly)) setLastNameError(lettersOnlyMessage);

    if (!email) setEmailError(requiredMessage);
    else if (!isValidEmail(email)) setEmailError(emailNotValid);

    if (!leaseExpirydateValue) {
      setDateValueError(requiredMessage);
    }
    if (moment(leaseExpirydateValue).format('x') < moment(moment().format('YYYY-MM-DD')).format('x')) {
      setDateValueError("Please select valid date, you can't select past date .");
    }

    if (firstName && firstName.match(stringOnly) && lastName && lastName.match(stringOnly) && email && isValidEmail(email)&&leaseExpirydateValue &&
    moment(leaseExpirydateValue).format('x') >= moment(moment().format('YYYY-MM-DD')).format('x') ) {
      onUserSave();
    }
  };

  const onUserSave = () => {
    const fname = capitalizeFLetter(firstName);
    const lname = capitalizeFLetter(lastName);
    const userDetail = {
      email,
      first_name: fname,
      last_name: lname,
      lease_expiry_date:moment(leaseExpirydateValue, 'YYYY-MM-DD').format('X'),
      status: status,
      access_for_call_center_services:checkValue
      // can_be_deleted:checkValue
    };

    if (currentUser) {
      dispatch(
        updateTenant(currentUser?._id, userDetail, () => {
          onCloseDialog();
          dispatch(getTenantList(id, orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
        }),
      );
    } else {
      dispatch(
        addNewTenant(id, userDetail, () => {
          onCloseDialog();
          dispatch(getTenantList(id, orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
        }),
      );
    }
  };
  const handleDateChange = date => {
    setLeaseExpiryDateValue(date);
    setDateValueError('');
    // getNextServiceDate(durationValue, date);
    // setStartDateValue(date)
  };
const handleChange=(checkValue)=>{
  // setCheckValue(true)
  setCheckValue(checkValue.target.checked);

}
  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>{currentUser ? 'Edit Tenant Details' : 'Add New Tenant'}</DialogTitle>
      <DialogContent dividers>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
          <GridContainer>
            <Grid item xs={12} sm={6}>
              <AppTextInput
                className={classes.root}
                fullWidth
                variant="outlined"
                label="First Name"
                value={firstName}
                onChange={e => {
                  setFirstName(e.target.value.slice(0, 20));
                  setFirstNameError('');
                }}
                helperText={firstNameError}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AppTextInput
                className={classes.root}
                fullWidth
                variant="outlined"
                label="Last Name"
                value={lastName}
                onChange={e => {
                  setLastName(e.target.value.slice(0, 20));
                  setLastNameError('');
                }}
                helperText={lastNameError}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <AppTextInput
                className={currentUser ? "" : classes.root}
                fullWidth
                variant="outlined"
                label="Email Address"
                value={email}
                onChange={e => {
                  setEmail(e.target.value);
                  setEmailError('');
                }}
                disabled={currentUser ? true : false}
                helperText={emailError}
              />
            </Grid>
            <Grid item xs={12} sm={12}  >
            <KeyboardDatePicker
              placeholder='DD/MM/YYYY'
                margin="normal"
                id="date-picker-dialog"
                label="Lease Expiry Date"
                format="DD/MM/YYYY"
                value={leaseExpirydateValue}
                minDate={new Date()}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'Due Date',
                }}
                className={classes.textField}
                helperText={dateValueError}

              />
              
            </Grid>
          </GridContainer>
        </Box>


        {/* <Box >
        <FormControl component="fieldset" className={classes.formControl}> */}
        {/* <FormLabel component="legend">Call Center Service Access</FormLabel> */}

        {/* <FormControlLabel
        control={
          <Checkbox
            checked={checkValue}
            onChange={(e)=>handleChange(e)}
            name="checkedB"
            // value={checkValue}
            color="primary"
          />
        }
        label="Call Center Service Access"
      />
      </FormControl> */}
        
  {/* <UserAccessADDEdit userAccess={userAccess} setUserAccess={setUserAccess} setUserModuleAccess={setUserModuleAccess} userModuleAccess={userModuleAccess}/> */}
{/* </Box> */}

        <Box display="flex" justifyContent="flex-end" mb={4} mt={5}>
          <Button variant="outlined" onClick={onCloseDialog}>
            Cancel
          </Button>
          <Box ml={2}>
            <Button variant="contained" style={{ minWidth: '100px' }} color="primary" onClick={onSubmitClick}>
              {currentUser ? 'UPDATE' : 'ADD'}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditUser;

AddEditUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
