import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';
import {
  GET_ASSETLOCATION,
  SET_ASSETLOCATION_DETAILS,
  GET_ASSETTYPE,
  GET_SYSTEMCATEGORIESLIST_ASSETLOCATION,
  GET_CLASSCATEGORIESLIST_ASSETLOCATION,
  GET_TYPECATEGORIESLIST_ASSETLOCATION,
  GET_SUBTYPECATEGORIESLIST_ASSETLOCATION,
  GET_PARTS_LIST,
  DOWNLOAD_BARCODE_QRCODE,
} from '../../@jumbo/constants/ActionTypes';
import {
  categoriesList,
  assestAddLoaction,
  assestListLoaction,
  assestStatusUpdateLoaction,
  assestTypeListApi,
  assestUpdateLoaction,
  assetDelete,
  assetDetail,
  assetPartsList,
  assetGenerateBarcodeQRCode,
} from '../../@jumbo/constants/ApiConstatnt';
import { setAuthUser } from './Auth';
import { history } from 'redux/store';
import JWTAuth from 'services/auth/jwt';

// get asset list
export const getASSETLOCATIONList = (id, sortBy, sort, searchTerm, page, limit, filterOptions, callbackFun) => {
  return dispatch => {
   
    dispatch(fetchStart());

    axios
      .get(assestListLoaction + id, {
        params: { sortBy: `${sortBy}:${sort}`, search: searchTerm, page: page + 1, limit: limit, status: filterOptions[0] },
      })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_ASSETLOCATION, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          
          dispatch({ type: GET_ASSETLOCATION, payload: [] });
          dispatch(fetchError());
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

export const getCategoriesList = (callbackFun) => {
  return dispatch => {

    dispatch(fetchStart());
    axios
      .get(categoriesList,
        { params: {limit: -1}}
        // { params: {}}
        )
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          // dispatch({type:FETCH_DATA_SUCCESS,})
          dispatch({ type: GET_SYSTEMCATEGORIESLIST_ASSETLOCATION, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Categories list not found.') {
          if (callbackFun) callbackFun();
          dispatch(fetchError());
          dispatch({ type: GET_SYSTEMCATEGORIESLIST_ASSETLOCATION, payload: [] });
        } else if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        } 
        
      });
  };
};

export const getClassCategoriesList = (id, callbackFun) => {
  return dispatch => {

    dispatch(fetchStart());
    axios
      .get(categoriesList, 
        { params: {_id: id, limit: -1} }
        )
      .then(data => {
        console.log("class data",data)
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_CLASSCATEGORIESLIST_ASSETLOCATION, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Categories list not found.') {
          if (callbackFun) callbackFun();
          dispatch(fetchError());
          dispatch({ type: GET_CLASSCATEGORIESLIST_ASSETLOCATION, payload: [] });
        } else if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        } 
        
      });
  };
};

export const getTypeCategoriesList = (id, callbackFun) => {
  return dispatch => {

    dispatch(fetchStart());
    axios
      .get(categoriesList,
        // { params: {_id: id} }
        { params: {_id: id, limit: -1} }
        )
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_TYPECATEGORIESLIST_ASSETLOCATION, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Categories list not found.') {
          if (callbackFun) callbackFun();
          dispatch(fetchError());
          dispatch({ type: GET_TYPECATEGORIESLIST_ASSETLOCATION, payload: [] });
        } else if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        } 
        
      });
  };
};

export const getSubTypeCategoriesList = (id, callbackFun) => {
  return dispatch => {

    dispatch(fetchStart());
    axios
      .get(categoriesList,
        // { params: {_id: id} }
        { params: {_id: id, limit: -1} }
        )
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_SUBTYPECATEGORIESLIST_ASSETLOCATION, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Categories list not found.') {
          if (callbackFun) callbackFun();
          dispatch(fetchError());
          dispatch({ type: GET_SUBTYPECATEGORIESLIST_ASSETLOCATION, payload: [] });
        } else if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        } 
        
      });
  };
};

export const getAssetType = () => {
  return dispatch => {
    dispatch(fetchStart());

    axios
      .get(assestTypeListApi)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_ASSETTYPE, payload: data.data.data });
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Asset data not found.') {
          dispatch(fetchError());
          dispatch({ type: GET_ASSETLOCATION, payload: [] });
        } else if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

export const getAssetDetail = (id,callbackfun) => {
  return dispatch => {
    dispatch(fetchStart());

    axios
      .get(assetDetail+id)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: SET_ASSETLOCATION_DETAILS, payload: data.data.data });
        if(callbackfun)callbackfun()
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Asset data not found.') {
          dispatch(fetchError());
          dispatch({ type: GET_ASSETLOCATION, payload: [] });
        } else if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};
// set current user for view tab
export const setCurrentASSETLOCATION = user => {
  return dispatch => {
    dispatch({ type: SET_ASSETLOCATION_DETAILS, payload: user });
  };
};

// add new asset
export const addNewASSETLOCATION = (id, user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(assestAddLoaction + id, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('New Asset added successfully.'));
          }, 2000);

          // dispatch({ type:  ADD_ASSETLOCATION, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

// get parts list
export const getAssetPartsList = (serviceTypes, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(assetPartsList, serviceTypes)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Parts fetched successfully.'));
          }, 2000);

          dispatch({ type:  GET_PARTS_LIST, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

// Download Barcode QRCode
export const getAssetDownloadBarcodeQRCode = (_id, asset_id, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(assetGenerateBarcodeQRCode, _id, asset_id)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Barcode and QR Code generated successfully.'));
          }, 2000);

          dispatch({ type: DOWNLOAD_BARCODE_QRCODE, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

export const updateASSETLOCATIONStatus = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(assestStatusUpdateLoaction, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Asset status updated successfully.'));
          }, 2000);

          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

export const updateASSETLOCATION = (siteId, assetId, data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(assestUpdateLoaction + assetId, data)
      .then(response => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Asset updated successfully.'));
          }, 2000);
          // dispatch({ type: EDIT_USER, payload: response.data });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

export const deleteAsset = (Id, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete(assetDelete+Id)
      .then(data => {
        if (data.status === 200) {
          setTimeout(() => {
            dispatch(fetchSuccess('Asset deleted successfully.'));   
          }, 2000);
         
          // dispatch({ type: DELETE_TENANT, payload: userId });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};



