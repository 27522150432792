import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Block, CheckCircleOutline, Visibility, Edit, Delete } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { sentMailToUser } from '../../../../../redux/actions/Users';
import { getIssueManagement, updateIssueManagementStatus } from '../../../../../redux/actions/IssueManagement';
import { Activate, Deactivate } from '../../../../../@jumbo/constants/stringConstant';
import moment from 'moment';
import { history } from 'redux/store';

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    // padding: '16px',
    letterSpacing: 0.25,
    color: theme.palette.text.primary,
      fontSize: 14,
      letterSpacing: 0.25,
      textOverflow: 'ellipsis',
      maxWidth:'200px',
      display: 'block',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      fontWeight: 300
  },
  starIcon: {
    color: theme.palette.text.secondary,
  },
}));

const getUserActions = user => {
  const actions = [{ action: 'view', label: 'View', icon: <Visibility /> },{ action: 'edit', label: 'Edit', icon: <Edit /> }];

  if (user.fm_issue_status===1) {
    actions.push({ action: 'suspend', label: Deactivate, icon: <Block /> });
  } else {
    actions.push({
      action: 'activate',
      label: Activate,
      icon: <CheckCircleOutline />,
    });
  }
  actions.push({ action: 'delete', label: 'Delete', icon: <Delete /> });
  return actions;
};


const UserListRow = ({ row, isSelected, onRowClick, onUserEdit, onUserDelete, onUserView, getIssueManagementParams,indexId }) => {
  const { orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions } = getIssueManagementParams;
  const classes = useStyles();
  const dispatch = useDispatch();

  const labelId = `enhanced-table-checkbox-${row._id}`;
  const userActions = getUserActions(row);

  return (
    <TableRow hover role="checkbox" tabIndex={-1} style={{cursor:'pointer'}} key={row._id} onClick={e=> history.push(`/vendor-management/vendor-management-details/${row?.vendor_details?._id}`)}>     
      <TableCell > <Typography className={classes.titleRoot} component="div">{row?.vendor_details?.first_name+" "+row?.vendor_details?.last_name}</Typography></TableCell>
      <TableCell > <Typography className={classes.titleRoot} component="div">{row?.first_name+" "+row?.last_name}</Typography></TableCell>
      <TableCell><Typography className={classes.titleRoot} component="div">{row?.service_type_name}</Typography></TableCell>
      <TableCell><Typography className={classes.titleRoot} component="div">{moment(row?.created_at * 1000).format('hh:mm A DD/MM/YYYY')}</Typography></TableCell>
    </TableRow>
  );
};

export default React.memo(UserListRow);