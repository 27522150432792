import React from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import BuildIcon from '@material-ui/icons/Build';
import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import useStyles from './index.style';
import Typography from '@material-ui/core/Typography';
import ExposureIcon from '@material-ui/icons/Exposure';
import TimelineIcon from '@material-ui/icons/Timeline';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import CropFreeIcon from '@material-ui/icons/CropFree';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AssignItem from 'redux/reducers/AssignItem';

const UserDetailView = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const { currentUser } = useSelector(({ LocationsItems }) => LocationsItems);

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.userInfoRoot}>
        <Box mr={3} display="flex" alignItems="center">
          <Box mt={-2}>
            <Box display="flex" alignItems="center">
            <Typography className={classes.titleRoot}>Part Detail</Typography>
              <Box ml={1}></Box>
            </Box>
          </Box>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}></Box>
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
       <Box px={6} py={5}>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <BuildIcon />
          <Box ml={5} display="flex">
            <Typography className={classes.labelField}>Part Name :</Typography>
            <Typography className={classes.textField}> {currentUser?.name}</Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <ExposureIcon />
          <Box ml={5} display="flex">
            <Typography className={classes.labelField}>Quantity :</Typography>
            <Typography className={classes.textField}> {currentUser?.remaining_quantity}</Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <TimelineIcon />
          <Box ml={5} display="flex">
            <Typography className={classes.labelField}>Threshold Quantity :</Typography>
            <Typography className={classes.textField}> {currentUser?.threshold}</Typography>
          </Box>
        </Box>
        <Box display="flex" mb={{ xs: 4, sm: 7 }}>
          <CropFreeIcon />
          <Box ml={5} display="flex">
            <Box><Typography className={classes.labelField}>Serial Number :</Typography></Box>
            {/* <Box><Typography className={classes.textField}> {currentUser?.remaining_items_serial_number?.length ? currentUser?.remaining_items_serial_number?.map((item,index) => <li className={classes.serialNumberView} key={index} style={{ listStyleType: "none" }}>{item}</li>) : '-'}</Typography></Box> */}
            {/* {currentUser?.remaining_items_serial_number?.length ? currentUser?.remaining_items_serial_number?.map((item,index) => <li className={classes.serialNumberView} key={index} style={{ listStyleType: "none" }}>{item}</li>) : '-'} */}
            {currentUser?.remaining_items_serial_number?.length > 0 ?
            <Box ml={5}>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
              <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableTitleCell}>No.</TableCell>
                    <TableCell className={classes.tableTitleCell}>Serial Numbers</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {currentUser?.remaining_items_serial_number?.length ? currentUser?.remaining_items_serial_number?.map((item,index) =>
                  <TableRow className={classes.tableRow}>
                    <TableCell className={classes.tableDataCell}>{<li className={classes.serialNumberView} key={index} style={{ listStyleType: "none" }}>{index+1}</li>}</TableCell>
                    <TableCell className={classes.tableDataCell}>{<li className={classes.serialNumberView} key={index} style={{ listStyleType: "none" }}>{item}</li>}</TableCell>
                  </TableRow>
                  ) : null}
                </TableBody>
              </Table>
            </TableContainer>
            </Box>
            : 
            <Box><Typography style={{marginLeft:'10px', AssignItem:'center'}}>{'-'}</Typography></Box> 
            }
          </Box>
        </Box>
      </Box>

      
    </Dialog>
  );
};

export default UserDetailView;

UserDetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
