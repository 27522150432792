import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';
import { DELETE_BULK_CONTRACTOR, DELETE_CONTRACTOR, EDIT_CONTRACTOR, GET_CONTRACTOR, SET_CONTRACTOR_DETAILS } from '../../@jumbo/constants/ActionTypes';
import { addUser, contractorApproved, priorityContractor, contractorDetails, contractorListVendor, contractorStatusUpdate } from '../../@jumbo/constants/ApiConstatnt';
import { setAuthUser } from './Auth';
import { history } from 'redux/store';

// get FM provider list
export const getContractorVendorList = (id,approved_status,sortBy, sort, searchTerm, page, limit, filterOptions, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(contractorListVendor, {
        params: {vendor_id:id,approved_status, sortBy: `${sortBy}:${sort}`, search: searchTerm, page: page + 1, limit: limit,status: filterOptions[0] },
      })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_CONTRACTOR, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch({ type: GET_CONTRACTOR, payload: [] });
          dispatch(fetchError());
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Contractor not found.') {
          if (callbackFun) callbackFun();
          dispatch(fetchError());
          dispatch({ type: GET_CONTRACTOR, payload: [] });
        }else if(error?.response?.status===401 ){
          dispatch(setAuthUser(null));
          history.push('/signin')
        } 
        else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });

  };
};

// Get Contractor list for Work Order in Service Request
export const getContractorListUnderVendorForWO = (assignVendor, approved_status, sortBy, sort, searchTerm, page, limit, filterOptions, serviceTypeValue, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(contractorListVendor, {
        params: {vendor_id:assignVendor, approved_status, sortBy: `${sortBy}:${sort}`, search: searchTerm, page: page + 1, limit: limit,status: filterOptions[0], service_type_id: serviceTypeValue },
      })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_CONTRACTOR, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch({ type: GET_CONTRACTOR, payload: [] });
          dispatch(fetchError());
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Contractor not found.') {
          if (callbackFun) callbackFun();
          dispatch(fetchError());
          dispatch({ type: GET_CONTRACTOR, payload: [] });
        }else if(error?.response?.status===401 ){
          dispatch(setAuthUser(null));
          history.push('/signin')
        } 
        else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });

  };
};

// set current user for view tab
export const setCurrentCONTRACTOR = user => {
  return dispatch => {
    dispatch({ type: SET_CONTRACTOR_DETAILS, payload: user });
  };
};

// add new FM provider 
export const addNewUser = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(addUser, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('New User added successfully.'));
          }, 2000);
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if(error?.response?.status===401 ){
          dispatch(setAuthUser(null));
          history.push('/signin')
        } else{
          dispatch(fetchError(error?.response?.data?.message));}
      });

  };
};

export const updateContractor = (id,user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(contractorApproved+id, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            if(user?.approved_status==2) dispatch(fetchSuccess('Contractor verification request approved successfully.'));
            else dispatch(fetchSuccess('Contractor verification request rejected successfully.'));
          }, 2000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if(error?.response?.status===401 ){
          dispatch(setAuthUser(null));
          history.push('/signin')
        } else{
          dispatch(fetchError(error?.response?.data?.message));}
      });

  };
};

export const updateCONTRACTORStatus = (data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(contractorStatusUpdate, data)
      .then(response => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Contractor status updated successfully.'));
          }, 2000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if(error?.response?.status===401 ){
          dispatch(setAuthUser(null));
          history.push('/signin')
        } else{
          dispatch(fetchError(error?.response?.data?.message));}
      });

  };
};

// all function below are not in use currntly
export const deleteBulkUsers = (userIds, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/users/bulk-delete', { userIds })
      .then(response => {
        if (response.status === 200) {
          dispatch(fetchSuccess('Selected users were deleted successfully.'));
          dispatch({ type: DELETE_BULK_CONTRACTOR, payload: userIds });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const deleteUser = (userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete('/users', { params: { id: userId } })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('Selected user was deleted successfully.'));
          dispatch({ type: DELETE_CONTRACTOR, payload: userId });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const sentMailToUser = () => {
  return dispatch => {
    dispatch(fetchSuccess('Email has been sent to user successfully'));
  };
};

export const updateUser = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/users', user)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('Selected user was updated successfully.'));
          dispatch({ type: EDIT_CONTRACTOR, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const updatePriority = (data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(priorityContractor, data)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('Selected user was updated successfully.'));
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const getContractorDetails = (userId,status,callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(contractorDetails+userId+"/"+status)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: SET_CONTRACTOR_DETAILS, payload: data.data.data });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Contractor not found.') {
          dispatch(fetchError(error?.response?.data?.message));
          dispatch({ type: SET_CONTRACTOR_DETAILS, payload: [] });
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};