import { fetchError, fetchStart, fetchSuccess } from './Common';
// import axios from 'axios';
import axios from '../../services/auth/jwt/config';
import {
  // ADD_FMPROVIDER,
  DELETE_BULK_FMPROVIDER,
  DELETE_FMPROVIDER,
  EDIT_FMPROVIDER,
  GET_CUSTOMIZATION,
  GET_FMPROVIDER,
  SET_FMPROVIDER_DETAILS,
} from '../../@jumbo/constants/ActionTypes';
import {
  addFMProvider,
  addUser,
  getCustomizationList,
  listFMProvider,
  updateCustomizationApi,
  updateFMProvider,
  updateUserManagement,
  userList,
} from '../../@jumbo/constants/ApiConstatnt';
import { setAuthUser } from './Auth';
import { history } from 'redux/store';
import JWTAuth from 'services/auth/jwt';

// get FM provider list
export const getCustomization = (sortBy, sort, searchTerm, page, limit, filterOptions, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    const pc = window.localStorage.getItem('p_c');
    const sc = window.localStorage.getItem('s_c');
    const tc = window.localStorage.getItem('t_c');
    axios
      .get(getCustomizationList)
      .then(data => {
        if (data.status === 200) {
          window.localStorage.setItem('p_c', data?.data?.data?.primary_color);
          window.localStorage.setItem('s_c', data?.data?.data?.secondary_color);
          window.localStorage.setItem('t_c', data?.data?.data?.font_color);
          dispatch({ type: GET_CUSTOMIZATION, payload: data.data.data });
          if (
            pc !== data?.data?.data?.primary_color ||
            sc !== data?.data?.data?.secondary_color ||
            tc !== data?.data?.data?.font_color
          ) {
            window.location.reload();
          }
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
        dispatch(fetchSuccess());
      })
      .catch(error => {
        if (error?.response?.data?.message === 'FM users not found.') {
          if (callbackFun) callbackFun();
          dispatch(fetchError());
          dispatch({ type: GET_FMPROVIDER, payload: [] });
        } else if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

// set current user for view tab
export const setCurrentFMProvider = user => {
  return dispatch => {
    dispatch({ type: SET_FMPROVIDER_DETAILS, payload: user });
  };
};

// add new FM provider
export const updateCustomization = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(updateCustomizationApi, user)
      .then(data => {
        if (data) {
          dispatch(getCustomization());
          setTimeout(() => {
            dispatch(fetchSuccess('New customize setting updated successfully.'));
          }, 2000);
          setTimeout(() => {
            window.location.reload();
          }, 3000);

          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};
export const updateUserData = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(updateUserManagement, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('User updated successfully.'));
          }, 2000);

          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};
export const updateFMProviderStatus = (data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(updateFMProvider, data)
      .then(response => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('User status updated successfully.'));
          }, 2000);

          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

// all function below are not in use currntly
export const deleteBulkUsers = (userIds, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/users/bulk-delete', { userIds })
      .then(response => {
        if (response.status === 200) {
          dispatch(fetchSuccess('Selected users were deleted successfully.'));
          dispatch({ type: DELETE_BULK_FMPROVIDER, payload: userIds });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const deleteUser = (userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete('/users', { params: { id: userId } })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('Selected user was deleted successfully.'));
          dispatch({ type: DELETE_FMPROVIDER, payload: userId });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const sentMailToUser = () => {
  return dispatch => {
    dispatch(fetchSuccess('Email has been sent to user successfully'));
  };
};

export const updateUser = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/users', user)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('Selected user was updated successfully.'));
          dispatch({ type: EDIT_FMPROVIDER, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};
