import React from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import EmailIcon from '@material-ui/icons/Email';
import useStyles from './index.style';
import { Block, CheckCircleOutline } from '@material-ui/icons';
import FaceIcon from '@material-ui/icons/Face';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import CertificationTable from '../CertificationTable/index'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import BuildIcon from "@material-ui/icons/Build";
import RateReviewIcon from '@material-ui/icons/RateReview';
import moment from 'moment';

const UserDetailView = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const { currentUser } = useSelector(({ CONTRACTOR }) => CONTRACTOR);
  const { first_name, last_name, email, status, user_type, _id } = currentUser;

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.userInfoRoot}>
        <Box mr={3} display="flex" alignItems="center">
          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.titleRoot}>Contractor Details</Typography>
              <Box ml={1}></Box>
            </Box>
          </Box>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}></Box>
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box px={6} py={5}>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <PermIdentityIcon />
          <Box display="flex">
            <Typography className={classes.titleRoot1}>Vendor Name :</Typography>
            <Typography className={classes.titleRoot2}>{currentUser?.vendor_first_name + " " + currentUser?.vendor_last_name}</Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <FaceIcon />
          <Box display="flex">
            <Typography className={classes.titleRoot1}>First Name :</Typography>
            <Typography className={classes.titleRoot2}>{first_name}</Typography>
            <Typography className={classes.titleRoot1}>Last Name :</Typography>
            <Typography className={classes.titleRoot2}>{last_name}</Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <EmailIcon />
          <Typography className={classes.titleRoot1}>Email :</Typography>
          <Typography className={classes.titleRoot2}>{email}</Typography>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <BuildIcon />
          <Typography className={classes.titleRoot1}>Service Type :</Typography>
          <Typography className={classes.titleRoot2}>{currentUser?.service_type_details.map((item,index) => <li style={{ listStyleType: "none", marginLeft: "5px" }} key={index}>{item?.name}</li>)}</Typography>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }}>
          {currentUser?.status === 1 ? <CheckCircleOutline /> : <Block />}
          <Typography className={classes.titleRoot1}>Status (Active/Inactive) :</Typography>
          <Typography className={classes.titleRoot2}>{currentUser?.status === 1 ? 'Active' : 'Inactive'}</Typography>
        </Box>
        <Typography className={classes.titleRoot1} style={{ marginBottom: '20px' }}>Certifications :</Typography>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <CertificationTable />
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <RateReviewIcon />
          <Typography className={classes.titleRoot1}>Reviewer Notes :</Typography>
          <Typography className={classes.reviewerNote}>{currentUser?.reviewer?.notes}</Typography>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
            <AssignmentTurnedInIcon />
            <Typography className={classes.titleRoot1}>Status :</Typography>
            <Typography className={classes.titleRoot2}>{currentUser?.approved_status == 2 ? "Approved" : "Pending"}</Typography>
          </Box>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }} style={{ marginLeft: "80px" }}>
            <HourglassEmptyIcon />
            <Typography className={classes.titleRoot1}>Reviewed On :</Typography>
            <Typography className={classes.titleRoot2}>{moment(currentUser?.reviewer?.date * 1000).format('hh:mm A DD/MM/YYYY')}</Typography>
          </Box>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }} style={{ marginLeft: "80px" }}>
            <PermIdentityIcon />
            <Typography className={classes.titleRoot1}>Reviewed by :</Typography>
            <Typography className={classes.titleRoot2}>{currentUser?.reviewer_details?.first_name + " " + currentUser?.reviewer_details?.last_name}</Typography>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default UserDetailView;

UserDetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
