import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';
import { setAuthUser } from './Auth';
import { history } from 'redux/store';
import { GET_ASSIGNEDSITE_LIST, SET_CURRENT_ASSIGNED_SITE, } from '../../@jumbo/constants/ActionTypes';
import { AssignedSiteList, AssignedSiteDetail} from '../../@jumbo/constants/ApiConstatnt'

export const getAssigneSiteList = (sortBy, sort, searchTerm, page, limit, filterOptions, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      (AssignedSiteList,{
        params: { sortBy: `${sortBy}:${sort}`, search: searchTerm, page: page + 1, limit: limit,status: filterOptions[0] },
      })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_ASSIGNEDSITE_LIST, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          if (callbackFun) callbackFun();
          dispatch({ type: GET_ASSIGNEDSITE_LIST, payload: [] });
          dispatch(fetchError());
        }
      })
      .catch(error => {
       
        if(error?.response?.status===401 ){
          dispatch(setAuthUser(null));
          history.push('/signin')
        } 
        else {
        
          dispatch(fetchError(error?.response?.data?.message));
        }
      });

  };
};

// set current user for view tab
export const setCurrentAssignedSite = user => {
  return dispatch => {
    dispatch({ type: SET_CURRENT_ASSIGNED_SITE, payload: user });
  };
};
export const getAssigneSiteDetail = (id, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      (AssignedSiteDetail+id)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: SET_CURRENT_ASSIGNED_SITE, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if(error?.response?.status===401 ){
          dispatch(setAuthUser(null));
          history.push('/signin')
        } 
        else {
         
          dispatch(fetchError(error?.response?.data?.message));
        }
      });

  };
};

