import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Block, CheckCircleOutline, Visibility, Edit, Delete } from "@material-ui/icons";
import CmtDropdownMenu from "@coremat/CmtDropdownMenu";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { sentMailToUser } from "../../../../../../redux/actions/Users";
import { updateVendorStatus } from "../../../../../../redux/actions/VendorManagement"
import { getVendorManagement } from "../../../../../../redux/actions/VendorManagement";
import { Activate, Deactivate } from "@jumbo/constants/stringConstant";
import moment from 'moment';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import { setCurrentWorkOrder } from '../../../../../../redux/actions/WorkOrder';

const useStyles = makeStyles((theme) => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.text.primary,
    textOverflow: "ellipsis",
    maxWidth: "150px",
    padding:'16px',
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontWeight: 300,
  },
  workOrderID: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.text.primary,
    // textOverflow: "ellipsis",
    // maxWidth: "150px",
    padding:'16px',
    // display: "block",
    // overflow: "hidden",
    // whiteSpace: "nowrap",
    fontWeight: 300,
  },
  emailIDField: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.text.primary,
    textOverflow: "ellipsis",
    maxWidth: "500px",
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontWeight: 300,
  },
}));

const UserListRow = ({
  row,
  isSelected,
  onRowClick,
  onUserEdit,
  onUserDelete,
  onUserAdd,
  onUserView,
  getWorkOrderListParams,
  onUserUpdate,
}) => {
  const {
    orderBy,
    order,
    debouncedSearchTerm,
    page,
    rowsPerPage,
  } = getWorkOrderListParams;

  const classes = useStyles();
  const { authUser } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const getUserActions = user => {

    const actions = !authUser?.fm_module_access[9]?.is_edit ? 
    [{ action: "view", label: "View", icon: <Visibility /> }] 
    : [{ action: "view", label: "View", icon: <Visibility /> }];
    actions.push({ action: 'changeAssignedVendor', label: 'Change Assigned Vendor', icon: <Edit /> });
    // if (user.status === 1 && authUser?.fm_module_access[9]?.is_enable) {
    //   actions.push({ action: "suspend", label: Deactivate, icon: <Block /> });
    // } else if (authUser?.fm_module_access[9]?.is_enable) {
    //   actions.push({
    //     action: "activate",
    //     label: Activate,
    //     icon: <CheckCircleOutline />,
    //   });
    // }
    // actions.push({ action: 'delete', label: 'Delete', icon: <Delete /> });
    return actions;
  };
  const onUserMenuClick = (menu) => {
    if (menu.action === "view") {
      onUserView(row);
    } 
    // else if (menu.action === "edit") {
    //   onUserUpdate(row);
    // }
    else if (menu.action === "changeAssignedVendor") {
      // dispatch(setCurrentWorkOrder(row));
      onUserEdit(row);
    } 
    // else if (menu.action === "email") {
    //   dispatch(sentMailToUser());
    // }
    // else if (menu.action === "suspend") {
    //   dispatch(
    //     updateVendorStatus({ _id: row._id, status: 2 }, refreshContactList)
    //   );
    // } else if (menu.action === "activate") {
    //   dispatch(
    //     updateVendorStatus({ _id: row._id, status: 1 }, refreshContactList)
    //   );
    // } 
    // else if (menu.action === "delete") {
    //   onUserDelete(row);
    // }
  };

  // function refreshContactList() {
  //   dispatch(
  //     getVendorManagement(
  //       orderBy,
  //       order,
  //       debouncedSearchTerm,
  //       page,
  //       rowsPerPage,
  //     )
  //   );
  // }

  const labelId = `enhanced-table-checkbox-${row._id}`;
  const userActions = getUserActions(row);

  // console.log("row : ", row)
  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
      {/* <TableCell padding="checkbox"></TableCell> */}
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Typography className={classes.workOrderID} component="div" variant="h4">
          {row?.work_order_id}
        </Typography>
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {row?.service_type}
        </Typography>
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {row?.vendor_details?.first_name + " " + row?.vendor_details?.last_name}
        </Typography>
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {row?.contractor_details?.first_name + " " + row?.contractor_details?.last_name}
        </Typography>
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {row?.priority}
        </Typography>
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {moment(row?.due_date * 1000).format('DD/MM/YYYY')}
        </Typography>
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Typography className={classes.workOrderID} component="div" variant="h4">
          {row?.service_status === 1 ? 'Request Mode'
          : row?.service_status === 2 ? 'Vendor Assigned'
          : row?.service_status === 3 ? 'Service Started'
          : row?.service_status === 4 ? 'Service Completed'
          : row?.service_status === 5 ? 'Dispute Raised'
          : row?.service_status === 6 ? 'Approved By Tenant'
          : row?.service_status === 7 ? 'Approved By FM Admin'
          : row?.service_status === 8 ? 'Payment Sent'
          : row?.service_status === 9 ? 'Rejected' : 'Inactive' }
        </Typography>
      </TableCell>
      <TableCell align="center" onClick={(event) => event.stopPropagation()}>
        <CmtDropdownMenu
          items={userActions}
          onItemClick={onUserMenuClick}
          TriggerComponent={<Edit />}
        />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(UserListRow);
