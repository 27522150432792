import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import Common from './Common';
import Dashboard from './Dashboard';
import TaskList from './TaskList';
import MailApp from './MailApp';
import Chat from './Chat';
import ContactApp from './ContactApp';
import ProfileApp from './ProfileApp';
import WallApp from './WallApp';
import Auth from './Auth';
import Users from './Users';
import FMProviderManagement from './UserManagement';
import ServiceType from './ServiceType';
import ServiceActivities from './ServiceActivities';
import IssueManagement from './IssueManagement';
import SubAdminManagement from './SubAdminManegement';
import ContentManager from './ContentManager';
import ContactUsQuery from './ContactUsQuery';
import Setting from './Setting';
import UserManagement from './UserManagement';
import Customization from './Customization';
import ItemsList from './ItemsList';
import AssignItem from './AssignItem';
import ReturnItem from './ReturnItem';
import PlannedMaintenance from './PlannedMaintenance';
import AssetLocation from './AssetLocation';
import SelfHelpContent from './SelfHelpContent';
import VendorManagement from './VendorManagement';
import ContractorVendorManagement from './ContractorVendorManagement';
import BudgetManagement from './BudgetManagement';
import Location from './Location';
import Tenant from './Tenant';
import Induction from './Induction';
import ConfirmDocument from './ConfirmDocument';
import LocationsItems from './LocationsItems';
import ServiceRequest from './ServiceRequest';
import CertificateType from './CertificateType';
import AssignedSite from './AssignedSite';
import CallCenterServiceRequest from './CallCenterServiceRequest';
import AccountUserVendor from './AccountUserVendor';
import AccountDetails from './AccountDetails';
import PaymentToVendors from './PaymentToVendors';
import ThresholdAmount from './ThresholdAmount';
import WorkOrder from './WorkOrder';

export default history =>
  combineReducers({
    router: connectRouter(history),
    common: Common,
    taskList: TaskList,
    dashboard: Dashboard,
    mailApp: MailApp,
    chat: Chat,
    auth: Auth,
    contactApp: ContactApp,
    profileApp: ProfileApp,
    wallApp: WallApp,
    usersReducer: Users,
    ItemsList: ItemsList,
    FMPROVIDER: UserManagement,
    SERVICETYPE: ServiceType,
    SERVICEACTIVITIES: ServiceActivities,
    ISSUEMANAGEMENT: IssueManagement,
    VENDORMANAGEMENT: VendorManagement,
    BUDGETMANAGEMENT: BudgetManagement,
    SUBADMIN: SubAdminManagement,
    CMS: ContentManager,
    CONTACTUSQUERY: ContactUsQuery,
    SETTING: Setting,
    CUSTOMIZATION: Customization,
    ITEMSLISTMANAGEMENT: ItemsList,
    ASSIGNITEM: AssignItem,
    RETURNITEM: ReturnItem,
    PLANNEDMAINTENANCE: PlannedMaintenance,
    LocationsItems: LocationsItems,
    CONTRACTOR: ContractorVendorManagement,
    LOCATION: Location,
    TENANT: Tenant,
    ASSETLOCATION: AssetLocation,
    SELFHELPCONTENT: SelfHelpContent,
    INDUCTION: Induction,
    CONFIRMDOCUMENT: ConfirmDocument,
    SERVICEREQUEST: ServiceRequest,
    CERTIFICATETYPE: CertificateType,
    ASSIGNEDSITE: AssignedSite,
    CALLCENTER: CallCenterServiceRequest,
    ACCOUNTVENDORS: AccountUserVendor,
    ACCOUNTDEAILS: AccountDetails,
    PAYMENTTOVENDORS: PaymentToVendors,
    THRESHOLDAMOUNT: ThresholdAmount,
    WORKORDER: WorkOrder,
  });
