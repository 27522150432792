import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';
import {
  DELETE_BULK_ITEMSLIST,
  GET_ITEMSLIST,
  SET_ITEMSLIST_DETAILS,
  GET_ITEMSLISTDETAILS,
  GET_MANUFACTURERLIST,
  GET_WAREHOUSELIST
} from '../../@jumbo/constants/ActionTypes';
import {
  addItems,
  listItems,
  updateItems,
  updateItemsDetails,
  listitemsdetails,
  deleteitemsdetails,
  ManufacturerList,
  addItemWarehouse
} from '../../@jumbo/constants/ApiConstatnt';

// get Item list
export const getItemsList = (sortBy, sort, searchTerm, page, limit, filterOptions, serviceTypes, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(listItems, {
        params: { sortBy: `${sortBy}:${sort}`, search: searchTerm, page: page + 1, limit: limit, status: filterOptions[0], user: serviceTypes },
      })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_ITEMSLIST, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError());
          dispatch({ type: GET_ITEMSLIST, payload: [] });
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Item list not found.') {
          dispatch({ type: GET_ITEMSLIST, payload: [] });
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

// set current user for view tab
export const setItemList = user => {
  return dispatch => {
    dispatch({ type: SET_ITEMSLIST_DETAILS, payload: user });
  };
};

// add new FM provider
export const addNewItemsList = (user,AddToWarehouse, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(addItems, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('New Part added successfully.'));
          }, 2000);
          if(AddToWarehouse){
            dispatch({ type: GET_WAREHOUSELIST, payload: data.data.data });
          }
          if (callbackFun) callbackFun(data.data);

        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

// add to warehouse part
export const addPartToWarehouse= (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(addItemWarehouse, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Part added to inventory site successfully.'));
          }, 2000);
          
          if (callbackFun) callbackFun(data.data);

        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};






export const updateItemListStatus = (data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(updateItems, data)
      .then(response => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Part status updated successfully.'));
          }, 3000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const getItemListDetails = (userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(listitemsdetails + userId)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_ITEMSLISTDETAILS, payload: data.data.data });

          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Item List not found.') {
          dispatch(fetchError(error?.response?.data?.message));
          dispatch({ type: GET_ITEMSLISTDETAILS, payload: [] });
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};


export const getManufacturerList = (all,callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(ManufacturerList,{
        params: { limit:all},
      })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_MANUFACTURERLIST, payload: data.data.data });

          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Item List not found.') {
          dispatch(fetchError(error?.response?.data?.message));
          dispatch({ type: GET_ITEMSLISTDETAILS, payload: [] });
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};



export const setItemListDetails = user => {
  return dispatch => {
    dispatch({ type: SET_ITEMSLIST_DETAILS, payload: user });
  };
};

// all function below are not in use currntly
export const deleteBulkUsers = (userIds, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/users/bulk-delete', { userIds })
      .then(response => {
        if (response.status === 200) {
          dispatch(fetchSuccess('Selected users were deleted successfully.'));
          dispatch({ type: DELETE_BULK_ITEMSLIST, payload: userIds });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const deleteUser = (userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete(deleteitemsdetails + userId)
      .then(data => {
        if (data.status === 200) {
          setTimeout(() => {
            dispatch(fetchSuccess('Part deleted successfully.'));
          }, 2000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError());
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const sentMailToUser = () => {
  return dispatch => {
    dispatch(fetchSuccess('Email has been sent to user successfully'));
  };
};

export const updateItemList = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(updateItemsDetails, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess(' Part updated successfully.'));
          }, 3000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};
