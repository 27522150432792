import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '../../../../../@jumbo/components/Common/formElements/AppTextInput';
import Button from '@material-ui/core/Button';
import { requiredMessage } from '../../../../../@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { addNewItemsList, getItemsList, updateItemList } from '../../../../../redux/actions/ItemsList';
import { emptySpace, numberOnly, numberOnly2, stringOnly } from '../../../../../@jumbo/constants/ValidationRegex';
import { Typography } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import InputAdornment from '@material-ui/core/InputAdornment';
import NoRecordFound from '../NoRecordFound';
import { capitalizeFLetter } from '@jumbo/utils/commonHelper';
import AppSelectBox from '../../../../../@jumbo/components/Common/formElements/AppSelectBox';
import InputLabel from '@material-ui/core/InputLabel';
// import AddToWarehouse from './AddToWarehouse'
import Multiselect from 'multiselect-react-dropdown';
import Select from 'react-select';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  titleRoot: {
    marginBottom: '10px',
    fontSize: 16,
    color: theme.palette.text.primary,
    marginLeft: '3px',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.text.primary,
    },
  },
  serviceTypeList: {
    fontSize: 14,
    letterSpacing: 0.25,
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
  },
  typeList: {
    width: 535,
    fontSize: 14,
    height: 300,
    backgroundColor: theme.palette.background.default,
    overflow: 'auto',
  },
  imageButton: {
    fontSize: '10px',
    marginLeft: '5px',
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  imageRoot: {
    marginTop: '5%',
  },
  assignInventoryTitle: {
    minWidth: 175,
    padding: 0,
    paddingBottom: 5,
    paddingTop: 15,
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.text.primary,
    },
  },
  assignInventorySiteView: {
    zIndex: 99,
    minHeight: '40px',
    minWidth: '350px',
    fontSize: '18px',
    '& .chip': {
      background: `${theme.palette.primary.main} !important`,
    },
    '& .highlightOption': {
      background: `${theme.palette.primary.main} !important`,
    },
    '& .multiSelectContainer li': {
      '&:hover': {
        background: `${theme.palette.primary.main} !important`,
      },
    },
    '& li': {
      fontSize: '14px',
      '&:hover': {
        background: `${theme.palette.primary.main} !important`,
      },
    },
    '& input': {
      fontSize: '16px',
      paddingLeft: '5px',
    },
  },
  manufacturerSelect: {
    zIndex: 70,
    minHeight: '40px',
    minWidth: '350px',
    fontSize: '16px',
    '& .chip':{
      background:`${theme.palette.primary.main} !important`
    },
    '& .highlightOption':{
      background:`${theme.palette.primary.main} !important`
    },
    '& .multiSelectContainer li':{
        '&:hover': {
          background:`${theme.palette.primary.main} !important`,
       },   
    },
    '& li':{
      fontSize:'14px',
      '&:hover': {
        background:`${theme.palette.primary.main} !important`,
     },
    },
  '& input':{
    fontSize:'16px',
    paddingLeft:'5px',
    minHeight: '28px',
  }
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    '& .MuiFormLabel-root': {
      color: '#909090',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      color: theme.palette.text.primary,
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  errText: {
    color: 'red',
    marginLeft: '14px',
    marginRight: '14px',
    fontSize: '0.75rem',
    marginTop: '3px',
    textAlign: 'left',
    fontFamily: 'Normal',
    fontWeight: 400,
    lineHeight: 1.66,
  },
}));

const AddEditUser = ({ open, onCloseDialog, getSERVICETYPEParams, setOpenWareHouseDialog }) => {
  const classes = useStyles();
  const { currentUser, ManufacturerList, wareHouse } = useSelector(({ ITEMSLISTMANAGEMENT }) => ITEMSLISTMANAGEMENT);
  const { users } = useSelector(({ SERVICETYPE }) => SERVICETYPE);
  const { orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions } = getSERVICETYPEParams;
  const [itemName, setItemName] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState('');
  const [price, setPrice] = useState('');
  const [serviceTypeValue, setServiceTypeValue] = useState([]);
  const [firstNameError, setFirstNameError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [serviceTypeValueError, setServiceTypeValueError] = useState('');
  const [imageError, setImageError] = useState('');
  const [priceError, setPriceError] = useState('');
  const [status, setStatus] = useState(1);
  const [selectedServiceType, setSelectedServiceType] = useState([]);
  const [manufacturer, setManufacturer] = useState('');
  const [model, setModel] = useState('');
  const [make, setMake] = useState('');
  const [lifeExpectancy, setLifeExpectancy] = useState('');
  const [manufacturerError, setManufacturerError] = useState('');
  const [modelError, setModelError] = useState('');
  const [makeError, setMakeError] = useState('');
  const [lifeExpectancyError, setLifeExpectancyError] = useState('');
  const [openAdd, setOpenAdd] = useState(true);
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const p_color=window.localStorage.getItem('p_c') ?? '#FC8119'
  const s_color=window.localStorage.getItem('s_c') ?? '#F0511E'
  const t_color=window.localStorage.getItem('t_c') ?? '#3B1A45'
  console.log("manufacturer : ",manufacturer)

  const onFilterOptionClick1 = (e, option) => {
    e.stopPropagation();
    const checkData = selectedServiceType?.map(item => {
      if (item._id === option._id) {
        return item.select
          ? { name: item.name, _id: item._id, select: false }
          : { name: item.name, _id: item._id, select: true };
      } else {
        return item;
      }
    });

    setSelectedServiceType(checkData);
    if (currentUser) {
      const filterArray = checkData?.filter(type => {
        if (type.select == true) {
          return type._id;
        }
      });

      setServiceTypeValue(filterArray);
    }
    setServiceTypeValueError('');
  };

  const onFilterOptionClick = (e, option) => {
    e.stopPropagation();
    setServiceTypeValue(prevState => {
      if (prevState.includes(option._id)) {
        return prevState.filter(item => item !== option._id);
      } else {
        return [...prevState, option._id];
      }
    });
    setServiceTypeValueError('');
  };

  const dispatch = useDispatch();
  console.log('currentUser', currentUser);
  useEffect(() => {
    if (currentUser) {
      setItemName(currentUser?.name);
      setDescription(currentUser?.description);
      setImage(currentUser?.image);
      setPrice(currentUser?.charge);
      setServiceTypeValue(currentUser?.service_type);
      const filterSelectedServiceTypeArray = users?.data?.map(item => {
        let data = false;
        data = currentUser?.service_type?.find(service => (item._id === service ? true : false));
        return data ? { name: item.name, _id: item._id, select: true } : { name: item.name, _id: item._id, select: false };
      });
      setSelectedServiceType(filterSelectedServiceTypeArray);
      setStatus(currentUser?.status);
      setManufacturer(currentUser?.manufacturer_details?._id);
      setModel(currentUser?.model);
      setMake(currentUser?.make);
      setLifeExpectancy(currentUser?.life_expectancy);
    }
  }, [currentUser]);

  const onSubmitClick = AddToWarehouse => {
    if (!itemName || itemName.match(emptySpace)) {
      setFirstNameError(requiredMessage);
    }
    if (!description || description.match(emptySpace)) {
      setDescriptionError(requiredMessage);
    }
    if (serviceTypeValue.length == 0) {
      setServiceTypeValueError(requiredMessage);
    }
    if (!image) {
      setImageError(requiredMessage);
    }
    // const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
    if (!price.toString().match(numberOnly) || Number(price) < 0) {
      // if ( Number(price) < 0) {
      setPriceError('Enter positive number only.');
    }

    if (price && Number(price) <= 1) {
      setPriceError('Price value should not be 0 .');
    }

    if (!price) {
      setPriceError(requiredMessage);
    }
    if (!manufacturer[0]) {
      setManufacturerError(requiredMessage);
    }
    if (!model || model.match(emptySpace)) {
      setModelError(requiredMessage);
    }
    if (!make || make.match(emptySpace)) {
      setMakeError(requiredMessage);
    }
    // if (!lifeExpectancy || lifeExpectancy.match(emptySpace)) {
    //   setLifeExpectancyError(requiredMessage);
    // }

    if (!lifeExpectancy.toString().match(numberOnly2) || Number(lifeExpectancy < 0)) {
      setLifeExpectancyError('Enter positive number only.');
    }
    if (lifeExpectancy && Number(lifeExpectancy) <= 1) {
      setLifeExpectancyError('LifeExpectancy value should not be 0 .');
    }
    if (!lifeExpectancy) {
      setLifeExpectancyError(requiredMessage);
    }

    // if (!price.toString().match(numberOnly2) || Number(price) < 0) {
    //   setPriceError('Enter positive number only.');
    // }
    if (
      itemName &&
      !itemName.match(emptySpace) &&
      price &&
      price > 0 &&
      // !price.match(emptySpace) &&
      image &&
      description &&
      !description.match(emptySpace) &&
      serviceTypeValue.length &&
      image &&
      lifeExpectancy &&
      lifeExpectancy > 0 &&
      // !lifeExpectancy.match(emptySpace) &&
      make &&
      !make.match(emptySpace) &&
      model &&
      !model.match(emptySpace) &&
      manufacturer[0]
      // && !manufacturer.match(emptySpace)
    ) {
      onUserSave(AddToWarehouse);
    }
  };

  const onUserSave = AddToWarehouse => {
    if (currentUser) {
      if (!image.size) {
        setImage(null);
      }
      let newArray = serviceTypeValue?.map(item => {
        if (item.select == true) {
          return item._id;
        }
      });
      const nameCaps = capitalizeFLetter(itemName);
      const descriptionCaps = capitalizeFLetter(description);
      const formData = new FormData();
      formData.append('name', nameCaps);
      formData.append('itemImage', image?.size ? image : null);
      formData.append('description', descriptionCaps);
      formData.append('charge', price);
      formData.append(
        'service_type',
        newArray[0] == undefined ? serviceTypeValue?.map(data => data?._id).join(',') : newArray,
      );
      formData.append('status', status);
      formData.append('manufacturer_id', manufacturer);
      formData.append('model', model);
      formData.append('make', make);
      formData.append('life_expectancy', lifeExpectancy);
      formData.append('_id', currentUser?._id);

      dispatch(
        updateItemList(formData, () => {
          onCloseDialog();
          dispatch(getItemsList(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
        }),
      );
    } else {
      const nameCaps = capitalizeFLetter(itemName);
      const descriptionCaps = capitalizeFLetter(description);
      const formData = new FormData();
      formData.append('name', nameCaps);
      formData.append('itemImage', image);
      formData.append('description', descriptionCaps);
      formData.append('charge', price);
      formData.append('service_type', serviceTypeValue?.map(data => data?._id).join(','));
      formData.append('manufacturer_id', manufacturer);
      formData.append('model', model);
      formData.append('make', make);
      formData.append('life_expectancy', lifeExpectancy);
      formData.append('status', status);
      if (!AddToWarehouse) {
        dispatch(
          addNewItemsList(formData, false, () => {
            onCloseDialog();
            dispatch(getItemsList(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
          }),
        );
      } else {
        dispatch(
          addNewItemsList(formData, true, () => {
            onCloseDialog();
            setOpenWareHouseDialog(true);
            dispatch(getItemsList(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
          }),
        );
      }
    }
  };
  const onOpenClick = add => {
    onSubmitClick(add);
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>{currentUser ? 'Edit Part Details' : 'Add New Part'}</DialogTitle>
      <DialogContent dividers>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
          <GridContainer>
            <Grid item xs={12} sm={12}>
              <AppTextInput
                className={classes.root}
                fullWidth
                variant="outlined"
                label=" Part Name "
                value={itemName}
                onChange={e => {
                  setItemName(e.target.value);
                  setFirstNameError('');
                }}
                helperText={firstNameError}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <AppTextInput
                className={classes.root}
                fullWidth
                variant="outlined"
                label="Description"
                value={description}
                onChange={e => {
                  setDescription(e.target.value);
                  setDescriptionError('');
                }}
                helperText={descriptionError}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography className={classes.titleRoot}>Part Image</Typography>
              <Button variant="contained" component="label">
                <CloudUploadIcon />
                <p className={classes.imageButton}>{image?.size ? image?.name : 'Choose Image'}</p>
                <input
                  type="file"
                  hidden
                  onChange={event => {
                    setImage(event.target.files[0]);
                    setImageError('');
                  }}
                  accept="image/png, image/jpeg"
                />
              </Button>{' '}
              <br />
              {currentUser ? <img src={image} width="200px" className={classes.imageRoot} /> : ''}
              <p className={classes.errText} style={{ color: 'red' }}>
                {imageError}
              </p>
            </Grid>

            {/* {currentUser === null ? (
              <Grid item xs={12} sm={6}>
                <Typography className={classes.titleRoot}>Service Type</Typography>
                <Box className={classes.typeList}>
                  {users?.data?.length > 0 ? (
                    users?.data?.map((value, index) => {
                      const labelId = `transfer-list-all-item-${value}-label`;

                      return (
                        <ListItem
                          key={index}
                          role="listitem"
                          button
                          onClick={e => document.getElementById(value._id).click()}>
                          <ListItemIcon>
                            <Checkbox
                              onClick={e => onFilterOptionClick(e, value)}
                              id={value._id}
                              tabIndex={-1}
                              disableRipple
                            />
                          </ListItemIcon>
                          <ListItemText id={labelId} primary={`${value.name}`} className={classes.serviceTypeList} />
                        </ListItem>
                      );
                    })
                  ) : (
                    <NoRecordFound>{'There are no records found.'}</NoRecordFound>
                  )}
                </Box>
                <p
                  className="MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-marginDense"
                  style={{ color: 'red' }}>
                  {serviceTypeValueError}
                </p>
              </Grid>
            ) : (
              <Grid item xs={12} sm={6}>
                <Typography className={classes.titleRoot}> Service Type</Typography>
                <Box className={classes.typeList}>
                  {selectedServiceType?.map((value, index) => {
                    const labelId = `transfer-list-all-item-${value}-label`;

                    return (
                      <ListItem key={index} role="listitem" button onClick={e => document.getElementById(value._id).click()}>
                        <ListItemIcon>
                          <Checkbox
                            onClick={e => onFilterOptionClick1(e, value)}
                            checked={value.select}
                            tabIndex={-1}
                            disableRipple
                            id={value._id}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={`${value.name}`} className={classes.serviceTypeList} />
                      </ListItem>
                    );
                  })}
                </Box>
                <p className={classes.errText} style={{ color: 'red' }}>
                  {serviceTypeValueError}
                </p>
              </Grid>
            )} */}

            <Grid item xs={12} sm={12}>
              {/* <Box>
            <Box><DialogTitle className={classes.assignInventoryTitle}>Service Types</DialogTitle></Box> */}
              {/* <Box mt={1}> */}
              <Multiselect
                options={users?.data} // Options to display in the dropdown
                selectedValues={serviceTypeValue} // Preselected value to persist in dropdown
                onSelect={(selectedList, selectedItem) => {
                  setServiceTypeValue(selectedList);
                  setServiceTypeValueError('');
                }} // Function will trigger on select event
                onRemove={(selectedList, selectedItem) => setServiceTypeValue(selectedList)} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options
                placeholder="Service Type"
                className={classes.assignInventorySiteView}
              />
              <p className={classes.errText} style={{ color: 'red' }}>
                {serviceTypeValueError}
              </p>
              {/* </Box> */}
              {/* </Box> */}
            </Grid>

            <Grid item xs={12} sm={12}>
              <AppTextInput
                className={classes.root}
                fullWidth
                InputProps={{
                  inputProps: { min: 0 },
                  startAdornment: price ? <InputAdornment position="start">$</InputAdornment> : '',
                }}
                variant="outlined"
                label="Part Price"
                value={price}
                onKeyPress={event => {
                  if (['-', '+', 'e', '/', '*', ' '].includes(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={e => {
                  setPrice(e.target.value);
                  setPriceError('');
                }}
                helperText={priceError}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
            <Typography className={classes.titleRoot}>Manufacturer</Typography>
              {/* <InputLabel
                id="demo-simple-select-label"
                style={{ marginBottom: '1%', marginLeft: 'auto', fontWeight: '700' }}>
                Manufacturer
              </InputLabel> */}

                      <Select
                      // className="style"
                      className={classes.manufacturerSelect}
                      placeholder="Manufacturer"
                      value={ManufacturerList?.data?.map(item=>{
                        return{ value:item?._id, label:item?.name}
                      }).filter(obj => obj?.value === manufacturer)}
                      onChange={e=>{setManufacturer(e?.value ?? ''); setManufacturerError('')}}
                      isClearable={isClearable}
                      isSearchable={isSearchable}
                      options={ManufacturerList?.data?.map(item=>{
                        return{ value:item?._id, label:item?.name}
                      })}
                      // styles={{
                      //   menuList: {
                      //     maxWidth: '50px',
                      //   },
                      // }}
                      styles={{
                        // option: (baseStyles, state) => ({
                        //   // ...baseStyles,
                        //   maxWidth: '350px',
                        //   overflow: 'hidden',
                        // }),
                        menuList: (baseStyles, state) => ({
                          // ...baseStyles,
                          maxWidth: '600px',
                          overflowX: 'hidden',
                          maxHeight: '200px',
                        }),
                      }}
                      theme={(theme) => ({
                        // zIndex: 70,
                        ...theme,
                        borderRadius: 0,
                        colors: {
                        ...theme.colors,
                          text: t_color,
                          // primary25: s_color,
                          primary: p_color,
                        },
                      })}
                    />
                <p className={classes.errText} style={{ color: 'red' }}>{manufacturerError}</p>

              {/* <Multiselect
                options={ManufacturerList?.data} // Options to display in the dropdown
                selectedValues={manufacturer} // Preselected value to persist in dropdown
                selectionLimit={1}
                onSelect={(selectedList, selectedItem)=>{setManufacturer(selectedList); setManufacturerError("")}} // Function will trigger on select event
                onRemove={(selectedList, selectedItem)=>setManufacturer(selectedList)} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options
                placeholder="Manufacturer"
                className={classes.assignInventorySiteView}
              /> */}

              {/* <AppSelectBox
                fullWidth
                
                data={ManufacturerList?.data}
                label="Manufacturer"
                valueKey="_id"
                variant="outlined"
                labelKey="name"
                value={manufacturer}
                onChange={e => {
                  setManufacturer(prev => e.target.value);
                  setManufacturerError('');
                }}
                helperText={manufacturerError}
              /> */}
            </Grid>
            <Grid item xs={12} sm={12}>
              <AppTextInput
                className={classes.root}
                fullWidth
                variant="outlined"
                label="Model"
                InputProps={{
                  inputProps: { min: 0, maxLength: 40 },
                }}
                value={model}
                onChange={e => {
                  setModel(e.target.value);
                  setModelError('');
                }}
                helperText={modelError}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <AppTextInput
                className={classes.root}
                fullWidth
                variant="outlined"
                label="Make"
                InputProps={{
                  inputProps: { maxLength: 40 },
                }}
                value={make}
                onChange={e => {
                  setMake(e.target.value);
                  setMakeError('');
                }}
                helperText={makeError}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <AppTextInput
                // type="number"
                className={classes.root}
                fullWidth
                InputProps={{
                  inputProps: { min: 0, maxLength: 2 },
                  // startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                variant="outlined"
                label="Life Expectancy (Months)"
                value={lifeExpectancy}
                onKeyPress={event => {
                  if (['-', '+', 'e', '.', ' '].includes(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={e => {
                  setLifeExpectancy(e.target.value);
                  setLifeExpectancyError('');
                }}
                helperText={lifeExpectancyError}
              />
            </Grid>
          </GridContainer>
        </Box>
        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button onClick={onCloseDialog} variant="outlined">
            Cancel
          </Button>

          <Box ml={2}>
            <Button variant="contained" color="primary" style={{ minWidth: '100px' }} onClick={() => onSubmitClick(false)}>
              {currentUser ? 'Update' : 'Add'}
            </Button>
          </Box>
          {!currentUser ? (
            <Box ml={2}>
              <Button variant="contained" color="primary" style={{ minWidth: '100px' }} onClick={() => onOpenClick(true)}>
                {'Add To Inventory Site'}
              </Button>
            </Box>
          ) : null}

          {/* <AddToWarehouse open={openModule}/> */}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditUser;

AddEditUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
