import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import useStyles from "./index.style";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import moment from "moment";
import { history } from "redux/store";
import BuildIcon from "@material-ui/icons/Build";
import DescriptionIcon from "@material-ui/icons/Description";
import AlarmOnIcon from "@material-ui/icons/AlarmOn";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import ClearIcon from "@material-ui/icons/Clear";
import IconButton from "@material-ui/core/IconButton";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import { priorityColor } from "@jumbo/constants/stringConstant";
import EventIcon from "@material-ui/icons/Event";
import { useDispatch } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import { setCurrentServiceRequset, setEdittServiceRequset,getServiceRequest } from '../../../../redux/actions/ServiceRequest';
import BusinessIcon from '@material-ui/icons/Business';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
// import BuildIcon from '@material-ui/icons/Build';
import FaceIcon from '@material-ui/icons/Face';
export default function ResponsiveDialog({
  setOpen,
  open,
  events,
  serviceRequset,
  onUserView
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const dispatch = useDispatch();
  console.log("even",serviceRequset.item.vendor_details)
  const handleOpen = () => {
    setOpen(false);
    serviceRequset.openFunction(serviceRequset?._id)
    // history.push(`/service-requests/${serviceRequset?.id}`);
  };

  const onCloseDialog = () => {
    setOpen(false);
  };
  const handelOpenAssign =()=>{

    if (serviceRequset?.item?.vendor_details === null) {
      dispatch(setCurrentServiceRequset(serviceRequset?.item));
      dispatch(
        getServiceRequest('name', 'asc', '', 0, -1, '', () => {
          
        }),
      );
      serviceRequset.clickButtonAssignvender(true)
    setOpen(false);
    } else {
      dispatch(setEdittServiceRequset(serviceRequset?.item));
      dispatch(
        getServiceRequest('name', 'asc', '', 0, -1, '', () => {
          
        }),
      );
      serviceRequset.clickButtonAssignvender(true)
    setOpen(false);
    }

    // serviceRequset.clickButtonAssignvender(true)
    // setOpen(false);
  }
  return (
    <Box onClick={(event)=>{event.stopPropagation();event.nativeEvent.stopImmediatePropagation();}}>
      <Dialog
        onClick={(event)=>{event.stopPropagation();event.nativeEvent.stopImmediatePropagation();}}
        className={classes.dialogRoot}
        fullScreen={fullScreen}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="responsive-dialog-title"
        aria-describedby="alert-dialog-slide-description"

      >
        
        <Box className={classes.userInfoRoot}>  
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Service Request Detail
        </DialogTitle>
          <Box ml={55} mt={-2} display="flex" alignItems="center" >
            {/* <Box ml={1}></Box> */}
            <Box mb={1}>
              <IconButton onClick={onCloseDialog}>
                <ClearIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>

        <Divider />
        <DialogContent>
          <DialogContentText>
            <Box px={6} py={5} mt={-2}>
              

              <Box display="flex" ml={-5} alignItems="center" mb={{ xs: 4, sm: 7 }}  onClick={(event)=>{event.stopPropagation();event.nativeEvent.stopImmediatePropagation();}}>
                <BusinessIcon />
                <Box display="flex" ml={2}>
                  <Typography className={classes.titleRoot1}>
                    Job Location :
                  </Typography>

                  <Typography className={classes.titleRoot2}>
                    {serviceRequset?.location}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }} ml={-5}>
                <PriorityHighIcon />
                <Box display="flex" ml={2}>
                  <Typography className={classes.titleRoot1}>
                    Priority:{" "}
                  </Typography>
                  <Typography className={classes.titleRoot2}>
                    {serviceRequset?.priority}
                  </Typography>
                </Box>
              </Box>

              
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }} ml={-5}>
                  <BuildIcon />
                  <Box display="flex" ml={2}>
                    <Typography className={classes.titleRoot1}>
                      Service Type :
                    </Typography>

                    <Typography className={classes.DateField}>
                      {serviceRequset?.serviceType}
                    </Typography>
                  </Box>
                </Box>
              

              <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }} ml={-5}>
                <FaceIcon />
                <Box display="flex" ml={2}>
                  <Typography className={classes.titleRoot1}>
                    Tenant  :{" "}
                  </Typography>
                  <Typography className={classes.titleRoot2}>
                    {serviceRequset?.tenantDetails
                     }{serviceRequset?.tenantDetailsLastName}
                  </Typography>
                </Box>
              </Box>

              <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }} ml={-5}>
                  <Box display="flex" mr={2}>
                    <DescriptionIcon />
                    <Typography className={classes.titleRoot11}>
                      Description :
                    </Typography>
                    <Typography className={classes.DescriptionNote}>
                      {serviceRequset?.desc}
                    </Typography>
                  </Box>
                </Box>

              
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }} ml={-5}>
                  <Box display="flex" mr={2}>
                    {/* <DescriptionIcon /> */}
                    <Typography className={classes.titleRoot11}>
                    <Button onClick={handelOpenAssign} variant="outlined" color="primary">
                  {serviceRequset?.item?.vendor_details === null ? 'Assign' : 'Change Assigned Vendor'}
                  {/* Assign Vender */}
                </Button>
                    </Typography>
                    {/* <Typography className={classes.DescriptionNote}>
                      {serviceRequset?.desc}
                    </Typography> */}
                  </Box>
                </Box>
              
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box alignItems="center" mb={4} mr={3}>
            <Button
              onClick={handleOpen}
              color="primary"
              autoFocus
              variant="contained"
            >
              Go to Details
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
