import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Block, CheckCircleOutline, Visibility, Edit, Delete } from '@material-ui/icons';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { sentMailToUser } from 'redux/actions/Users';
import { Activate, Deactivate } from '@jumbo/constants/stringConstant';
import { getTenantList, updateTenantStatus } from 'redux/actions/Tenant';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    fontWeight:300,
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    maxWidth: '150px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  taxtSize:{
    fontWeight:300
  }
}));

const UserListRow = ({
  row,
  isSelected,
  onRowClick,
  onUserEdit,
  onUserDelete,
  onUserView,
  getFMProviderParams,
  onUserUpdate,
  indexId,
}) => {
  const { id, orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions } = getFMProviderParams;
  const { authUser } = useSelector(({ auth }) => auth);
  const classes = useStyles();
  const dispatch = useDispatch();
  const getUserActions = user => {
    const actions = !authUser?.fm_module_access[4]?.is_edit
      ? [{ action: 'view', label: 'View', icon: <Visibility /> }]
      : [
          { action: 'view', label: 'View', icon: <Visibility /> },
          { action: 'edit', label: 'Edit', icon: <Edit /> }
        ];

    if (user.status === 1 && authUser?.fm_module_access[4]?.is_enable) {
      actions.push({ action: 'suspend', label: Deactivate, icon: <Block /> });
    } else if (authUser?.fm_module_access[4]?.is_enable) {
      actions.push({
        action: 'activate',
        label: Activate,
        icon: <CheckCircleOutline />,
      });
    }
    if(authUser?.fm_module_access[4]?.is_edit){
      actions.push({ action: "delete", label: "Delete", icon: <Delete /> })
    }
    return actions;
  };
  const onUserMenuClick = menu => {
    if (menu.action === 'view') {
      onUserView(row);
    }  else if (menu.action === 'edit') {
      onUserEdit(row);
    } else if (menu.action === 'email') {
      dispatch(sentMailToUser());
    } else if (menu.action === 'suspend') {
      dispatch(updateTenantStatus({ _id: row._id, status: 2 }, refreshContactList));
    } else if (menu.action === 'activate') {
      dispatch(updateTenantStatus({ _id: row._id, status: 1 }, refreshContactList));
    } else if (menu.action === 'delete') {
      onUserDelete(row);
    }
  };
  function refreshContactList() {
    dispatch(getTenantList(id, orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
  }
  const labelId = `enhanced-table-checkbox-${row._id}`;

  const userActions = getUserActions(row);

  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
      <TableCell padding="checkbox"></TableCell>
      <TableCell>{Number(indexId)+1}</TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {row?.first_name}
        </Typography>
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {row?.last_name}
        </Typography>
      </TableCell>
      <TableCell className={classes.taxtSize}>{row?.email}</TableCell>
      
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Typography className={classes.titleRoot} component="div" variant="h4">
          { !row?.lease_expiry_date?"-": moment(row?.lease_expiry_date * 1000).format('DD/MM/YYYY ')}
        </Typography>
      </TableCell>
      <TableCell className={classes.taxtSize}>{row?.status === 1 ? `Active` : 'Inactive'}</TableCell>

      <TableCell align="center" onClick={event => event.stopPropagation()}>
        <CmtDropdownMenu items={userActions} onItemClick={onUserMenuClick} TriggerComponent={<Edit />} />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(UserListRow);
