import { lighten, makeStyles } from '@material-ui/core/styles';
import { deepOrange, green } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
    '& .MuiDialog-paperWidthSm': {
      width: '100%',
      maxWidth: '500px',
    },
  },
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(4),
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  container: {
    maxHeight: 415,
  },
  table: {
    minWidth: 750,
    '& .MuiTableCell-head':{
      fontWeight:600
          }
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  titleRoot1:{
    fontWeight:"600",
    whiteSpace: 'nowrap',
    // marginLeft:8,
    '&.MuiTypography-body1':{
      fontSize: 14,
    }
    
  },
  titleRoot11:{
    fontWeight:"600",
    whiteSpace: 'nowrap',
    marginLeft:7,
    // marginLeft:8,
    '&.MuiTypography-body1':{
      fontSize: 14,
    }
    
  },
  titleRoot2:{
    fontWeight:"300",
    marginLeft:"5px",
    fontSize: 14,
    textOverflow: 'ellipsis',
    maxWidth: '180px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    
  },
  DateField: {
    marginLeft:2,
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.text.primary,
    fontWeight: 300,
    whiteSpace: "nowrap",
    maxWidth: "150px",
  },
  square: {
    color: theme.palette.getContrastText(deepOrange[500]),
    // backgroundColor: deepOrange[500],
  },
  eventTitle:{
    color: theme.palette.text.primary,
    textOverflow: "ellipsis",
    maxWidth: "122.5px",
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    marginTop: "4px", marginLeft: "4px",
    
  },
  userInfoRoot: {
    // borderBottom: `1px solid ${theme.palette.borderColor.main}`,
    // padding: '20px 24px',
    padding: '8px 15px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  DescriptionNote: {
    letterSpacing: 0.25,
    maxHeight:'130px',
    overflow: 'auto',
    wordWrap: 'break-word',
    fontWeight: '300',
    marginLeft: '5px',
    fontSize: 14,
    textOverflow: 'ellipsis',
    wordBreak: 'break-all',

  },
}));

export default useStyles;
