import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';
import {
  DELETE_BULK_SERVICETYPE,
  DELETE_SERVICETYPE,
  EDIT_SERVICETYPE,
  GET_ACCOUNTDETAILS,
  SET_SERVICETYPE_DETAILS,
  GET_ACCOUNTDETAILSLIST,
} from '../../@jumbo/constants/ActionTypes';
import {
  getTokenApi,
  updateTokenApi,
  updateServiceTypeStatus,
  createTokenApi,
  getKeyList,
} from '../../@jumbo/constants/ApiConstatnt';
import JWTAuth from 'services/auth/jwt';

export const getAccountDetails = (check) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(getTokenApi)
      .then(data => {
        if (data.status === 200) {
if(check){
  dispatch(fetchSuccess('Stripe key document downloaded successfully.'));
}else{
  dispatch(fetchSuccess());
}
          
          dispatch({ type: GET_ACCOUNTDETAILS, payload: data.data.data });
        } else {
          dispatch(fetchError());
          dispatch({ type: GET_ACCOUNTDETAILS, payload: [] });
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'FM Provider not found.') {
          dispatch(fetchError(error?.response?.data?.message));
          dispatch({ type: GET_ACCOUNTDETAILS, payload: [] });
        } else {
          dispatch(fetchError());
        }
      });
  };
};

export const getAccountDetailsUnderstading = () => {
  return dispatch => {
    dispatch(fetchStart());

    axios
      .get(getKeyList)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_ACCOUNTDETAILSLIST, payload: data.data.data });
        } else {
          dispatch(fetchError());
          dispatch({ type: GET_ACCOUNTDETAILSLIST, payload: [] });
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'FM Provider not found.') {
          dispatch(fetchError(error?.response?.data?.message));
          dispatch({ type: GET_ACCOUNTDETAILSLIST, payload: [] });
        } else {
          dispatch(fetchError());
        }
      });
  };
};

export const setCurrentSERVICETYPE = user => {
  return dispatch => {
    dispatch({ type: SET_SERVICETYPE_DETAILS, payload: user });
  };
};

export const createNewToken = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(createTokenApi, user)
      .then(data => {
        console.log('add data', data);
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Stripe key added successfully.'));
          }, 2000);
          dispatch(JWTAuth.getAuthUser());
          // dispatch({ type:  ADD_FMPROVIDER, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError());
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const sentMailToUser = () => {
  return dispatch => {
    dispatch(fetchSuccess('Email has been sent to user successfully'));
  };
};

export const updateSecretToken = (key, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(updateTokenApi, key)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Stripe key updated successfully.'));
          }, 3000);

          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const updateUser = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/users', user)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('Selected Service type updated successfully .'));
          dispatch({ type: EDIT_SERVICETYPE, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const updateSERVICETYPEStatus = (data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(updateServiceTypeStatus, data)
      .then(response => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Service Type status updated successfully.'));
          }, 2000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};

export const deleteBulkUsers = (userIds, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/users/bulk-delete', { userIds })
      .then(response => {
        if (response.status === 200) {
          dispatch(fetchSuccess('Selected  service type were deleted successfully.'));
          dispatch({ type: DELETE_BULK_SERVICETYPE, payload: userIds });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const deleteUser = (userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete('/users', { params: { id: userId } })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('Selected user was deleted successfully.'));
          dispatch({ type: DELETE_SERVICETYPE, payload: userId });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};
