import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '../../../../../@jumbo/components/Common/formElements/AppTextInput';
import Button from '@material-ui/core/Button';
import { requiredMessage } from '../../../../../@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { addNewItemsList, getItemsList, updateItemList, addPartToWarehouse } from '../../../../../redux/actions/ItemsList';
// import { emptySpace, numberOnly, stringOnly } from '../../../../../@jumbo/constants/ValidationRegex';

import AppSelectBox from '../../../../../@jumbo/components/Common/formElements/AppSelectBox';
import InputLabel from '@material-ui/core/InputLabel';
import axios from 'services/auth/jwt/config';
import { setAuthUser } from 'redux/actions/Auth';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import { history } from 'redux/store';
import { numberOnly, numberOnly2, emptySpace } from '@jumbo/constants/ValidationRegex';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
    // width: 535,
  },

  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.text.primary,
    },
  },
  serviceTypeList: {
    fontSize: 14,
    letterSpacing: 0.25,
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
  },
  typeList: {
    width: 535,
    fontSize: 14,
    height: 300,
    backgroundColor: theme.palette.background.default,
    overflow: 'auto',
  },
  imageButton: {
    fontSize: '10px',
    marginLeft: '5px',
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  imageRoot: {
    marginTop: '5%',
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    '& .MuiFormLabel-root': {
      color: '#909090',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      color: theme.palette.text.primary,
    },
  },
  appSelectRoot: {
    "& .MuiFormLabel-root": {
      color: '#c7c3bf'
    },
    '& .MuiFormLabel-root': {
      color: '#909090',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      color: theme.palette.text.primary,
    },
    
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
}));

const AddToWarehouse = ({ open1, onCloseDialog1, getWarehouseParams }) => {
  const classes = useStyles();
  const { currentUser, wareHouse } = useSelector(({ ITEMSLISTMANAGEMENT }) => ITEMSLISTMANAGEMENT);
  // const { users } = useSelector(({ SERVICETYPE }) => SERVICETYPE);
  const { orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions } = getWarehouseParams;
  const [wareHouseData, setWareHouseData] = useState([]);
  const [itemName, setItemName] = useState('');
  const [threshold, setThreshold] = useState('');
  const [warehouseTypeError, setWarehouseTypeError] = useState('');
  const [warehouseValue, setWarehouseValue] = useState('');
  const [serialNumber, setSerialNumber] = useState(['']);
  const [tError, setTError] = useState('');
  const [qError, setQError] = useState('');
  const [serialNumberError, setSerialNumberError] = useState('');
  const [quantity, setQuantity] = useState('');
  const dispatch = useDispatch();

  // const onSubmitClick = () => {
  //   onCloseDialog()
  // };
  useEffect(() => {
    setQuantity(serialNumber.length);
  }, [serialNumber.length]);

  let FindMatchSerial = serialNumber.filter((el, index) => serialNumber.indexOf(el) !== index)
  // console.log("match",FindMatchSerial)

  const onSubmitClick = () => {
    // const phoneNumbers = phones.filter(item => item.phone.trim());
    // if (!itemName) {
    //   setItemError(requiredMessage);
    // }
    if (!warehouseValue) {
      setWarehouseTypeError(requiredMessage);
    }
    if (!quantity) {
      setQError(requiredMessage);
    }

    if (!quantity.toString().match(numberOnly2) || Number(quantity) < 0) {
      setQError('Enter positive number only.');
    }
    if (!threshold.toString().match(numberOnly2) || Number(threshold) < 0) {
      setTError('Enter positive number only.');
    }
    if (threshold && Number(threshold) <= 1) {
      setTError('Threshold value should not be 0 .');
    }
    if (!threshold) {
      setTError(requiredMessage);
    }
    
    
    if(FindMatchSerial.length >= 1)
    {
      setSerialNumberError("Please enter unique serial number.")
    }
    if (serialNumber.includes('') || serialNumber.includes(emptySpace)) {
      setSerialNumberError(requiredMessage);
    }
    
    
    if (warehouseValue&&
      quantity &&
      Number(quantity) >= 0 &&
      serialNumber &&
      !serialNumber.includes('')&&
      threshold &&
      threshold.toString().match(numberOnly) &&
      threshold > 0 &&
      FindMatchSerial.length <1
    ) {
      onUserSave();
    }
  };

  const onUserSave = () => {
    const userDetail = {
      item_id: wareHouse?._id,
      warehouse_data: [
        {
          warehouse_id: warehouseValue,
          serial_number: serialNumber,
          quantity: Number(quantity),
          threshold: threshold,
        },
      ],
    };

    dispatch(
      addPartToWarehouse(userDetail, () => {
        onCloseDialog1();
      }),
    );
  };

  async function fetchData() {
    let response = await axios('locations/list', {
      params: { sortBy: 'first_name:asc', search: '', page: 1, limit: -1, status: 1, type: 2 },
    }).catch(error => {
      if (error?.response?.status === 401) {
        dispatch(setAuthUser(null));
        history.push('/signin');
      } else {
        dispatch(fetchError());
      }
    });
    let user = await response?.data;
    setWareHouseData(response?.data?.data);

    dispatch(fetchSuccess());
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handelAddTextInput = () => {
    setSerialNumber([...serialNumber, '']);
  };

  const handelserialNumberSet = (e, index) => {
    const { value } = e.target;
    // if(serialNumber.includes(value)){
    //   setSerialNumberError("Please enter uniq serial number.")
    // }
    // else{
      const list = [...serialNumber];
      list[index] = value;
      setSerialNumber(list);
    // }
    
  };

  const handleremove = index => {
    const list = [...serialNumber];
    list.splice(index, 1);
    setSerialNumber(list);
  };

  return (
    <Dialog open={open1} onClose={onCloseDialog1} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>{'Add To Inventory Site'}</DialogTitle>
      <DialogContent dividers>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
          <GridContainer>
            <Grid item xs={12} sm={12} className={classes.root}>
              {/* <InputLabel
                id="demo-simple-select-label"
                style={{ marginBottom: '1%', marginLeft: 'auto', fontWeight: '700' }}>
                Inventory Site
              </InputLabel> */}
              <AppSelectBox
                fullWidth
                data={wareHouseData?.data}
                valueKey="_id"
                variant="outlined"
                label="Inventory Site"
                labelKey="name"
                value={warehouseValue}
                onChange={e => {
                  setWarehouseValue(prev => e.target.value);
                  setWarehouseTypeError('');
                }}
                helperText={warehouseTypeError}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <AppTextInput
              
                // className={classes.appSelectRoot}
                fullWidth
                variant="outlined"
                label=" Part"
                value={wareHouse?.name}
                disabled={true}
                onChange={e => {
                  setItemName(e.target.value);
                  // setFirstNameError('');
                }}
                // helperText={firstNameError}
              />
            </Grid>

            <Grid item xs={12} sm={12} >
              <AppTextInput
              // <InputLabel
                // className={classes.appSelectRoot}
                disabled={true}
                // pattern="[1-9]"
                InputProps={{
                  pattern: '[1-9]',
                  inputProps: { min: 0, pattern: '[1-9]' },
                }}
                fullWidth
                // type='number'
                variant="outlined"
                label="Quantity"
                // value={quantity}

                value={serialNumber.length}
                onChange={e => {
                  setQuantity(e.target.value);
                  setQError('');
                }}
                onKeyPress={event => {
                  if (['-', '+', 'e', '.'].includes(event.key)) {
                    event.preventDefault();
                  }
                }}
                onPaste={e => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={e => {
                  e.preventDefault();
                  return false;
                }}
                // helperText={qError}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <AppTextInput
                className={classes.root}
                // pattern="[1-9]"
                InputProps={{
                  pattern: '[1-9]',
                  inputProps: { min: 0, pattern: '[1-9]' },
                }}
                fullWidth
                // type='number'
                variant="outlined"
                label="Set Threshold Quantity"
                value={threshold}
                onChange={e => {
                  setThreshold(e.target.value);
                  setTError('');
                }}
                onKeyPress={event => {
                  if (['-', '+', 'e', '.'].includes(event.key)) {
                    event.preventDefault();
                  }
                }}
                onPaste={e => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={e => {
                  e.preventDefault();
                  return false;
                }}
                helperText={tError}
              />
            </Grid>
            {/* <Grid item xs={12} sm={12}>
              <Button variant="contained" color="primary" onClick={handelAddTextInput}>
                Add More
              </Button>
            </Grid> */}
            {serialNumber.map((item, i) => {
              return (
                <>
                  <Grid item xs={10} sm={10} display="flex">
                    <AppTextInput
                      className={classes.root}
                      fullWidth
                      variant="outlined"
                      label="Serial Number"
                      InputProps={{
                        inputProps: {maxLength: 24 },
                        // startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      onKeyPress={event => {
                        if (['-', '+', 'e', '.', '/', '*' ,' '].includes(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      value={item}
                      onChange={e => {
                        // setSerialNumber(e.target.value);
                        setSerialNumberError('');
                        handelserialNumberSet(e, i);
                      }}
                      helperText={ FindMatchSerial.includes(item) && !item.match(emptySpace) && item ? "Please enter unique serial number." 
                      : (!item && serialNumberError) || (item.match(emptySpace) && serialNumberError) ? "This field is required!" : ''}
                      // helperText={!item || item.match(emptySpace) || FindMatchSerial.includes(item) || Number(item) <= 1? serialNumberError : '' }
                    />
                  </Grid>
                  <Grid item xs={2} sm={2}>
                    {serialNumber.length !== 1 && (
                      <IconButton aria-label="delete" onClick={() => handleremove(i)}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Grid>
                </>
              );
            })}

            <Grid item xs={12} sm={12}>
              <Button variant="outlined" onClick={handelAddTextInput}>
                + Add More
              </Button>
            </Grid>
          </GridContainer>
        </Box>
        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button onClick={onCloseDialog1} variant="outlined">
            Cancel
          </Button>

          <Box ml={2}>
            <Button variant="contained" color="primary" style={{ minWidth: '100px' }} onClick={onSubmitClick}>
              {currentUser ? 'Update' : 'Add'}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddToWarehouse;

// AddEditUser.prototype = {
//   open: PropTypes.bool.isRequired,
//   onCloseDialog: PropTypes.func,
// };
