import {
  ADD_CONFIRMDOCUMENT,
  DELETE_BULK_CONFIRMDOCUMENT,
  DELETE_CONFIRMDOCUMENT,
  EDIT_CONFIRMDOCUMENT,
  GET_CONFIRMDOCUMENT,
  SET_CONFIRMDOCUMENT_DETAILS,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  users: [],
  currentUser: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CONFIRMDOCUMENT: {
      return {
        ...state,
        users: action.payload,
      };
    }
    case SET_CONFIRMDOCUMENT_DETAILS: {
      return {
        ...state,
        currentUser: action.payload,
      };
    }
    case ADD_CONFIRMDOCUMENT: {
      return {
        ...state,
        users: [action.payload, ...state.users],
      };
    }
    case EDIT_CONFIRMDOCUMENT: {
      return {
        ...state,
        users: state.users.map(user => (user.id === action.payload.id ? action.payload : user)),
      };
    }
    case DELETE_CONFIRMDOCUMENT: {
      return {
        ...state,
        users: state.users.filter(user => user.id !== action.payload),
      };
    }
    case DELETE_BULK_CONFIRMDOCUMENT: {
      return {
        ...state,
        users: state.users.filter(user => !action.payload.includes(user.id)),
      };
    }
    default:
      return state;
  }
};
