import React , { useState }from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import CmtAvatar from '@coremat/CmtAvatar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { useSelector } from 'react-redux';
import CmtList from '@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import useStyles from './index.style';
import { Block, CheckCircleOutline } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import FaceIcon from '@material-ui/icons/Face';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import UserAccessModule from '../UserAccessModule/index'
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// import ApprovedContractor from '../'
// import ContractorVerification from '../VendorDetails/ContractorVerification/index'

const UserDetailView = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { currentUser } = useSelector(({ FMPROVIDER }) => FMPROVIDER);
  const { first_name, last_name, email, status,user_type } = currentUser;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.userInfoRoot}>
        <Box ml={5} mt={-2} display="flex" alignItems="center">
        <Box mb={3} component="h3" color="primary.dark">
          Vendor Detail
        </Box>
          <Box ml={1}></Box>
          <Box mr={3}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box px={6} py={5}>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <FaceIcon />
          <Box ml={5}>
            <Box
              ml={0}
              component="p"
              style={{
                fontSize: 14,
                letterSpacing: 0.25,

                textOverflow: 'ellipsis',
                maxWidth: '350px',
                display: 'block',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}>
              First name : {first_name}
            </Box>{' '}
            <Box
              ml={0}
              mt={5}
              component="p" 
              style={{
                fontSize: 14,
                letterSpacing: 0.25,

                textOverflow: 'ellipsis',
                maxWidth: '350px',
                display: 'block',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}>
              Last name : {last_name}
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <EmailIcon />
          <Box ml={5} color="primary" component="p" className="pointer">
            Email : {email}
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <EmailIcon />
          <Box ml={5} color="primary" component="p" className="pointer">
            Service Type : {"Static Service Type"};
          </Box>
        </Box>
       
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }}>
          {status === 1 ? <CheckCircleOutline /> : <Block />}
          <Box ml={5}>Status: {status === 1 ? 'Active' : 'Inactive'}</Box>
        </Box>
      
      <Box mb={3} component="h3" color="primary.dark">
          Contact Detail
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <EmailIcon />
          <Box ml={5} color="primary" component="p" className="pointer">
            Contact No. : {"8888888888"}
          </Box>
        </Box>
        <Box mb={3} component="h3" color="primary.dark">
          Insurance Detail
        </Box>
        
        {/* <Paper className={classes.root} >
      <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" >
        <Tab label="Approved Contractors" />
        <Tab label="Contractor Verification Requests" />
      </Tabs>
    </Paper>
    {value === 0 ? <ApprovedContractor/>  :null}
{value === 1 ? <ContractorVerification/>  :null} */}
        </Box>
    </Dialog>
  );
};

export default UserDetailView;

UserDetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
